import { Injectable } from '@angular/core';
import { ITaskHistory, ITransformer, IActivity } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class HistoryActivityTransformer implements ITransformer {
  /** Convert task history to activity */
  public transform(history: ITaskHistory): IActivity {
    return {
      reported_at: history.date_created,
      created_at: history.date_created,
      item: history
    };
  }
}
