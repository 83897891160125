import { Injectable } from '@angular/core';
import { VariantsClient } from '../../clients/cp-api/variants/variants.client';
import { TasksService } from '../tasks/tasks.service';
import TaskDueTransformer from '../tasks/transformers/task-due/task-due.transformer';
import SearchFilterBuilder from '../tasks/search-filter-builder/search-filter.builder';
import { sortByMultiple } from '../../../utils/sort.util';
import { HelperService } from '../../helper.service';
import { IChecklistVariant, IChecklistVariantShort, ITask, ITasksPage, IPagination, IPage, IBaseObject, IUnit, IRound } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class VariantsService {
  constructor(
    private readonly variantsClt: VariantsClient,
    private readonly tasksSrv: TasksService,
    private taskDueTrans: TaskDueTransformer,
    private readonly helperService: HelperService
  ) {
  }

  /** Check object type */
  private static isUnitObject(object: IBaseObject): boolean {
    return object.hasOwnProperty('unit_type');
  }

  /** Apply pagination for request */
  private static applyPagination(
    request: SearchFilterBuilder,
    pagination: IPagination
  ): SearchFilterBuilder {
    if (Number.isInteger(pagination.page)) {
      request.setCurrentPage(pagination.page);
    }

    if (Number.isInteger(pagination.size)) {
      request.setPageSize(pagination.size);
    }

    return request;
  }

  /** Get unit/round variants */
  public getObjectVariants(
    object: IRound | IUnit,
    pagination: IPagination = {}
  ): Promise<IPage<IChecklistVariantShort>> {
    return VariantsService.isUnitObject(object)
      ? this.getUnitVariants(object.uid, pagination)
      : this.getRoundVariants(object.uid, pagination);
  }

  /**
  * Sort items by due value
  * @param {Array<any>} variants
  * @return {Array<any>}
  */
  public sortByDue(variants: Array<any>): Array<any> {
    variants.forEach(variant => {
      variant._due_sort = this.getDueSortValue(variant);
    });

    return sortByMultiple(variants, { _due_sort: 'asc', name: 'asc' });
  }

  /**
   * Get value that can be used to sort all variants
   * @param variant
   */
  private getDueSortValue(variant) {
    const due_data = this.getVariatDueData(variant);
        switch (due_data?.due_unit) {
            case 'h':
              return due_data?.due / 24;
            case 's':
            case 'sec':
              return (due_data?.due || 0) / (60 * 60 * 24);
        }
    return due_data?.due;
  }
  /**
   * Get due data of variant
   * @param variant
   */
  private getVariatDueData(variant) {
    if (variant?.type === this.helperService.protocol.checklists.variants.SMART_INTERVAL && variant.due_data?.smart_intervals) {
      // Filter due and display 1, if no due, get first
      const smartIntervals = variant.due_data.smart_intervals.filter((smartIntervalDue: IChecklistVariant) => smartIntervalDue.due_data.is_due);
      const smartInterval = smartIntervals.length ? smartIntervals[0] : variant.due_data.smart_intervals[0];
      return {
        due: smartInterval?.due_data?.due || 0,
        due_unit: smartInterval?.due_data?.due_unit || 's'
      }
    }
    return variant?.due_data;
  }

  /** Get unit variants */
  private getUnitVariants(
    unitUid: string,
    pagination: IPagination = {}
  ): Promise<IPage<IChecklistVariantShort>> {
    return VariantsService.applyPagination(
      this.tasksSrv.requireUnitVariants(unitUid),
      pagination
    )
      .fetch()
      .then(page => this.fetchTaskVariants(page));
  }

  /** Get round variants */
  private getRoundVariants(
    roundUid: string,
    pagination: IPagination = {}
  ): Promise<IPage<IChecklistVariantShort>> {
    return VariantsService.applyPagination(
      this.tasksSrv.requireRoundVariants(roundUid),
      pagination
    )
      .fetch()
      .then(page => this.fetchTaskVariants(page));
  }

  /** Fetch checklist-variants of the tasks list */
  private fetchTaskVariants(
    tasksPage: ITasksPage
  ): Promise<IPage<IChecklistVariantShort>> {
    const tasks = tasksPage.data;
    const variantTasks = new Map<string, ITask>(
      tasks.map(task => [task.origin.id, task])
    );
    const variantUids = new Set<string>(
      tasks.map(task => task.origin.id)
    );
    const objectUids = Array.from(new Set(tasks.map(task => task.objectable.id)));

    return this.variantsClt
      .getVariantsByUids([...variantTasks.keys()], objectUids) // Fetch variants of the tasks
      .then(variants => variants.filter(variant => variantUids.has(variant.uid)))
      .then(variants => ({
        data: variants.map(variant => this.addDueData(variant, variantTasks.get(variant.uid))),
        current_page: tasksPage.current_page,
        page_size: tasksPage.page_size,
        total: tasksPage.total
      }));
  }

  /** Add due-data to the variant */
  private addDueData(
    variant: IChecklistVariantShort,
    task: ITask
  ): IChecklistVariantShort {
    return Object.assign(
      variant,
      {
        due_data: this.taskDueTrans.transform(task)
      }
    );
  }
}
