import { SelectDropdownComponent } from './select-dropdown.component';

export class SelectDropdownService {
  constructor(
    private selectDropdown: SelectDropdownComponent
  ) { }

  /**
   * Check two options are equal or no
   * @param a
   * @param b
   * @return {boolean}
   */
  static defaultOptionCompare(a, b) {
    return a.id && b.id && a.id === b.id;
  }

  /**
   * Create an option from item
   * @param item
   * @param {any} groupItem
   * @return {object}
   */
  public createOption(item, groupItem = null) {
    let title = '';
    if (typeof this.selectDropdown.bindLabelFunc === 'function') {
      title = this.selectDropdown.bindLabelFunc(item);
    } else if (this.selectDropdown.bindLabel) {
      title = item[this.selectDropdown.bindLabel];
    } else {
      title = item;
    }

    const id = (item[this.selectDropdown.bindId] ? item[this.selectDropdown.bindId] + '-' : '') + title;

    return {
      id: id,
      title: title,
      disabled: false,
      selected: false,
      children: false,
      hidden: false,
      parent: !!groupItem,
      value: item
    };
  }
}
