import { Injectable } from '@angular/core';
import { IAsset } from '@interfaces';
import { AssetTypeEnum } from '@enums';

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  /**
   * Make assets by parameters
   *
   * @param assetsData Response data
   */
  public makeAssets(assetsData: object[]): IAsset[] {
    return (assetsData || []).map((data: { data?: string }) => {
      const asset: IAsset = Object.assign(
        {
          id: null,
          key: null,
          url: null,
          type: null,
          sourceUrl: null,
          mov_url: null,
          mpeg_url: null,
          video_thumbnail_url: null,
        },
        data
      );
      asset.url = asset.url || data.data || null;
      asset.type = asset.type || AssetTypeEnum.IMAGE;

      return asset;
    });
  }
}
