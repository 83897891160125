import { Component, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  public isDisabled = false;

  private value;

  @Input() toggleLabel;

  @Output() changed = new EventEmitter();

  constructor() {}

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Triggers by clicking on label
   */
  public onLabelClick() {
    if (this.isDisabled) {
      return;
    }
    this.toggleValue = !this.toggleValue;
    this.changed.emit(this.toggleValue);
  }

  /**
   * get accessor for toggleValue
   * @return {any}
   */
  get toggleValue(): any {
    return this.value;
  }

  /**
   * set accessor for toggleValue
   * @param v
   */
  set toggleValue(v: any) {
    if (v !== this.value) {
      this.value = v;
      this._onChange(v);
    }
  }

  /**
   * Triggers when ion-toggle is changed
   */
  public onToggleChange() {
    this.changed.emit(this.toggleValue);
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};
}
