import { Injectable } from '@angular/core';
import SearchFilterBuilder from './search-filter-builder/search-filter.builder';

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  constructor(
    /** Tasks provider */
    private readonly tasksSearch: SearchFilterBuilder,
  ) {
  }

  /** Prepare request of unit checklist variants tasks */
  public requireUnitVariants(unitUid: string): SearchFilterBuilder {
    return this.tasksSearch
      .create()
      .setShowInactive(true)
      .enableUnits()
      .getDueTimeSection()
      .withNoDueDate()
      .getParent()
      .getUnitSection()
      .withChecks()
      .setObjects([unitUid])
      .getParent();
  }

  /** Prepare request of round checklist variants  tasks */
  public requireRoundVariants(roundUid: string): SearchFilterBuilder {
    return this.tasksSearch
      .create()
      .setShowInactive(true)
      .enableRounds()
      .getDueTimeSection()
      .withNoDueDate()
      .getParent()
      .getRoundSection()
      .withChecks()
      .setObjects([roundUid])
      .getParent();
  }

  /** Prepare request of service intervals */
  public requireServices(unitUid: string): SearchFilterBuilder {
    return this.tasksSearch
      .create()
      .enableUnits()
      .getUnitSection()
      .withServices()
      .setObjects([unitUid])
      .getParent();
  }

  /** Prepare request of inspections and certificates intervals */
  public requireInspections(unitUid: string, pageSize?: number): SearchFilterBuilder {
    return this.tasksSearch
      .create()
      .setPageSize(pageSize ?? this.tasksSearch.getPageSize())
      .enableUnits()
      .getUnitSection()
      .withInspections()
      .setObjects([unitUid])
      .getParent();
  }

  /** Prepare request of service intervals */
  public requireByWorkplaces(workplaceUids: string[], sections: string[] = []): SearchFilterBuilder {
    return this.tasksSearch
      .create()
      .setShowInactive(true)
      .getDueTimeSection()
       // Setup no due date tasks
      .withNoDueDate()
      .getParent()
      // Units
      .enableUnits()
      .getUnitSection()
      .withChecks()
      .withServices()
      .withInspections()
      .withDeviations()
      .setWorkplaces(workplaceUids)
      .setSections(sections)
      // Rounds
      .getParent()
      .enableRounds()
      .getRoundSection()
      .withChecks()
      .withInspections()
      .withDeviations()
      .setWorkplaces(workplaceUids)
      .setSections(sections)
      .getParent();
  }
}
