import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NotificationFormatUtil {
  private textFormats = [
    {
      placeholder: ':User',
      valueKey: 'user'
    },
    {
      placeholder: ':Resource',
      valueKey: 'resource'
    },
    {
      placeholder: ':Group',
      valueKey: 'group'
    },
    {
      placeholder: ':expiration',
      valueKey: 'expiration'
    },
    {
      placeholder: ':object',
      valueKey: 'object'
    }
  ];

  public static getNotificationIcon(type): string {
    if (!type) {
      return '';
    }

    let iconClass;
    switch (type) {
      case 'App\\Notifications\\MentionedUser':
        iconClass = 'fal fa-comment';
        break;
      case 'App\\Notifications\\AssignedUser':
        iconClass = 'fal fa-hand-point-right';
        break;
      case 'App\\Notifications\\DueNotification':
      case 'App\\Notifications\\PerformAvailableNotification':
        iconClass = 'fal fa-clock';
        break;
      case 'App\\Notifications\\ResourceUpdatedNotification':
        iconClass = 'fas fa-eye';
        break;
    }

    return iconClass;
  }

  constructor() {}

  public formatText(notification: object): string {
    return this.compute(notification);
  }

  private compute(params): string {
    const notificationData = params;
    const values = notificationData.values;
    const formatters = this.textFormats;
    let text = notificationData.body;

    if (text === undefined || text === null || values === undefined) {
      return '';
    }

    formatters.forEach(formatter => {
      text = this.format(text, formatter, values);
    });

    return text;
  }

  private format(text, formatter, values): string {
    return text.replace(formatter.placeholder, values[formatter.valueKey]);
  }
}
