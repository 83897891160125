import { Injectable } from '@angular/core';
import { MicroserviceService } from './microservice.service';
import { AppFeatureEnum } from '@enums';
import { AuthService } from './auth.service';
import { IFleetOptimizationMS } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class FleetOptimizationService {
  /** @const URL path patters. **/
  private readonly PATHS = {
    FLEETMANAGEMENT_URL: 'fleet-management/fleetmanagement',
    LEADERBOARD: '/leaderboard/',
  };

  /**
   * @param microService service.
   */
  constructor(private microService: MicroserviceService, private authService: AuthService) {}

  /**
   * Checks if FleetOptimization is enabled in checkproof
   * @public
   */
  public fleetOptimizationEnabled(): boolean {
    const allowedFeatures = this.authService.getAllowedFeatures();
    const companySettings = this.authService.companySettings;
    return companySettings.allowFleetOptimization && allowedFeatures[AppFeatureEnum.FLEET_OPTIMIZATION];
  }

  /**
   * Fetch fleet ranking by unitId
   */
  public getFleetRankingByUnitId(companyUid, unitUid): Promise<IFleetOptimizationMS> {
    return this.microService.get(this.PATHS.FLEETMANAGEMENT_URL + this.PATHS.LEADERBOARD + companyUid + '/' + unitUid);
  }
}
