import { Pipe, PipeTransform } from '@angular/core';
import { LangService, ResponsiblesClient } from '@services';

@Pipe({
  name: 'userNameByUid'
})
export class UserNameByUidPipe implements PipeTransform {
  constructor(
    private readonly responsiblesClt: ResponsiblesClient,
    private readonly langSrv: LangService,
  ) {
  }

  /** Get full user name by UID */
  public async transform(uid: string): Promise<string> {
    return this.responsiblesClt
      .getUser(uid)
      .then(user => user ? `${user.firstname} ${user.lastname}` : this.langSrv.t('users.removedUser'));
  }
}
