import { Component, OnInit, ViewChild, ElementRef, EventEmitter, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NavParams, ModalController, IonicModule } from '@ionic/angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HelperService } from '@services';

import { SwiperOptions } from 'swiper/types';
import { ZoomOptions } from 'swiper/types/modules/zoom';
import { SwiperContainer } from 'swiper/element';
import { SwiperDirective } from '../../directive/swiper.directive';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from '../video-player/video-player.component';

@Component({
  selector: 'app-image-modal',
  templateUrl: './image-modal.component.html',
  styleUrls: ['./image-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, SwiperDirective, IonicModule, VideoPlayerComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageModalComponent implements OnInit {
  public readonly ASSETS_TYPES = {
    VIDEO: this.helperService.protocol?.assets.types.VIDEO,
    IMAGE: this.helperService.protocol?.assets.types.IMAGE,
  };

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  public assets: any = [];
  public swiperOptions: SwiperOptions;
  initialSlide: number;
  public onClose = new EventEmitter();

  constructor(private navParams: NavParams, private modalController: ModalController, private sanitizer: DomSanitizer, private helperService: HelperService) {}
  ngOnInit() {
    // Values sent from image-viewer.services
    this.assets = this.navParams.get('assets');
    this.initialSlide = this.navParams.get('initialSlide');
    this.swiperOptions = {
      initialSlide: this.initialSlide,
      centeredSlides: true,
      simulateTouch: true,
      pagination: {
        clickable: true,
      },
      slidesPerView: 1,
      zoom: {
        maxRatio: 5,
        minRation: 1,
      } as ZoomOptions,
    };
  }

  /**
   * Returns a converted safe url from raw url
   *
   * @param url
   * @return SafeUrl
   */
  public getUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  /**
   * Close modal
   */
  public close() {
    this.modalController.dismiss();
    this.onClose.emit();
  }
}
