import { Injectable } from '@angular/core';
import { ObjectableUtil } from '../utils/objectable.util';
import { UserAppPreferencesService } from './user-app-preferences.service';
import { EventsService } from './events.service';
import pick from 'lodash/pick';
import isEqual from 'lodash/isEqual';
import { FavoritesTypeEnum, GroupTypeEnum } from '@enums';
import { IFavorite, IChecklistVariant } from '@interfaces';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { CheckTask } from '../models/tasks/checkTask';
import { sortBy } from '../utils/sort.util';
import { isSameDay, parseISO } from 'date-fns';
import { IntervalTriggerService } from './interval-trigger.service';
import { RouteService } from './route.service';
import { ICheckConnection } from '@app/interfaces/due-data/check-connection.interface';

@Injectable({
  providedIn: 'root',
})
export class CheckService {
  public objectVariantsFavoritesType = 'object-check-variant';
  private sortHighNumber = 3075840000;

  constructor(
    private userAppPreferencesService: UserAppPreferencesService,
    private events: EventsService,
    private apiService: ApiService,
    private helperService: HelperService,
    private intervalTriggerService: IntervalTriggerService,
    private routeSrv: RouteService,
  ) {}

  /**
   * Get favorite for object-variant
   * @param variant
   * @param objectable
   * @return Favorite
   */
  public getObjectVariantFavorite(variant, objectable): IFavorite {
    let section = null;
    if (objectable.section && objectable.section.length > 0) {
      section = pick(objectable.section[0], ['id', 'name']);
    }

    const type = ObjectableUtil.isUnit(objectable) ? 'unit' : 'round';
    const id = type + '-' + objectable.id + '-' + variant.id;

    return {
      id: id,
      variant: pick(variant, ['id', 'name']),
      objectable: pick(objectable, ['id', 'name', 'unit_type']),
      workplace: pick(objectable.workplace, ['id', 'name']),
      section: section,
      type: FavoritesTypeEnum.CHECK_VARIANT,
    };
  }

  /**
   * Update object-variant favorite (if its different)
   * @param variant
   * @param objectable
   */
  public async updateObjectVariantFavorite(variant, objectable) {
    const favorites = await this.userAppPreferencesService.getFavorites(
      this.objectVariantsFavoritesType
    );
    if (!favorites) {
      return;
    }

    const favoriteObject: IFavorite = this.getObjectVariantFavorite(
      variant,
      objectable
    );

    const currentFav = favorites.find((fav) => {
      return fav.id === favoriteObject.id;
    });

    if (!currentFav) {
      return;
    }

    if (isEqual(currentFav, favoriteObject)) {
      return;
    }

    await this.userAppPreferencesService.updateFavoriteItem(
      this.objectVariantsFavoritesType,
      favoriteObject,
      () => {
        this.events.publish(EventsService.FAVORITES_CHECKS_CHANGED);
      }
    );
  }

  public loadChecks(options): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      options.page = 1;
      this.apiService
        .get(this.routeSrv.PATHS.user.me.tasks.checks, options)
        .then((response) => {
          if (!response || !response.data) {
            return resolve(response);
          }

          if (response.last_page < 2) {
            return resolve(response);
          }

          const promises = [];

          const maxPages = response.last_page;
          for (let i = 2; i <= maxPages; i++) {
            const chunkOptions = Object.assign({}, options);
            chunkOptions.page = i;
            promises.push(
              this.apiService.get(this.routeSrv.PATHS.user.me.tasks.checks, chunkOptions)
            );
          }

          Promise.all(promises)
            .then((pages) => {
              pages.forEach((page) => {
                if (page.data && page.data.length) {
                  response.data = response.data.concat(page.data);
                  response.total += page.total;
                  response.totalActive += page.totalActive;
                }
              });

              resolve(response);
            })
            .catch(reject);
        })
        .catch(reject);
    });
  }

  /**
   * Process array of checks
   * @param checks
   */
  public async processChecks(checks) {
    if (!checks || !checks.length) {
      return [];
    }

    const checksOngoing = await this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.checksongoing.id, { id: '' }), {
      with: ['user'],
      appends: ['variant_due_data'],
      per_page: 0,
    });

    let checksOverdue = [];
    let checksUpcoming = [];
    const checksConnected = [];
    const checksTriggered = [];

    checks.forEach((checkRes) => {
      const check = new CheckTask(checkRes);
      const isCheckForUnit = check.objectable.hasOwnProperty('unit_type');
      check.dueSort = this.getCheckDueSort(check.variant);
      check.ongoing = checksOngoing.find((item) => {
        const isOngoingForUnit = item.objectable_type.indexOf('Unit') !== -1;
        return (
          check.variant.id === item.checklist_variant_id &&
          isCheckForUnit === isOngoingForUnit &&
          check.objectable.id === item.objectable_id
        );
      });

      if (
        check.variant.type ===
        this.helperService.protocol.checklists.variants.CONNECTED
      ) {
        this.flattenConnectedVariants(check, checksConnected, checksUpcoming);
        return;
      }

      if (
          [
            this.helperService.protocol.checklists.variants.ODOMETER_RANGE,
            this.helperService.protocol.checklists.variants.MANUAL_TRIGGER,
          ].includes(check.variant.type)
      ) {
        this.flattenTriggeredVariants(check, checksTriggered);
        return;
      }

      // Set check due values for smart interval check
      if (
          check.variant.type ===
          this.helperService.protocol.checklists.variants.SMART_INTERVAL
      ) {
        this.setSmartIntervalDatetimeCheck(check);
      }

      if (check.variant.due_data && check.variant.due_data.is_due) {
        checksOverdue.push(check);
      } else {
        checksUpcoming.push(check);
      }
    });

    if (checksOverdue.length) {
      checksOverdue = sortBy(checksOverdue, 'dueSort');
      checksOverdue.forEach((check) => (check.group = GroupTypeEnum.OVERDUE));
    }
    if (checksUpcoming.length) {
      checksUpcoming = sortBy(checksUpcoming, 'dueSort');
      checksUpcoming.forEach((check) => (check.group = GroupTypeEnum.UPCOMING));
    }
    if (checksConnected.length) {
      checksConnected
        .filter((c) => c.variant?.due_data?.active)
        .forEach((check) => (check.group = GroupTypeEnum.TODAY));
    }

    const allChecks = [...checksOverdue, ...checksUpcoming, ...checksConnected, ...checksTriggered];
    allChecks.forEach((check) => {
      if (check.variant && this.isVariantWhenAtToday(check.variant)) {
        check.group = GroupTypeEnum.TODAY;
      }

      if (
        check?.variant?.due_data?.no_check_done &&
        (check.variant.type === this.helperService.protocol.checklists.variants.DAILY ||
         check.variant.type === this.helperService.protocol.checklists.variants.WEEKLY ||
         check.variant.type === this.helperService.protocol.checklists.variants.MONTHLY ||
         check.variant.type === this.helperService.protocol.checklists.variants.CUSTOM)
      ) {
        check.group = GroupTypeEnum.TODAY;
        check.isFixed = true;
      }

      if ( check.variant.type === this.helperService.protocol.checklists.variants.NO_INTERVAL) {
        check.group = GroupTypeEnum.TODAY;
        check.isFixed = true;
        check.noInterval = true;
      }

      if ( check.isTodayInterval ) {
        check.group = GroupTypeEnum.TODAY;
      }

      if (
          check.variant.type === this.helperService.protocol.checklists.variants.ODOMETER_RANGE ||
          (!check.group && check.variant.type === this.helperService.protocol.checklists.variants.MANUAL_TRIGGER)
      ) {
        check.group = GroupTypeEnum.OVERDUE;
      }
    });

    return allChecks;
  }

  /**
   * Extract all connected checks and convert them to independent check variant
   */
  private flattenConnectedVariants(check, variantsList, checksUpcoming) {
    if (!check.variant.due_data?.check_connections) {
      return;
    }

    check.variant.due_data.check_connections.forEach((check_connection: ICheckConnection) => {
      const connectedVariant = pick(check.variant, [
        'id',
        'type',
        'name',
        'objectable',
      ]);
      connectedVariant.due_data = {
        active: true,
        due: null,
        check_connection: check_connection,
      };

      variantsList.push(
        new CheckTask({
          objectable: check.objectable,
          variant: connectedVariant,
        })
      );
    });


    check.variant.due_data.connection_occasions?.forEach((connection_occasions) => {
      const connectedVariant = pick(check.variant, [
        'id',
        'type',
        'name',
        'objectable',
      ]);
      connectedVariant.due_data = connection_occasions;

      checksUpcoming.push(
          new CheckTask({
            objectable: check.objectable,
            variant: connectedVariant,
            dueSort: this.getSortValue(
                connection_occasions.due,
                connection_occasions.is_due,
                'no_interval',
                connectedVariant.order,
            ),
          })
      );
    });
  }

  /**
   * Extract all triggered checks and convert them to independent check variant
   */
  private flattenTriggeredVariants(check, variantsList) {
    if (!check.variant.due_data?.interval_due_triggers) {
      return;
    }

    check.variant.due_data.interval_due_triggers.forEach((trigger) => {
      const triggeredVariant = pick(check.variant, [
        'id',
        'type',
        'name',
        'objectable',
      ]);

      triggeredVariant.due_data = {
        active: true,
        is_due: true,
        due: trigger.due,
        due_unit: trigger.odometer_data?.suffix,
        extra_text: this.intervalTriggerService.getTriggerTypeOdometerText(trigger),
        odometer_data: trigger.odometer_data,
        time_data: trigger.time_data,
        interval_due_trigger_id: trigger.id,
      };

      variantsList.push(
          new CheckTask({
            objectable: check.objectable,
            variant: triggeredVariant,
          })
      );
    });
  }

  /**
   * get sort value
   * @param value
   * @param is_due
   * @param type
   * @param order
   * @return {number|*}
   */
  private getSortValue(value, is_due, type = '', order = 0) {
    value = (is_due ? -1 : 1) * Math.abs(value);

    // odometer intervals
    if (type === 'odometer') {
      if (is_due) {
        // Overdue after Time intervals
        value = value / this.sortHighNumber;
      } else {
        // Upcoming after Time intervals
        value = value + this.sortHighNumber;
      }
    } else if (type === 'nocheck') {
      // No Checks after Odometers
      value = this.sortHighNumber * 2 + order;
    } else if (type === 'nointerval') {
      // No Interval after No Checks
      value = this.sortHighNumber * 3 + order;
    }
    return value;
  }

  /**
   * Get proper sort value for check variant
   * @param variant
   * @return {any}
   */
  private getCheckDueSort(variant) {
    let type = '';

    if (
      variant.type ===
      this.helperService.protocol.checklists.variants.NO_INTERVAL
    ) {
      type = 'nointerval';
    } else if (variant.due_data.no_check_done === true) {
      type = 'nocheck';
    } else if (
      variant.type === this.helperService.protocol.checklists.variants.ODOMETER
    ) {
      type = 'odometer';
    }

    return this.getSortValue(
      variant.due_data.due,
      variant.due_data.is_due,
      type,
      variant.order
    );
  }

  /**
   * Use smart interval datetime variant to define isTodayInterval and dueSort
   */
  private setSmartIntervalDatetimeCheck(check) {
    if (!check.variant.due_data?.smart_intervals) {
      return;
    }

    let isNoneDatetimeOverdued = false;

    check.variant.due_data.smart_intervals.forEach((smartIntervalVariant) => {
      // Define isTodayInterval
      if (smartIntervalVariant.type !== this.helperService.protocol.checklists.variants.ODOMETER) {
        const tmpCheckTask = new CheckTask({
          variant: smartIntervalVariant,
        });

        check.isTodayInterval = tmpCheckTask.isTodayInterval;

        // Check again if due and is Today
        if (typeof check.isTodayInterval === 'undefined') {
          check.isTodayInterval = this.isVariantWhenAtToday(smartIntervalVariant);
        }
      } else {
        // Collect odometer is_due for isTodayInterval override
        isNoneDatetimeOverdued = smartIntervalVariant.due_data.is_due;
      }

      // Define lowest dueSort
      const tmpDueSort = this.getCheckDueSort(smartIntervalVariant);
      if (!isNaN(tmpDueSort) && tmpDueSort > check.dueSort) {
        check.dueSort = tmpDueSort;
      }
    });

    // Odometer is_due will cancel isTodayInterval if it's due
    if (isNoneDatetimeOverdued) {
      check.isTodayInterval = false;
    }
  }

  /**
   * Check if when at is today
   * @param variant
   * @return boolean
   */
  private isVariantWhenAtToday(variant: IChecklistVariant): boolean {
    const whenAt = variant.due_data?.time_data?.when_at;
    return (whenAt && isSameDay(parseISO(whenAt), new Date()));
  }
}
