import { IPage, IExtTask } from '@interfaces';
import TaskModel from './task.model';

export default class TasksPageModel implements IPage<IExtTask> {
  public readonly data: TaskModel[];
  public readonly current_page: number;
  public readonly page_size: number;
  public readonly total: number;
  public readonly last_page?: number;
  public readonly from?: number;
  public readonly to?: number;

  public constructor(
    page: IPage<IExtTask>
  ) {
    this.data = page.data.map(datum => new TaskModel(datum))
    this.current_page = page.current_page
    this.page_size = page.page_size
    this.total = page.total
  }
}
