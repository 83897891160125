import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

import { ModalService } from './modal.service';
import { AuthService } from './auth.service';
import { LangService } from './lang.service';
import { AppFeatureEnum } from '@enums';
import { ObjectableUtil } from '../utils/objectable.util';
import { ActionSheetController, NavController } from '@ionic/angular';
import { IRfidTag } from '@interfaces';
import { RfidClientService } from './clients/rfid/rfid-client.service';
import { NfcService } from './nfc/nfc.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class RfidService {
  constructor(
    private modalService: ModalService,
    private authService: AuthService,
    private langService: LangService,
    private navController: NavController,
    private actionSheetController: ActionSheetController,
    private rfidClt: RfidClientService,
    private nfcService: NfcService,
    private routeSrv: RouteService
  ) {}

  /**
   * Checks if the hardware have access to nfc
   * @public
   */

  public async hasRfidAccess(): Promise<boolean> {
    // for dev env we activate the rfid on webapp.
    if (Capacitor.getPlatform() === 'web') {
      return true;
    }
    return Capacitor.isNativePlatform() && (await this.nfcService.isSupported());
  }

  /**
   * Checks if rfid is enabled in checkproof config and hardware is supporting nfc
   * @public
   */
  public rfidEnabled(): Promise<boolean> {
    const allowedFeatures = this.authService.getAllowedFeatures();

    return this.hasRfidAccess()
      .then(hasRfidAccess => {
        return !!allowedFeatures[AppFeatureEnum.RFID] && !!hasRfidAccess;
      })
      .catch(() => {
        // If hardware is not supporting nfc or nfc not activated return false
        return !!(allowedFeatures[AppFeatureEnum.RFID] && false);
      });
  }

  /**
   * Choose RFID action after clicking on the setup scan button for all Modal object selector
   * @param object
   * @param type
   * @public async
   */
  public async chooseSetupRfidActionForModal(object: any, type: string): Promise<void> {
    // set by default true
    let firstTimeUnitorRound = true;

    // If the tag has a unique id  in the DB transfer it to the resolver/
    const tagUid = object.tag.uid || '';
    const tag_uid = object.tag.tag_uid || '';

    // Check if this is the first time you check the unit or round for this tag
    if (object.tag.objectables.length > 0) {
      // if one objectable id of the array is same as object id then return false
      firstTimeUnitorRound = !object.tag.objectables.some(objectable => objectable.id === object.id);
    }

    // Setup the Rfid buttons actions
    const actionSheetButtonArray = [
      {
        text: this.langService.t('rfid.actions.controlPoint'),
        cssClass: 'action-sheet-icon-fa check-perform',
        handler: () => {
          // Transfer the user to the checkpoint setup screen
          this.navController.navigateForward([
            '/rfid-interval-list',
            {
              objectId: object.id,
              objectType: type,
              uid: tagUid,
              tag_uid: tag_uid,
              connectedTag: !firstTimeUnitorRound,
            },
          ]);
        },
      },
      {
        text: this.langService.t('rfid.actions.positionsAndCategories'),
        cssClass: 'action-sheet-icon-fa cabinet-filing',
        handler: () => {
          // Transfer the user to the positions and categories setup screen
          // if firstTimeUnitorRound = true then connectedTag should be false
          this.navController.navigateForward([
            '/rfid-object-positions-categories',
            {
              objectId: object.id,
              objectType: type,
              uid: tagUid,
              tag_uid: tag_uid,
              connectedTag: !firstTimeUnitorRound,
            },
          ]);
        },
      },
    ];

    if (firstTimeUnitorRound) {
      // If it is the first time scan purpose to the user to link the tag to the selected unit/rounds
      actionSheetButtonArray.push({
        text: this.langService.t('rfid.actions.connectTo') + ' ' + object.name,
        cssClass: 'action-sheet-icon-checkproof unitsrounds',
        handler: () => {
          // Open a modal to Ask to the user to confirm the connection to the unit/round
          this.modalService
            .showDialog({
              title: this.langService.t('rfid.actions.unitConnect'),
              message: object.name,
              buttons: [
                {
                  text: this.langService.t('actions.cancel'),
                  iconClass: 'fal fa-times color-danger',
                  value: false,
                },
                {
                  text: this.langService.t('actions.continue'),
                  iconClass: `fal fa-check-circle color-success`,
                  value: true,
                },
              ],
            })
            .then(decision => {
              // If confirmed transfer the user to the selected unit/round
              if (decision) {
                // Written in a different way in the db 'Unit' : 'Round'
                const typeForDb = ObjectableUtil.isUnit(object) ? 'Unit' : 'Round';
                const tagData: IRfidTag = {
                  taggables_id: object.id,
                  taggables_type: typeForDb,
                  objectable_id: object.id,
                  objectable_type: typeForDb,
                };
                // Link the tag to a new unit
                this.rfidClt.replaceTagInDatabase(object.tag.tag_uid, tagData).then(rfidTag => {
                  if (rfidTag) {
                    this.navController.navigateForward(this.routeSrv.makeUrl(this.routeSrv.PATHS.objectable.id, { objectType: ObjectableUtil.getObjectType(object), id: object.id }));
                  }
                });
              }
            });
        },
      });
    }

    actionSheetButtonArray.push({
      text: this.langService.t('actions.cancel'),
      cssClass: '',
      handler: () => {},
    });

    const select = await this.actionSheetController.create({
      cssClass: 'new-items-action-sheet',
      buttons: actionSheetButtonArray,
    });
    await select.present();
  }
}
