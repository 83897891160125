import { Injectable } from '@angular/core';
import { ApiService } from '../../api.service';

@Injectable({
  providedIn: 'root'
})
export class MicroserviceClient extends ApiService {
  protected statusPath = '';

  /** @inheritDoc */
  public initService(): void {
    const environment = this.environmentService.getEnvironment();
    this.API_URL = environment.microservicesBaseUrl;
    const url = new URL(this.API_URL);
    this.BASE_URL = `${url.protocol}//${url.hostname}`;
    this.httpOptions.headers = { // Exclude 'App-Version' header
      'Content-Type': 'application/json; charset=UTF-8',
      Accept: 'application/json',
    } as any;
  }

  /** @inheritDoc */
  protected saveRequest(): Promise<any> {
    /** Do nothing. Microservice clients have their own caching of requests. */
    return Promise.resolve();
  }
}
