

import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentRef, AfterViewInit } from '@angular/core';
import { LangService } from '@services';

@Component({
  selector: 'app-odometer-confirmation',
  templateUrl: './odometer-confirmation.component.html',
  styleUrls: ['./odometer-confirmation.component.scss'],
})
export class OdometerConfirmationComponent implements OnInit, AfterViewInit {
  @Input() headerTitle: string;
  @Input() message: string;
  @Input() confirmOdometerValue: string;
  @Input() currentValue: number;
  @Input() newValue: number;
  @Input() suffix: string;
  @Input() component;
  @Input() buttons;
  @Input() showSpinner = false;

  @ViewChild('container', { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>;

  public closeHandler = null;
  public buttonsExpand = '';
  public enteredValueText: string;
  public currentValueText: string;
  public newValueWithSuffix: string;
  public currentValueWithSuffix: string;
  private readonly BLOCK = 'block';
  private readonly RIGHT = 'right';
  public readonly LEFT = 'left';

  constructor(private langService: LangService) {}

  ngOnInit() {
    if (this.buttons && this.buttons.length === 2) {
      this.buttonsExpand = this.BLOCK;

      this.buttons.forEach((button, index) => {
        button.iconAlign = this.LEFT;
        if (index === 1) {
          button.iconAlign = this.RIGHT;
        }
      });
    }
    this.enteredValueText = this.langService.t('units.enteredOdometerValue');
    this.currentValueText = this.langService.t('units.currentOdometerValue');
    this.newValueWithSuffix = this.newValue + this.suffix;
    this.currentValueWithSuffix = this.currentValue + this.suffix;
  }

  ngAfterViewInit() {
    if (this.component) {
      this.createComponent();
    }
  }

  createComponent() {
    this.container.clear();
    this.componentRef = this.container.createComponent(OdometerConfirmationComponent);
  }

  /**
   * Triggers by clicking on button
   * @param button
   */
  public onButtonClick(button) {
    button.handler();
  }
}
