import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { RouteService } from './route.service';
import { IServiceInterval } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class ServiceIntervalService {
  constructor(private apiService: ApiService, private routeSrv: RouteService) {}

  /**
   * Fetch Service-interval
   *
   * @param id Service-interval ID
   */
  public getInterval(id: number): Promise<IServiceInterval> {
    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.serviceInterval.serviceInterval, { id }));
  }
}
