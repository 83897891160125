<div *ngIf="asset.url" class="video-player">
    <video controls id="{{asset.url}}" poster="{{asset.video_thumbnail_url}}">
      <source [src]="asset.mpeg_url" type="video/mp4">
      <source [src]="asset.mov_url" type="video/quicktime">
    </video>
</div>

<div *ngIf="!asset.url" class="video-preview-image">
  <div>{{'general.video-preview-not-available' | lang}}</div>
</div>
