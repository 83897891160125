
export class PromiseQueueUtil {
  private concurrency = 5;  // maximum promises that will be executed simultaneously
  private running = 0;
  private queue = [];

  constructor(concurrency: number) {
    this.concurrency = concurrency;
  }

  /**
   * Push callback function that returns promise
   * @param {() => Promise<any>} promise
   * @return {() => Promise<any>}
   */
  pushPromise(promise: () => Promise<any>): () => Promise<any> {
    this.queue.push(promise);
    this.next();
    return promise;
  }

  /**
   * Execute next promise in queue
   */
  next(): void {
    while (this.running < this.concurrency && this.queue.length) {
      const promise = this.queue.shift();

      promise().then(() => {
        this.running--;
        this.next();
      });

      this.running++;
    }
  }

  /**
   * Remove all promises from queue
   */
  clear(): void {
    this.queue = [];
    this.running = 0;
  }
}
