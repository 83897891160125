import { Injectable } from '@angular/core';
import { LangService } from '../../../../lang.service';
import { IChecklistVariant, ITask, IDueValue, ITransformer, IDueData } from '@interfaces';
import { DueTimeTransformer } from '../due-time/due-time.transformer';
import { DueOdometerTransformer } from '../due-odometer/due-odometer.transformer';
import { UnitsSecondsTransformer } from '../units-seconds/units-seconds.transformer';
import { HelperService } from '../../../../helper.service';
import { UnitsTimeTransformer } from '../units-time/units-time.transformer';
import { differenceInSeconds, parseISO } from 'date-fns';
import { UnitsTimeSubTransformer } from '../units-time/units-time-sub.transformer';
import { TaskStatusEnum } from '@enums';

/** Transformer PT task to CP Due-data */
@Injectable({
  providedIn: 'root'
})
export default class TaskToDueTransformer implements ITransformer {
  public constructor(
    private readonly langSrv: LangService,
    private readonly timeUnitsTrans: UnitsSecondsTransformer,
    private readonly dueTimeTrans: DueTimeTransformer,
    private readonly dueOdoTrans: DueOdometerTransformer,
    private readonly  helperService: HelperService,
    private readonly unitsTimeTrans: UnitsTimeTransformer,
    private readonly timeUnitsTransSub: UnitsTimeSubTransformer,
  ) {
  }

  /** Create due-data from task */
  public transform(task: ITask): IDueData {
    return Object.assign(
      this.makeDueData(task),
      {
        smart_intervals: this.makeSmartIntervals(task)
      }
    );
  }

  /** Create due-data */
  private makeDueData(task: ITask): IDueData {
    return Object.assign(
      {
        active: task.status !== TaskStatusEnum.INACTIVE,
        earliest_value: null,
        extra_text: null,
        is_completed: null,
        is_due: task.status === TaskStatusEnum.OVERDUE,
        latest_value: null,
        no_check_done: task?.dateTime_data || task?.odometer_data?.within_value ? false : true,
        text: null,
        time_data: this.dueTimeTrans.transform(task.dateTime_data),
        odometer_data: this.dueOdoTrans.transform(task.odometer_data),
        interval_due_trigger_id: null,
      },
      this.getDueValue(task)
    );
  }

    /** Create due-data with only odometers data */
    private makeDueOdometerDataWithoutTime(task: ITask): IDueData {
       // Create a deepCopy of task and remove datetime
        const taskDeepCopy =  JSON.parse(JSON.stringify(task));
        delete taskDeepCopy.dateTime_data;
        const dueOdometerData = this.makeDueData(taskDeepCopy);
        // add due_unit property to map well  on existing legacy screen
        dueOdometerData.due_unit = taskDeepCopy.odometer_data && Number.isInteger(taskDeepCopy.odometer_data.measurement_units) ? this.makeOdometerSuffix(taskDeepCopy.odometer_data.measurement_units): null;
        return dueOdometerData;
    }

    /** Create due-data with only time data */
    private makeDueTimeDataWithoutOdometer(task: ITask): IDueData {
        const dueTimeData = this.makeDueData(task);
        delete dueTimeData.odometer_data;
        return dueTimeData;
    }


  /** Create nested variants of smart interval */
  private makeSmartIntervals(task: ITask): IChecklistVariant[] {
    return (task.dateTime_data && task.odometer_data)
      ? [
        {
          due_data: this.makeDueTimeDataWithoutOdometer(task),
          // add type property to map well  on existing legacy screen
          type: this.helperService.protocol.checklists.variants.FIXED
        },
        {
            due_data: this.makeDueOdometerDataWithoutTime(task),
            // add type property to map well  on existing legacy screen
            type: this.helperService.protocol.checklists.variants.ODOMETER
        }
      ] as IChecklistVariant[]
      : [];
  }

  /** Get value and units */
  private getDueValue(task: ITask): IDueValue {
    if (task.dateTime_data) {
        return {
        due: this.timeUnitsTrans.transform(task.dateTime_data.due_value, task.dateTime_data.due_unit),
        due_unit: 'sec',
        earliest_date: differenceInSeconds(
            new Date(),
            this.timeUnitsTransSub.transform(
                parseISO(task.dateTime_data.when),
                task.dateTime_data.activates_value,
                task.dateTime_data.activates_unit
            )
        ),
        latest_date: differenceInSeconds(
            this.unitsTimeTrans.transform(
                task.dateTime_data.expires_value,
                task.dateTime_data.expires_unit,
                parseISO(task.dateTime_data.when)
            ),
            new Date()
        ),
      };
    }

    if (task.odometer_data) {
      return {
        due: task.odometer_data.within_value,
        due_unit: this.makeOdometerSuffix(task.odometer_data.measurement_units),
        earliest_date: null,
        latest_date: null,
      };
    }

    return {
        due: null,
        due_unit: null,
        earliest_date: null,
        latest_date: null,
    };
  }

  /** Get suffix of unit */
  private makeOdometerSuffix(unit: number): string {
    return this.langSrv.t(`odometers.measurement_units.full.${unit}`);
  }
}
