<div class="card-label">{{'checklists.interval' | lang}}</div>
<app-select-dropdown class="ion-margin-bottom"
                     [placeholder]="'checks.select-interval' | lang"
                     groupBy="variants"
                     [items]="variantsPromise"
                     [isLoading]="variantsLoading"
                     (changed)="onVariantChange()"
                     [(ngModel)]="selectedVariant">
  <ng-template #itemContent let-item>
    <div>{{item.name}}</div>
    <ul class="text-medium list-nested-names">
      <li *ngIf="item.object.section && item.object.section.length">{{item.object.section[0].name}}</li>
      <li>{{item.object.name}}</li>
    </ul>
  </ng-template>
</app-select-dropdown>
