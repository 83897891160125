import { ObjectTypeEnum, ObjectableTypeEnum } from '@enums';
import { IPublicToken } from '@interfaces';

export class ObjectableUtil {
  /**
   * Check is current object unit or not
   * @param object
   * @return {boolean}
   */
  static isUnit(object: any): boolean {
    return object && object.hasOwnProperty('unit_type');
  }

  /**
   * Check is current object round or not
   * @param object
   * @return {boolean}
   */
  static isRound(object: any): boolean {
    return !object.hasOwnProperty('unit_type');
  }

  /**
   * Get type of object
   * @param object
   * @return {string}
   */
  static getObjectableType(object: any): string {
    return this.isUnit(object) ? ObjectableTypeEnum.UNIT : ObjectableTypeEnum.ROUND;
  }

   /**
   * Get type of object
   * @param object
   * @return {string}
   */
   static getObjectType(object: any): string {
    return this.isUnit(object) ? ObjectTypeEnum.UNIT : ObjectTypeEnum.ROUND;
  }
  /**
   * Compare objects
   * @param objectA
   * @param objectB
   * @return {boolean}
   */
  static isSameObjects(objectA, objectB): boolean {
    return this.getObjectableType(objectA) === this.getObjectableType(objectB) && objectA.id === objectB.id;
  }

  /**
   * Check is object has writable permissions
   * @param object
   * @param PublicToken
   */
  static hasWritePermissions(object, publicToken: IPublicToken = null) {
    if (!object) {
      return false;
    }
    if (publicToken?.objectable_id === object.id) {
      return true;
    }
    if (object.hasOwnProperty('writable')) {
      return object.writable;
    }
    if (object.permission && object.permission.actions && object.permission.actions.indexOf('write') >= 0) {
      return true;
    }
    return false;
  }
}
