import { Component, OnInit, OnDestroy } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { MenuController, NavController, ActionSheetController } from '@ionic/angular';
import { AuthService, SharedUserService, EnvironmentService, ModalService, NotificationService, EventsService, AppUpdaterService, OfflineHandlerService, 
  SamlAuthService, RfidService, ApiService, RouteService, HelperService, IntercomService, LangService } from '@services';
import { AppFeatureEnum } from '@enums';
import { NotificationCenterComponent } from '../../modals/notification-center/notification-center.component';
import { ObjectSelectorComponent } from '../../modals/object-selector/object-selector.component';
import { PlaceableSelectorComponent } from '../../modals/placeable-selector/placeable-selector.component';
import { ObjectableUtil } from '../../utils/objectable.util';
import { IAcademyToken } from '@interfaces';

@Component({
  selector: 'app-side-bar-menu',
  templateUrl: './side-bar-menu.component.html',
  styleUrls: ['./side-bar-menu.component.scss']
})
export class SideBarMenuComponent implements OnInit, OnDestroy {
  public canSwitchCompany: boolean;
  public canEditObjects = false;
  public canLinkAzureAccount = false;
  public appVersionNumber: string;
  public allowedFeatures = null;
  public isEnabled = false;
  public isOfflineMode = false;
  public AppFeatureEnum: typeof AppFeatureEnum = AppFeatureEnum;
  public hasUnread$ = this.notificationService.totalUnreadCount.asObservable();
  public showNewVersionNotice$ = this.appUpdater.hasNewerVersion$.asObservable();
  public allowedSetupRFID: boolean;
  public linkForUpdate: string;
  public isNativePlatform: boolean;
  private ssoId: string;

  constructor(
    private sideMenu: MenuController,
    public events: EventsService,
    private authService: AuthService,
    private sharedUserService: SharedUserService,
    private navController: NavController,
    private environmentService: EnvironmentService,
    private modalService: ModalService,
    private notificationService: NotificationService,
    private appUpdater: AppUpdaterService,
    private actionSheetController: ActionSheetController,
    private langService: LangService,
    private offlineHandlerService: OfflineHandlerService,
    public samlAuthService: SamlAuthService,
    private rfidService: RfidService,
    private intercomService: IntercomService,
    private apiService: ApiService,
    private routeSrv: RouteService
  ) {}

  ngOnInit() {
    const environment = this.environmentService.getEnvironment();

    this.appVersionNumber = environment.appVersion;

    this.events.subscribe('sideMenu:open', () => {
      this.open();
    });

    this.events.subscribe('sideMenu:showNotificationCenter', () => {
      this.showNotificationCenter();
    });

    // Subscribing to logout event and disable sidebar menu
    this.events.subscribe('user:logout', () => {
      this.setDisabled();
    });

    // Subscribing to user init event and triggers menu initializeMenu
    this.events.subscribe('user:init', () => {
      this.initializeMenu();
    });

    this.events.subscribe('offline:activated', (active) => {
      this.isOfflineMode = active;
    });

    // Set sidebar menu as disabled when not authenticated
    this.authService.isAuthenticated()
      .then(loggedIn => {
        if (!loggedIn) {
          this.setDisabled();
        } else {
          this.initializeMenu();
        }
      });
  }

  ngOnDestroy() {
    this.events.unsubscribe('sideMenu:showNotificationCenter');
    this.events.unsubscribe('sideMenu:open');
    this.events.unsubscribe('user:init');
    this.events.unsubscribe('user:logout');
  }

  /**
   * Initialize sidebar menu, enables it and checks if user has clones to enable switching user functionality
   *
   */
  private async initializeMenu(): Promise<any> {
    const user = await this.authService.getCurrentUser();

    this.canSwitchCompany = (user.clones && user.clones.length > 1);
    this.allowedFeatures = this.authService.getAllowedFeatures();
    this.canEditObjects = this.authService.isManagerOrAbove();

    this.isOfflineMode = await this.offlineHandlerService.isOfflineEnable();

    if (!user.has_external_user && this.allowedFeatures[AppFeatureEnum.SAML] && user.company.slug) {
      this.canLinkAzureAccount = true;
      this.ssoId = user.company.slug;
    }

    this.authService.getCurrentUser().then(async user => {
      this.canSwitchCompany = user.has_clones;
      this.allowedFeatures = this.authService.getAllowedFeatures();
      this.allowedSetupRFID = this.authService.canManageRFID();
    });

    this.isNativePlatform = Capacitor.isNativePlatform();
    this.linkForUpdate = Capacitor.getPlatform() === 'ios' ? this.langService.t('app.app-store-link') : this.langService.t('app.play-market-link');
    this.setDisabled(false);
  }

  /**
   * Closing sidebar menu
   */
  public close(): void {
    this.sideMenu.close('sidebar-menu');
  }

  /**
   * Opens sidebar menu
   */
  public open(): void {
    this.sideMenu.open('sidebar-menu');
  }

  /**
   * Listen to logout event sent from view
   */
  public logout(): void {
    this.close();

    this.authService.attemptLogout().then(result => {
      if (result === true) {
        // Disable menu when logging out
        this.setDisabled();
      }
    });
  }

  /**
   * Listen to switchCompany event sent from view
   */
  public switchCompany(): void {
    this.close();

    // Triggers switchCompany logic in sharedUser
    this.sharedUserService.switchCompany();
  }

  public sendEmailToSupport(): void {
    window.location.href = 'mailto:' + HelperService.getSupportEmail();
  }

  public get supportEmail(): string {
    return HelperService.getSupportEmail();
  }

  public callToTheSupport(): void {
    window.location.href = 'tel:' + HelperService.getSupportPhone();
  }

  public get supportPhoneFormatted(): string {
    return HelperService.getSupportPhoneFormatted();
  }

  /**
   * Listen to openIntercomChat event sent from view
   */
  public openIntercomChat(): void {
    if (Capacitor.isNativePlatform()) {
      this.intercomService.displayIntercomMessenger();
    } else {
      this.close();
      // Opens a new browser window for helpdesk
      window.open('http://help.checkproof.com/', '_blank');
    }
  }

  /**
   * Listen to goToCheckProofAcademy event sent from view
   */
  public async goToCheckProofAcademy(): Promise<void> {
      this.close();
      const environment = this.environmentService.getEnvironment();

      // Default to old academy
      if (!environment.academyUrl) {
        window.open('https://academy.checkproof.com/');
        return;
      }

      const res: IAcademyToken = await this.apiService.get(this.routeSrv.PATHS.tokens.academy);
      window.open(environment.academyUrl + `auth/?jwt=${res.jwt_token}&redirect=/courses/app/`);
  }


  /**
   * Listen to offlineMode event sent from view
   */
  public offlineMode(): void {
    this.close();
    this.navController.navigateForward('/offline-mode');
  }

  /**
   * Open RFID setup actions
   */
  public async setupRfid(): Promise<void> {
    this.close();
    await this.navController.navigateRoot(['/home']);
    await this.modalService.open({
      verticalAlignCenter: false,
      component: ObjectSelectorComponent,
      componentProps: {
        title: this.langService.t('rfid.setupRfid'),
        allowedSetupRFID: this.allowedSetupRFID,
        onSelect: (selected) => {
          const object = selected.object;
          const type = ObjectableUtil.isUnit(object) ? 'unit' : 'round';
          this.rfidService.chooseSetupRfidActionForModal(object, type);
        }
      },
    });
  }

  public showNotificationCenter(): void {
    this.modalService.open({
      component: NotificationCenterComponent,
      componentProps: {},
      backdropDismiss: true,
      verticalAlignCenter: false
    });

    this.close();
  }

  /**
   * Set side bar menu disabled/enabled
   * @param disabled
   */
  public setDisabled(disabled = true) {
    this.isEnabled = !disabled;
    this.sideMenu.enable(!disabled, 'sidebar-menu');
  }

  public createNewObject() {
    this.showObjectTypeSelector();
  }

  public editObject() {
    this.showObjectTypeSelector(false);
  }

  /**
   * Triggers by clicking on "Link to Azure Account" button
   */
  public async linkAzureAccount() {
    const options = {
      backPath: '/sso-connect',
      forceAuth: true
    };

    const result = await this.samlAuthService.login(this.ssoId, options);

    if (!result) {
      return;
    }

    await this.samlAuthService.connect(result);
  }

  private async showObjectTypeSelector(isNew = true) {
    const buttons = [];

    if (this.allowedFeatures[AppFeatureEnum.UNITS_ROUNDS] &&
      (!isNew || this.allowedFeatures[AppFeatureEnum.UNITS_ROUNDS_CREATE])
    ) {
      buttons.push(
        {
          text: this.langService.t('units.singular'),
          handler: () => {
            if (isNew) {
              this.close();
              this.navController.navigateForward(['/units/create']);
            } else {
              this.modalService.open({
                verticalAlignCenter: false,
                component: ObjectSelectorComponent,
                componentProps: {
                  title: this.langService.t('units.singular'),
                  writableOnly: true,
                  unitsOnly: true,
                  onSelect: (selected) => {
                    if (!selected || !selected.object) {
                      return;
                    }
                    this.close();
                    this.navController.navigateForward(['/units/' + selected.object.id + '/edit']);
                  }
                },
              });
            }
          }
        },
        {
          text: this.langService.t('rounds.singular'),
          handler: () => {
            if (isNew) {
              this.close();
              this.navController.navigateForward(['/rounds/create']);
            } else {
              this.modalService.open({
                verticalAlignCenter: false,
                component: ObjectSelectorComponent,
                componentProps: {
                  title: this.langService.t('rounds.singular'),
                  writableOnly: true,
                  unitsOnly: false,
                  roundsOnly: true,
                  onSelect: (selected) => {
                    if (!selected || !selected.object) {
                      return;
                    }
                    this.close();
                    this.navController.navigateForward(['/rounds/' + selected.object.id + '/edit']);
                  }
                },
              });
            }
          }
        }
      );
    }

    buttons.push(
      {
        text: this.langService.t('workplaces.singular'),
        handler: () => {
          if (isNew) {
            this.close();
            this.navController.navigateForward(['/workplaces/create']);
          } else {
            this.modalService.open({
              verticalAlignCenter: false,
              component: PlaceableSelectorComponent,
              componentProps: {
                title: this.langService.t('workplaces.singular'),
                writableOnly: true,
                type: 'workplace',
                onSelect: (selected) => {
                  if (!selected || !selected.workplace) {
                    return;
                  }
                  this.close();
                  this.navController.navigateForward(['/workplaces/' + selected.workplace.id + '/edit']);
                }
              },
            });
          }
        }
      },
      {
        text: this.langService.t('sections.singular'),
        handler: () => {
          if (isNew) {
            this.modalService.open({
              verticalAlignCenter: false,
              component: PlaceableSelectorComponent,
              componentProps: {
                title: this.langService.t('sections.singular'),
                writableOnly: true,
                type: 'workplace',
                onSelect: (selected) => {
                  if (!selected || !selected.workplace) {
                    return;
                  }
                  const pathParams = <any>{workplaceId: selected.workplace.id};
                  this.close();
                  this.navController.navigateForward(['/sections/create', pathParams]);
                }
              },
            });
          } else {
            this.modalService.open({
              verticalAlignCenter: false,
              component: PlaceableSelectorComponent,
              componentProps: {
                title: this.langService.t('sections.singular'),
                writableOnly: true,
                type: 'section',
                onSelect: (selected) => {
                  if (!selected || !selected.section) {
                    return;
                  }
                  this.close();
                  this.navController.navigateForward(['/sections/' + selected.section.id + '/edit']);
                }
              },
            });
          }
        }
      },
      {
        text: this.langService.t('actions.cancel'),
        role: 'cancel',
        handler: () => { }
      }
    );

    const newItemsActionSheet = await this.actionSheetController.create({
      buttons: buttons
    });

    newItemsActionSheet.present();
  }


  /**
   * WorktimeHistory event sent from view to redirect the user
   */
  public worktimeHistory(): void {
    this.close();
    this.navController.navigateForward(['/worktimes-history']);
  }

  /**
   * phoneSettings event sent from view to redirect the user to the phone settings
   */
  public async phoneSettings(): Promise<void> {
    this.close();
    await this.navController.navigateRoot(['/settings']);
  }
}
