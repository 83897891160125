import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';

import { environment } from '../../environments/environment';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  private region = '';

  constructor(
    private storage: Storage,
    private events: EventsService
  ) { }

  /**
   * Initialize environment
   * @return {Promise<any>}
   */
  public async initService() {
    const region = await this.getRegion();

    if (region) {
      this.region = region;
    }

    return Promise.resolve();
  }

  /**
   * Get environment object
   * @return {any}
   */
  public getEnvironment() {
    if (!this.region || !environment.region || !environment.region[this.region]) {
      return environment;
    }

    const regionEnv = environment.region[this.region];
    return Object.assign({}, environment, regionEnv);
  }

  /**
   * Get specific property from environment configuration
   * @param {string} property
   * @param {any} defaultValue
   * @return {any}
   */
  public getEnvironmentProperty(property: string, defaultValue = null): any {
    const environment = this.getEnvironment();

    if (environment[property]) {
      return environment[property];
    }

    return defaultValue;
  }

  /**
   * Set region for environment
   * @param {string} region
   * @return {Promise<any>}
   */
  public setRegion(region: string): Promise<any> {
    if (region === 'default') {
      region = '';
    }
    this.region = region;
    return this.storage.set('envRegion', region)
      .then(() => {
        this.events.publish('env:changeRegion', this.region);
        return this.region;
      })
      .catch(() => {});
  }

  /**
   * Get saved region
   * @return {Promise<any>}
   */
  public getRegion(): Promise<any> {
    return this.storage.get('envRegion');
  }

  /**
   * Check if environment has regions.
   * @return boolean
   */
  public hasRegions(): boolean {
    return Object.keys(this.getEnvironment().region).length > 0;
  }

  /**
   * Check is current region set to USA
   * @return {boolean}
   */
  public isRegionUSA() {
    return this.region === 'usa';
  }
}
