<div *ngIf="rfidAutoScan">
  <app-button
          [disabled]="!requireRfidScan"
          type="button"
          class="rfid-btn"
          iconClass="fa-solid fa-nfc-signal"
          [spinner]="rfidAutoScan"
          (onClick)="doRfidScan()">{{ buttonLabel | lang}}</app-button>
</div>
<div *ngIf="!rfidAutoScan">
  <app-button
          [disabled]="!requireRfidScan"
          type="button"
          class="rfid-btn"
          iconClass="fa-solid fa-nfc-signal"
          (onClick)="doRfidScan()">{{ buttonLabel | lang}}</app-button>
</div>


