export class StyleUtil {
  private static isBrightColorCache = {};

  /**
   * Check is color bright or not
   * @param {string} color
   * @return {boolean}
   */
  static isBrightColor(color: string): boolean {
    if (!color) {
      return true;
    }

    if (StyleUtil.isBrightColorCache.hasOwnProperty(color)) {
      return StyleUtil.isBrightColorCache[color];
    }

    const c = color.substring(1);      // strip #
    const r = parseInt(c.substring(0, 2), 16);
    const g = parseInt(c.substring(2, 4), 16);
    const b = parseInt(c.substring(4, 6), 16);
    const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    StyleUtil.isBrightColorCache[color] = (yiq >= 128);
    return StyleUtil.isBrightColorCache[color];
  }
}

