import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { HomeWidgetComponent } from './home-widget.component';
import { HomeScreenModule } from '../home-screen/home-screen.module';
import { OfflineBannerModule } from '../offline-banner/offline-banner.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    HomeScreenModule,
    OfflineBannerModule
  ],
  declarations: [
    HomeWidgetComponent
  ],
  exports: [
    HomeWidgetComponent
  ],
})
export class HomeWidgetModule {}

