import { Injectable } from '@angular/core';
import { MicroserviceClient } from '../../microservice.client';
import { RouteService } from '../../../../route.service';
import { ITaskDetails, ITaskInfo, ITasksPage, ISearchFilterSet, ITasksClient, ICompletedTaskId, ISearchParamsSet, IActiveTasksWithHistory } from '@interfaces';
import { HttpStatusCode } from '@angular/common/http';
import { FNS_DD_MM_YYYY } from '../../../../../constants/data-time-formats';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class TasksClient implements ITasksClient {
  constructor(
    private readonly client: MicroserviceClient,
    private readonly routeSrv: RouteService,
  ) {
  }

  /** @inheritDoc */
  public getTasks(filter: ISearchFilterSet = {}): Promise<ITasksPage> {
    return this.client
      .post(
        this.routeSrv.makeUrl(
          this.routeSrv.PATHS.planningTool.tasks.list,
          TasksClient.extractTasksQueries(filter),
        ),
        this.formatDates(filter)
      );
  }

  /** @inheritDoc */
  public getTaskInfo(uid: string): Promise<ITaskInfo> {
    return this.client
      .get(
        this.routeSrv.makeUrl(
          this.routeSrv.PATHS.planningTool.tasks.task,
          { id: uid }
        ),
      );
  }

  /** Fetch task & history By Origin UID */
  public getTaskDetailsByOrigin(requestParam: IActiveTasksWithHistory): Promise<ITaskDetails> {
    return this.client
      .get(
        this.routeSrv.makeUrl(
          this.routeSrv.PATHS.planningTool.tasks.activeTaskWithHistory,
          requestParam
        ),
      );
  }

  /** Get performed task & history */
  public getCompletedTaskDetails(identifiers: ICompletedTaskId): Promise<ITaskDetails | null> {
    if (Object.keys(identifiers).length !== 1) {
      throw new Error('One type of identifier must be defined.');
    }

    return this.client
      .get(
        this.routeSrv.makeUrl(
          this.routeSrv.PATHS.planningTool.tasks.taskWithHistory,
          identifiers
        ),
        null,
        {
          disableErrorHandler: true
        }
      )
      .catch((errorResponse: { status: number }) => {
        if (errorResponse.status !== HttpStatusCode.NotFound) { // Exclude "Not found" case
          throw errorResponse;
        }
        return null;
      });
  }

  /**
   * Update task
   * TODO: Declare "parameters" type
   */
  public updateTask(id: string, parameters: any): Promise<ITaskInfo> {
    return this.client
      .post(
        this.routeSrv.makeUrl(
          this.routeSrv.PATHS.planningTool.tasks.task,
          { id }
        ),
        parameters
      );
  }

  /** Fetch and remove query parameters from the filter and make an object of them */
  private static extractTasksQueries(filter: ISearchFilterSet): ISearchParamsSet {
    const searchKeys = {
      companyUid: 'companyId',
      calendarResult: 'calendarResult',
      showInactive: 'showInactive',
    };

    return Object.keys(searchKeys)
      .filter((key: string) => filter[key] !== undefined)
      .reduce(
        (params: object, srcKey: string) => {
          const dstKey = searchKeys[srcKey];
          Object.assign(params, { [dstKey]: '' + filter[srcKey] });
          delete filter[srcKey];
          return params;
        },
        {}
      );
  }

  /** Recursively format all Date properties to valid string value */
  private formatDates(data: object): object {
    for (const key in data) {
      if (data[key] instanceof Date) {
        data[key] = format(data[key], FNS_DD_MM_YYYY);
      } else if (data[key] instanceof Object) {
        this.formatDates(data[key]);
      }
    }
    return data;
  }
}
