import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver, ComponentRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit, AfterViewInit {
  @Input() title;
  @Input() headerTitle;
  @Input() message;
  @Input() component;
  @Input() componentProps;
  @Input() buttons;
  @Input() showCloseIcon = false;
  @Input() showSpinner = false;

  @ViewChild('container', { read: ViewContainerRef }) container;
  componentRef: ComponentRef<any>;

  public closeHandler = null;
  public buttonsExpand = '';

  constructor(private resolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (this.buttons && this.buttons.length === 2) {
      this.buttonsExpand = 'block';

      this.buttons.forEach((button, index) => {
        button.iconAlign = 'left';
        if (index === 1) {
          button.iconAlign = 'right';
        }
      });
    }
  }

  ngAfterViewInit() {
    if (this.component) {
      this.createComponent();
    }
  }

  createComponent() {
    this.container.clear();
    const factory = this.resolver.resolveComponentFactory(this.component);
    this.componentRef = this.container.createComponent(factory);

    if (this.componentProps) {
      for (const key of Object.keys(this.componentProps)) {
        this.componentRef.instance[key] = this.componentProps[key];
      }
    }
    // this.componentRef.instance.type = type;
  }

  /**
   * Triggers by clicking on button
   * @param button
   */
  public onButtonClick(button) {
    button.handler();
  }

  /**
   * Triggers by clicking on "x" icon
   */
  public closeDialog() {
    if (typeof this.closeHandler === 'function') {
      this.closeHandler();
    }
  }
}
