import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NumericKeyboard } from '../../models/numeric-keyboard';

@Injectable({
  providedIn: 'root',
})
export class NumericKeyboardService {

  private _openSubject = new Subject<NumericKeyboard>();
  private _closeSubject = new Subject<null>();
  private _valueSubject = new Subject<string>();

  public open(value: string, decimals: number) {
    this._openSubject.next(new NumericKeyboard({value: value, decimals: decimals}));
  }

  public close(){
    this._closeSubject.next(null);
  }

  public changeValue(value: string): void{
    this._valueSubject.next(value);
  }

  public onOpen(): Observable<NumericKeyboard>{
    return this._openSubject.asObservable();
  }

  public onClose(): Observable<null>{
    return this._closeSubject.asObservable();
  }

  public onValueChange(): Observable<string>{
    return this._valueSubject.asObservable();
  }
}
