<app-select-workplace-section
        *ngIf="!forceRefresh"
        #workplaceSectionComponent
                              [multiple]="multiple"
                              [writableOnly]="writableOnly"
                              [company]="company"
                              [isRequired]="isRequired"
                              [withSelectAllButton]="withSelectAllButton"
                              [restrictNumberOfSelectedSite]="restrictNumberOfSelectedSite"
                              [preset]="preset"
                              [withFavorite]="withFavorite"
                              [withEmptySection]="withEmptySection"
                              (withEmptySectionChange)="onEmptySectionChange($event)"
                              (allSectionsCheckedChange)="changeAllSections($event)"
                              (changed)="onPlaceChange()"
                              [(ngModel)]="selectedPlace">
</app-select-workplace-section>

<div *ngIf="isPerformCheck && availableStatusBar" class="ion-padding-vertical">
  <app-segment [(ngModel)]="selectType" class="ion-margin-bottom">
    <app-segment-button value="object">
      {{'units.singular' | lang}} / {{'rounds.singular' | lang}}
    </app-segment-button>
    <app-segment-button value="interval">
      {{'checklists.interval' | lang}}
    </app-segment-button>
  </app-segment>

  <app-toggle *ngIf="selectType === 'object'"
              [(ngModel)]="showStatusBar"
              [toggleLabel]="'actions.show-status' | lang"></app-toggle>
</div>

<div *ngIf="!forceRefresh">
  <app-select-unit-round *ngIf="displayUnitRoundComponent()"
                         [unitsOnly]="unitsOnly"
                         [roundsOnly]="roundsOnly"
                         [multiple]="multiple"
                         [writableOnly]="writableOnly"
                         [dependables]="selectedPlace"
                         [company]="company"
                         [rfidTag]="rfidTag"
                         [showChecksStatusBar]="availableStatusBar && showStatusBar"
                         [isRequired]="isRequired"
                         [withSelectAllButton]="withSelectAllButton"
                         (allObjectsCheckedChange)="changeAllObjectsChecked($event)"
                         [withEmptySection]="withEmptySection"
                         [withFavorite]="withFavorite"
                         [preset]="preset"
                         [objectInUse]="objectInUse"
                         (changed)="onObjectChange()"
                         [(ngModel)]="selectedObject">
  </app-select-unit-round>
</div>

<app-select-check-variant *ngIf="selectType === 'interval'"
                          [dependables]="selectedPlace"
                          [writableOnly]="writableOnly"
                          (changed)="onCheckVariantChange()"
                          [(ngModel)]="selectedCheckVariant"></app-select-check-variant>
