import { Injectable } from '@angular/core';
import { ITransformer } from '@interfaces';
import { add } from 'date-fns';
import { MAP_UNITS } from '../../../../../constants/units-time';

@Injectable({
  providedIn: 'root'
})
export class UnitsTimeTransformer implements ITransformer {
  /** Map between PT units & Date-FNS units */
  private readonly UNITS = MAP_UNITS;

  /** Transform time units to time */
  public transform(value: number, unit: number,  initialDate?: Date): Date {
    return this.makeDate(value, unit, initialDate); // Due time
  }

  /** Create date-time by units */
  private makeDate(value: number, unit: number, initialDate?: Date): Date {
    return add(
        initialDate ?? new Date(),
      {
        [this.UNITS[unit]]: value
      }
    );
  }
}
