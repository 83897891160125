<div class="sd-dropdown-header">
  <app-input *ngIf="searchable"
             iconClass="far fa-search"
             class="card-search-input"
             [placeholder]="'general.search' | lang"
             [(ngModel)]="searchValue"
             (ionChange)="onSearch($event)"></app-input>
</div>

<div class="sd-dropdown-panel-items scroll-host" (scroll)="onScroll()" #scrollContainer>
  <div>
    <ng-container *ngIf="isLoading || hasVisibleOptions; else noOptionsBlock">
      <ng-container *ngFor="let option of options">
        <div *ngIf="!option.hidden"
             class="sd-option" [class.sd-show-overflow]="showOverflow"
             tappable
             [class.sd-option-disabled]="option.disabled"
             [class.sd-option-selected]="option.selected"
             [class.sd-optgroup]="option.children"
             [class.sd-option]="!option.children"
             (click)="toggleOption(option)">
          <ng-container *ngIf="!option.children">
            <ng-container *ngTemplateOutlet="itemContentTmpl, context: { $implicit: option.value }"></ng-container>
          </ng-container>

          <div *ngIf="option.children" class="sd-option-subgroup">{{option.title}}</div>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="showAddTag && searchValue">
      <div class="sd-option" (click)="addTagOption()" tappable>
        {{addTagText}} "{{searchValue}}"
      </div>
    </ng-container>

    <ng-template #noOptionsBlock>
      <ng-container *ngIf="!showAddTag">
        <div class="sd-option">
          <div *ngIf="hasInitialItems || isFiltered; else typeToSearchBlock">{{notFoundText}}</div>
          <ng-template #typeToSearchBlock>{{typeToSearchText}}</ng-template>
        </div>
      </ng-container>
    </ng-template>
    <div *ngIf="scrollEventWaitingForLoading && isLoading">
      <div class="sd-option">
        {{'powerSelect.loading' | lang}}
      </div>
    </div>
  </div>
</div>
