export class OptionUtil {
  static delimiter = '<>';

  /**
   * Parse component options
   */
  static parseOptions(options) {
    if (!options) {
      return [];
    }

    if (Array.isArray(options)) {
      return options;
    }

    return options.split(this.delimiter);
  }
}
