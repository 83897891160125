<ion-item lines="full" (click)="callToTheSupport()" tappable>
    <ion-avatar slot="start">
        <i class="fal fa-phone round-icon"></i>
    </ion-avatar>
    <ion-label>
        <h2>{{ 'actions.callSupport' | lang }}</h2>
        <h3>
            <a>{{ supportPhoneFormatted }}</a>
        </h3>
    </ion-label>
</ion-item>