import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import LangJS from 'lang.js';

import { langDefaults } from '../constants/lang-defaults';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private langJS: LangJS;

  constructor(
    private storage: Storage
  ) {
    this.langJS = new LangJS({
      messages: []
    });
  }

  /**
   * Init lang service with default translations
   */
  public initService() {
    const locale = 'en';
    this.setTranslations({
      messages: langDefaults,
      locale: locale
    });
  }

  /**
   * Set translation strings and locale
   * @param settings
   */
  public setTranslations(settings = {}) {
    this.langJS = new LangJS(settings);
  }

  /**
   * Get translation string for given key.
   * Pass replacements parameter for replacement data.
   * Pass "count" replacement key for pluralization.
   */
  public t(key: string, replacements: any = {}) {
    if (typeof replacements.count === 'number') {
      return this.langJS.choice(key, parseInt(replacements.count, 10), replacements);
    }

    return this.langJS.get(key, replacements);
  }

  /**
   * Clear cache for all storage keys prefixed with "translations-".
   */
  public clearTranslationsCache(): Promise<any> {
    return this.storage.forEach((value, key) => {
      if (key.indexOf('translations-') === 0) {
        this.storage.remove(key);
      }
    });
  }

  /**
   * Delegation for fetching data from storage
   */
  public getData(key: string) {
    return this.storage.get('translations-' + key);
  }

  /**
   * Delegation for getting data from storage
   */
  public setData(key: string, value: any) {
    return this.storage.set('translations-' + key, value);
  }
}
