import { Injectable } from '@angular/core';
import {Capacitor} from '@capacitor/core';
import {PushNotifications} from '@capacitor/push-notifications';
import { Intercom } from '@capacitor-community/intercom';


@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor(
  ) { }

  public async initService(user) {
    // Register for push notifications from Intercom
    if (Capacitor.isNativePlatform()) {
      await PushNotifications.register();
      const mail = user.email === null ? '' : user.email;
      await Intercom.registerIdentifiedUser({ userId: user.id.toString(),  email: mail });
    }
  }

  public async disconnectFromIntercom() {
    if (Capacitor.isNativePlatform()) {
      await Intercom.logout();
    }
  }

  public async displayIntercomMessenger() {
    await Intercom.displayMessenger();
  }
}
