import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { IOdometer } from '@interfaces';
/** Odometers client */
@Injectable({
  providedIn: 'root'
})
export class OdometersClient {
  constructor(
    private readonly apiSrv: ApiService,
    private readonly routeSrv: RouteService,
  ) {}

  /**
   * Fetch all odometers for unit
   */
  public getOdometers(unitID: number, cache = true): Promise<IOdometer[]> {
    return this.apiSrv.get(
      this.routeSrv.makeUrl(this.routeSrv.PATHS.odometers.unitOdometers, {
        unitID
      }),
      {},
      {
        cache
      }
    );
  }
}
