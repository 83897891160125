import { Component, OnInit, forwardRef, Output, Input, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

import { sortBy } from '../../utils/sort.util';
import { AuthService } from '@services';
import { HelperService } from '@services';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCompanyComponent),
      multi: true,
    },
  ],
})
export class SelectCompanyComponent implements OnInit, ControlValueAccessor {
  public isDisabled = false;
  public companiesList;

  private value;
  private user;
  @Output() changed = new EventEmitter();

  @Input() disabled = false;

  constructor(private authService: AuthService, private helperService: HelperService) {}

  ngOnInit() {
    this.initComponent();
  }

  async initComponent() {
    this.user = await this.authService.getCurrentUser();
    if (this.user.has_clones && this.user.clones.length) {
      this.companiesList = this.user.clones.map(clone => ({
        id: clone.id,
        company_id: clone.company.id,
        company_uid: clone.company.uid,
        userUid: clone.uid,
        name: clone.company.name,
        hasPlanningToolFunction: !clone.company.admin_disabled_functions.includes(this.helperService.protocol.adminFunctions.PLANNER_TOOL),
      }));
      this.companiesList = sortBy(this.companiesList, 'name');
      this.setCompanyByValue();
    }
  }

  /**
   * get accessor for selectedObject
   * @return {any}
   */
  get selectedCompany(): any {
    return this.value;
  }

  /**
   * set accessor for selectedObject
   * @param v
   */
  set selectedCompany(v: any) {
    if (v !== this.value) {
      this.value = v;
      this._onChange(v);
    }
  }

  writeValue(obj: any): void {
    this.value = obj;
    this.setCompanyByValue();
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  /**
   * Triggers when company is changed
   */
  public onCompanyChange() {
    this.changed.emit();
  }

  private setCompanyByValue() {
    if (!this.companiesList || !this.companiesList.length) {
      return;
    }

    this.selectedCompany = this.companiesList.find(company => {
      if (this.value) {
        if (this.value.company_id) {
          return this.value.company_id === company.company_id;
        } else {
          return this.value.id === company.company_id;
        }
      } else {
        return company.id === this.user.id;
      }
    });
  }
}
