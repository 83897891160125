import { Injectable } from '@angular/core';
import { LangService } from './lang.service';
import { HelperService } from './helper.service';
import { IServiceDate } from '@interfaces';
import { FormatService } from './format.service';

@Injectable({
  providedIn: 'root',
})
export class IntervalTriggerService {
  constructor(
      private langService: LangService,
      private helperService: HelperService,
      private formatService: FormatService,
  ) {
  }

  public getTriggerTypeOdometerText(intervalDueTrigger: any): string|null {
    if (!intervalDueTrigger) {
      return null;
    }

    let txt = null;

    switch (intervalDueTrigger.type) {
      case this.helperService.protocol.intervalTriggerTypes.MANUAL:
        txt = this.langService.t('intervalTrigger.types.manual');
        txt += `, ${this.langService.t('dates.ago', {
          'when': this.formatService.dueWithinValue(intervalDueTrigger.due)
        })}`;

        break;
      case this.helperService.protocol.intervalTriggerTypes.ODOMETER_LOW:
        txt = intervalDueTrigger.odometer ? `${this.langService.t('intervalTrigger.types.low_trigger')}, ${intervalDueTrigger.odometer.name}` : '';

        break;
      case this.helperService.protocol.intervalTriggerTypes.ODOMETER_HIGH:
        txt = intervalDueTrigger.odometer ? `${this.langService.t('intervalTrigger.types.high_trigger')}, ${intervalDueTrigger.odometer.name}` : '';

        break;
    }

    return txt;
  }

  public flattenTriggeredServices(service: IServiceDate) {
    if (!service.interval_due_triggers) {
      return [];
    }

    const serviceTriggers = [];
    service.interval_due_triggers.forEach((trigger) => {
      serviceTriggers.push({
        ...service,
        interval_due_trigger: trigger,
      });
    });

    return serviceTriggers;
  }
}
