<div class="modal-header ion-margin-bottom">
  {{title}}
  <i class="fal fa-window-close" (click)="closeModal()" tappable></i>
</div>

<div class="ion-margin-bottom">
  <app-select-workplace-section [writableOnly]="writableOnly"
                                [workplaceOnly]="workplaceOnly"
                                (changed)="onPlaceChange()"
                                [(ngModel)]="selectedPlace">
  </app-select-workplace-section>
</div>

<div class="ion-text-right ion-padding-vertical">
  <app-button (onClick)="clickNext()" class="object-select-next" [disabled]="disabled" shape="simple">{{'actions.next' | lang}} <i class="far fa-angle-right"></i></app-button>
</div>

