import { IDuePair } from '@interfaces';
import { Injectable } from '@angular/core';
import { AuthService } from '../../../auth.service';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

@Injectable({
  providedIn: 'root'
})
export class DueDateService {
  public constructor(
    private readonly authService: AuthService
  ) {
  }

  /** Check if the due-date is "overdue" */
  public isOverdue(duePair: IDuePair): boolean {
    return (!!duePair.odometer && duePair.odometer.within_value < 0) // Any of due is over
      || (!!duePair.time && duePair.time.due_value < 0);
  }

  /** Check if the due-date is "today" */
  public isToday(duePair: IDuePair): boolean {
    return duePair.time?.when
      && this.getDateString(parseISO(duePair.time.when)) === this.getDateString(new Date());
  }

  /** Check if the due-date is "upcoming" */
  public isUpcoming(duePair: IDuePair): boolean {
    return (
      !this.isNoDueDate(duePair) // Exclude "no-due-date"
      && !this.isOverdue(duePair) // Exclude "overdue"
      && !this.isToday(duePair) // Exclude "today"
    ); // The rest possible option is "upcoming"
  }

  /** Check if the due-date is "no due date" */
  public isNoDueDate(duePair: IDuePair): boolean {
    return !duePair.time && !duePair.odometer; // Doesn't have any due-date
  }

  /** Get date string in company time zone */
  public getDateString(dateTime: Date, withTime?: boolean): string {
    return formatInTimeZone(dateTime, this.authService.companySettings.timeZone , withTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd');
  }

  /** Check if date is end of the day */
  public isEndOfDay(dateTime: Date): boolean {
    return dateTime.getHours() === 23 && dateTime.getMinutes() === 59;
  }
}
