import { Device } from '@capacitor/device';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { HelperService } from './helper.service';
import { ApiService } from './api.service';
import { LangService } from './lang.service';
import { EnvironmentService } from './environment.service';
import { UserAppPreferencesService } from './user-app-preferences.service';
import { NotificationService } from './notification.service';
import { OfflineHandlerService } from './offline/handler.service';
import { sortBy } from '../utils/sort.util';
import { EventsService } from './events.service';
import { ApiNotAvailableError } from '../utils/error.util';
import { PusherService } from './pusher.service';
import { StyleUtil } from '../utils/style.util';
import { AppFeatureEnum } from '@enums';
import { WorktimeService } from './worktime.service';
import { IUser, IWorktimeCategory } from '@interfaces';
import { MicroserviceService } from './microservice.service';
import { IntercomService } from './intercom.service';
import UserInfo from '../constants/user-info';
import { MicroserviceClient } from './clients/microservices/microservice.client';
import {PublicTokenService} from './public-token.service';
import {environment} from '@environments/environment';
import {datadogRum} from '@datadog/browser-rum';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  private afterInit = [];

  constructor(
    private offlineHandlerService: OfflineHandlerService,
    private apiService: ApiService,
    private helperService: HelperService,
    private authService: AuthService,
    private langService: LangService,
    private environmentService: EnvironmentService,
    private userAppPreferencesService: UserAppPreferencesService,
    private notificationService: NotificationService,
    private events: EventsService,
    private pusherService: PusherService,
    private worktimeService: WorktimeService,
    private microservicesService: MicroserviceService,
    private intercomService: IntercomService,
    private planningToolClient: MicroserviceClient,
    private publicTokenService: PublicTokenService,
    private routeSrv: RouteService,
    ) {
    this.events.subscribe('user:logout', () => {
      this.resetBrandings();
    });
    this.events.subscribe('company:itemsChanged', (itemsType) => {
      if (itemsType === 'tags') {
        this.getTags();
      } else if (itemsType === 'categories') {
        this.getCategories();
      } else if (itemsType === 'refillTypes') {
        this.getRefillTypes();
      } else if (itemsType === 'worktimesCategories') {
        this.getWorktimesCategories();
      }
    });
  }

  /**
   * Get list of promises for initialize services
   * @return {Promise<any>}
   */
  public initServices(): Promise<any> {
    return this.environmentService.initService()
      .then(() => {
        this.pusherService.initService();
        this.langService.initService();
        this.apiService.initService();
        this.planningToolClient.initService();
        return this.authService.initService();
      });
  }

  /**
   * Get list of promises that should be initialized for app despite on current user
   * @return {Promise<any>}
   */
  public baseInit(): Promise<any> {
    return this.checkApiStatus()
      .then(() => this.authService.checkAccessToken())
      .then(() => this.getProtocol())
      .catch((error) => {
        let message = '';
        if (typeof error === 'string') {
          message = error;
        } else if (error && error.status === 401) {
          throw error;
        } else {
          message = 'Something went wrong';
        }
        throw new ApiNotAvailableError(message);
      });
  }

  /**
   * Initialize not logged in user
   */
  public async initNotLoggedInUser(): Promise<any> {
    const locale = await this.deviceLanguage();
    await this.fetchTranslations(locale);
  }

  private deviceLanguage(): Promise<any> {
    return Device.getLanguageCode().then(language => {
      const allowed = Object.values(this.helperService.protocol.languages);

      let locale = language.value;
      let exists = true;

      if (allowed.indexOf(locale) === -1 ) {
        locale = locale.split('-')[0];

        if (allowed.indexOf(locale) === -1) {
          exists = false;
        }
      }

      return exists ? locale : '';
    });
  }

  /**
   * Initialize logged in user
   */
  public async initLoggedInUser(): Promise<any> {
    const user = await this.authService.getCurrentUser();
    if (!user) {
      throw new Error('access_denied');
    }

    if (user?.role?.hierarchy === this.helperService.protocol.roles.PUBLIC_USER) {
      await this.initPublicUser(user);
    } else {
      await this.initRegularUser(user);
    }

    return user;
  }

  private async initRegularUser(user): Promise<void> {
    UserInfo.locale = user.language ? user.language : user.company.language;

    await this.fetchTranslations(UserInfo.locale);

    if (user.role.hierarchy === this.helperService.protocol.roles.OBSERVER || user.is_disabled) {
      throw new Error('access_denied');
    }

    if (user.has_clones) {
      const clones = await this.authService.getCurrentUserClones();
      // Only add clones to companies that is active, this filter away inactive companies.
      user.clones = clones.filter(clone => clone.company !== null);
    }

    await Promise.all([
      this.getCategories(),
      this.getRefillTypes(),
      this.getTags(),
      this.notificationService.setupNotifications(user),
      this.notificationService.setupSystemNotifications()
    ]);

    this.authService.setAllowedFeatures();
    this.authService.setCompanySettings();
    this.microservicesService.initMicroService();
    this.setBrandings(user);
    this.userAppPreferencesService.setUser(user);
    if (environment.datadog.enabled) {
      this.setDatadogAnonymousInfo(user);
    }
    this.setUserPreferences(user);
    this.setMeasurements(user);
    this.getWorktimesCategories();
    this.intercomService.initService(user);
    this.events.publish('user:init', user);
  }

  private async initPublicUser(user): Promise<void> {
    await this.initPublicUserLocale(user.company?.language);
    await this.getCategories();

    if (user.is_disabled || !user.publicToken?.token) {
      throw new Error('access_denied');
    }

    await this.publicTokenService.setCurrentPublicToken(user.publicToken.token);
    this.microservicesService.initMicroService();
    this.setBrandings(user);
    if (environment.datadog.enabled) {
      try {
        await this.setDatadogAnonymousInfo(user);
      } catch (e) {
        console.error(e);
      }
    }
    this.setMeasurements(user);
    this.events.publish('user:init', user);
  }

  public async initPublicUserLocale(companyLang?: string) {
    let locale = await this.deviceLanguage();
    if (!locale || (companyLang && companyLang.startsWith(locale + '-'))) {
      locale = companyLang; // support sub-lang, like en-secondary
    }

    UserInfo.locale = locale;
    await this.fetchTranslations(UserInfo.locale);
  }

  /**
   * Triggers after app has been initialized
   */
  public onAppInit() {
    this.notificationService.checkPushNotifications();
    if (this.afterInit.length) {
      const promises = this.afterInit.map((func) => func());
      Promise.all(promises).finally(() => {
        this.afterInit = [];
      });
    }
  }

  /**
   * Run function after app is init
   * @param func
   */
  public runAfterAppInit(func) {
    this.afterInit.push(func);
  }

  /**
   * Triggers when app restores/resumes from background process
   */
  public async restoreApp() {
    const isAuthenticated = await this.authService.isAuthenticated();

    if (!isAuthenticated) {
      return;
    }

    const user = await this.authService.getCurrentUser();
    return this.notificationService.setupNotifications(user);
  }

  /**
   * Get protocol from api
   * @return {Promise<any>}
   */
  private getProtocol(): Promise<any> {
    return this.apiService.get(this.routeSrv.PATHS.protocol).then(protocol => {
      this.helperService.protocol = protocol;
      return protocol;
    });
  }

  /**
   * Get categories from api
   * @return {Promise<any>}
   */
  private getCategories(): Promise<any> {
    return this.apiService.get(this.routeSrv.PATHS.categories).then(categories => {
      this.helperService.categories = categories.slice();
      return categories.slice();
    });
  }

  /**
   * Get refill types from api
   * @return {Promise<any>}
   */
  private getRefillTypes(): Promise<any> {
    return this.apiService.get(this.routeSrv.PATHS.refilltypes.all).then(refilltypes => {
      this.helperService.refillTypes = sortBy(refilltypes, 'name');
      return refilltypes;
    });
  }

  private getTags(): Promise<any> {
    return this.apiService.get(this.routeSrv.PATHS.tags).then(tags => {
      this.helperService.companyTags = sortBy(tags, 'order');
      return this.helperService.companyTags;
    });
  }

  /**
   * Set branding colors based on current user company
   * @param user
   */
  private setBrandings(user): void {
    if (!user || !user.company) {
      return;
    }
    let brandingBackground = 'var(--app-default-branding-background-color)';
    let brandingColor = 'var(--app-default-branding-text-color)';
    let brandingColorNegative = 'var(--app-default-branding-text-color-negative)';

    if (user.company.branding) {
      brandingBackground = user.company.branding;

      if (!StyleUtil.isBrightColor(brandingBackground)) {
        brandingColor = '#fff';
        brandingColorNegative = 'var(--app-default-branding-text-color)';
        this.helperService.isLightTheme = false;
      }
    }

    document.documentElement.style.setProperty('--app-branding-background-color', brandingBackground);
    document.documentElement.style.setProperty('--app-branding-text-color', brandingColor);
    document.documentElement.style.setProperty('--app-branding-text-color-negative', brandingColorNegative);
  }

  private resetBrandings() {
    document.documentElement.style.removeProperty('--app-branding-background-color');
    document.documentElement.style.removeProperty('--app-branding-text-color');
    document.documentElement.style.removeProperty('--app-branding-text-color-negative');
  }

  /**
   * Fetch translations.
   *
   * If offline mode: fetch from storage.
   *
   * If not offline mode: check translation version from api and if translation matches
   * then fetch from storage, else fetch fresh translations from api.
   */
  public async fetchTranslations(locale: string): Promise<any> {
    const isOffline = await this.offlineHandlerService.isOfflineEnable();
    if (isOffline) {
      const storageTranslations = await this.langService.getData(locale);
      if (storageTranslations !== null) {
        this.langService.setTranslations({
          messages: storageTranslations,
          locale: locale
        });
      }
      return;
    }

    const apiVersion = await this.apiService.get(this.routeSrv.PATHS.translations.version);
    const storageVersion = await this.langService.getData('version');

    if (storageVersion !== null && apiVersion.version === storageVersion) {
      const storageTranslations = await this.langService.getData(locale);
      if (storageTranslations !== null) {
        this.langService.setTranslations({
          messages: storageTranslations,
          locale: locale
        });
        return;
      }
    }

    const query = locale.length ? { locale } : {};
    const apiTranslations = await this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.translations.all, query));
    this.langService.setTranslations({
      messages: apiTranslations,
      locale: locale
    });

    await this.langService.setData('version', apiVersion.version);
    await this.langService.setData(locale, apiTranslations);
  }

  private setMeasurements(user) {
    if (!user || !user.company) {
      return;
    }

    this.helperService.measurementSystem = user.company.measurement === this.helperService.protocol.measurements.METRIC ? 'metric' : 'imperial';

    this.helperService.measurementUnits.volume = this.langService.t('measurements.' + this.helperService.measurementSystem + '.volumeShort');
    this.helperService.measurementUnits.distance = this.langService.t('measurements.' + this.helperService.measurementSystem + '.distanceShort');
    this.helperService.measurementUnits.weight = this.langService.t('measurements.' + this.helperService.measurementSystem + '.weightShort');
    this.helperService.measurementUnits.length = this.langService.t('measurements.' + this.helperService.measurementSystem + '.lengthShort');
    this.helperService.measurementUnits.area = this.langService.t('measurements.' + this.helperService.measurementSystem + '.areaShort');
    this.helperService.measurementUnits.amount = this.langService.t('article-measurements.amount');
    this.helperService.measurementUnits.hour = this.langService.t('protocol.odometerTypes.hoursShort');
  }

  /**
   * Check if API server is available
   * @return {Promise}
   */
  private checkApiStatus() {
    return new Promise<void>((resolve, reject) => {
      this.apiService.checkApiStatus().then(res => {
        if (!res || !res.status) {
          throw new Error();
        }

        if (res.status === 200) {
          return resolve();
        }

        return reject(res.message);
      }).catch(() => {
        reject('Checkproof\'s server is not responding right now or you are offline. Please try again later!');
      });
    });
  }

  /**
   * Get getWorktimesCategories from API server
   * @return {Promise}
   */
  private async getWorktimesCategories(): Promise<IWorktimeCategory[]> {
    return this.worktimeService.getCategories().then(worktimesCategories => {
      this.helperService.worktimesCategories = worktimesCategories;
      return this.helperService.worktimesCategories;
    });
  }

  private async setDatadogAnonymousInfo(user: IUser) {
    datadogRum.setUser({
      id: user.id.toString(),
      uid: user.uid,
      company_id: user.company_id,
      server_region: this.environmentService.isRegionUSA() ? 'USA' : 'EU',
      offline_mode: await this.offlineHandlerService.isOfflineEnable(),
      planning_tool_company:  await this.authService.getAllowedFeatures()[AppFeatureEnum.PLANNER_TOOL],
    });
  }

  private async setUserPreferences(user: IUser) {
    if (user.hasOwnProperty('settings') && user.settings.length >= 1) {
      // in the future we need to add a checker to know if settings saved in db are including all setting
      const currentAppPref = await this.userAppPreferencesService.getAppPreferences();
      if (currentAppPref !== null && currentAppPref.length >= 1) {
        // check if the current user has settings saved on the phone different from db than we update local
        currentAppPref.forEach(appPref => {
          if (!this.userAppPreferencesService.isLocalSettingSameAsDbSetting(appPref, user.settings)) {
            this.userAppPreferencesService.setAppPreferences(user.settings);
          }
        });
      } else {
        // otherwise no settings exist on the phone and we setup the one saved in db
        await this.userAppPreferencesService.setAppPreferences(user.settings);
      }
    } else {
      // if no settings exist in db and device for this user we setup app preferences and save it in db
      // update this function each time we will add a new setting in our setting page
      await this.userAppPreferencesService.initFirstTimeAppPreferences();
    }
  }
}
