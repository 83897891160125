import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { ApiService } from './api.service';
import { FileService } from './file.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})

export class S3Service {
  constructor(
    private http: HttpClient,
    private httpNative: HTTP,
    private apiService: ApiService,
    private fileService: FileService,
    private routeSrv: RouteService,
  ) {
  }

  private getExtension(mimetype: string): string {
    switch (mimetype) {
      case 'video/quicktime':
        return '.mov';

      case 'video/mp4':
        return '.mp4';
    }

    return '';
  }

  private base64ToUint8(base64) {
    return Uint8Array.from(window.atob(base64), (v) => v.charCodeAt(0));
  }

  public async uploadVideo(uid: string, file: any, mimetype: string): Promise<string> {
    const filename = uid + this.getExtension(mimetype);

    const uploadKey = await this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.assets.getvideokey, { filename }));

    if (typeof file === 'string') {
      file = await this.fileService.readFile(file);
      file = new Blob([this.base64ToUint8(file.data)], { type: mimetype });
    }
    const headers = new HttpHeaders({ 'Content-Type': mimetype });
    await this.http.put(uploadKey.uri, file, { headers: headers }).toPromise();

    return filename;
  }
}
