import AbstractObjectBuilder from '../abstract.object.builder';
import { IUnitFilterSet } from '@interfaces';

/** Builder of unit filter */
export default class UnitBuilder extends AbstractObjectBuilder<IUnitFilterSet> {
  /** Include/exclude service tasks */
  private enabledServices = false;

  /** Include service type tasks */
  public withServices(): this {
    this.enabledServices = true;
    return this;
  }

  /** Exclude service type tasks */
  public withoutServices(): this {
    this.enabledServices = false;
    return this;
  }

  /** @inheritDoc */
  public build(): IUnitFilterSet {
    return Object.assign(
      super.build(),
      {services: this.enabledServices}
    );
  }

  /** @inheritDoc */
  public reset(): this {
    super.reset();
    this.enabledServices = false;
    return this;
  }
}
