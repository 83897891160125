import { Component, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-segment-button',
  templateUrl: './segment-button.component.html',
  styleUrls: ['./segment-button.component.scss']
})
export class SegmentButtonComponent {
  @Input() checked = false;
  @Input() disabled = false;

  @HostBinding('attr.checked')
  get attrChecked() {
    return (this.checked ? '' : null);
  }

  @HostBinding('attr.disabled')
  get attrDisabled() {
    return (this.disabled ? '' : null);
  }

  constructor() { }

}
