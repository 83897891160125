import { Component } from '@angular/core';
import { ModalService, NotificationService } from '@services';
import { INotification } from '@interfaces';

@Component({
  selector: 'app-privacy-policy-updated',
  templateUrl: './privacy-policy-updated.component.html',
  styleUrls: ['./privacy-policy-updated.component.scss'],
})
export class PrivacyPolicyUpdatedComponent {
  public notification: INotification;

  constructor(private modalService: ModalService, private notificationService: NotificationService) {}

  public closeModal() {
    this.notificationService.readSystemNotification(this.notification.id);
    this.modalService.close();
  }
}
