export class ArrayUtil {
  /**
   * helper function to compare two arrays based on object ids
   * @param arrA
   * @param arrB
   * @return {boolean}
   */
  static isDifferentArrays(arrA, arrB) {
    arrA = arrA || [];
    if (!Array.isArray(arrA)) {
      arrA = [arrA];
    }

    arrB = arrB || [];
    if (!Array.isArray(arrB)) {
      arrB = [arrB];
    }

    let isDifferent = false;
    if (arrA.length !== arrB.length) {
      isDifferent = true;
    } else if (arrA.length) {
      arrA.forEach(itemA => {
        const found = arrB.find(itemB => itemB.id === itemA.id);
        if (!found) {
          isDifferent = true;
        }
      });
    }

    return isDifferent;
  }
}
