import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-nc-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MessageComponent implements OnInit {
  @Input() message;
  @Output() markAsRead = new EventEmitter();

  public isRead: boolean;
  public isExpanded = false;

  constructor() { }

  ngOnInit() {
    this.isRead = (this.message.read !== false);
  }

  /**
   * Toggle collapsed state
   */
  public toggleCollapse(): void {
    this.isExpanded = !this.isExpanded;

    // Mark messages as read when collapsing an unread message
    if (!this.isExpanded && !this.isRead && this.message.read) {
      this.isRead = true;
    }

    if (this.message.read === false) {
      this.message.read = true;
      this.markAsRead.emit();
    }
  }
}
