import { IApiOptions } from '@interfaces';
import { RequestsHandler } from '../requests-handler';
import cloneDeep from 'lodash/cloneDeep';

export class OfflineRequestBase {
  protected options = <IApiOptions>{
    forceOnline: true,
    headers: {
      'Checkproof-App-Offline-Request': 'yes'
    }
  };

  constructor(
    public requestsHandler: RequestsHandler
  ) {}

  public execute(request) {
    return this.sendRequest(request);
  }

  /**
   * Send request to api
   * @param request
   * @return {Promise<any>}
   */
  public sendRequest(request): Promise<any> {
    let promise = Promise.resolve();

    const options = cloneDeep(this.options);
    if (request.options) {
      Object.assign(options, request.options);
    }
    if (request.id) {
      options.headers['request-id'] = request.id;
    }

    if (request.method === 'post') {
      if (request.data && request.data.id) {
        delete request.data.id;  // remove temporary id
      }
      promise = this.requestsHandler.apiService.post(request.url, request.data, options);
    } else if (request.method === 'put') {
      promise = this.requestsHandler.apiService.put(request.url, request.data, options);
    } else if (request.method === 'delete') {
      promise = this.requestsHandler.apiService.delete(request.url, options);
    }

    return promise;
  }

  /**
   * Recursive method to process all request's chunks
   * @param obj
   * @param args
   * @return {Promise<any>}
   */
  protected handleRequestChunk(obj, ...args): Promise<any> {
    let promise = null;
    if (obj.completed) {
      promise = Promise.resolve(obj.response);
    } else if (obj.method && this[obj.method]) {
      promise = this[obj.method](obj, ...args);
    }

    return promise.then(res => {
      obj.response = res;
      obj.completed = true;

      if (!obj.children || !obj.children.length) {
        return res;
      }

      const promises = [];
      obj.children.forEach((child) => {
        promises.push(this.handleRequestChunk(child, res, ...args));
      });
      return Promise.all(promises).then(() => {
        // return response from parent request
        return res;
      });
    });
  }
}
