import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { IPositionShort} from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class PositionsClient {
  constructor(
    private readonly apiService: ApiService,
    private readonly routeService: RouteService,
  ) {
  }

  public getPositionsByUids(uids: string[]): Promise<IPositionShort[]> {
    return this.apiService.post(
      this.routeService.makeUrl(
        this.routeService.PATHS.positions.batches.list,
      ),
      { uids }
    );
  }
}
