import { Injectable } from '@angular/core';
import { TasksService } from '../tasks/tasks.service';
import { ServicesListService } from '../../services-list.service';
import { UnitService } from '../../unit.service';
import { from, Observable } from 'rxjs';
import { combineLatest, map, mergeMap } from 'rxjs/operators';
import { IUnit, ITask, IServiceDate, IDuePair, IDateTimeDue, IOdometerDue } from '@interfaces';
import { DueServiceDateTransformer } from '../tasks/transformers/due-service-date/due-service-date.transformer';
import { AuthService } from '../../auth.service';
import { AppFeatureEnum } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class ServiceIntervalsService {
  constructor(
    private readonly tasksSrv: TasksService,
    private readonly unitsSrv: UnitService,
    private readonly servicesSrv: ServicesListService,
    private readonly dueServiceDateTrans: DueServiceDateTransformer,
    private readonly authSrv: AuthService,
  ) {
  }

  /** Fetch planned service-dates (overdue, upcoming) of unit */
  public getServiceDates(unitId: number): Observable<IServiceDate[]> {
    const stream = this.fetchServiceDates(unitId);

    if (!this.authSrv.isFeatureAllowed(AppFeatureEnum.PLANNER_TOOL)) { // Return as is
      return stream;
    }

    return stream
      .pipe( // Complete service-dates by due-dates from PT
        combineLatest(this.fetchTasks(unitId)),
        map(
          ([serviceDates, tasks]: [IServiceDate[], ITask[]]) => {
            // Index tasks by interval UIDs
            const timeDataItems = new Map<string, IDateTimeDue>();
            const odoDataItems = new Map<string, IOdometerDue>();
            tasks.forEach(task => {
              timeDataItems.set(task.origin.id, task.dateTime_data);
              odoDataItems.set(task.origin.id, task.odometer_data);
            });

            // Complete tasks by due-dates
            return serviceDates.map(
              serviceDate => {
                // Define set of interval tasks
                const uids = ServiceIntervalsService.getDateIntervalUids(serviceDate);
                const pair: IDuePair = {
                  time: uids.map(uid => timeDataItems.get(uid)).find((data) => data),
                  odometer: uids.map(uid => odoDataItems.get(uid)).find((data) => data),
                };

                // Replace intervals by ones from PT
                return (pair.time || pair.odometer)
                  ? this.dueServiceDateTrans.transform(
                    serviceDate,
                    pair
                  )
                  : null;
              }
            )
              .filter(serviceDate => serviceDate); // Filter service-dates without tasks
          })
      );
  }

  /** Fetch unit service-dates */
  private fetchServiceDates(unitId: number): Observable<IServiceDate[]> {
    return from(
      this.servicesSrv.getUpcomingServices(unitId)
    );
  }

  /** Fetch unit services tasks */
  private fetchTasks(unitId: number): Observable<ITask[]> {
    return this.fetchUnit(unitId)
      .pipe(
        mergeMap(
          unit => this.tasksSrv
            .requireServices(unit.uid)
            .fetchAll()
        ),
        map(page => page.data)
      );
  }

  /** Fetch unit model */
  private fetchUnit(unitId: number): Observable<IUnit> {
    return from(this.unitsSrv.getUnit(unitId));
  }

  /** Get interval identifiers from service-date */
  private static getDateIntervalUids(serviceDate: IServiceDate): string[] {
    return Array.from(
      new Set<string>([
        serviceDate.interval?.uid,
        (serviceDate.smart_intervals || [])[0]?.interval?.uid,
        (serviceDate.smart_intervals || [])[1]?.interval?.uid,
      ])
    ).filter(uid => uid);
  }
}
