import { Injectable } from '@angular/core';
import { OptionUtil } from '../utils/option.util';
import { IMatrixSettings, IComponentSettings } from '@interfaces';
import { HelperService } from './helper.service';

@Injectable({
  providedIn: 'root'
})
export class FormComponentService {
  constructor(
    private helperService: HelperService,
  ) {
  }

  public getComponentSettings(component): IComponentSettings {
    const settings = this.getDefaultSettings(component.type);

    this.applySnapshotToSettings(component, settings);

    if (component.settings && 'object' === typeof component.settings && !Array.isArray(component.settings)) {
      return Object.assign(settings, component.settings);
    }

    if (component.options) {
      this.applyOptionsToSettings(component.type, component.options, settings);
    }

    return settings;
  }

  private applyOptionsToSettings(type: number, options: string, settings: IComponentSettings) {
    const optionsArray = OptionUtil.parseOptions(options);

    if (type === this.helperService.protocol.inputTypes.TEXT_FIELD) {
      settings.singleRow = options === 'singleRow';
    } else if (type === this.helperService.protocol.inputTypes.RELATION) {
      settings.relationType = options || null;
    } else if (type === this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_CHECKBOX) {
      settings.options = optionsArray.map(option => ({
        val: option,
        required: false,
      }));
    } else if (
      type === this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_RADIOBUTTON ||
      type === this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_SELECT
    ) {
      settings.options = optionsArray.map(option => ({
        val: option,
      }));
    } else if (
      type === this.helperService.protocol.inputTypes.TAKE_PHOTO ||
      type === this.helperService.protocol.inputTypes.IMAGE
    ) {
      settings.maxImages = parseInt(optionsArray[0], 10);
      if (optionsArray.length > 1 && optionsArray[1] === 'true') {
        settings.allowCameraRoll = true;
      }
    } else if (type === this.helperService.protocol.inputTypes.ODOMETER) {
      settings.odometerOrder = parseInt(options, 10) || 0;
    } else if (type === this.helperService.protocol.inputTypes.DATE) {
      settings.dateFormat = options;
    } else if (type === this.helperService.protocol.inputTypes.NUMBER) {
      settings.decimalCount = parseInt(options, 10);
    } else if (
      type === this.helperService.protocol.inputTypes.MONEY ||
      type === this.helperService.protocol.inputTypes.COST
    ) {
      settings.currency = options.toUpperCase();
    }
  }

  private getDefaultSettings(type): IComponentSettings {
    switch (type) {
      case this.helperService.protocol.inputTypes.TEXT_FIELD:
        return {
          singleRow: false
        };
      case this.helperService.protocol.inputTypes.TAKE_PHOTO:
      case this.helperService.protocol.inputTypes.IMAGE:
        return {
          maxImages: 4,
          allowCameraRoll: false
        };
      case this.helperService.protocol.inputTypes.COST:
      case this.helperService.protocol.inputTypes.MONEY:
        return {
          currency: 'SEK'
        };
      case this.helperService.protocol.inputTypes.RELATION:
        return {
          relationType: null
        };
      case this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_CHECKBOX:
      case this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_RADIOBUTTON:
      case this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_SELECT:
        return {
          options: []
        };
      case this.helperService.protocol.inputTypes.ODOMETER:
        return {
          odometerOrder: 0
        };
      case this.helperService.protocol.inputTypes.DATE:
        return {
          dateFormat: 'datetime'
        };
      case this.helperService.protocol.inputTypes.NUMBER:
        return {
          decimalCount: 0,
          percentage: false
        };
    }

    return {};
  }

  private applySnapshotToSettings(component, settings: IComponentSettings) {
    if (!component.control_point_snapshot) {
      return;
    }

    const controlPointSnapshot = 'object' === typeof component.control_point_snapshot
      ? component.control_point_snapshot
      : JSON.parse(component.control_point_snapshot);

    if (component.type === this.helperService.protocol.inputTypes.NUMBER) {
      settings.percentage =  controlPointSnapshot.is_percentage || false;
    } else if (component.type === this.helperService.protocol.inputTypes.MULTIPLE_CHOICE_CHECKBOX) {
      if (controlPointSnapshot && controlPointSnapshot.options_settings) {
        settings.options = controlPointSnapshot.options_settings.map(option => ({
          label: option.val,
          required: option.required || false
        }));
      }
    } else if (component.type === this.helperService.protocol.inputTypes.MATRIX) {
      settings.matrixSettings = controlPointSnapshot?.options_settings as IMatrixSettings;
    }
  }
}
