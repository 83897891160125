import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { NavController } from '@ionic/angular';
import { AppInitService } from './app-init.service';
import { HelperService } from './helper.service';
import { OfflineHandlerService } from './offline/handler.service';
import { LangService } from './lang.service';
import { ActionSheetService } from './action-sheet.service';

@Injectable({
  providedIn: 'root'
})
export class SharedUserService {
  constructor(
    private authService: AuthService,
    private navController: NavController,
    private appInitService: AppInitService,
    private helperService: HelperService,
    private offlineHandlerService: OfflineHandlerService,
    private actionSheetService: ActionSheetService,
    private langService: LangService
  ) { }

  /**
   * Initiating switch company logic.
   */
  public switchCompany(): void {
    this.helperService.showLoader().then(() => {
      this.authService.getCurrentUser()
        .then(user => {
          // Show actionsheet
          this.helperService.dismissLoader().then(() => {
            this.showSwitchCompanyActionSheet(user);
          });
        });
    });
  }

  /**
   * Generates buttons from user clones and displaying company actionsheet.
   * @param user
   */
  async showSwitchCompanyActionSheet(user) {
    const buttons = [];
    for (let i = 0; i < user.clones.length; i++) {
      buttons.push({
        text: user.clones[i].company.name,
        cssClass: (user.company.id === user.clones[i].company.id) ? 'action-sheet-icon-fa check' : '', // Mark the active company
        handler: () => {
          this.checkSwitchCompany({ 'original_user': user, 'switch_to_user': user.clones[i] });
        }
      });
    }

    buttons.push({
      text: this.langService.t('actions.cancel'),
      role: 'cancel',
      handler: () => {}
    });

    await this.actionSheetService.open({
      header: this.langService.t('companies.company.choose'),
      buttons: buttons
    });
  }

  /**
   * Navigate to some url from other company
   * @param company_id
   * @param url
   * @return {Promise<void>}
   */
  public async navigateTo(company_id, url) {
    const user = await this.authService.getCurrentUser();
    const userClone = user.clones.find(clone => clone.company.id === company_id);
    if (!userClone) {
      return;
    }

    const switchData = {
      original_user: user,
      switch_to_user: userClone,
      url: url
    };

    this.checkSwitchCompany(switchData);
  }

  /**
   * Checks if user tries to switch to already logged in company or if app is in offline mode.
   * If app is in offline mode, user will be notified offline mode will be reset
   *
   * @param switchData
   */
  private checkSwitchCompany(switchData): void {
    // If user is trying to switch to already logged in company nothing will happen
    if (switchData.switch_to_user.id === switchData.original_user.id) {
      this.helperService.dismissLoader();
    } else {
      // Checks if app is in offline mode
      this.offlineHandlerService.isOfflineActive()
        .then(isOffline => {
          if (isOffline) {
            this.helperService.showAlert({
              header: this.langService.t('offline.alert-title'),
              message: this.langService.t('offline.switch-company.message'),
              buttons: [
                {
                  text: this.langService.t('actions.cancel'),
                  role: 'cancel',
                  handler: () => {}
                }, {
                  text: this.langService.t('actions.continue'),
                  handler: () => {
                    // Continue switch company process
                    this.offlineHandlerService.onUserLogout()
                      .then(() => {
                        this.doSwitchCompany(switchData);
                      });
                  }
                }
              ]
            });
          } else { // If app not in offline mode
            this.doSwitchCompany(switchData);
          }
        });
    }
  }

  /**
   * Performing the switch company logic.
   *
   * @param switchData
   */
  private doSwitchCompany(switchData): void {
    this.navController.navigateRoot(['/loading'], { animated: true, animationDirection: 'forward'})
      .then(() => {
        this.helperService.showLoader({ message: this.langService.t('offline.switch-company.loading-message')})
          .then(() => {

            this.authService.switchUser(switchData.switch_to_user.id)
              .then(() => {
                this.appInitService.initLoggedInUser()
                  .then(() => {
                    const url = switchData.url || ['/home'];
                    // Route to homescreen after switching user process is done
                    this.navController.navigateRoot(url, { animated: true, animationDirection: 'back'})
                      .then(() => {
                        // Dismissing loader after routing is done
                        this.helperService.dismissLoader();
                      });
                  });
              })
              .catch((err) => {
                console.error(err);
              });
          });
      });
  }
}
