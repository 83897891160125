<div *ngIf="companiesList && companiesList.length">
  <div class="card-label">{{'companies.company.singular' | lang}}</div>
  <app-select-dropdown  class="ion-margin-bottom"
                        [placeholder]="'companies.company.choose' | lang"
                        [items]="companiesList"
                        [clearable]="false"
                        (changed)="onCompanyChange()"
                        [(ngModel)]="selectedCompany"
                        [disabled]="disabled">
    <ng-template #itemContent let-item>
      {{item.name}}
    </ng-template>
  </app-select-dropdown>
</div>
