<div class="card-label" *ngIf="unitsOnly">{{'units.singular' | lang}}</div>
<div class="card-label" *ngIf="roundsOnly">{{'rounds.singular' | lang}}</div>
<div class="card-label" *ngIf="!unitsOnly && !roundsOnly">{{'units.singular' | lang}} / {{'rounds.singular' | lang}}</div>
<div class="card-label">
  <app-checkbox *ngIf="withSelectAllButton"
                [label]="'general.all' | lang"
                [(ngModel)]="allObjectsChecked"
                (ionChange)="onAllObjectsChecked()"
                [style]="'horizontal'"></app-checkbox>
</div>
<app-select-dropdown class="ion-margin-bottom" #objSelectDrop
                     [placeholder]="(unitsOnly ? 'objects.chooseUnit' : (roundsOnly ? 'objects.chooseRound' : 'objects.chooseObject')) | lang"
                     groupBy="objects"
                     [multiple]="multiple"
                     [items]="objectsPromise"
                     [isLoading]="objectsLoading"
                     [disabled]="isDisabled || allObjectsChecked"
                     [isRequired]="isRequired"
                     [showOverflow]="true"
                     (typeahead)="onObjectsSearch($event)"
                     (changed)="onObjectChange()"
                     (closed)="onSelectClose()"
                     (scrollChange)="scrolled()"
                     [(ngModel)]="selectedObject">
  <ng-template #itemContent let-item>
    <div *ngIf="showChecksStatusBar" class="object-status-bar">
      <div *ngFor="let status of item.checkStatuses" [ngClass]="status"></div>
    </div>
    <div [ngClass]="{'inactive-option': !item.in_use}" class="select-option-favorite">
      <div>
        <i [ngClass]="{'object-status-in-use': item.in_use, 'object-status-not-in-use': !item.in_use}" class="object-status"></i>
        {{item.name}}
      </div>
      <ul class="text-medium list-nested-names">
        <li>{{item.workplace.name}}</li>
        <li *ngIf="item.section && item.section.length">{{item.section[0].name}}</li>
      </ul>
      <i *ngIf="withFavorite" [ngClass]="{'fas': item.isFavorite, 'far': !item.isFavorite}" class="fa-star action-make-favorite" (click)="!!toggleFavorite($event, 'object', item)" tappable></i>
    </div>
  </ng-template>
</app-select-dropdown>
