import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IPermissible } from '@interfaces';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class PermissibleService {
  constructor(private apiService: ApiService, private routeSrv: RouteService) {}

  private listsCache = <any>{};

  /**
   * Get permissibles list for unit/round
   * @param object
   */
  public getListForObject(object): Promise<IPermissible[]> {
    if (!object) {
      return Promise.resolve([]);
    }

    if (object.section && object.section.length) {
      return this.getListForSection(object.section[0].id);
    } else if (object.workplace_id) {
      return this.getListForWorkplace(object.workplace_id);
    }

    return Promise.resolve([]);
  }

  /**
   * Get permissibles list for workplace/section
   * @param workplace_id
   * @param section_id
   * @return {any}
   */
  public getListForPlaceable(workplace_id, section_id) {
    if (section_id) {
      return this.getListForSection(section_id);
    } else if (workplace_id) {
      return this.getListForWorkplace(workplace_id);
    } else {
      return Promise.resolve([]);
    }
  }

  /**
   * Get permissibles list workplace
   * @param workplace_id
   * @return {any}
   */
  public getListForWorkplace(workplace_id) {
    return this.getList(this.routeSrv.makeUrl(this.routeSrv.PATHS.workplaces.permissibles, { id: workplace_id }));
  }

  /**
   * Get permissibles list for section
   * @param section_id
   * @return {any}
   */
  public getListForSection(section_id) {
    return this.getList(this.routeSrv.makeUrl(this.routeSrv.PATHS.sections.permissibles, { id: section_id }));
  }

  /**
   * Get permissibles list
   * @param url
   * @return {any}
   */
  private getList(url) {
    if (this.listsCache.hasOwnProperty(url)) {
      return Promise.resolve(this.listsCache[url]);
    }

    return this.apiService.get(url).then(data => {
      this.listsCache[url] = data;
      return data;
    });
  }
}
