import { Injectable } from '@angular/core';
import { PublicTokensClient } from './clients/cp-api/public-tokens/public-tokens.client';
import { IPublicToken } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class PublicTokenService {
  private publicToken: IPublicToken;

  constructor(private publicTokensClient: PublicTokensClient) {}

  async setCurrentPublicToken(token: string): Promise<void> {
    this.publicToken = await this.publicTokensClient.get(token);
  }

  getCurrentPublicToken(): IPublicToken {
    return this.publicToken;
  }
}
