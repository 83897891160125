import { BaseTask } from './baseTask';
import { DeviationUtil } from '../../utils/deviation.util';
import {differenceInDays, isSameDay, parseISO} from 'date-fns';
import { GroupTypeEnum, TaskTypeEnum } from '@enums';

export class DeviationTask extends BaseTask {

    constructor(res) {
        super(res, TaskTypeEnum.DEVIATION);
        this.group = GroupTypeEnum.UPCOMING;
        // Days overdue
        this.due = 0;
        if (res?.resource?.due_date) {
            const today = new Date();
            const due_date = parseISO(res.resource.due_date);
            this.due = differenceInDays(due_date, today);
            if (isSameDay(today, due_date)) {
                this.group = GroupTypeEnum.TODAY;
                this.due = 0;
            } else if (this.due < 0) {
                this.group = GroupTypeEnum.OVERDUE;
            }
        } else {
            this.group = GroupTypeEnum.TODAY;
        }
        this.name = DeviationUtil.formatLabel(res?.resource || { label: '' });
    }
}
