import find from 'lodash/find';
import { ICategory } from '@interfaces';

export class CategoryUtil {
  /**
   * Get category by category id
   * @param id
   * @param categories
   * @return {}
   */
  static getCategoryRecursive(id, categories) {
    for (let i = 0; i < categories.length; i++ ) {
      if (categories[i].id === id) {
        return categories[i];
      }
      if (categories[i].children && categories[i].children.length) {
        const subCategory = this.getCategoryRecursive(id, categories[i].children);
        if (subCategory) {
          return subCategory;
        }
      }
    }

    return null;
  }

  /**
   * Get all related categories by category id
   * @param id
   * @param categories
   * @param selected
   * @return {boolean}
   */
  static getCategoriesRecursive(id, categories, selected) {
    for (let i = 0; i < categories.length; i++ ) {
      if (categories[i].id === id) {
        selected.push(categories[i]);
        return true;
      }
      if (categories[i].children && categories[i].children.length) {
        if (this.getCategoriesRecursive(id, categories[i].children, selected)) {
          selected.unshift(categories[i]);
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Convert category object to corresponding array of categories
   * @param category
   * @return {any[]}
   */
  static convertCategoryToArray(category) {
    if (!category) {
      return [];
    }

    const sortedCategories = [];
    sortedCategories.push({id: category.id});
    if (category.parent) {
      let parent = category.parent;
      while (parent) {
        sortedCategories.unshift({id: parent.id});
        parent = parent.parent;
      }
    }

    return sortedCategories;
  }

  /**
   * Get array of category ids that is "valid" for current setup
   */
  static getValidCategoryIds(selectedCategories) {
    const validCategories = [];

    selectedCategories.forEach((category, index) => {
      validCategories.push(category.id);

      if (selectedCategories.length < 3 && index === selectedCategories.length - 1) {
        // if selected only 1st or 2nd level - add all children to validCategories
        if (category.children) {
          category.children.forEach(child => {
            validCategories.push(child.id);

            // if selected only 1st level - add all children to validCategories
            if (selectedCategories.length < 2 && child.children) {
              child.children.forEach(subChild => {
                validCategories.push(subChild.id);
              });
            }
          });
        }
      }
    });

    return validCategories;
  }

  /**
   * Get category level in hierarchy
   * @param category
   * @param categories
   * @return {number}
   */
  static getCategoryLevel(category, categories) {
    let level = 1;
    if (category.parent_id === 0) {
      return level;
    }

    let nextCategory = find(categories, { parent_id: 0 });
    while (nextCategory) {
      level++;
      nextCategory = find(categories, {parent_id: nextCategory.id});

      if (nextCategory.id === category.id || level > 10) {
        break;
      }
    }

    return level;
  }

  /**
   * Get a category by id as an array with all it's parents
   * @param id
   * @param tree
   */
  static getCategoryWithParents(id: number, tree: ICategory[]): ICategory[] {
    const categories: ICategory[] = [];

    for (const category of tree) {
      if (id === category.id) {
        categories.push(category);
        break;
      }

      if (category.children?.length > 0) {
        const subCats = this.getCategoryWithParents(id, category.children);
        if (subCats.length > 0) {
          categories.push(...subCats); // add all found sub categories
          categories.unshift(category); // add parent category to the beginning of array
        }
      }
    }

    return categories;
  }
}
