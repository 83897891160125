/**
 * Run a promise with a timeout
 * @param mainPromise
 * @param time
 * @throws PromiseTimeoutError
 */
export function promiseTimeout(mainPromise, time): Promise<any> {
  let timer;

  return Promise.race([
    mainPromise,
    new Promise((resolve, reject) => timer = setTimeout(reject, time, new PromiseTimeoutError()))
  ]).finally(() => clearTimeout(timer));
}

/**
 * Error entity for promise timeouts
 * @param message
 */
export function PromiseTimeoutError(message = null) {
  this.message = message;
}
