export {
  FNS_ATOM_DATE_TIME,
  FNS_DB_DATE_TIME,
  FNS_YYYY_MM_DD,
  FNS_YYYYMMDD,
  FNS_DD_MM_YYYY,
  FNS_DDMMMYYYY,
  FNS_DDMMYYYYHHmm,
  FNS_DD_MMY_YYY_HHmm,
  FNS_DDddMMM,
  FNS_HHmm,
  FNS_EddMMM,
  FNS_MMMM_do,
  FNS_EddMMMHHmm
};

/** Date time format */
const FNS_ATOM_DATE_TIME = 'yyyy-MM-dd\'T\'HH:mm:ssxxx';

/** Date time without time zone */
const FNS_DB_DATE_TIME = 'yyyy-MM-dd HH:mm:ss';

////////////
////// USE IN format-date.util.ts
////////////

// 2021/09/24
const FNS_YYYY_MM_DD = 'yyyy/MM/dd';
// 2021/09/24
const FNS_YYYYMMDD = 'yyyy-MM-dd';
// 24/09/2021
const FNS_DD_MM_YYYY = 'dd/MM/yyyy';
// 24 Sept 2021
const FNS_DDMMMYYYY = 'dd MMM yyyy';
// 24/09/2021, 09:41
const FNS_DDMMYYYYHHmm = 'dd/MM/yyyy, HH:mm';
// 13 Apr 2022, 02:00
const FNS_DD_MMY_YYY_HHmm = 'dd MMM yyyy, HH:mm';
// 24/09/2021, 09:41
const FNS_DDddMMM = 'DD dd MMM';
// 09:41 = Moment LT
const FNS_HHmm = 'HH:mm';
// Wed Apr 06 2022
const FNS_EddMMM = 'E dd MMM';
// Wed Apr 06 2022 13:25
const FNS_EddMMMHHmm = 'E dd MMM HH:mm';
// May 31st
const FNS_MMMM_do = 'MMMM do';
