import natsort from 'natsort';
import get from 'lodash/get';
import {getUnixTime, parseISO} from 'date-fns';

/**
 * Sort array by some field (use natural sort function)
 * @param {Array<any>} items
 * @param {string} field
 * @param {boolean} descending
 * @return {Array<any>}
 */
export function sortBy(items: Array<any>, field: string, descending = false): Array<any> {
  if (!items || !items.length) {
    return items;
  }

  const natSortFunc = natsort({desc: descending});
  return items.sort((a, b) => {
    return natSortFunc(a[field], b[field]);
  });
}

/**
 * Sort array by multiple fields
 * @param {Array<any>} items
 * @param fields
 * @return {Array<any>}
 */
export function sortByMultiple(items: Array<any>, fields): Array<any> {
  if (!items || !items.length) {
    return items;
  }

  const natSortFuncs = [];
  Object.keys(fields).forEach((field) => {
    natSortFuncs.push({
      field: field,
      func: natsort({desc: fields[field] === 'desc'})
    });
  });

  return items.sort((a, b) => {
    for (let i = 0; i < natSortFuncs.length; i++) {
      const field = natSortFuncs[i].field;
      const res = natSortFuncs[i].func(a[field], b[field]);
      if (res) {
        return res;
      }
    }

    return 0;
  });
}

/**
 * Sort items by date field
 * @param {Array<any>} items
 * @param {string} field
 * @param {boolean} descending
 * @return {Array<any>}
 */
export function sortByDateField(items: Array<any>, field: string, descending = false): Array<any> {
  return items.sort((a, b) => {
    const aUnix = getUnixTime(parseISO(get(a, field))) * (descending ? -1 : 1);
    const bUnix = getUnixTime(parseISO(get(b, field))) * (descending ? -1 : 1);
    if (aUnix < bUnix) {
      return -1;
    }
    if (aUnix > bUnix) {
      return 1;
    }
    return 0;
  });
}

/**
 * Sort items by due interval value
 * @param {Array<any>} items
 * @return {Array<any>}
 */
export function sortByDueInterval(items: Array<any>): Array<any> {
  items.forEach(item => {
    item._due_sort = getDueIntervalSortValue(item.interval_value, item.is_due, item.is_odometer);
  });

  return sortBy(items, '_due_sort');
}

/**
 * Get value that can be used to sort all service intervals
 * @param value
 * @param is_due
 * @param {boolean} is_odometer
 * @return {any}
 */
function getDueIntervalSortValue(value, is_due, is_odometer = false) {
  const sortHighNumber = 3075840000;
  value = (is_due ? -1 : 1) * Math.abs(value);

  // odometer intervals
  if (is_odometer) {
    if (is_due) {
      // Overdue after Time intervals
      value = value / sortHighNumber;
    } else {
      // Upcoming after Time intervals
      value = value + sortHighNumber;
    }
  }

  return value;
}
