import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import * as FileSaver from 'file-saver';
import { OfflineHandlerService } from '../handler.service';
import { AuthService } from '../../auth.service';
import { EnvironmentService } from '../../environment.service';
import { format } from 'date-fns';
import { FNS_ATOM_DATE_TIME } from '../../../constants/data-time-formats';
import { Device } from '@capacitor/device';
import { FileService } from '../../file.service';
import { Directory } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Capacitor } from '@capacitor/core';
import { LangService } from '../../lang.service';

@Injectable({
  providedIn: 'root'
})
export class ExportService {
  constructor(
    private offlineHandlerService: OfflineHandlerService,
    private authService: AuthService,
    private environmentService: EnvironmentService,
    private fileService: FileService,
    private langService: LangService,
  ) { }

  /**
   * Share/Save backup file content based on current device/browser
   */
  public async sendBackup(backupFileContent) {
    const fileName = this.generateFileName();

    if (Capacitor.isNativePlatform()) {
      const filePath = await this.fileService.saveFile(backupFileContent, fileName, Directory.Cache);

      Share.share({
        title: 'CheckProof Offline Backup',
        text: 'CheckProof offline backup file attached”',
        dialogTitle: this.langService.t('offline.share-backup-file'),
        url: filePath,
      }).catch(() => {});
    } else {
      FileSaver.saveAs(backupFileContent, fileName);
    }
  }

  /**
   * Create a backup from current pending offline requests
   */
  public async createBackup() {
    const zip = new JSZip();

    const requestsToSync = await this.offlineHandlerService.getRequestsToSync();
    if (!requestsToSync || requestsToSync.length === 0) {
      console.error('No requests to backup');
      return Promise.reject();
    }

    const metaObject = await this.getMetaData();
    if (!metaObject) {
      console.error('Can not get meta data for backup');
      return Promise.reject();
    }

    zip.file('backup/meta', this.getFileContentForObject(metaObject));

    requestsToSync.forEach((request) => {
      if (request.url === '/assets') {
        this.addAsset(zip, request);
      }
      zip.file('backup/requests/' + request.id, this.getFileContentForObject(request));
    });

    const zipOutputType = Capacitor.isNativePlatform() ? 'base64' : 'blob';

    const fileContent = await zip.generateAsync({ type: zipOutputType });

    if (!fileContent) {
      console.error('Offline backup file was not created');
      return Promise.reject();
    }

    return fileContent;
  }

  /**
   * Add an asset file to zip based on request object
   *
   * @param zip
   * @param request
   */
  private addAsset(zip, request) {
    if (!request.data || !request.data.asset || !request.data.uid || !request.data.type) {
      return;
    }

    try {
      const assetPromise = new Promise((resolve) => {
        this.fileService.readFile(request.data.asset)
            .then(image => resolve(image.data))
            .catch(() => {
              resolve(''); // it will be saved as empty file if file doesn't exist
            });
      });

      zip.file('backup/assets/' + request.data.type + '/' + request.data.uid, assetPromise, {base64: true});
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Convert object to a string that will be saved to a file
   *
   * @param object
   */
  private getFileContentForObject(object): string {
    return JSON.stringify(object);
  }

  /**
   * Get a metadata object with information about user/company/device/app/etc
   */
  private async getMetaData() {
    const user = await this.authService.getCurrentUser();
    if (!user || !user.company) {
      return null;
    }

    let deviceInfo = null;
    try {
      deviceInfo = await Device.getInfo();
    } catch (e) {
      console.error(e);
    }

    return {
      user: {
        id: user.id,
        uid: user.uid,
      },
      company: {
        id: user.company.id,
        uid: user.company.uid,
      },
      app_version: this.environmentService.getEnvironment().appVersion,
      created_at: format(new Date(), FNS_ATOM_DATE_TIME),
      device_info: deviceInfo,
      region: (await this.environmentService.getRegion()) ?? 'eu'
    };
  }

  /**
   * Generate name of backup file
   */
  private generateFileName(): string {
    return 'offline-backup-' + format(new Date(), 'yyyy_MM_dd_HH_mm_ss') + '.cpbak';
  }
}
