<div class="ion-margin ion-padding">
    <p class="ion-padding">
        <i class="fa-solid fa-cloud-exclamation fa-2xl"></i>
    </p>
    <p class="ion-padding">
        Unable to connect
    </p>
    <p class="ion-padding">
        Make sure that Wi-Fi or mobile data is turned on, then try again.
    </p>
    <app-button type="button" iconClass="far fa-sync-alt" (onClick)="retryInit()">Retry</app-button>
</div>
<div class="ion-margin ion-padding">
    <div class="card ion-padding">
        <p>
            Click the link below to check service status :
            <br />
            <a href="https://status.checkproof.com">status.checkproof.com</a>
        </p>
    </div>
</div>
<div class="ion-margin ion-padding">
    <div class="card ion-padding">
        <p>
            Or please contact us :
        </p>
        <p class="ion-padding">
            Call support
            <br />
            <a (click)="callToTheSupport()">{{tel}}</a>
        </p>
        <p class="ion-padding">
            Email support
            <br />
            <a (click)="sendEmailToSupport()">{{email}}</a>
        </p>
    </div>
</div>
<p class="ion-padding">
    <app-button iconClass="far fa-sign-out-alt color-danger" (onClick)="logOut()">Log out</app-button>
</p>


