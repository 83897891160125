import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { HomeScreenComponent } from './home-screen.component';
import { OfflineBannerModule } from '../offline-banner/offline-banner.module';
import { HomeTabComponent } from './home-tab/home-tab.component';
import { OngoingTabComponent } from './ongoing-tab/ongoing-tab.component';
import { SharedModule } from '../shared.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';
import { MineChecksComponent } from './ongoing-tab/mine-checks/mine-checks.component';
import { OthersChecksComponent } from './ongoing-tab/others-checks/others-checks.component';
import { FavoritesComponent } from './home-tab/favorites/favorites.component';
import { TasksListTabComponent } from './tasks-list-tab/tasks-list-tab.component';
import { UserTasksModule } from '../user-tasks/user-tasks.module';

@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SharedPipesModule,
    OfflineBannerModule,
    FormsModule,
    UserTasksModule,
  ],
  declarations: [
    HomeScreenComponent,
    HomeTabComponent,
    TasksListTabComponent,
    OngoingTabComponent,
    MineChecksComponent,
    OthersChecksComponent,
    FavoritesComponent
  ],
  exports: [
    HomeScreenComponent
  ],
})
export class HomeScreenModule {}

