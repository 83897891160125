import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumericInputService {

  private readonly _decimalSeparator = /[.,]/g;
  private readonly _numericPattern = /^-?\d*\.?\d*$/;

  public replaceCommaWithDot(input: string): string {
    return input && input.includes(',') ? input.replace(/,/g, '.') : input;
  }

  public isValidNumericInput(input: string, decimals: number): boolean {
    /**
    * To only allow numbers 0-9 comman period and minus as valid characters
    */
    const isValidCharacters = this._numericPattern.test(this.replaceCommaWithDot(input));

    if (!isValidCharacters) {
      return false;
    }
    if (
      /**
      * To only allow minus on the first string index
      */
      (input.slice(-1) === '-' && input.length > 1) ||
      /**
      * To only allow 1 decimal separator (comma or period)
      */
      (input.slice(-1).match(this._decimalSeparator) && (input.match(this._decimalSeparator) || []).length > 1) ||
      /**
      * To limit maximum digits in decimal number
      */
      (input.match(this._decimalSeparator) &&
        (decimals < 1 || input.substring(input.indexOf(input.match(this._decimalSeparator)?.[0]), input.length - 1).length > decimals))
    ) {
      return false;
    }
    return true;
  }
}
