import { Injectable } from '@angular/core';
import { IActivity } from '@interfaces';
import ClientProvider from '../../client-provider/client-provider';
import { HistoryActivityTransformer } from '../transformers/history-activity/history-activity.transformer';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  public constructor(
    private readonly clientProvider: ClientProvider,
    private readonly historyActivity: HistoryActivityTransformer,
  ) {
  }

  /** Get history of a service task */
  public getServiceHistory(serviceUid: string): Promise<IActivity[]> {
    return this.clientProvider
      .getClient()
      .then(client => client.getCompletedTaskDetails({ serviceId: serviceUid }))
      .then(task => (task?.history || []).map(this.historyActivity.transform));
  }

  /** Get history of a interval task */
  public getInspectionHistory(inspectionUid: string): Promise<IActivity[]> {
    return this.clientProvider
      .getClient()
      .then(client => client.getCompletedTaskDetails({ inspectionId: inspectionUid }))
      .then(task => (task?.history || []).map(this.historyActivity.transform));
  }
}
