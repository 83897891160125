import { Injectable } from '@angular/core';
import { ObjectTypeEnum, ObjectableTypeEnum, OriginTypeEnum, TaskTypeEnum } from '@enums';
import { IRoute } from '@interfaces';
import TaskModel from '../ext-search-filter-builder/models/task.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationRouteService {
  public getTaskRoute(task: TaskModel): IRoute {
    switch (task.type) {
      case TaskTypeEnum.DEVIATION:
        return this.getDeviationRoute(task);
      case TaskTypeEnum.CHECKLIST:
        return this.getCheckIntervalRoute(task);
      case TaskTypeEnum.SERVICE:
        return this.getServiceIntervalRoute(task);
    }

    return null;
  }

  private getDeviationRoute(task: TaskModel): IRoute {
    const type = task.origin.type === OriginTypeEnum.INDEPENDENT_DEVIATION ? 'independent' : 'deviation';

    return {
      url: '/deviation',
      queryParams: {
        id: task.relations.deviation.id,
        type: type
      },
      companyId: task.relations.company?.id
    } as IRoute;
  }

  private getCheckIntervalRoute(task: TaskModel): IRoute {
    const type: ObjectTypeEnum = task.objectable.type === ObjectableTypeEnum.UNIT ? ObjectTypeEnum.UNIT : ObjectTypeEnum.ROUND;
    const trigger = task.dueTrigger

    const queryParams = Object.assign(
      {
        taskId: task.id,
        objectId: task.objectable.id,
        objectType: type
      },
      trigger ? { intervalDueTrigger: trigger.id } : {}
    ) as any;

    return {
      url: '/check-variant/' + task.relations.checkInterval.id,
      queryParams: queryParams,
      companyId: task.relations.company?.id
    };
  }

  private getServiceIntervalRoute(task: TaskModel): IRoute {
    if (task.origin.type === OriginTypeEnum.SERVICE_INTERVAL) {
      return {
        url: `/service-interval/${task.relations.serviceInterval.unit.id}/${task.relations.serviceInterval.id}`,
        queryParams: {
          taskId: task.id,
          intervalDueTrigger: task.dueTrigger?.id ?? undefined,
        },
        companyId: task.relations.company?.id
      };
    }

    return null;
  }
}
