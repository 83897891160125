import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { LangService } from './lang.service';
import { UserAppPreferencesService } from './user-app-preferences.service';
import { UserPreferencePrefixEnum } from '@enums';
import { Capacitor } from '@capacitor/core';
import { FormInstance } from '../models/form-instance';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class FormService {
  private isShowAlertSaveLocallyAlreadyOpened = false;

  constructor(private apiService: ApiService,
    private helperService: HelperService,
    private langService: LangService,
    private userAppPreferencesService: UserAppPreferencesService,
    private routeSrv: RouteService) {}

  public submitFormInstance(formInstance: FormInstance) {
    if (!formInstance.pendingItems || !formInstance.pendingItems.length) {
      return Promise.resolve();
    }

    const promises = [];

    formInstance.pendingItems.forEach(item => {
      if (item.executed || !this[item.type]) {
        return;
      }

      promises.push(
        this[item.type]
          .call(this, item.params)
          .then(res => {
            // set the pending item to executed only if an uid exist in the res object
            if (res.uid) {
              item.executed = true;
            }
            return res;
          })
          .catch(() => {
            return null;
          })
      );
    });

    return Promise.all(promises).then(res => {
      formInstance.pendingItems = formInstance.pendingItems.filter(item => !item.executed);
      return res;
    });
  }

  private uploadImage(params) {
    if (Capacitor.isNativePlatform()) {
      return this.uploadFileNative({ file: params.data, uid: params.uid }, 'image');
    } else {
      const data = {
        type: 'image',
        uid: params.uid,
        data: params.data,
      };
      return this.apiService.post(this.routeSrv.makeUrl(this.routeSrv.PATHS.assets.id, { id: ''}), data);
    }
  }

  private removeAsset(params) {
    return this.apiService.delete(this.routeSrv.makeUrl(this.routeSrv.PATHS.assets.uid, { uid: params.uid}));
  }

  private async uploadVideo(params) {
    if (Capacitor.isNativePlatform()) {
      return this.uploadFileNative(params, 'video');
    } else {
      return this.uploadFileDesktop(params);
    }
  }

  private async uploadFileNative(params, type) {
    let options, data;

    if (type === 'video') {
      const filename = await params.s3.uploadVideo(params.uid, params.file, params.data.mimetype);
      options = {};

      data = {
        type: type,
        uid: params.uid,
        filename: filename,
        mimetype: params.data.mimetype,
        size: params.data.size,
        originalName: params.data.originalName,
      };
    } else {
      options = {
        upload: ['asset'],
      };

      data = {
        asset: params.file,
        uid: params.uid,
        type: type,
      };
    }

    // added return to make executeAll() function getting a response in Promise.all(promises) call
    return await this.apiService
      .post(this.routeSrv.makeUrl(this.routeSrv.PATHS.assets.id, { id: ''}), data, options)
      .then(res => {
        return res;
      })
      .catch(e => {
        this.isPreferenceAssetsSavedInLocal();
        return e;
      });
  }

  private async uploadFileDesktop(params) {
    const filename = await params.s3.uploadVideo(params.uid, params.file, params.data.mimetype);
    const data = {
      uid: params.uid,
      filename: filename,
      mimetype: params.data.mimetype,
      size: params.data.size,
      originalName: params.data.originalName,
    };

    return await this.apiService.post(this.routeSrv.PATHS.assets.uploadvideo, data, {});
  }

  private async isPreferenceAssetsSavedInLocal() {
    const isAssetSavedLocally = await this.userAppPreferencesService.getAppPreference(UserPreferencePrefixEnum.SAVE_PICTURES_LOCALLY);
    if (!isAssetSavedLocally.value) {
      const preferenceParam = { value: true, osRestriction: Capacitor.getPlatform() === 'ios' };
      // on iOS the camera plugins will force the system to ask rights to the user the first time he takes a shot.
      // if the user did not allow access to the photo album from the system, this preference will not be enough to save on it
      if (!this.isShowAlertSaveLocallyAlreadyOpened) {
        this.isShowAlertSaveLocallyAlreadyOpened = true;
        await this.helperService.showAlert({
          header: '',
          message: this.langService.t('app.settings.enable-save-pictures-locally'),
          buttons: [
            {
              text: this.langService.t('general.no'),
              handler: () => {
                // if the user clicked no, Re ask to the user on the next time an http error happens
                this.isShowAlertSaveLocallyAlreadyOpened = false;
              },
            },
            {
              text: this.langService.t('general.yes'),
              handler: async () => {
                await this.userAppPreferencesService.setAppPreference(UserPreferencePrefixEnum.SAVE_PICTURES_LOCALLY, preferenceParam);
                return;
              },
            },
          ],
        });
      }
    }
  }
}
