import {NgModule} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';
import { MentionModule } from 'angular-mentions';
import { ButtonComponent } from './button/button.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { SegmentComponent } from './segment/segment.component';
import { SegmentButtonComponent } from './segment/segment-button/segment-button.component';
import { InputComponent } from './input/input.component';
import { InputValidationComponent } from './input-validation/input-validation.component';
import { IsFeatureAllowedPipe } from '../pipes/is-feature-allowed.pipe';
import { FormatDatePipe } from '../pipes/format-date.pipe';
import { HeaderComponent } from './header/header.component';
import { HeaderRoundedComponent } from './header-rounded/header-rounded.component';
import { PublicTokenComponent as HeaderRoundedPublicTokenComponent } from './header-rounded/public-token/public-token.component';
import { CollapsableComponent } from './collapsable/collapsable.component';
import { SelectDropdownComponent } from './select-dropdown/select-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectWorkplaceSectionComponent } from './select-workplace-section/select-workplace-section.component';
import { SelectUnitRoundComponent } from './select-unit-round/select-unit-round.component';
import { SelectPlaceableObjectComponent } from './select-placeable-object/select-placeable-object.component';
import { ImageComponent } from './image/image.component';
import { SelectCompanyComponent } from './select-company/select-company.component';
import { ToggleComponent } from './toggle/toggle.component';
import { CardDeviationComponent } from './card-deviation/card-deviation.component';
import { ButtonLoadMoreComponent } from './button-load-more/button-load-more.component';
import { CardCheckVariantComponent } from './card-check-variant/card-check-variant.component';
import { SelectResponsibleComponent } from './select-responsible/select-responsible.component';
import { SelectCategoryComponent } from './select-category/select-category.component';
import { TextareaComponent } from './textarea/textarea.component';
import { SelectObjectPositionComponent } from './select-object-position/select-object-position.component';
import { SelectGroupPositionComponent } from './select-object-position/select-group-position/select-group-position.component';
import { PositionInfoComponent } from './select-object-position/position-info/position-info.component';
import { InputTimeDurationComponent } from './input-time-duration/input-time-duration.component';
import { InputDatetimeComponent } from './input-datetime/input-datetime.component';
import { InputWorktimeComponent } from './input-worktime/input-worktime.component';
import { InputToggleButtonsComponent } from './input-toggle-buttons/input-toggle-buttons.component';
import { ActivityFeedComponent } from './activity-feed/activity-feed.component';
import { FormatContentLinksPipe } from '../pipes/format-content-links.pipe';
import { FormatLineBreaksPipe } from '../pipes/format-line-breaks.pipe';
import { FormatMentionsPipe } from '../pipes/format-mentions.pipe';
import { PositionNamePipe } from '../pipes/position-name.pipe';
import { MeasurementPipe } from '../pipes/measurement.pipe';
import { SharedPipesModule } from '../pipes/shared-pipes.module';
import { InputFluidRefillComponent } from './input-fluid-refill/input-fluid-refill.component';
import { InputNumberComponent } from './input-number/input-number.component';
import { InputArticleComponent } from './input-article/input-article.component';
import { TakePhotoComponent } from './take-photo/take-photo.component';
import { ImageAnnotationModule } from '../modals/image-annotation/image-annotation.module';
import { FormInputRendererComponent } from './form-input-renderer/form-input-renderer.component';
import { RadioButtonComponent } from './radio-button/radio-button.component';
import { InputCheckboxButtonsComponent } from './input-checkbox-buttons/input-checkbox-buttons.component';
import { CardCheckOngoingComponent } from './card-check-ongoing/card-check-ongoing.component';
import { SortDirectionToggleComponent } from './sort-direction-toggle/sort-direction-toggle.component';
import { SignatureComponent } from './signature/signature.component';
import { RefreshableContentComponent } from './refreshable-content/refreshable-content.component';
import { SelectCategoryMultipleComponent } from './select-category-multiple/select-category-multiple.component';
import { InputWorkdayComponent } from './input-workday/input-workday.component';
import { InputRiskComponent } from './input-risk/input-risk.component';
import { InputRelationComponent } from './input-relation/input-relation.component';
import { ItemsCounterBadgeComponent } from './items-counter-badge/items-counter-badge.component';
import { ItemIncidentComponent } from './item-incident/item-incident.component';
import { DeviationStatusIconComponent } from './deviation-status-icon/deviation-status-icon.component';
import { DeviationThumbnailsToggleComponent } from './deviation-thumbnails-toggle/deviation-thumbnails-toggle.component';
import { SelectDropdownPanelComponent } from './select-dropdown/select-dropdown-panel/select-dropdown-panel.component';
import { SelectCheckVariantComponent } from './select-check-variant/select-check-variant.component';
import { FormInputValueComponent } from './form-input-value/form-input-value.component';
import { SelectTagComponent } from './select-tag/select-tag.component';
import { TagComponent } from './tag/tag.component';
import { InputHiddenComponent } from './input-hidden/input-hidden.component';
import { InputAttendeesComponent } from './input-attendees/input-attendees.component';
import { InputCoordinatesComponent } from './input-coordinates/input-coordinates.component';
import { AssetComponent } from './asset/asset.component';
import { CardRfidTagComponent } from './rfid/card-rfid-tag/card-rfid-tag.component';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { VideoComponent } from './video/video.component';
import { ObjectCheckVariantsComponent } from './object-check-variants/object-check-variants.component';
import { CheckControlPointsComponent } from './check-control-points/check-control-points.component';
import { OdometerLastChangeComponent } from './odometer-last-change/odometer-last-change.component';
import { InputWasteReportComponent } from './input-waste-report/input-waste-report.component';
import { WasteTransportPlanningTemplateComponent } from './input-waste-report/transport-planning-template/transport-planning-template.component';
import { WasteWasteComponent } from './input-waste-report/sub-components/waste/waste.component';
import { WastePlaceComponent } from './input-waste-report/sub-components/place/place.component';
import { WasteOperatorComponent } from './input-waste-report/sub-components/operator/operator.component';
import { WasteTransportComponent } from './input-waste-report/sub-components/transport/transport.component';
import { WasteTransportTemplateComponent } from './input-waste-report/transport-template/transport-template.component';
import { WasteDateComponent } from './input-waste-report/sub-components/date/date.component';
import { WasteOrganizationComponent } from './input-waste-report/sub-components/organization/organization.component';
import { InputMatrixComponent } from './input-matrix/input-matrix.component';
import { WasteTreatmentTransferTemplateComponent } from './input-waste-report/treatment-transfer-template/treatment-transfer-template.component';
import { WasteTreatmentReceptionTemplateComponent } from './input-waste-report/treatment-reception-template/treatment-reception-template.component';
import { WasteTradeTemplateComponent } from './input-waste-report/trade-template/trade-template.component';
import { WasteCollectionTransportTemplateComponent } from './input-waste-report/collection-transport-template/collection-transport-template.component';
import { WasteCollectionTemplateComponent } from './input-waste-report/collection-template/collection-template.component';
import { TaskDeviationComponent } from './home-screen/tasks-list-tab/task/task-deviation/task-deviation.component';
import { TaskComponent } from './home-screen/tasks-list-tab/task/task.component';
import { TaskServiceComponent } from './home-screen/tasks-list-tab/task/task-service/task-service.component';
import { TaskCheckComponent } from './home-screen/tasks-list-tab/task/task-check/task-check.component';
import {ActivityFeedWorktimeComponent} from './activity-feed/activity-feed-worktime/activity-feed-worktime.component';
import { CardWorktimeComponent } from './card-worktime/card-worktime.component';
import { CardWorktimesHistoryComponent } from './card-worktimes-history/card-worktimes-history.component';
import {KeyDownListenerDirective} from '../directive/key-down-listener.directive';
import {ObjectFleetOptimizationComponent} from './object-fleet-optimization/object-fleet-optimization.component';
import { ObjectItemInfoComponent } from './object-item-info/object-item-info.component';
import {ButtonRfidComponent} from './rfid/button-rfid/button-rfid.component';
import { WorkTimesComponent } from './work-times/work-times.component';
import { WorkTimeComponent } from './work-times/work-time/work-time.component';
import { WorkTimeEditComponent } from './work-times/work-time/work-time-edit/work-time-edit.component';
import { DevToolsComponent } from './dev-tools/dev-tools.component';
import { ControlPointValueMatrixComponent } from './control-point-value-matrix/control-point-value-matrix.component';
import { TaskHistoryComponent } from './task-history/task-history.component';
import { ShowOnMapComponent } from './google-maps/show-on-map/show-on-map.component';
import { HistoryItemComponent } from './task-history/history-item/history-item.component';
import { IconComponent } from './icon/icon.component';
import {
  ServiceIntervalDescriptionComponent
} from './service-interval-description/service-interval-description.component';
import { CardEmptyListComponent } from './card-empty-list/card-empty-list.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ServiceDueDateComponent } from './service-due-date/service-due-date.component';
import { InputNumberFieldComponent } from './input-number-field/input-number-field.component';
import { NumericKeyboardComponent } from './numeric-keyboard/numeric-keyboard.component';
import { InputPhoneComponent } from './input-phone/input-phone.component';
import { SideBarMenuPhoneSupportComponent } from './side-bar-menu-phone-support/side-bar-menu-phone-support.component';
import { SideBarMenuEmailSupportComponent } from './side-bar-menu-email-support/side-bar-menu-email-support.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SharedPipesModule,
    MentionModule,
    ImageAnnotationModule,
    ReactiveFormsModule,
  ],
  declarations: [
    AssetComponent,
    ButtonComponent,
    CheckboxComponent,
    SegmentComponent,
    SegmentButtonComponent,
    InputComponent,
    InputValidationComponent,
    InputTimeDurationComponent,
    InputDatetimeComponent,
    InputWorktimeComponent,
    InputWorkdayComponent,
    InputToggleButtonsComponent,
    InputFluidRefillComponent,
    InputNumberComponent,
    InputArticleComponent,
    InputRiskComponent,
    InputRelationComponent,
    InputAttendeesComponent,
    InputCoordinatesComponent,
    InputMatrixComponent,
    InputPhoneComponent,
    TextareaComponent,
    RadioButtonComponent,
    FormInputRendererComponent,
    FormInputValueComponent,
    IsFeatureAllowedPipe,
    FormatDatePipe,
    FormatContentLinksPipe,
    FormatLineBreaksPipe,
    FormatMentionsPipe,
    PositionNamePipe,
    MeasurementPipe,
    HeaderComponent,
    HeaderRoundedComponent,
    HeaderRoundedPublicTokenComponent,
    CollapsableComponent,
    SelectDropdownComponent,
    SelectDropdownPanelComponent,
    SelectWorkplaceSectionComponent,
    SelectUnitRoundComponent,
    SelectPlaceableObjectComponent,
    SelectResponsibleComponent,
    SelectCategoryComponent,
    SelectCategoryMultipleComponent,
    SelectObjectPositionComponent,
    SelectGroupPositionComponent,
    PositionInfoComponent,
    SelectCheckVariantComponent,
    SelectTagComponent,
    ImageComponent,
    SelectCompanyComponent,
    ToggleComponent,
    CardDeviationComponent,
    CardCheckVariantComponent,
    CardCheckOngoingComponent,
    ButtonLoadMoreComponent,
    ActivityFeedComponent,
    TakePhotoComponent,
    InputCheckboxButtonsComponent,
    SortDirectionToggleComponent,
    SignatureComponent,
    RefreshableContentComponent,
    ItemsCounterBadgeComponent,
    ItemIncidentComponent,
    DeviationStatusIconComponent,
    DeviationThumbnailsToggleComponent,
    TagComponent,
    InputHiddenComponent,
    GoogleMapsComponent,
    ObjectCheckVariantsComponent,
    CardRfidTagComponent,
    VideoComponent,
    CheckControlPointsComponent,
    OdometerLastChangeComponent,
    InputWasteReportComponent,
    WasteTransportPlanningTemplateComponent,
    WasteTreatmentTransferTemplateComponent,
    WasteTreatmentReceptionTemplateComponent,
    WasteWasteComponent,
    WastePlaceComponent,
    WasteOperatorComponent,
    WasteTransportComponent,
    WasteDateComponent,
    WasteOrganizationComponent,
    WasteTransportTemplateComponent,
    WasteTradeTemplateComponent,
    WasteCollectionTemplateComponent,
    WasteCollectionTransportTemplateComponent,
    TaskComponent,
    TaskDeviationComponent,
    TaskServiceComponent,
    TaskCheckComponent,
    ActivityFeedWorktimeComponent,
    CardWorktimeComponent,
    CardWorktimesHistoryComponent,
    KeyDownListenerDirective,
    ObjectFleetOptimizationComponent,
    ObjectItemInfoComponent,
    ButtonRfidComponent,
    WorkTimesComponent,
    WorkTimeComponent,
    WorkTimeEditComponent,
    DevToolsComponent,
    ControlPointValueMatrixComponent,
    TaskHistoryComponent,
    ShowOnMapComponent,
    HistoryItemComponent,
    IconComponent,
    ServiceIntervalDescriptionComponent,
    CardEmptyListComponent,
    MaintenanceComponent,
    ServiceDueDateComponent,
    InputNumberFieldComponent,
    NumericKeyboardComponent,
    SideBarMenuPhoneSupportComponent,
    SideBarMenuEmailSupportComponent,
  ],
  exports: [
    AssetComponent,
    ButtonComponent,
    CheckboxComponent,
    SegmentComponent,
    SegmentButtonComponent,
    InputComponent,
    InputValidationComponent,
    InputTimeDurationComponent,
    InputDatetimeComponent,
    InputWorktimeComponent,
    InputWorkdayComponent,
    InputToggleButtonsComponent,
    InputFluidRefillComponent,
    InputNumberComponent,
    InputArticleComponent,
    InputRiskComponent,
    InputRelationComponent,
    InputAttendeesComponent,
    InputCoordinatesComponent,
    InputMatrixComponent,
    InputPhoneComponent,
    TextareaComponent,
    RadioButtonComponent,
    FormInputRendererComponent,
    FormInputValueComponent,
    IsFeatureAllowedPipe,
    FormatDatePipe,
    FormatContentLinksPipe,
    FormatLineBreaksPipe,
    FormatMentionsPipe,
    PositionNamePipe,
    MeasurementPipe,
    HeaderComponent,
    HeaderRoundedComponent,
    HeaderRoundedPublicTokenComponent,
    CollapsableComponent,
    SelectDropdownComponent,
    SelectDropdownPanelComponent,
    SelectWorkplaceSectionComponent,
    SelectUnitRoundComponent,
    SelectPlaceableObjectComponent,
    SelectResponsibleComponent,
    SelectCategoryComponent,
    SelectCategoryMultipleComponent,
    SelectObjectPositionComponent,
    SelectGroupPositionComponent,
    PositionInfoComponent,
    SelectCheckVariantComponent,
    SelectTagComponent,
    ImageComponent,
    SelectCompanyComponent,
    ToggleComponent,
    CardDeviationComponent,
    CardCheckVariantComponent,
    CardCheckOngoingComponent,
    ButtonLoadMoreComponent,
    ActivityFeedComponent,
    TakePhotoComponent,
    InputCheckboxButtonsComponent,
    SortDirectionToggleComponent,
    SignatureComponent,
    RefreshableContentComponent,
    ItemsCounterBadgeComponent,
    ItemIncidentComponent,
    DeviationStatusIconComponent,
    DeviationThumbnailsToggleComponent,
    TagComponent,
    InputHiddenComponent,
    GoogleMapsComponent,
    ObjectCheckVariantsComponent,
    CardRfidTagComponent,
    VideoComponent,
    CheckControlPointsComponent,
    OdometerLastChangeComponent,
    InputWasteReportComponent,
    WasteTransportPlanningTemplateComponent,
    WasteTreatmentTransferTemplateComponent,
    WasteWasteComponent,
    WastePlaceComponent,
    WasteOperatorComponent,
    WasteTransportComponent,
    WasteDateComponent,
    WasteOrganizationComponent,
    WasteTransportTemplateComponent,
    WasteTreatmentReceptionTemplateComponent,
    WasteTradeTemplateComponent,
    WasteCollectionTemplateComponent,
    WasteCollectionTransportTemplateComponent,
    TaskComponent,
    TaskDeviationComponent,
    TaskServiceComponent,
    TaskCheckComponent,
    ActivityFeedWorktimeComponent,
    CardWorktimeComponent,
    CardWorktimesHistoryComponent,
    KeyDownListenerDirective,
    ObjectFleetOptimizationComponent,
    ObjectItemInfoComponent,
    ButtonRfidComponent,
    WorkTimesComponent,
    DevToolsComponent,
    ControlPointValueMatrixComponent,
    ShowOnMapComponent,
    TaskHistoryComponent,
    IconComponent,
    ServiceIntervalDescriptionComponent,
    CardEmptyListComponent,
    MaintenanceComponent,
    ServiceDueDateComponent,
    InputNumberFieldComponent,
    NumericKeyboardComponent,
    SideBarMenuPhoneSupportComponent,
    SideBarMenuEmailSupportComponent,
  ],
})
export class SharedModule {}
