import {Injectable} from '@angular/core';
// import {FirebaseApp} from '@capacitor-firebase/app';
import {EnvironmentService} from './environment.service';
import {OfflineHandlerService} from './offline/handler.service';

@Injectable({
  providedIn: 'root'
})

export class FirebaseService {
    fireBaseAppCheckToken: string;

    constructor(private readonly envService: EnvironmentService,
              private offlineHandler: OfflineHandlerService) {}

    // public async initializeFirebase(): Promise<void> {
    //   const isOffline = await this.offlineHandler.isOfflineEnable();
    //   if (!isOffline && Capacitor.getPlatform() === 'android' && this.envService.getEnvironment().production) {
    //       try {
    //           const options = await this.getOptions();
    //           const appName = await this.getName();
    //           initializeApp(options, appName);
    //       } catch (e) { /* empty */ } // we don't we to display error if rejected by Firebase or Play store out of date
    //   }
    //   return ;
    // }
    //
    // private getName = async () => await FirebaseApp.getName();
    //
    // private getOptions = async () => await FirebaseApp.getOptions();
}
