import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService as AuthGuard } from './guards/auth-guard.service';
import { AppFeatureEnum, UserRoleEnum } from './enums';


// for authGuard provide - "data: { authConfig: { roles: [UserRole.USER], features: [AppFeatureEnum.OFFLINE_MODE] }"

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./views/login/login.module').then(m => m.LoginPageModule) },
  { path: 'forgot-password', loadChildren: () => import('./views/login/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'home', loadChildren: () => import('./views/home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard] },
  { path: 'offline-mode', loadChildren: () => import('./views/offline-mode/offline-mode.module').then(m => m.OfflinemodePageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.OFFLINE_MODE], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'offline-mode/backup-import', loadChildren: () => import('./views/offline-backup-import/offline-backup-import.module').then(m => m.OfflineBackupImportPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.OFFLINE_BACKUP_IMPORT], roles: [UserRoleEnum.USER] } } },
  { path: 'loading', loadChildren: () => import('./views/loading/loading.module').then(m => m.LoadingPageModule), canActivate: [AuthGuard] },
  { path: 'company-info', loadChildren: () => import('./views/company-info/company-info.module').then(m => m.CompanyInfoPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INFO], roles: [UserRoleEnum.USER] } } },
  { path: 'company-info/documents', loadChildren: () => import('./views/company-info/documents/documents.module').then(m => m.CompanyInfoDocumentsPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INFO], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'company-info/documents/:type', loadChildren: () => import('./views/company-info/documents/type/type.module').then(m => m.CompanyInfoTypePageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INFO], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'company-info/documents/:type/:id', loadChildren: () => import('./views/company-info/documents/type/list/list.module').then(m => m.CompanyInfoListPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INFO], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'documents/:folderUID', loadChildren: () => import('./views/company-info/documents/folder/folder.module').then(m => m.DocumentsFolderModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INFO], roles: [UserRoleEnum.USER] } } },
  { path: 'incidents-my', loadChildren: () => import('./views/incidents-my/incidents-my.module').then(m => m.IncidentsMyPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INCIDENTS], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'incident-template', loadChildren: () => import('./views/incident-template/incident-template.module').then(m => m.IncidentTemplatePageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INCIDENTS], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'incident-start/:id', loadChildren: () => import('./views/incident-start/incident-start.module').then(m => m.IncidentStartPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INCIDENTS], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'incident-report/:id', loadChildren: () => import('./views/incident-report/incident-report.module').then(m => m.IncidentReportPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INCIDENTS], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'incident/:id', loadChildren: () => import('./views/incident/incident.module').then(m => m.IncidentPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INCIDENTS], roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'deviation', loadChildren: () => import('./views/deviation/deviation.module').then(m => m.DeviationPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.DEVIATIONS], roles: [UserRoleEnum.USER] } } },
  { path: 'units/create', loadChildren: () => import('./views/object-edit/object-edit.module').then(m => m.ObjectEditPageModule), canActivate: [AuthGuard], data: { type: 'unit', authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'rounds/create', loadChildren: () => import('./views/object-edit/object-edit.module').then(m => m.ObjectEditPageModule), canActivate: [AuthGuard], data: { type: 'round', authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'units/:id', loadChildren: () => import('./views/object-single/object-single.module').then(m => m.ObjectSinglePageModule), canActivate: [AuthGuard], data: { type: 'unit', authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'rounds/:id', loadChildren: () => import('./views/object-single/object-single.module').then(m => m.ObjectSinglePageModule), canActivate: [AuthGuard], data: { type: 'round', authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'units/:id/edit', loadChildren: () => import('./views/object-edit/object-edit.module').then(m => m.ObjectEditPageModule), canActivate: [AuthGuard], data: { type: 'unit', authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'rounds/:id/edit', loadChildren: () => import('./views/object-edit/object-edit.module').then(m => m.ObjectEditPageModule), canActivate: [AuthGuard], data: { type: 'round', authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'deviations-list', loadChildren: () => import('./views/deviations-list/deviations-list.module').then(m => m.DeviationsListPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.DEVIATIONS], roles: [UserRoleEnum.USER] } } },
  { path: 'services-list/:unit', loadChildren: () => import('./views/services-list/services-list.module').then(m => m.ServicesListPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.SERVICES], roles: [UserRoleEnum.USER] } } },
  { path: 'service-interval/:unit/:id', loadChildren: () => import('./views/service-interval/service-interval.module').then(m => m.ServiceIntervalPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.SERVICES], roles: [UserRoleEnum.USER] } } },
  { path: 'service-perform', loadChildren: () => import('./views/service-perform/service-perform.module').then(m => m.ServicePerformPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.SERVICES], roles: [UserRoleEnum.USER] } } },
  { path: 'service-perform/:id', loadChildren: () => import('./views/service-perform/service-perform.module').then(m => m.ServicePerformPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.SERVICES], roles: [UserRoleEnum.USER] } } },
  { path: 'check-variants-list', loadChildren: () => import('./views/check-variants-list/check-variants-list.module').then(m => m.CheckVariantsListPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.CHECKS], roles: [UserRoleEnum.USER] } } },
  { path: 'rfid-interval-list', loadChildren: () => import('./views/rfid-interval-list/rfid-interval-list.module').then(m => m.RfidIntervalListPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.CHECKS], roles: [UserRoleEnum.USER] } } },
  { path: 'check-variant/:id', loadChildren: () => import('./views/check-variant/check-variant.module').then(m => m.CheckVariantPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.CHECKS], roles: [UserRoleEnum.USER] } } },
  { path: 'check-perform/:id', loadChildren: () => import('./views/check-perform/check-perform.module').then(m => m.CheckPerformPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.CHECKS], roles: [UserRoleEnum.USER] } } },
  { path: 'check-view/:id', loadChildren: () => import('./views/check-view/check-view.module').then(m => m.CheckViewPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.CHECKS], roles: [UserRoleEnum.USER] } } },
  { path: 'refill', loadChildren: () => import('./views/refill/refill.module').then(m => m.RefillsPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.REFILLS], roles: [UserRoleEnum.USER] } } },
  { path: 'object-documents/:type/:id', loadChildren: () => import('./views/object-documents/object-documents.module').then(m => m.ObjectDocumentsPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'object-activities/:type/:id', loadChildren: () => import('./views/object-activities/object-activities.module').then(m => m.ObjectActivitiesPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.UNITS_ROUNDS], roles: [UserRoleEnum.USER] } } },
  { path: 'inspections-list/:type/:id', loadChildren: () => import('./views/inspections-list/inspections-list.module').then(m => m.InspectionsListPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INSPECTIONS], roles: [UserRoleEnum.USER] } } },
  { path: 'inspection-interval/:type/:object/:id', loadChildren: () => import('./views/inspection-interval/inspection-interval.module').then(m => m.InspectionIntervalPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INSPECTIONS], roles: [UserRoleEnum.USER] } } },
  { path: 'inspection/:id', loadChildren: () => import('./views/inspection/inspection.module').then(m => m.InspectionPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.INSPECTIONS], roles: [UserRoleEnum.USER] } } },
  { path: 'rfid-control-points/:id', loadChildren: () => import('./views/rfid-control-points/rfid-control-points.module').then(m => m.RfidControlPointsPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.RFID], roles: [UserRoleEnum.USER] } } },
  { path: 'rfid-object-info', loadChildren: () => import('./views/rfid-object-info/rfid-object-info.module').then(m => m.RfidObjectInfoPageModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.RFID], roles: [UserRoleEnum.USER] } } },
  { path: 'rfid-object-positions-categories', loadChildren: () => import('./views/rfid-object-positions-categories/rfid-object-positions-categories.module').then(m => m.RfidObjectPositionsCategoriesModule), canActivate: [AuthGuard], data: { authConfig: { features: [AppFeatureEnum.RFID], roles: [UserRoleEnum.USER] } } },
  { path: 'worktimes-history', loadChildren: () => import('./views/worktimes-history/worktimes-history.module').then(m => m.WorktimesHistoryPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.INCIDENT_USER] } } },
  { path: 'workplaces/create', loadChildren: () => import('./views/workplace-edit/workplace-edit.module').then(m => m.WorkplaceEditPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.USER] } }  },
  { path: 'workplaces/:id/edit', loadChildren: () => import('./views/workplace-edit/workplace-edit.module').then(m => m.WorkplaceEditPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.USER] } }  },
  { path: 'sections/create', loadChildren: () => import('./views/section-edit/section-edit.module').then(m => m.SectionEditPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.USER] } }  },
  { path: 'sections/:id/edit', loadChildren: () => import('./views/section-edit/section-edit.module').then(m => m.SectionEditPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.USER] } } },

  { path: 'public/token/:token', loadChildren: () => import('./views/public-user/login/login.module').then(m => m.LoginPageModule) },
  { path: 'public/complete-registration', loadChildren: () => import('./views/public-user/complete-registration/complete-registration.module').then(m => m.CompleteRegistrationPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.PUBLIC_USER] } } },
  { path: 'public/home', loadChildren: () => import('./views/public-user/home/home.module').then(m => m.HomePageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.PUBLIC_USER] } } },
  { path: 'public/check-variant/:id', loadChildren: () => import('./views/check-variant/check-variant.module').then(m => m.CheckVariantPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.PUBLIC_USER] } } },
  { path: 'public/check-perform/:id', loadChildren: () => import('./views/check-perform/check-perform.module').then(m => m.CheckPerformPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.PUBLIC_USER] } } },
  { path: 'public/check-performed/:id', loadChildren: () => import('./views/check-performed/check-performed.module').then(m => m.CheckPerformedPageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.PUBLIC_USER] } } },
  { path: 'public/check-performed/:id/share', loadChildren: () => import('./views/check-share/check-share.module').then(m => m.CheckSharePageModule), canActivate: [AuthGuard], data: { authConfig: { roles: [UserRoleEnum.PUBLIC_USER] } } },

  { path: 'sso-connect', loadChildren: () => import('./views/sso-connect/sso-connect.module').then(m => m.SsoConnectPageModule), canActivate: [AuthGuard] },
  { path: 'settings', loadChildren: () => import('./views/settings/settings.module').then( m => m.SettingsPageModule), canActivate: [AuthGuard] },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
