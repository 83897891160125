import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class HomeScreenService {
  public checksOngoingMine$ = new Subject<any>();
  public tasksDeviations$ = new Subject<any>();
  public tasksChecks$ = new Subject<any>();
  public tasks$: Subject<void> = new Subject();

  private userChecks = null; // cached array of user's checks

  constructor(
    private apiService: ApiService,
    private routeSrv: RouteService,
  ) {}

  public clearAllCache() {
    this.clearUserChecksCache();
  }

  public clearUserChecksCache() {
    this.userChecks = null;
  }

  public fetchOngoingMineChecks(force = false) {
    if (this.userChecks && !force) {
      this.checksOngoingMine$.next({
        checks: this.userChecks
      });
      return Promise.resolve();
    }

    const data = {
      filterMine: true,
      include: 'removable',
      per_page: ''
    };

    this.checksOngoingMine$.next({
      loading: true,
      checks: this.userChecks
    });

    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.checksongoing.id, { id: '' }), data).then(checks => {
      this.userChecks = checks;
      this.checksOngoingMine$.next({
        checks: this.userChecks
      });
    }).catch(() => {});
  }

  public fetchTasks() {
    this.tasks$.next();
  }
}
