<div *ngIf="isDevToolsEnabled">
  <div id="dev-tools-icon" (click)="toggleDevTools()"><i class="fa fa-wrench"></i></div>

  <div id="dev-tools-window" *ngIf="showDevTools">
    <div *ngFor="let msg of messages" class="log-message-{{msg.type}}">
      {{msg.text}}
      <ng-container *ngIf="msg.description">
        <span *ngIf="msg.collapsed" (click)="toggleMsgDescription(msg)" class="text-underline"> (Details)</span>
        <div *ngIf="!msg.collapsed" (click)="toggleMsgDescription(msg)">{{msg.description}}</div>
      </ng-container>
    </div>
  </div>
</div>
