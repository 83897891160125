import { Injectable } from '@angular/core';
import { OfflineHandlerService } from './offline/handler.service';
import { OfflineDataUtil } from '../utils/offline-data.util';

@Injectable({
  providedIn: 'root'
})
export class IncidentService {
  private storageKey = 'incident-report-';

  constructor(
    private offlineData: OfflineHandlerService,
  ) { }

  /**
   * Create an instance of incident report in local storage
   * @param template
   * @return {Promise<{id: string; template_id: any}>}
   */
  public async createIncidentReport(template) {
    const report = {
      id: OfflineDataUtil.generateRandomId(),
      template_id: template.id
    };

    await this.saveReport(report);

    return report;
  }

  /**
   * Get incident report from local storage
   * @param id
   * @return {Promise<any>}
   */
  public async getReport(id) {
    return this.offlineData.getData(this.storageKey + id);
  }

  /**
   * Save incident report to local storage
   * @param report
   * @return {Promise<void>}
   */
  public async saveReport(report) {
    await this.offlineData.setData(this.storageKey + report.id, report);
  }
}
