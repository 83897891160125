import { Injectable } from '@angular/core';
import { RequestsHandler } from './requests-handler';
import { HelperService } from '../helper.service';
import { ApiService } from '../api.service';
import { OfflineHandlerService } from './handler.service';
import { LangService } from '../lang.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineSyncService {
  public progress = {
    total: 0,
    synced: 0
  };

  constructor (
    private helperService: HelperService,
    private apiService: ApiService,
    private offlineHandlerService: OfflineHandlerService,
    private langService: LangService
  ) {}

  /**
   * Execute outgoing requests
   * @param requests
   * @return {Promise<any>}
   */
  public executeOutgoingRequests(requests): Promise<any> {
    if (!requests || !requests.length) {
      return Promise.resolve();
    }

    let failedRequests = 0;
    let failedControlPointsSync = false;
    const promises = [];

    const dependedRequests = requests.filter(request => {
      // if current request has depends on some parent request
      // - check if this parent request is still in current list
      if (request.parent_id) {
        const parent = requests.find(req => req.id === request.parent_id);
        if (parent) {
          return true;
        }
      }

      const requestHandler = new RequestsHandler(this.apiService, this.helperService, this.offlineHandlerService);
      const promise = requestHandler.execute(request);

      if (promise) {
        promises.push(
          promise
            .then((response) => {
              if (response && response.error && response.error.code === 'control_point_already_completed') {
                failedControlPointsSync = true;
              }
            })
            .catch((e) => {
              console.error(e);
              failedRequests++;
            })
            .finally(() => {
              this.progress.synced++;
            })
        );
      }

      return false;
    });

    return Promise.all(promises).then(() => {
      if (failedRequests) {
        throw new Error('failed to sync outgoing requests');
      } else {
        if (failedControlPointsSync) {
          this.helperService.showAlert({
            header: this.langService.t('checks.error-sync-control-points'),
            buttons: [{
              text: this.langService.t('general.ok'),
              handler: () => {}
            }]
          });
        }
        return this.executeOutgoingRequests(dependedRequests);
      }
    });
  }
}
