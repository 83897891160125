import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DrawService {
  private _background = 'transparent';
  private _color = '#000000';
  private _lineWidth = 3;
  private _throttle = 12;
  private _canvas: HTMLCanvasElement;

  private canvasContext: any;
  private canvasOffset: any;

  private line: {color: string, coords: any[]};
  private lines = [];

  private isDrawing = false;
  private blockDraw = false;

  get background(): string {
    return this._background;
  }
  set background(color: string) {
    this._background = (color.match(/^#[0-9A-F]{6}$/i) ? color : 'transparent');
  }

  get color(): string {
    return this._color;
  }
  set color(color: string) {
    this._color = color;
  }

  get lineWidth(): number {
    return this._lineWidth;
  }
  set lineWidth(width: number) {
    this._lineWidth = width;
  }

  get throttle(): number {
    return this._throttle;
  }

  set throttle(throttle: number) {
    this._throttle = throttle;
  }

  get canvas(): HTMLCanvasElement {
    return this._canvas;
  }

  set canvas(canvas: HTMLCanvasElement) {
    this._canvas = canvas;
    this.canvasContext = canvas.getContext('2d');
    this.canvasOffset = canvas.getBoundingClientRect();
  }

  constructor(
  ) {
  }

  /**
   * Clear canvas from drawings.
   */
  public clear(width = 0, height = 0): void {
    width = width || this.canvas.width;
    height = height || this.canvas.height;

    this.reset(width, height);

    this.line = {
      color: this.color,
      coords: []
    };

    this.lines = [];
  }

  /**
   * Reset canvas.
   */
  public reset(width: number, height: number): void {
    this.canvas.width = width;
    this.canvas.height = height;

    this.canvasOffset = this.canvas.getBoundingClientRect();
    this.canvasContext.clearRect(0, 0, this.canvas.width, this.canvas.height);

    if (this.background !== 'transparent') {
      this.canvasContext.fillStyle = this.background;
      this.canvasContext.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }

    this.canvasContext.strokeStyle = this.color;
    this.canvasContext.lineWidth = this.lineWidth;
    this.canvasContext.lineJoin = 'round';
    this.canvasContext.lineCap = 'round';
  }

  public hasDrawing() {
    return this.lines.length > 0;
  }

  /**
   * Undo last drawn line on canvas.
   */
  public undo() {
    if (this.hasDrawing()) {
      this.lines = this.lines.slice(0, this.lines.length - 1);
      this.redraw(this.canvasContext);
    }
  }

  /**
   * Redraw all lines into given context.
   */
  private redraw(context: any, scale = { x: 1, y: 1 }): void {
    if (typeof this.line !== 'object') {
      return;
    }

    const lines = this.lines.slice();
    const linesLength = lines.push(this.line);

    let coords;

    this.reset(this.canvas.width, this.canvas.height);

    for (let i = 0; i < linesLength; i++) {
      coords = lines[i].coords;

      // Do line scaling
      if (scale.x !== 1 || scale.y !== 1) {
        for (let i = 0, l = coords.length; i < l; i++) {
          coords[i] = {
            x: Math.floor(coords[i].x * scale.x),
            y: Math.floor(coords[i].y * scale.y)
          };
        }
      }

      this.drawLine(context, lines[i]);
    }
  }

  /**
   * Create a new copy of current canvas.
   * Specify width and height dimensions.
   * Lines will be scaled proportionally.
   */
  public copy(width: number, height: number): HTMLCanvasElement {
    const scale = { x: width ? width / this.canvas.width : 1, y: height ? height / this.canvas.height : 1 };
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    canvas.width = width;
    canvas.height = height;

    context.lineWidth = this.lineWidth * ((scale.x + scale.y) / 2);
    context.lineJoin = 'round';
    context.lineCap = 'round';

    this.redraw(context, scale);

    return canvas;
  }

  /**
   * Get current mouse/touch coordinate.
   */
  private getCoord(event: any): any {
    switch (event.type) {
      case 'mousemove': case 'mousedown': case 'mouseup':
        return { x: event.pageX, y: event.pageY };

      case 'touchmove': case 'touchstart': case 'touchend':
        return { x: event.changedTouches[0].pageX, y: event.changedTouches[0].pageY };
    }

    return false;
  }

  /**
   * Begin drawing a line.
   */
  public beginDraw(event: MouseEvent | TouchEvent): void {
    if (this.isDrawing) {
      return;
    }

    this.canvasOffset = this.canvas.getBoundingClientRect();

    const coord = this.getCoord(event);
    if (coord !== false) {
      this.line = {
        color: this.color,
        coords: [{
          x: Math.floor(coord.x - this.canvasOffset.left),
          y: Math.floor(coord.y - this.canvasOffset.top)
        }]
      };
    }

    this.redraw(this.canvasContext);

    this.isDrawing = true;

    // Avoid violation event report, not all event are cancelable
    if (event.cancelable) {
      event.preventDefault();
    }
  }

  /**
   * While drawing a line.
   */
  public duringDraw(event: MouseEvent | TouchEvent ): void {
    if (!this.isDrawing) {
      return;
    }

    const coord = this.getCoord(event);
    if (coord !== false) {
      this.line.coords.push({
        x: Math.floor(coord.x - this.canvasOffset.left),
        y: Math.floor(coord.y - this.canvasOffset.top)
      });
    }

    if (!this.blockDraw) {
      if (this.throttle > 0) {
        this.blockDraw = true;

        setTimeout(() => {
          this.redraw(this.canvasContext);
          this.blockDraw = false;
        }, this.throttle);
      } else {
          this.redraw(this.canvasContext);
      }
    }

    // Avoid violation event report, not all event are cancelable
    if (event.cancelable) {
      event.preventDefault();
    }
  }

  /**
   * Finish drawing line.
   */
  public endDraw(event: MouseEvent | TouchEvent): void {
    if (!this.isDrawing) {
      return;
    }

    const coord = this.getCoord(event);
    if (coord !== false) {
      this.line.coords.push({
        x: Math.floor(coord.x - this.canvasOffset.left),
        y: Math.floor(coord.y - this.canvasOffset.top)
      });
    }

    this.lines.push(this.line);

    this.isDrawing = false;

    this.line = {
      color: this.color,
      coords: []
    };

    this.redraw(this.canvasContext);

    // Avoid violation event report, not all event are cancelable
    if (event.cancelable) {
      event.preventDefault();
    }
  }

  /**
   * Draw a line on given canvas context.
   */
  private drawLine(context: any, line: { color, coords: {x, y}[] }): void {
    const coords = line.coords;
    const l = coords.length;
    let i = 0; // Current coord index

    context.beginPath();

    // Draw all full beziers
    while (i < l - 3) {
      context.moveTo(coords[i].x, coords[i].y);

      context.bezierCurveTo(
        coords[i + 1].x, coords[i + 1].y,
        coords[i + 2].x, coords[i + 2].y,
        coords[i + 3].x, coords[i + 3].y
      );

      i = i + 3;
    }

    // Draw partial ending
    if (i < l) {
      context.moveTo(coords[i].x, coords[i].y);

      let diff = false;
      coords.forEach(coord => {
        if (coord.x !== coords[0].x || coord.y !== coords[0].y) {
          diff = true;
        }
      });

      if (!diff) {
        context.lineTo(coords[i].x + 1, coords[i].y + 1);
      } else {
        context.bezierCurveTo(
          coords[(i + 1 < l - 1 ? i + 1 : l - 1)].x, coords[(i + 1 < l - 1 ? i + 1 : l - 1)].y,
          coords[(i + 2 < l - 1 ? i + 2 : l - 1)].x, coords[(i + 2 < l - 1 ? i + 2 : l - 1)].y,
          coords[(i + 3 < l - 1 ? i + 3 : l - 1)].x, coords[(i + 3 < l - 1 ? i + 3 : l - 1)].y
        );
      }
    }

    context.closePath();
    context.strokeStyle = line.color;
    context.stroke();
  }
}
