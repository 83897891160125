import { Component, OnInit } from '@angular/core';
import { ModalService, NotificationService } from '@services';
import { NotificationFormatUtil } from '../../utils/format-notification-data.util';
import { INotification } from '@interfaces';

@Component({
  selector: 'app-system-notification',
  templateUrl: './system-notification.component.html',
  styleUrls: ['./system-notification.component.scss'],
})
export class SystemNotificationComponent implements OnInit {
  public notification: INotification;
  public title: string;
  public body: string;

  constructor(private modalService: ModalService, private notificationService: NotificationService, private notificationFormatUtil: NotificationFormatUtil) {}

  ngOnInit() {
    this.title = this.notification.data.title ?? '';
    this.body = this.notificationFormatUtil.formatText(this.notification.data ?? {});
  }

  public closeModal() {
    this.notificationService.readSystemNotification(this.notification.id);
    this.modalService.close();
  }
}
