import { Injectable, NgZone } from '@angular/core';
import { parseUrl } from '../utils/url.util';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Capacitor } from '@capacitor/core';
import { LangService } from './lang.service';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class SamlAuthService {
  public hasConnected = false;  // flag that indicates that account was just connected

  private browser = null;

  constructor(
    private helperService: HelperService,
    private langService: LangService,
    private apiService: ApiService,
    private iab: InAppBrowser,
    private zone: NgZone,
    private routeSrv: RouteService,
  ) {}

  /**
   * Start sso process
   * @param ssoId
   * @param options
   */
  public async login(ssoId, options) {
    await this.helperService.showLoader();

    try {
      const res = await this.apiService.post(this.routeSrv.PATHS.saml.loginUrl, {ssoId: ssoId});

      await this.helperService.dismissLoader();

      if (!res || !res.loginUrl) {
        return Promise.reject('no_sso_id_found');
      }

      return this.openLoginUrl(res.loginUrl, options);
    } catch (e) {
      await this.helperService.dismissLoader();

      return Promise.reject();
    }
  }

  /**
   * Connect current user to external user
   * @param params
   * @return Promise<boolean>
   */
  public async connect(params): Promise<boolean> {
    if (!params.samlConnectToken) {
      return false;
    }

    if (params.samlConnectedUserName &&
      !(await this.helperService.showConfirm(
        this.langService.t('saml.confirm-reconnect-account', {userName: params.samlConnectedUserName})
      ))
    ) {
      return false;
    }

    await this.helperService.showLoader();

    try {
      const result = await this.apiService.post(this.routeSrv.PATHS.saml.connect, {token: params.samlConnectToken});
      if (result.success) {
        this.hasConnected = true;
      }
    } catch (e) {
      console.error(e);
    }

    await this.helperService.dismissLoader();

    return this.hasConnected;
  }

  /**
   * Open login url
   * @param url
   * @param options
   * @return Promise<any>
   */
  private openLoginUrl(url: string, options): Promise<any> {
    const params = new URLSearchParams();

    if (options.hasOwnProperty('forceAuth')) {
      params.append('forceAuth', String(options.forceAuth));
    }

    if (Capacitor.isNative) {
      params.set('backUrl', 'app');
      return this.openUrlNative(url, params);
    }

    let backUrl = window.location.href;
    if (options.hasOwnProperty('backPath')) {
      backUrl = window.location.origin + options.backPath;
    }
    params.set('backUrl', backUrl);

    this.openUrlDesktop(url, params);
  }

  /**
   * Open login url in native app
   * @param url
   * @param params
   * @return Promise<any>
   */
  private async openUrlNative(url: string, params: URLSearchParams): Promise<any> {
    return new Promise((resolve, reject) => {
      const options: InAppBrowserOptions = {
        hidenavigationbuttons: 'yes',
        location: 'no',
        usewkwebview: 'yes'
      };

      const urlParams = params.toString();
      this.browser = this.iab.create(url + '?' + urlParams, '_blank', options);

      this.browser.on('loadstop').subscribe(event => {
        if (event.url.indexOf('/saml/result') > 0) {
          this.zone.run(() => {
            this.browser.close();

            const urlObj = parseUrl(event.url);
            if (!urlObj.query) {
              return reject();
            }

            resolve(urlObj.query);
          });
        }
      });
    });
  }

  /**
   * Open login url for web app
   * @param url
   * @param params
   * @private
   */
  private openUrlDesktop(url: string, params: URLSearchParams) {
    window.location.href = url + '?' + params.toString();
  }

}
