import { Injectable } from '@angular/core';
import { LangService } from '../../../../lang.service';
import { IOdometerData, ITransformer, IOdometerDue } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class DueOdometerTransformer implements ITransformer {
  constructor(
    private readonly langSrv: LangService,
  ) {
  }

  /** Create odometer-data from due odometer */
  public transform(odoDue: IOdometerDue): IOdometerData | null {
    return odoDue
      ? {
        when: odoDue.within_value,
        expires: odoDue.expires,
        activates: odoDue.activates,
        current: odoDue.current,
        suffix: Number.isInteger(odoDue.measurement_units)
          ? this.makeOdometerSuffix(odoDue.measurement_units)
          : null,
        expire_at: null
      }
      : null;
  }

  /** Get suffix of unit */
  private makeOdometerSuffix(unit: number): string {
    return this.langSrv.t(`odometers.measurement_units.full.${unit}`);
  }
}
