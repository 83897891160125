import { sortBy } from './sort.util';

export class DeviationUtil {
  /**
   * Format deviation label
   * @param deviation
   * @return {string}
   */
  static formatLabel(deviation: any): string {
    let deviationLabel = deviation.label;
    if (deviation.label_append) {
      deviationLabel += ' [' + deviation.label_append + ']';
    }
    return deviationLabel;
  }

  /**
   * Check is deviation independent or not
   * @param deviation
   * @return {boolean}
   */
  static isIndependent(deviation: any): boolean {
    return !deviation.hasOwnProperty('check_control_point_id');
  }

  /**
   * Get deviation type
   * @param deviation
   * @return {string}
   */
  static getType(deviation: any): string {
    return this.isIndependent(deviation) ? 'independent' : 'deviation';
  }

  /**
   * Get full deviation type
   * @param deviation
   * @return {string}
   */
  static getFullType(deviation: any): string {
    return DeviationUtil.isIndependent(deviation) ? 'independentdeviations' : 'deviations';
  }

  static prepareDeviation(deviation) {
    if (deviation && deviation.tags && deviation.tags.length) {
      deviation.tags = sortBy(deviation.tags, 'order');
    }
    return deviation;
  }

  /**
   * Check is deviation has writable permissions
   * @param deviation
   */
  static hasWritePermissions(deviation) {
    if (!deviation) {
      return false;
    }
    if (deviation.permission && deviation.permission.actions && deviation.permission.actions.indexOf('write') >= 0) {
      return true;
    }
    if (deviation.objectable && deviation.objectable.hasOwnProperty('writable')) {
      return deviation.objectable.writable;
    }
    return false;
  }
}
