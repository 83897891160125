import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { IServiceIntervalShort} from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class ServiceIntervalsClient {
  constructor(
    private apiService: ApiService,
    private routeSrv: RouteService,
  ) {
  }

  /** Fetch intervals by list of UID */
  public getIntervalsByUids(uids: string[]): Promise<IServiceIntervalShort[]> {
    return this.apiService.get(
      this.routeSrv.makeUrl(
        this.routeSrv.PATHS.serviceInterval.batches.list,
        { uids },
      ),
    );
  }
}
