import { OfflineRequestBase } from './base';
import { OfflineDataUtil } from '../../../utils/offline-data.util';

export class OfflineRequestCheck extends OfflineRequestBase {
  private errorControlPointsSync;

  /**
   * Execute check request
   * @param request
   * @return {any}
   */
  public execute(request) {
    if ('delete' === request.method) {
      return this.sendRequest(request);
    }

    if (!request.chunk) {
      this.createChunk(request);
    }

    return this.handleRequestChunk(request.chunk).then((check) => {
      if (request.chunk.finalizeCheck) {
        return this.sendRequest({
          method: 'put',
          url: '/checksongoing/' + check.id,
          data: {
            progress: this.requestsHandler.helperService.protocol.progress.COMPLETED
          },
          id: request.id || null,
        });
      }

      if ('delete' === request.method) {
        return this.sendRequest(request);
      }

      return check;
    }).then((check) => {
      if (this.errorControlPointsSync) {
        return {
          error: this.errorControlPointsSync
        };
      }

      return check;
    });
  }

  /**
   * Create a chunk for current request
   * chunk is a map what requests should be performed for this check
   * chunk will be stored to local storage if some request was failed
   * @param request
   */
  private createChunk(request) {
    const checkData = <any>{};
    const controlPointsToSave = [];
    Object.keys(request.data).forEach(key => {
      if (key === 'check_control_points') {
        const controlPointsNotChanged = request.data[key].filter(controlPoint => {
          if (controlPoint.app_offline_reported_at) {
            controlPointsToSave.push(controlPoint);
            return false;
          }

          return true;
        });

        // send control points only while creating a check
        if (request.method === 'post') {
          checkData[key] = controlPointsNotChanged;
        }

        return;
      }

      checkData[key] = request.data[key];
    });

    let finalizeCheck = false;
    // if check is completed and there some control points wait for save - finalize check after
    if (checkData.hasOwnProperty('progress') && checkData.progress === this.requestsHandler.helperService.protocol.progress.COMPLETED && controlPointsToSave.length) {
      checkData.progress = this.requestsHandler.helperService.protocol.progress.ONGOING;
      finalizeCheck = true;
    }

    const checkChildren = [];
    controlPointsToSave.forEach(controlPointData => {
      const pointChildren = [];
      if (controlPointData.deviations) {
        controlPointData.deviations.forEach(deviationData => {
          if (!deviationData.app_offline_reported_at) {
            return;
          }

          pointChildren.push({
            method: 'saveDeviation',
            data: deviationData,
            completed: false,
            response: null,
            id: this.requestsHandler.offlineHandlerService.generateRequestId(),
          });
        });
      }

      checkChildren.push({
        method: 'saveControlPoint',
        data: controlPointData,
        completed: false,
        response: null,
        children: pointChildren,
        id: this.requestsHandler.offlineHandlerService.generateRequestId(),
      });
    });

    this.validateAndFixCheckData(checkData, request.result);

    request.chunk = {
      method: 'saveCheck',
      data: checkData,
      completed: false,
      response: null,
      children: checkChildren,
      finalizeCheck: finalizeCheck,
      id: this.requestsHandler.offlineHandlerService.generateRequestId(),
    };
  }

  /**
   * Send request for saving check
   * @param request
   * @return {any}
   */
  private saveCheck(request) {
    const checkData = request.data;
    let method = 'post';
    let url = '/checksongoing';
    if (checkData.id && !OfflineDataUtil.isNewItem(checkData)) {
      method = 'put';
      url += '/' + checkData.id;
    }

    if (method === 'put' && Object.keys(checkData).length === 1 && checkData.id) {
      // if checkData contains only 'id' property - nothing was changed for check itself
      return Promise.resolve(checkData);
    }

    return this.sendRequest({
      method: method,
      url: url,
      data: checkData,
      id: request.id || null,
    });
  }

  /**
   * Send request for saving check control point
   * @param request
   * @param check
   * @return {any}
   */
  private saveControlPoint(request, check) {
    const controlPointData = request.data;
    const cpData = <any>{};
    Object.keys(controlPointData).forEach(key => {
      if (key === 'deviations') {
        return;
      }

      cpData[key] = controlPointData[key];
    });

    let method = 'post';
    let url = '/checksongoing/' + check.id + '/control';
    if (controlPointData.id && !OfflineDataUtil.isNewItem(controlPointData)) {
      method = 'put';
      url += '/' + controlPointData.id;
    }

    return this.sendRequest({
      method: method,
      url: url,
      data: cpData,
      id: request.id || null,
    }).then((controlPoint) => {
      if (!controlPoint) {
        throw new Error();
      }

      // not critical errors, that should not stop sync
      if (controlPoint.error) {
        this.errorControlPointsSync = controlPoint.error;
      }

      return controlPoint;
    });
  }

  /**
   * Send request for saving check deviations
   * @param request
   * @param controlPoint
   * @param check
   * @return {Promise<any>}
   */
  private saveDeviation(request, controlPoint, check) {
    const deviationData = request.data;
    let deviationMethod = 'post';
    let deviationUrl = '/deviations';

    if (deviationData.id && !OfflineDataUtil.isNewItem(deviationData)) {
      deviationMethod = 'put';
      deviationUrl += '/' + deviationData.id;
    } else {
      deviationData.check_id = check.id;
      deviationData.check_control_point_id = controlPoint.id;
      if (deviationData.check) {
        deviationData.check.id = check.id;
      }

      if (deviationData.deviation_components && deviationData.deviation_components.length) {
        deviationData.deviation_components.forEach(deviationComponent => {
          deviationComponent.check_id = check.id;
        });
      }

      if (deviationData.new_activities && deviationData.new_activities.length) {
        deviationData.new_activities.forEach(newActivity => {
          if (newActivity.deviation_activity_components && newActivity.deviation_activity_components.length) {
            newActivity.deviation_activity_components.forEach(deviationActivityComponent => {
              deviationActivityComponent.check_id = check.id;
            });
          }
        });
      }
    }

    return this.sendRequest({
      method: deviationMethod,
      url: deviationUrl,
      data: deviationData,
      id: request.id || null,
    });
  }

  /**
   * Temporary workaround to fix an unresolved issue when offline check doesn't contain all info needed for api
   */
  private validateAndFixCheckData(checkData, result) {
    checkData.name = checkData.name || result.name;
    checkData.reported_at = checkData.reported_at || result.reported_at;
    checkData.checklist_variant_id = checkData.checklist_variant_id || result.checklist_variant_id;

    if (!checkData.unit_id && result.unit_id) {
      checkData.unit_id = result.unit_id;
    }
    if (!checkData.round_id && result.round_id) {
      checkData.round_id = result.round_id;
    }
  }
}
