import { IResponsibleFilterSet }
  from '@interfaces';
import AbstractSubBuilder from '../abstract.sub-builder';
import SearchFilterBuilder from '../../search-filter.builder';

/** Filter by responsible users/groups */
export default class ResponsibleBuilder extends AbstractSubBuilder<SearchFilterBuilder, IResponsibleFilterSet> {
  /** Assigned users/groups */
  private usersGroups: string[] = [];

  /** Set UIDs of target users/groups */
  public setUsersGroups(uids: string[]): this {
    this.usersGroups = uids;
    return this;
  }

  /** @inheritDoc */
  public build(): IResponsibleFilterSet {
    return {
      userNames: this.usersGroups.map(id => ({id})),
    };
  }

  /** @inheritDoc */
  public reset(): this {
    this.usersGroups = [];
    return this;
  }
}
