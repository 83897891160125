<div class="ion-text-center">
  <ion-grid fixed>
    <ion-row>
      <ion-col class="text">{{title}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col>{{body}}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-button iconClass="fal fa-check-circle color-success" (onClick)="closeModal()">{{'general.ok' | lang }}</app-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
