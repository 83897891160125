import AbstractSubBuilder from '../abstract.sub-builder';
import { IDueTimeFilterSet }  from '@interfaces';
import SearchFilterBuilder from '../../search-filter.builder';

/** Due-time section */
export default class DueTimeBuilder extends AbstractSubBuilder<SearchFilterBuilder, IDueTimeFilterSet> {
  /** Included/excluded tasks without due-date */
  private enableNoDueDate = false;
  /** Included/excluded from date filter  */
  private from: Date = null;
  /** Included/excluded to date filter  */
  private to: Date = null;

  /** Include tasks without due-date */
  public withNoDueDate(): this {
    this.enableNoDueDate = true;
    return this;
  }

  /** Exclude tasks without due-date */
  public withoutNoDueDate(): this {
    this.enableNoDueDate = false;
    return this;
  }

  /** Include service type tasks */
  public setFrom(from: Date): this {
    this.from = from;
    return this;
  }

  /** Include service type tasks */
  public setTo(to: Date): this {
    this.to = to;
    return this;
  }

  /** @inheritDoc */
  public build(): IDueTimeFilterSet {
    return {
      noDueDate: this.enableNoDueDate,
      overdue: true,
      upcoming: true,
      from: this.from,
      to: this.to,
    };
  }

  /** @inheritDoc */
  public reset(): this {
    this.enableNoDueDate = false;
    this.from = null;
    this.to = null;
    return this;
  }
}
