import { Injectable } from '@angular/core';
import { ISearchFilterBuilder, ISearchFilterSet, ITasksPage } from '@interfaces';
import UnitBuilder from './sub-sections/object-builder/unit-builder/unit.builder';
import RoundBuilder from './sub-sections/object-builder/round-builder/round.builder';
import ResponsibleBuilder from './sub-sections/responsible-builder/responsible.builder';
import DueTimeBuilder from './sub-sections/due-time/due-time.builder';
import { Observable, Subscriber } from 'rxjs';
import ClientProvider from '../../client-provider/client-provider';

/** Configurable provider of PT tasks */
@Injectable({
  providedIn: 'root'
})
export default class SearchFilterBuilder implements ISearchFilterBuilder {
  /** Default page size */
  private readonly PAGE_SIZE = 50;
  /** Enable unit section */
  private enabledUnits = false;
  /** Enable round section */
  private enabledRounds = false;
  /** Current page number */
  private currentPage = 1;
  /** Size of returned page */
  private pageSize: number = this.PAGE_SIZE;
  /** Company uid **/
  private companyUid: string;
  /** Unit section */
  private unitSection = new UnitBuilder(this);
  /** Round section */
  private roundSection = new RoundBuilder(this);
  /** Due-time section */
  private dueTimeSection = new DueTimeBuilder(this);
  /** Responsible section */
  private responsibleSection = new ResponsibleBuilder(this);
  /** Show inactive tasks **/
  private showInactive = false;

  /** Return result with format for calendar */
  private isCalendarResult = false;

  public constructor(
    protected readonly provider: ClientProvider,
  ) {
  }

  /** Get build of unit section */
  public getUnitSection(): UnitBuilder {
    return this.unitSection;
  }

  /** Get builder of due-time */
  public getDueTimeSection(): DueTimeBuilder {
    return this.dueTimeSection;
  }

  /** Get build of round section */
  public getRoundSection(): RoundBuilder {
    return this.roundSection;
  }

  /** Get build of responsible */
  public getResponsibleSection(): ResponsibleBuilder {
    return this.responsibleSection;
  }

  /** Enable units section */
  public enableUnits(): this {
    this.enabledUnits = true;
    return this;
  }

  /** Disable units section */
  public disableUnits(): this {
    this.enabledUnits = false;
    return this;
  }

  /** Enabled rounds section */
  public enableRounds(): this {
    this.enabledRounds = true;
    return this;
  }

  /** Disable rounds section */
  public disableRounds(): this {
    this.enabledRounds = false;
    return this;
  }

  /** Set required page number */
  public setCurrentPage(page: number): this {
    this.currentPage = page;
    return this;
  }

  /** Increment current page size */
  public incrementCurrentPage(): this {
    this.currentPage++;
    return this;
  }

  /** Set required company uid */
  public setCompanyUid(uid: string): this {
    this.companyUid = uid;
    return this;
  }

  /** get company uid  */
  public getCompanyUid(): string | null {
    return this.companyUid;
  }

  /** Set size of page */
  public setPageSize(size: number | null): this {
    this.pageSize = size;
    return this;
  }

  /** Get current page size */
  public getPageSize(): number | null {
    return this.pageSize;
  }

  /** Set status in use or not in use */
  public setShowInactive(showInactive: boolean): this {
    this.showInactive = showInactive;
    return this;
  }

  /** Get status in use or not in use */
  public getShowInactive(): boolean {
    return this.showInactive;
  }

  /** Set calendar result */
  public enableCalendarResult(): this {
    this.isCalendarResult = true;

    return this;
  }

  /** Set calendar result */
  public disableCalendarResult(): this {
    this.isCalendarResult = false;

    return this;
  }

  /** Get calendar-result value */
  public getCalendarResult(): boolean {
    return this.isCalendarResult;
  }

  /** Create filtering object */
  public build(): ISearchFilterSet {
    const filter = {
      units: this.enabledUnits,
      rounds: this.enabledRounds,
      current_page: this.currentPage,
      page_size: this.pageSize,
      dueTime: this.dueTimeSection.build(),
      responsible: this.responsibleSection.build(),
      showInactive: this.showInactive,
      calendarResult: this.isCalendarResult,
    };
    if (this.enabledUnits) {
      filter['unit'] = this.unitSection.build();
    }

    if (this.enabledRounds) {
      filter['round'] = this.roundSection.build();
    }

    if (this.companyUid) {
      filter['companyUid'] = this.getCompanyUid();
    }

    return filter;
  }

  /** @inheritDoc */
  public reset(): this {
    this.enabledUnits = false;
    this.enabledRounds = false;
    this.currentPage = 1;
    this.pageSize = this.PAGE_SIZE;
    this.companyUid = null;
    this.showInactive = false;
    this.isCalendarResult = false;
    this.unitSection.reset();
    this.roundSection.reset();
    this.dueTimeSection.reset();
    this.responsibleSection.reset();

    return this;
  }

  /** Create a new filter */
  public create(): SearchFilterBuilder {
    return new SearchFilterBuilder(this.provider);
  }

  /** Fetch the first page by the filter */
  public fetch(): Promise<ITasksPage> {
    return this.provider
      .getClient()
      .then(client => client.getTasks(this.build()));
  }

  /** Fetch all pages by the filter */
  public fetchAll(): Observable<ITasksPage> {
    /** Check if the page is the last one */
    const isLastPage = (page: ITasksPage): boolean => this.pageSize > page.data?.length;

    /** Collect page items and recursively process next pages */
    const processPage = (subscriber: Subscriber<ITasksPage>, page: ITasksPage) => {
      subscriber.next(page);

      return isLastPage(page)
        ? subscriber.complete()
        : this
          .incrementCurrentPage()
          .fetch()
          .then(page => processPage(subscriber, page));
    };

    return new Observable(
      subscriber => {
        this.fetch()
          .then(page => processPage(subscriber, page));
      }
    );
  }
}
