/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable prefer-spread */
import { OfflineHandlerService } from './handler.service';
import { OfflineDataUtil } from '../../utils/offline-data.util';
import cloneDeep from 'lodash/cloneDeep';
import { sortBy } from '../../utils/sort.util';
import { CategoryUtil } from '../../utils/category.util';
import { kebabCase } from 'lodash';
import {format} from 'date-fns';
import {FNS_ATOM_DATE_TIME} from '../../constants/data-time-formats';
import { Capacitor } from '@capacitor/core';
// don't delete these import, included to make ng test pass
import { BaseController } from './controllers/base-controller';
import { RfidTagsController } from './controllers/rfid-tags-controller';
import { TasksController } from './controllers/tasks-controller';
import { AppSettingsController } from './controllers/app-settings-controller';
import { FormTemplatesController } from './controllers/form-templates-controller';
import { ChecklistsController } from './controllers/checklists-controller';
import { ServiceIntervalsController } from './controllers/service-intervals-controller';
import { DeviationsController } from './controllers/deviations-controller';

export class OfflineDataController {
  constructor(
    private offlineHandlerService: OfflineHandlerService,
    private route,
    private params
  ) { }

  /**
   * Get proper offline response based on current route and parameters
   * @return
   */
  public getResponse(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (this.route.hasOwnProperty('method')) {
        const args = this.route.hasOwnProperty('arguments') ? this.route.arguments : [];
        return this[this.route.method].apply(this, args).then((objects) => {
          resolve(objects);
        }).catch(reject);
      } else if (this.route.hasOwnProperty('controller') && this.route.hasOwnProperty('action')) {
        return import('./controllers/' + kebabCase(this.route.controller)).then(controllerModule => {
          const controller = new controllerModule[this.route.controller](
            this.offlineHandlerService,
            this.route,
            this.params
          );
          return controller[this.route.action].apply(controller, Object.values(this.route.params))
            .then(resolve)
            .catch(reject);
        });
      } else {
        return this.getDataFromRoute(this.route, this.params || {})
          .then((data) => {
            resolve(data);
          }).catch(reject);
      }
    });
  }

  /**
   * Get proper offline response based on current Request and parameters
   * method + arguments => check example in route.ts : '/checksongoing' & '/checksongoing/:id'
   * controller + action => check example in route.ts : '/users/me/settings'
   * @return
   */
    public performRequest(method, url): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const methodName = 'on' + method.charAt(0).toUpperCase() + method.slice(1);
            if (this.route.hasOwnProperty(methodName)) {
                const methodObject = this.route[methodName];
                if (methodObject.hasOwnProperty('method')) {
                    const args = methodObject.hasOwnProperty('arguments') ? methodObject.arguments : [];
                    return this[methodObject.method].apply(this, args).then((objects) => {
                        resolve(objects);
                    }).catch(reject);
                } else if (methodObject.hasOwnProperty('controller') && methodObject.hasOwnProperty('action')) {
                    return import('./controllers/' + kebabCase(methodObject.controller)).then(controllerModule => {
                        const controller = new controllerModule[methodObject.controller](
                            this.offlineHandlerService,
                            methodObject,
                            this.params
                        );
                        const param = methodObject.hasOwnProperty('param') ? Object.values(methodObject.param) : null;
                        return controller[methodObject.action].apply(controller, param)
                            .then(resolve)
                            .catch(reject);
                    });
                } else {
                    reject();
                }
            } else {
                resolve(null);
            }
        });
    }

  /**
   * Custom method to get response for /objects request
   * @return
   */
  private getObjects(): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = this.params;
      const roundsCollection = this.offlineHandlerService.getCollection('/rounds/list');
      const unitsCollection =  this.offlineHandlerService.getCollection('/units/list');
      const promises = [
        params.roundsOnly || unitsCollection === null ? Promise.resolve([]) : unitsCollection,
        params.unitsOnly || roundsCollection === null ? Promise.resolve([]) : roundsCollection,
      ];

      Promise.all(promises).then(resArr => {
        let units = resArr[0];
        let rounds = resArr[1];
        let objects;

        params.workplaces = OfflineDataUtil.convertToArray(params.workplaces);
        params.sections = OfflineDataUtil.convertToArray(params.sections);
        units = this.filterObjectsByRequest(units, params);
        rounds = this.filterObjectsByRequest(rounds, params);

        if (params) {
          if (params.units || params.rounds) {
            const unitIds = params.units || [];
            const roundIds = params.rounds || [];

            units = units?.filter(unit => unitIds.indexOf(unit.id) >= 0) || [];
            rounds = rounds?.filter(round => roundIds.indexOf(round.id) >= 0) || [];
          }

          if (params.writable) {
            units = units?.filter(unit => unit.writable) || [];
            rounds = rounds?.filter(round => round.writable) || [];
          }
        }

        const per_page = this.params && this.params.per_page ? this.params.per_page : 0;

        if (this.params.search || this.params.query) {
          const searchTerm = this.params.search || this.params.query;
          units = OfflineDataUtil.filterBySearch(units, searchTerm, ['object_brand', 'object_id', 'object_make', 'object_type']);
          rounds = OfflineDataUtil.filterBySearch(rounds, searchTerm, ['object_name', 'object_id', 'object_type']);
          objects = units.concat(rounds);
        } else if (per_page) {
          const page = this.params && this.params.page ? this.params.page : 1;
          units = OfflineDataUtil.paginate(units, per_page, page);
          rounds = OfflineDataUtil.paginate(rounds, per_page, page);
          let last_page = 0;
          if (rounds !== null && units !== null) {
            last_page = units.last_page > rounds.last_page ? units.last_page : rounds.last_page;
            objects = {
              current_page: page,
              data: units.data.concat(rounds.data),
              per_page: per_page,
              last_page: last_page,
              total: units.total + rounds.total
            };
          } else if (rounds !== null && units === null) {
            last_page = rounds.last_page;
            objects = {
              current_page: page,
              data: rounds.data,
              per_page: per_page,
              last_page: last_page,
              total: rounds.total
            };
          } else if (rounds === null && units !== null) {
            last_page = units.last_page;
            objects = {
              current_page: page,
              data: units.data,
              per_page: per_page,
              last_page: last_page,
              total: units.total
            };
          }
        } else {
          objects = units.concat(rounds);
        }

        resolve(objects);
      }).catch(reject);
    });
  }

  /**
   * Custom method to get response for /workplaces request
   * @return
   */
  private getWorkplaces(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.offlineHandlerService.getCollection('/workplaces').then(workplaces => {
        if (this.params && this.params.ids) {
          const ids = this.params.ids || [];
          workplaces = workplaces.filter(workplace => ids.indexOf(workplace.id) >= 0);
        }
        if (this.params && this.params.writable) {
          workplaces = workplaces.filter(workplace => {
            if (workplace.sections && workplace.sections.length) {
              workplace.sections = workplace.sections.filter(section => section.writable);
            }
            return workplace.writable;
          });
        }
        if (this.params.query) {
          workplaces = OfflineDataUtil.filterBySearch(workplaces, this.params.query, ['name']);
        }

        resolve(workplaces);
      }).catch(reject);
    });
  }

  /**
   * Filter units/rounds based on request parameters
   * @param items
   * @param params
   * @return
   */
  private filterObjectsByRequest(items: Array<any>, params: any): Array<any> {
    if (!items || !Array.isArray(items)) {
      return items;
    }
    return items.filter(item => {
      if (params.workplaces && params.workplaces.length && params.workplaces.indexOf(item.workplace_id) < 0) {
        return false;
      }

      if (params.sections && params.sections.length) {
        if (!item.section || !item.section.length) {
          return false;
        }

        const foundSection = item.section.find(section => params.sections.indexOf(section.id) >= 0);

        if (!foundSection) {
          return false;
        }
      }

      return true;
    });
  }

  /**
   * Custom method to get response for /units/:id/incidents and /rounds/:id/incidents requests
   * @param objectType
   * @return
   */
  private getObjectIncidents(objectType: string): Promise<any> {
    return this.offlineHandlerService.getCollection('/incidents').then(incidents => {
      incidents = incidents.filter(incident => {
        if (!incident.incident_fields || !incident.incident_fields.length) {
          return false;
        }

        let found = false;

        const fieldOption = (objectType === 'Unit') ? 3 : 4;
        const validOptions = [fieldOption, 11];

        incident.incident_fields.forEach(field => {
          const type = parseInt(field.options, 10);
          if (!field.value || validOptions.indexOf(type) < 0) {
            return;
          }

          const objects = field.value.split(',');
          objects.forEach(object => {
            let value = null;
            if (type === fieldOption) {
              value = parseInt(object, 10);
            } else if (type === 11) {
              value = object.split('-');
              value = value.length === 2 && value[0] === objectType ? parseInt(value[1], 10) : null;
            }
            if (value && value === this.route.params[':id']) {
              found = true;
            }
          });
        });

        return found;
      });

      const per_page = this.params && this.params.per_page ? this.params.per_page : 0;
      if (per_page) {
        const page = this.params && this.params.page ? this.params.page : 1;
        return OfflineDataUtil.paginate(incidents, per_page, page);
      }

      return incidents;
    });
  }

  /**
   * Custom method to get my incidents response for /incidents/me request
   * @return
   */
  private getMyIncidents(): Promise<any> {
    return this.offlineHandlerService.getCollection('/incidents').then(incidents => {
      const per_page = this.params && this.params.per_page ? this.params.per_page : 0;
      const page = this.params && this.params.page ? this.params.page : 1;
      const active = this.params.active === 'true' ? true : false;

      incidents = incidents.filter(incident => {
        if (incident.is_mine !== true || !active) {
          return false;
        }
        return true;
      });

      return OfflineDataUtil.paginate(incidents, per_page, page);
    });
  }

  /**
   * Save incident
   * @return
   */
  private async saveIncident() {
    const collection = '/incidents';
    const items = await this.offlineHandlerService.getCollection(collection);
    const incident = cloneDeep(this.params);
    const currentUser = await this.offlineHandlerService.getResponse('/users/me', {withOnly: []});

    incident.id = OfflineDataUtil.generateRandomId();
    incident.serial_number = this.offlineHandlerService.langService.t('general.not-synced-yet');

    if (!incident.report_anonymous) {
      incident.is_mine = true;
      incident.created_by = currentUser;
      incident.updated_by = currentUser;
    }

    if (incident.incident_categories && incident.incident_categories.length) {
      const template = await this.offlineHandlerService.getResponse('/incidenttemplates/' + incident.incident_template_id);
      const templateCategories = template.incident_categories;
      const categories = [];
      incident.incident_categories.forEach(categoryId => {
        const category = CategoryUtil.getCategoryRecursive(categoryId, templateCategories);
        if (category) {
          categories.push(category);
        }
      });
      incident.incident_categories = categories;
    }

    if (incident.incident_steps && incident.incident_steps.length) {
      const reportStep = incident.incident_steps.find(step => step.type === this.offlineHandlerService.helperService.protocol.incidents.steps.REPORT);

      if (reportStep && reportStep.incident_sections && reportStep.incident_sections.length) {
        const section = reportStep.incident_sections.find(section => section.type === this.offlineHandlerService.helperService.protocol.incidents.sections.PREDEFINED_OTHER);

        if (section && section.incident_fields && section.incident_fields.length) {
          const incidentLabelField = section.incident_fields.find(field => field.type === this.offlineHandlerService.helperService.protocol.inputTypes.TEXT);

          if (incidentLabelField) {
            incident.name = incidentLabelField.value;
          }
        }
      }
    }

    items.push(incident);

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(incident);
  }

  /**
   * Custom method to get response for units/:id/categories and rounds/:id/categories requests
   * @param objectType
   * @return
   */
  private async getObjectCategories(objectType: string): Promise<any> {
    const objectUrl = objectType === 'Unit' ? '/units/' : '/rounds/';
    const object = await this.offlineHandlerService.getResponse(objectUrl + this.route.params[':id']);

    if (!object) {
      return Promise.resolve([]);
    }

    let objectTypeCategories = await this.offlineHandlerService.getCollection('/objecttypes/categories');
    objectTypeCategories = objectType === 'Unit' ? objectTypeCategories.unit : objectTypeCategories.round;
    if (objectTypeCategories[object.object_type]) {
      objectTypeCategories = objectTypeCategories[object.object_type];
    } else {
      objectTypeCategories = [];
    }

    const categoryExcludes = object.object_category_excludes;
    let categoryExcludeIds = [];
    if (categoryExcludes && categoryExcludes.length) {
      categoryExcludeIds = categoryExcludes.map(category => category.category_id);
    }

    const allCategories = this.offlineHandlerService.helperService.categories;
    const objectTypes = this.offlineHandlerService.helperService.protocol.objectTypes;
    const categoryObjectType = objectType === 'Unit' ? objectTypes.UNIT : objectTypes.ROUND;
    const categoryType = this.offlineHandlerService.helperService.protocol.categoryEnums.COMPANY_CATEGORY;

    const objectCategories = allCategories.filter(category => {
      if (category.object_type !== categoryObjectType || category.type !== categoryType
        || categoryExcludeIds.indexOf(category.id) >= 0
      ) {
        return false;
      }
      if (objectTypeCategories && objectTypeCategories.length && objectTypeCategories.indexOf(category.id) < 0) {
        return false;
      }

      return true;
    });

    return Promise.resolve(objectCategories);
  }

  /**
   * Custom method to get specific checklist variant for specific object
   * @param objectType
   * @private
   */
  private async getObjectChecklistVariant(objectType: string): Promise<any> {
    const checklistVariant = await this.offlineHandlerService.getResponse('/checklistvariants/'  + this.route.params[':id']);
    if (!checklistVariant) {
      return Promise.reject();
    }

    if (this.params?.appends === 'dueData') {
      const objectUrl = objectType === 'Unit' ? '/units/' : '/rounds/';
      const objectVariants = await this.offlineHandlerService.getResponse(objectUrl + this.route.params[':uid'] + '/checklistvariants');
      if (objectVariants?.checklist_variants?.length) {
        const objectVariant = objectVariants.checklist_variants.find(variant => variant.id === this.route.params[':id']);
        if (objectVariant) {
          checklistVariant.due_data = objectVariant.due_data;
        }
      }
    }

    return checklistVariant;
  }

  /**
   * Get correct permissibles list
   * @param type
   * @return
   */
  private async getPermissibles(type: string) {
    const permissibles = await this.offlineHandlerService.getCollection('/groupsandusers/permissibles');
    const id = parseInt(this.route.params[':id'], 10);

    return permissibles.filter(permissible => {
      let ids = null;
      if (type === 'Workplace') {
        ids = permissible.workplaces;
      } else if (type === 'Section') {
        ids = permissible.sections;
      }

      if (!ids) {
        return false;
      }

      ids = Object.keys(ids).map(_id => parseInt(_id, 10));

      return ids.indexOf(id) >= 0;
    });
  }

  /**
   * Get response based on route object
   * @param route
   * @param params
   * @return
   */
  private getDataFromRoute(route: any, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (route.hasOwnProperty('route')) {
        return this.getDataFromRoute(route.route, {}).then(data => OfflineDataUtil.processData(data, params, route)
            .then(resolve)
            .catch(reject)).catch(reject);
      }

      this.offlineHandlerService.getCollection(route.collection).then(data => OfflineDataUtil.processData(data, params, route)
          .then(resolve)
          .catch(reject)).catch(reject);
    });
  }

  private getInspection(): Promise<any> {
    const id = this.route.params[':id'];

    return new Promise((resolve, reject) => {
      const promises = [
        this.offlineHandlerService.getCollection('/units/list'),
        this.offlineHandlerService.getCollection('/rounds/list')
      ];

      Promise.all(promises).then(result => {
        let objects = result[0] ? result[0] : [];
        let inspectioData;

        objects = objects.concat(result[1] || []);
        objects.forEach(object => {
          object.lastInspections.forEach(inspection => {
            if (inspection.id === id) {
              inspectioData = inspection;
              inspectioData.objectable = object;
              inspectioData.inspection_interval = object.inspection_intervals.find(inspection_interval => inspection_interval.id === inspection.inspection_interval_id);
            }
          });
        });
        if (inspectioData) {
          resolve(inspectioData);
        } else {
          reject();
        }
      });
    });
  }

  /**
   * Call on updating deviation collection
   * @param method
   * @param type
   * @return
   */
  private async updateDeviationCollection(method, type) {
    const deviation = this.params;

    this.prepareDeviation(deviation);

    const collection = '/deviations/query/active';
    const deviationForCollection = <any>{
      deviationable_id: deviation.id,
      deviationable_type: 'App\\Models\\' + type,
      deviation_title: deviation.label,
      deviation_serial: deviation.serial_number,
      deviationable: deviation
    };

    const items = await this.offlineHandlerService.getCollection(collection);

    let isUpdated = false;

    if (method === 'post') {
      items.push(deviationForCollection);
      isUpdated = true;
    } else if (method === 'put') {
      items.forEach((item, key) => {
        if (item.deviationable_id !== deviationForCollection.deviationable_id ||
          item.deviationable_type !== deviationForCollection.deviationable_type
        ) {
          return;
        }

        items[key] = deviationForCollection;
        isUpdated = true;
      });
    }

    if (!isUpdated) {
      return Promise.resolve();
    }

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(deviation);
  }

  private prepareDeviation(deviation) {
    if (!deviation.id) {
      deviation.id = OfflineDataUtil.generateRandomId();
      deviation.serial_number = this.offlineHandlerService.langService.t('general.not-synced-yet');
      deviation.updated_at = format(new Date(), FNS_ATOM_DATE_TIME);

      if (deviation.objectable) {
        if (deviation.objectable.workplace_id) {
          deviation.workplace_id = deviation.objectable.workplace_id;
        }
        if (deviation.objectable.section && deviation.objectable.section.length) {
          deviation.section_id = deviation.objectable.section[0].id;
        }
      }

      deviation.deviation_activities = [];
    }

    if (deviation.worktimes && deviation.worktimes.length) {
      deviation.worktimes.forEach(worktime => {
        if (worktime.temp_id) {
          worktime._id = worktime.temp_id;
        }
      });
    }
    if (deviation.newActivity) {
      this.prepareDeviationActivity(deviation.newActivity);
    }
    if (deviation.new_activities) {
      deviation.new_activities.forEach(activity => this.prepareDeviationActivity(activity));
    }

    if (deviation.categories && deviation.categories.length > 0) {
      deviation.category = deviation.categories[deviation.categories.length - 1];
    }
  }

  /**
   * Prepare deviation activity object
   * @param activity
   */
  private prepareDeviationActivity(activity) {
    if (activity.refills && activity.refills.length) {
      activity.refills.forEach(refill => {
        if (refill.temp_id) {
          refill._id = refill.temp_id;
        }
      });
    }

    if (activity.article_usages && activity.article_usages.length) {
      activity.article_usages.forEach(article => {
        if (article.temp_id) {
          article._id = article.temp_id;
        }
      });
    }
  }

  /**
   * Call on removing refill from deviation
   * @param type
   * @return
   */
  private async removeDeviationRefill(type) {
    const collection = '/deviations/query/active';
    const items = await this.offlineHandlerService.getCollection(collection);
    const deviationable_id = this.route.params[':id'];
    const deviationable_type = 'App\\Models\\' + type;

    let removedRefill = null;
    let isDeleted = false;
    items.forEach((item, key) => {
      if (isDeleted || item.deviationable_id !== deviationable_id ||
        item.deviationable_type !== deviationable_type
      ) {
        return;
      }

      const deviation = items[key].deviationable;
      if (deviation && deviation.refills && deviation.refills.length) {
        deviation.refills = deviation.refills.filter(refill => {
          if (refill.id === this.route.params[':rid']) {
            removedRefill = refill;
            removedRefill.deviationable = deviation;
            isDeleted = true;
            return false;
          }

          return true;
        });
      }

      items[key].deviationable = deviation;
    });

    if (!isDeleted) {
      return Promise.resolve();
    }

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(removedRefill);
  }

  /**
   * Call on removing article from deviation
   * @param type
   * @return
   */
  private async removeDeviationArticle(type) {
    const collection = '/deviations/query/active';
    const items = await this.offlineHandlerService.getCollection(collection);
    const deviationable_id = this.route.params[':id'];
    const deviationable_type = 'App\\Models\\' + type;

    let removedArticle = null;
    let isDeleted = false;
    items.forEach((item, key) => {
      if (isDeleted || item.deviationable_id !== deviationable_id ||
        item.deviationable_type !== deviationable_type
      ) {
        return;
      }

      const deviation = items[key].deviationable;
      if (deviation && deviation.article_usages && deviation.article_usages.length) {
        deviation.article_usages = deviation.article_usages.filter(article => {
          if (article.id === this.route.params[':aid']) {
            removedArticle = article;
            removedArticle.deviationable = deviation;
            isDeleted = true;
            return false;
          }

          return true;
        });
      }

      items[key].deviationable = deviation;
    });

    if (!isDeleted) {
      return Promise.resolve();
    }

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(removedArticle);
  }

  /**
   * Get ongoing checks
   * @return
   */
  private async getOngoingChecks() {
    let items = await this.offlineHandlerService.getCollection('/checksongoing');

    if (!this.params) {
      return items;
    }

    const workplaces = OfflineDataUtil.convertToArray(this.params.workplaces);
    const sections = OfflineDataUtil.convertToArray(this.params.sections);
    const units = OfflineDataUtil.convertToArray(this.params.units);
    const rounds = OfflineDataUtil.convertToArray(this.params.rounds);
    const filterMine = this.params.filterMine || false;
    const filterNotMine = this.params.filterNotMine || false;
    const progress = OfflineDataUtil.convertToArray(this.params.progress);

    let currentUserId = null;
    if (filterMine || filterNotMine) {
      const user = await this.offlineHandlerService.getResponse('/users/me');
      currentUserId = user.id;
    }

    items = items.filter(item => {
      if (filterMine && currentUserId !== item.user_id) {
        return false;
      }
      if (filterNotMine && currentUserId === item.user_id) {
        return false;
      }

      if (progress && progress.length && progress.indexOf(item.progress) < 0) {
        return false;
      }

      if (workplaces && workplaces.length && workplaces.indexOf(item.workplace_id) < 0) {
        return false;
      }

      if (sections && sections.indexOf(item.section_id) < 0) {
        return false;
      }

      if (units && units.length) {
        if (!item.objectable_type || item.objectable_type.indexOf('Unit') < 0 || units.indexOf(item.objectable_id) < 0) {
          return false;
        }
      }

      if (rounds && rounds.length) {
        if (!item.objectable_type || item.objectable_type.indexOf('Round') < 0 || rounds.indexOf(item.objectable_id) < 0) {
          return false;
        }
      }

      return true;
    });

    if (this.params.sortProperty) {
      items = sortBy(items, this.params.sortProperty, this.params.sortDirection === 'desc');
    }

    const per_page = this.params && this.params.per_page ? this.params.per_page : 0;
    if (per_page) {
      const page = this.params && this.params.page ? this.params.page : 1;
      return OfflineDataUtil.paginate(items, per_page, page);
    }

    return items;
  }

  /**
   * Update check collection
   * @param method
   * @return
   */
  private async updateCheckCollection(method) {
    const collection = '/checksongoing';
    const items = await this.offlineHandlerService.getCollection(collection);
    let isUpdated = false;
    let result = null;
    let check = null;
    const currentUser = await this.offlineHandlerService.getResponse('/users/me', {withOnly: []});

    if (method === 'post') {
      check = <any>{};
      check.id = OfflineDataUtil.generateRandomId();
      check.serial_number = this.offlineHandlerService.langService.t('general.not-synced-yet');
      check.progress = this.offlineHandlerService.helperService.protocol.progress.ONGOING;
      check.checklist_variant_id = this.params.checklist_variant_id;
      check.name = this.params.name;
      if (this.params.unit_id) {
        check.objectable_id = this.params.unit_id;
        check.objectable_type = 'App\\Models\\Unit';
        check.objectable = await this.offlineHandlerService.getResponse('/units/' + this.params.unit_id, {withOnly: ['workplace', 'section']});
      } else if (this.params.round_id) {
        check.objectable_id = this.params.round_id;
        check.objectable_type = 'App\\Models\\Round';
        check.objectable = await this.offlineHandlerService.getResponse('/rounds/' + this.params.round_id, {withOnly: ['workplace', 'section']});
      }
      check.odometer = 0;
      check.status = 0;
      check.updated_at = format(new Date(), FNS_ATOM_DATE_TIME);
      check.reported_at = this.params.reported_at;

      check.check_control_points = this.params.check_control_points.map(checkpoint => {
        checkpoint.id = OfflineDataUtil.generateRandomId();
        return checkpoint;
      });
      check.user = currentUser;
      check.user_id = currentUser.id;
      check.removable = true;

      items.push(check);
      isUpdated = true;
      result = check;
    } else if (method === 'put') {

      check = cloneDeep(this.params);

      check.updated_at = format(new Date(), FNS_ATOM_DATE_TIME);
      check.user = currentUser;
      check.user_id = currentUser.id;

      if (check.check_control_points) {
        check.check_control_points.forEach(checkpoint => {
          if (checkpoint.deviations && checkpoint.deviations.length) {
            checkpoint.deviations.forEach((deviation, index) => {
              if (deviation._need_process) {

                this.prepareDeviation(deviation);
                delete deviation._need_process;
              }
            });
          }
        });
      }

      items.forEach((item, key) => {
        if (item.id !== check.id) {
          return;
        }

        items[key] = Object.assign({}, item, check);
        result = items[key];

        isUpdated = true;
      });
    }

    if (!isUpdated) {
      return Promise.resolve();
    }

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(result);
  }

  /**
   * Remove ongoing check from storage
   * @return
   */
  private async removeCheckOngoing() {
    const collection = '/checksongoing';
    let items = await this.offlineHandlerService.getCollection(collection);

    const check_id = this.route.params[':id'];

    let removedItem = null;
    items = items.filter((item) => {
      if (item.id === check_id) {
        removedItem = item;
        return false;
      }

      return true;
    });

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(removedItem);
  }

  /**
   * Get list of unit services
   * @param type
   * @return
   */
  private async getUnitServices() {
    const unitId = this.route.params[':id'];
    const unit = await this.offlineHandlerService.getResponse('/units/' + unitId);

    let status = null;
    if (this.params.hasOwnProperty('status')) {
      status = this.params.status;
    }

    let services = (unit.services || []);
    if (status !== null) {
      services = services.filter(service => service.status === status);
    }

    const per_page = this.params && this.params.per_page ? this.params.per_page : 0;

    if (per_page) {
      const page = this.params && this.params.page ? this.params.page : 1;
      services = OfflineDataUtil.paginate(services, per_page, page);
    }

    return services;
  }

  /**
   * Call on updating services collection
   * @param method
   * @param params
   * @return
   */
  private async updateServiceCollection(method, params = null) {
    const service = params || this.params;

    if (!service.id) {
      service.id = OfflineDataUtil.generateRandomId();
      service.serial_number = this.offlineHandlerService.langService.t('general.not-synced-yet');
      service.updated_at = format(new Date(), FNS_ATOM_DATE_TIME);
      service.service_activities = [];
    }

    if (service.worktimes && service.worktimes.length) {
      service.worktimes.forEach(worktime => {
        if (worktime.temp_id) {
          worktime._id = worktime.temp_id;
        }
      });
    }

    const collection = '/units/list';

    const items = await this.offlineHandlerService.getCollection(collection);

    let isUpdated = false;

    const unitObjectId = items.map(function(e) {
 return e.id;
}).indexOf(service.unit.id);

    service.unit = {
      id: service.unit.id,
      name: service.unit.name,
      workplace: service.unit.workplace,
      section: service.unit.section,
    };

    if (method === 'post') {
      items[unitObjectId]['services'].push(service);
      isUpdated = true;
    } else if (method === 'put') {
      const serviceItem = items[unitObjectId]['services'].map(function(e) {
 return e.id;
}).indexOf(service.id);
      items[unitObjectId]['services'][serviceItem] = service;
      isUpdated = true;
    }

    if (!isUpdated) {
      return Promise.resolve();
    }

    await this.offlineHandlerService.setCollectionData(collection, items);

    return Promise.resolve(service);
  }

  /**
   * Call on removing refill from service
   * @return
   */
  private async removeServiceRefill() {
    const id = this.route.params[':id'];
    const service = await this.offlineHandlerService.getResponse('/services/' + id);

    let removedRefill = null;
    let isDeleted = false;

    if (service && service.refills && service.refills.length) {
      service.refills = service.refills.filter(refill => {
        if (refill.id === this.route.params[':rid']) {
          removedRefill = refill;
          isDeleted = true;
          return false;
        }

        return true;
      });
    }

    if (!isDeleted) {
      return Promise.resolve();
    }

    await this.updateServiceCollection('put', service);

    return Promise.resolve(removedRefill);
  }

  /**
   * Call on removing article from service
   * @return
   */
  private async removeServiceArticle() {
    const id = this.route.params[':id'];
    const service = await this.offlineHandlerService.getResponse('/services/' + id);

    let removedArticle = null;
    let isDeleted = false;

    if (service && service.article_usages && service.article_usages.length) {
      service.article_usages = service.article_usages.filter(article => {
        if (article.id === this.route.params[':aid']) {
          removedArticle = article;
          removedArticle.service = service;
          isDeleted = true;
          return false;
        }

        return true;
      });
    }

    if (!isDeleted) {
      return Promise.resolve();
    }

    await this.updateServiceCollection('put', service);

    return Promise.resolve(removedArticle);
  }

  /**
   * Get company users and groups
   * @return
   */
  private async getGroupsAndUsers() {
    const items = await this.offlineHandlerService.getCollection('/groupsandusers/permissibles');

    const per_page = this.params && this.params.per_page ? this.params.per_page : 10;
    const term = this.params.search || this.params.query || '';

    const usersGroups = sortBy(items, 'name', false);

    const users = [];
    const groups = [];
    usersGroups.forEach(item => {
      if (term && item.name.toLowerCase().indexOf(term.toLowerCase()) < 0) {
        return false;
      }

      if (item.type === 0) {
        if (users.length >= per_page) {
          return;
        }
        item.username = item.name;
        item.firstname = item.name;
        item.lastname = '';
        users.push(item);
      } else {
        if (groups.length >= per_page) {
          return;
        }
        groups.push(item);
      }
    });

    return users.concat(groups);
  }

  /**
   * Get asset data by uid
   */
  private getAssetByUid() {
    const uid = this.route.params[':uid'];
    return this.offlineHandlerService.getData('asset-uid-' + uid);
  }

  /**
   * Store asset in local storage
   */
  private async uploadAsset() {
    const asset = {
      id: OfflineDataUtil.generateRandomId(),
      url: Capacitor.convertFileSrc(this.params.asset),
      uid: this.params.uid,
      type: this.params.type
    };

    await this.offlineHandlerService.setData('asset-uid-' + this.params.uid, asset);

    return Promise.resolve(asset);
  }

  /**
   * Remove asset from local storage
   */
  private async removeAssetByUid() {
    const uid = this.route.params[':uid'];
    return this.offlineHandlerService.removeData('asset-uid-' + uid);
  }
}
