<ion-content [scrollY]="false" style="--padding-bottom: 10px">
  <div class="close" (click)="close()">
    <i class="fal fa-times"></i>
  </div>
  <swiper-container
          class="swiper-containers"
          #swiper
          appSwiper
          [config]="swiperOptions"
          [assets]="assets"
          init="false">
    <swiper-slide *ngFor="let asset of assets">
      <div class="swiper-zoom-container" *ngIf="asset.type === this.ASSETS_TYPES.IMAGE">
        <img alt="image" class="img" [src]="getUrl(asset.url)">
      </div>
      <app-video-player [asset]="asset" *ngIf="asset.type === this.ASSETS_TYPES.VIDEO"></app-video-player>
    </swiper-slide>
  </swiper-container>
</ion-content>
