<div class="card-label" *ngIf="showLabels">{{'workplaces.singular' | lang}}<span class="input-required" *ngIf="isRequired"></span></div>
<app-select-dropdown class="ion-margin-bottom"
                     [placeholder]="'workplaces.chooseWorkplace' | lang"
                     groupBy="workplaces"
                     [items]="workplacesPromise"
                     [isLoading]="workplacesLoading"
                     [multiple]="multiple"
                     [invalid]="isInvalid"
                     [isRequired]="isRequired"
                     [restrictNumberOfSelectedItem]="restrictNumberOfSelectedSite"
                     (typeahead)="onWorkplacesSearch($event)"
                     (changed)="onWorkplaceChange()"
                     (closed)="onWorkplaceSelectClose()"
                     [(ngModel)]="selectedWorkplace"
                     [(disabled)]="isDisabled">
  <ng-template #itemContent let-item>
    {{item.name}}
    <i *ngIf="withFavorite" [ngClass]="{'fas': item.isFavorite, 'far': !item.isFavorite}" class="fa-star ion-float-right" (click)="!!toggleFavorite($event, 'workplace', item)" tappable></i>
  </ng-template>
</app-select-dropdown>

<div *ngIf="sectionsList && (sectionsList.length || numberOfSelectedWorkplace() > 0) && !workplaceOnly">
  <div class="card-label" *ngIf="showLabels">
    {{'sections.singular' | lang}}
  </div>
  <div class="card-label" *ngIf="showLabels">
    <app-checkbox *ngIf="withSelectAllButton"
                  [label]="'general.all' | lang"
                  [(ngModel)]="allSectionsChecked"
                  (ionChange)="onAllSectionsChecked()"
                  [style]="'horizontal'"></app-checkbox>

    <app-checkbox *ngIf="withSelectAllButton"
                  [label]="'sections.with-empty-section' | lang"
                  [(ngModel)]="withEmptySection"
                  [disabled]="allSectionsChecked"
                  (ionChange)="onChangeWithEmptySection()"
                  [style]="'horizontal'"></app-checkbox>
  </div>
  <app-select-dropdown class="ion-margin-bottom"
                       [placeholder]="'sections.chooseSection' | lang"
                       groupBy="sections"
                       [items]="sectionsList"
                       [multiple]="multiple"
                       (changed)="onSectionChange()"
                       [(ngModel)]="selectedSection"
                       [disabled]="isDisabled || allSectionsChecked">
    <ng-template #itemContent let-item>
      <div class="select-option-favorite">
        <div>{{item.name}}</div>
        <div class="text-small" *ngIf="multiple">{{item.workplace_name}}</div>
        <i *ngIf="withFavorite" [ngClass]="{'fas': item.isFavorite, 'far': !item.isFavorite}" class="fa-star action-make-favorite" (click)="!!toggleFavorite($event, 'section', item)" tappable></i>
      </div>
    </ng-template>
  </app-select-dropdown>
</div>
