import { Injectable } from '@angular/core';

interface IParamsSet {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class RouteService {
  /** Available paths */
  public readonly PATHS = {
    units: {
      all: '/units',
      unit: '/units/{id}',
      checklistVariants: '/units/{id}/checklistvariants',
      template: '/unittemplate',
      categoryrefilltypes: '/units/{id}/categoryrefilltypes',
      allservicedates: '/units/{id}/allservicedates',
      articles: '/units/{id}/articles',
      servicesSearch: '/units/{id}/services/search',
      refills: '/units/{id}/refills',
      fuelrefills: '/units/{id}/fuelrefills',
      refillsLatest: '/units/{id}/refills/latest'
    },
    rounds: {
      all: '/units',
      round: '/rounds/{id}',
      checklistVariants: '/rounds/{id}/checklistvariants',
      template: '/roundtemplate'
    },
    objectable: {
      objects: '/{objectType}s',
      id: '/{objectType}s/{id}',
      edit: '/{objectType}s/{id}/edit',
      odometers: '/{objectType}s/{id}/odometers',
      positions: '/{objectType}s/{id}/positions',
      categories: '/{objectType}s/{id}/categories',
      activity: '/{objectType}s/{id}/activity',
      incidents: '/{objectType}s/{id}/incidents',
      documents: '/{objectType}s/{id}/documents',
      checklist: '/{objectType}s/{id}/checklist/{checklistId}',
      checklistvariants: '/{objectType}s/{id}/checklistvariants',
      checklistvariantsById: '/{objectType}s/{id}/checklistvariants/{variantId}',
      independentDeviationsFormtemplates: '/{objectType}s/{id}/independent-deviations/formtemplates',
      categoryrefilltypes: '/{objectType}s/{id}/categoryrefilltypes',
      inspectionintervals: '/{objectType}s/{id}/inspectionintervals/{intervalId}',
      image: '/{objectType}s/{id}/image/{assetId}'
    },
    serviceInterval: {
      serviceInterval: '/serviceintervals/{id}',
      unitId: '/serviceintervals/{unit}/{id}',
      batches: {
        list: '/serviceintervals/batches'
      }
    },
    services: {
      id: '/services/{id}',
      batches: {
        list: '/services/batches',
      },
      refills: '/services/{id}/refills/{refillId}',
      articles: '/services/{id}/articles/{articleId}'
    },
    checklistVariants: {
      batch: {
        list: '/checklist-variants/batches',
      }
    },
    checks: {
      all: '/checks',
      id: '/checks/{id}',
      batches: {
        list: '/checks/batches',
      },
      export: '/checks/{id}/export',
      share: '/checks/{id}/share',
    },
    checksongoing: {
      id: '/checksongoing/{id}',
      control: '/checksongoing/{id}/control/{dataId}',
    },
    positions: {
      all: '/positions',
      batches: {
        list: '/positions/batches',
      }
    },
    inspectionsCertificates: {
      intervals: '/{objectType}s/{objectID}/inspectionintervals',
      performed: '/{objectType}s/{objectID}/inspections',
    },
    user: {
      all: '/users',
      id: '/users/{id}',
      me: {
        me: '/users/me',
        groups: '/users/me/groups',
        tasksChecks: '/users/me/tasks/checks',
        device: '/users/me/device',
        notifications: '/users/me/notifications',
        notificationsRead: '/users/me/notifications/unread/read',
        tasks: {
          deviation: '/users/me/tasks/deviations',
          checks: '/users/me/tasks/checks'
        },
        worktimes: '/users/me/worktimes',
        documents: '/users/me/company/documents'
      },
      usersInfo: '/users/info',
      servicesTasks: {
        upcoming: '/users/me/tasks/services/upcoming',
        ongoing: '/users/me/tasks/services/ongoing',
        performed: '/users/me/tasks/services/performed',
      },
      groupsandusersSearch: '/groupsandusers/search',
      clones: '/users/clones',
      jwt: '/jwt/user',
      switch: '/users/switch',
    },
    responsibles: {
      batches: '/responsibles/batches'
    },
    deviations: {
      all: '/deviations/query/all',
      batch: {
        list: '/deviations/batches',
      },
      deviation: '/deviations/{id}',
    },
    independentDeviations: {
      batch: {
        list: '/independentdeviations/batches',
      },
      deviation: '/independentdeviations/{id}',
    },
    deviationObject: {
      id: '/{objectType}/{id}',
      articles: '/{objectType}/{id}/articles/{byId}',
      refills: '/{objectType}/{id}/refills/{byId}',
    },
    planningTool: {
      tasks: {
        activeTaskWithHistory: 'planning-tool/active-task-with-history',
        taskWithHistory: 'planning-tool/task-with-history',
        task: 'planning-tool/tasks/{id}',
        list: 'planning-tool/filter-tasks',
      }
    },
    objects: '/objects',
    odometers: {
      unitOdometers: '/units/{unitID}/odometers',
    },
    groups: {
      search: '/groups/search',
    },
    tokens: {
      academy: '/tokens/academy'
    },
    publicTokens: {
      getByToken: '/publictokens/token/{token}'
    },
    publicUsers: {
      sendCode: '/publicusers/sendcode',
      updateProfile: '/publicusers/me',
    },
    notifications: {
      system: {
        list: '/users/me/notifications/system',
        read: '/users/me/notifications/system/{id}/read'
      }
    },
    rfid: {
      rfid: '/rfid',
      tag: '/rfid/tag',
      object: '/rfid/{objectType}/{objectId}',
      tags: {
        list: '/rfid/tags/',
        uid: '/rfid/tags/tag_uid/{tag_uid}',
        positions: '/rfid/tags/{tag_uid}/{objectType}s/{objectId}/positions',
        categories: '/rfid/tags/{tag_uid}/{objectType}s/{objectId}/categories',
        checklistcontrolpoints: '/rfid/tags/{objectType}s/{objectId}/checklistcontrolpoints'
      }
    },
    workplaces: {
      all: '/workplaces',
      list: '/workplaces/list',
      id: '/workplaces/{id}',
      permissibles: '/workplaces/{id}/permissibles',
      documents: '/workplaces/{id}/documents'
    },
    sections: {
      all: '/sections',
      id: '/sections/{id}',
      permissibles: '/sections/{id}/permissibles'
    },
    refilltypes: {
      all: '/refilltypes',
      documents: '/refilltypes/{id}/documents'
    },
    incidents: {
      all: '/incidents/',
      me: '/incidents/me',
      export: '/incidents/{id}/export',
      types: '/incidents/types',
      templates: '/incidenttemplates/{id}'
    },
    articles: {
      all: '/articles',
    },
    protocol: '/protocol',
    translations: {
      all: '/translations/',
      version: '/translations/version'
    },
    categories: '/categories',
    tags: '/tags',
    messages: {
      all: '/messages',
      id: '/messages/{id}',
    },
    externals: '/externals',
    logout: '/logout',
    outhToken: '/oauth/token',
    documents: '/documents/{uid}/list',
    checklists: {
      all: '/checklists',
      deviation: '/checklists/deviation/{templateId}'
    },
    inspections: '/inspections/{id}',
    formtemplates: {
      id: '/formtemplates/{templateId}',
      defaultType: '/formtemplates/default/type/{templateType}'
    },
    assets: {
      id: '/assets/{id}',
      uid: '/assets/uid/{uid}',
      getvideokey: '/assets/getvideokey',
      urls: '/assets/urls',
      uploadvideo: '/assets/uploadvideo'
    },
    worktimes: { 
      categories: '/worktimes/categories'
    },
    fuelRefill: {
      types: '/fuelrefilltypes',
      typeSuffixes: '/refilltypesuffixes',
      typeSuffixesStatistics: '/units/{id}/suffixesstatistics'
    },
    wastemanagement: {
      formdata: '/wastemanagement/formdata'
    },
    saml: {
      loginUrl: '/saml/loginUrl',
      connect: '/saml/connect'
    },
    password: {
      remind: '/password/remind/',
      codeCheck: '/password/code/check/',
      reset: '/password/reset/',
    },
    privacyPolicy: 'https://www.checkproof.com/privacy-policy/'
  };

  /** Fill template of URL by parameters */
  public makeUrl(template: string, parameters: IParamsSet = {}): string {
    const query = {};
    return Object.keys(parameters) // Make url
        .reduce(
          (url, key) => {
            if (-1 === url.indexOf(`{${key}}`)) {
              query[key] = parameters[key];
            }
            return url.replace(`{${key}}`, parameters[key]);
          },
          template
        ).replace(/\/\//g, '/')
      + this.encodeQuery(query);
  }

  /** Encode query search parameters */
  private encodeQuery(query: IParamsSet): string {
    const keys = Object.keys(query);

    return keys.length
      ? '?' + keys
      .map(
        key => Array.isArray(query[key])
          ? RouteService.encodeArray(key, query[key])
          : RouteService.encodeScalar(key, query[key])
      )
      .join('&')
      .replace(/&$/, '')
      : '';
  }

  /** Encode scalar query parameter */
  private static encodeScalar(key: string, value: any): string {
    return `${key}=${encodeURIComponent(encodeURIComponent(value))}`;
  }

  /** Encode array query parameter */
  private static encodeArray(key: string, value: any[]): string {
    return value.reduce(
      (params, item) => params.concat(
        encodeURIComponent(`${key}[]`) + '=' + encodeURIComponent(item)
      ),
      []
    )
      .join('&');
  }
}
