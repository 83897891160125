import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IServiceDate, IService } from '@interfaces';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private api: ApiService, private routeSrv: RouteService) {}

  public getAssignedServicesTasks(): [Promise<IServiceDate>, Promise<IService>, Promise<IService>] {
    return [this.getAssignedUpcomingServicesTasks(), this.getAssignedOngoingServicesTasks(), this.getAssignedPerformedServicesTasks()];
  }

  public getAssignedUpcomingServicesTasks(options?): Promise<IServiceDate> {
    return this.api.get(this.routeSrv.PATHS.user.servicesTasks.upcoming, options);
  }

  public getAssignedOngoingServicesTasks(options?): Promise<IService> {
    return this.api.get(this.routeSrv.PATHS.user.servicesTasks.ongoing, options);
  }

  public getAssignedPerformedServicesTasks(): Promise<IService> {
    return this.api.get(this.routeSrv.PATHS.user.servicesTasks.performed);
  }
}
