import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { IRefillTypeSuffix, IRefillType, IFuelSuffixesStatistics } from '@interfaces';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class FuelSuffixesService {
  public fuelSuffixes: IRefillTypeSuffix[] = null;
  public fuelRefillTypes: IRefillType[] = null;

  constructor(private api: ApiService, private routeSrv: RouteService) {}

  private retrieveFuelSuffixes(): Promise<IRefillTypeSuffix[]> {
    return this.api.get(this.routeSrv.PATHS.fuelRefill.typeSuffixes, null, {
      cache: true,
    });
  }

  public async getFuelSuffixes(): Promise<IRefillTypeSuffix[]> {
    if (!this.fuelSuffixes) {
      this.fuelSuffixes = await this.retrieveFuelSuffixes();
    }

    return this.fuelSuffixes;
  }

  public getNameByID(id: number, short: boolean = true): string {
    const foundSuffix = this.fuelSuffixes.find(suffix => suffix.id === +id);
    return short ? foundSuffix.suffix : foundSuffix.name;
  }

  private retrieveFuelRefillTypes(): Promise<IRefillType[]> {
    return this.api.get(this.routeSrv.PATHS.fuelRefill.types, null, {
      cache: true,
    });
  }

  public async getFuelRefillTypes(): Promise<IRefillType[]> {
    if (!this.fuelRefillTypes) {
      this.fuelRefillTypes = await this.retrieveFuelRefillTypes();
    }

    return this.fuelRefillTypes;
  }

  public getDefaultFuelRefillType(): IRefillType {
    return this.fuelRefillTypes.find((refillType: IRefillType) => refillType.locked);
  }

  public getFuelRefillTypeByID(id: number): IRefillType {
    return this.fuelRefillTypes.find(fuel => fuel.id === +id);
  }

  public getStatisticsForUnit(unitID: number): Promise<IFuelSuffixesStatistics> {
    return this.api.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.fuelRefill.typeSuffixesStatistics, { id: unitID }), null, {
      cache: true,
    });
  }
}
