import { uniqid } from './uniqid.util';
import { NotFoundError, NotFoundOfflineDeviation } from './error.util';

export class OfflineDataUtil {
  /**
   * Process data from local storage before return it as response
   * @param data
   * @param params
   * @param route
   * @return {any}
   */
  static async processData(data: any, params: any, route: any): Promise<any> {
    if (!data) {
      return data;
    }

    params = Object.assign({}, route.defaultParams || {}, params || {});

    if (route.beforeProcess && data && ((Array.isArray(data) && data.length) || typeof data === 'object')) {
      data = route.beforeProcess(data, params, route);
    }

    if (Array.isArray(data) && data.length) {
      // apply filters from router
      if (route.filterBy) {
        data = data.filter(item => {
          return OfflineDataUtil.filterData(item, route.filterBy, route.params, params);
        });
      } else if (route.findBy) {
        data = data.find(item => {
          return OfflineDataUtil.filterData(item, route.findBy, route.params, params);
        });
        if (!data) {
          if (route.findBy.deviationable_id) {
            //if there is no data found for the deviation and its name contains the cached tag
            //reject to a special alert NotFoundOfflineDeviation
            if (route.findBy.deviationable_id.toString().search('cached-') !== -1) {
              return Promise.reject(new NotFoundOfflineDeviation());
            }
          }
          return Promise.reject(new NotFoundError());
        }
      }
    }

    // select specific attribute from object
    if (data && route.select) {
      if (typeof data === 'object') {
        data = data.hasOwnProperty(route.select) ? data[route.select] : null;
      } else if (Array.isArray(data)) {
        data = data.map(item => {
          return item.hasOwnProperty(route.select) ? item[route.select] : null;
        });
      }
    }

    // format response as paginated data
    const per_page = params.per_page || 0;
    if (per_page && Array.isArray(data)) {
      const page = params && params.page ? params.page : 1;
      data = OfflineDataUtil.paginate(data, per_page, page);
    }

    if (params.withOnly && typeof data === 'object') {
      Object.keys(data).forEach(key => {
        if (data[key] && typeof data[key] === 'object' && params.withOnly.indexOf(key) < 0) {
          delete data[key];
        }
      });
    }

    // process data before return
    if (data && route.beforeReturn) {
      data = route.beforeReturn(data);
    }

    return data;
  }

  /**
   * Return paginated object from array
   * @param {Array<any>} data
   * @param {number} per_page
   * @param {number} page
   * @return {object}
   */
  static paginate(data: Array<any>, per_page: number, page: number): object {
    if (!per_page || !Array.isArray(data)) {
      return data;
    }

    // return all items
    if (per_page === -1) {
      return {
        current_page: 1,
        data: data,
        per_page: per_page,
        last_page: 1,
        total: data.length
      };
    }

    const start = (page - 1) * per_page;
    const last_page = Math.ceil(data.length / per_page);

    return {
      current_page: page,
      data: data.slice(start, start + per_page),
      per_page: per_page,
      last_page: last_page,
      total: data.length
    };
  }

  /**
   * Return true if passed item responds to passed filter and parameters
   * @param {object} item
   * @param filter
   * @param {object} routeParams
   * @param requestParams
   * @return {any}
   */
  static filterData(item: object, filter: any, routeParams: object, requestParams: any): any {
    let matched = true;

    if (typeof filter === 'object') {
      for (const key in filter) {
        if (!filter.hasOwnProperty(key)) {
          continue;
        }
        if (filter[key] !== item[key]) {
          matched = false;
          break;
        }
      }
    } else if (typeof filter === 'function') {
      matched = filter(item, routeParams, requestParams);
    }

    return matched;
  }

  /**
   * Return filtered array based on search term
   * @param {Array<any>} data
   * @param {string} term
   * @param {Array<any>} fields
   * @return {Array<any>}
   */
  static filterBySearch(data: Array<any>, term: string, fields: Array<any>): Array<any> {
    if (!data || !Array.isArray(data)) {
      return data;
    }
    return data.filter(item => {
      let found = false;
      fields.forEach(field => {
        if (item.hasOwnProperty(field) && item[field]) {
          let fieldValue = item[field];
          if (typeof fieldValue !== 'string') {
            fieldValue = fieldValue.toString();
          }

          if (fieldValue.toLowerCase().indexOf(term.toLowerCase()) >= 0) {
            found = true;
          }
        }
      });
      return found;
    });
  }

  /**
   * Convert object to array with this object (TODO: maybe move to utils?)
   * @param obj
   * @return {Array<any> | null}
   */
  static convertToArray(obj: any): null|Array<any> {
    if (!obj) {
      return null;
    }

    return Array.isArray(obj) ? obj : [obj];
  }

  /**
   * Generate random id for offline cached objects
   * @return {string}
   */
  static generateRandomId(): string {
    return uniqid('cached');
  }

  /**
   * Check is this item was created during offline mode or not
   * @param item
   * @return {boolean}
   */
  static isNewItem(item) {
    if (item && item.id && item.id.toString().indexOf('cached-') === 0) {
      return true;
    }
    return false;
  }

  /**
   * Check is this id temporary
   * @param id
   * @return {boolean}
   */
  static isOfflineCreated(id) {
    if (id && id.toString().indexOf('cached-') === 0) {
      return true;
    }
    return false;
  }
}


