import { Component, QueryList, AfterViewInit, ElementRef, ContentChildren, Renderer2, Input, Output, EventEmitter, HostBinding, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { SegmentButtonComponent } from './segment-button/segment-button.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-segment',
  templateUrl: './segment.component.html',
  styleUrls: ['./segment.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SegmentComponent),
      multi: true,
    },
  ],
})
export class SegmentComponent implements AfterViewInit, ControlValueAccessor {
  @Input() set disabled(isDisabled) {
    this.isDisabled = isDisabled;
  }

  @Output() ionChange = new EventEmitter();

  @ContentChildren(SegmentButtonComponent, { read: ElementRef }) buttons: QueryList<ElementRef>;

  @HostBinding('class.app-segment-disabled') isDisabled = false;

  private currentChecked: string;

  constructor(private renderer: Renderer2, private router: Router) {}

  ngAfterViewInit() {
    this.buttons.forEach(button => {
      this.renderer.listen(button.nativeElement, 'click', event => {
        this.onButtonClick(event);
      });
    });
  }

  writeValue(value: any): void {
    this.changeCurrentChecked(value);
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  private changeCurrentChecked(value) {
    this.currentChecked = value;
    this.checkSegmentButtons();
  }

  /**
   * Fired when user clicks on any child segment-button
   * @param event
   */
  onButtonClick(event): void {
    if (this.isDisabled) {
      return;
    }

    const attributes: any = (<HTMLBaseElement>event.currentTarget).attributes;
    if (attributes.disabled) {
      return;
    }

    if (attributes.href) {
      const href = attributes.href.nodeValue;

      if (href !== this.currentChecked) {
        this.router.navigate([href]);
        this.ionChange.emit({
          detail: {
            href: href,
          },
        });
        this.currentChecked = href;
      }
    } else if (attributes.value) {
      const value = attributes.value.nodeValue;

      if (value !== this.currentChecked) {
        this.changeCurrentChecked(value);
        this._onChange(this.currentChecked);

        this.ionChange.emit({
          detail: {
            value: value,
          },
        });
      }
    }
  }

  /**
   * Check segment buttons
   */
  private checkSegmentButtons() {
    if (!this.buttons) {
      return;
    }

    this.buttons.forEach(button => {
      if (this.currentChecked === button.nativeElement.attributes.value.nodeValue) {
        this.renderer.setAttribute(button.nativeElement, 'checked', '');
      } else {
        this.renderer.removeAttribute(button.nativeElement, 'checked');
      }
    });
  }
}
