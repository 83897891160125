import { Injectable } from '@angular/core';
import { IServiceDate, ITransformer, IDuePair, IOdometerDue, IDateTimeDue } from '@interfaces';
import { DueOdometerTransformer } from '../due-odometer/due-odometer.transformer';
import { DueTimeTransformer } from '../due-time/due-time.transformer';
import { UnitsSecondsTransformer } from '../units-seconds/units-seconds.transformer';

@Injectable({
  providedIn: 'root'
})
export class DueServiceDateTransformer implements ITransformer {
  constructor(
    private readonly timeTrans: DueTimeTransformer,
    private readonly odometerTrans: DueOdometerTransformer,
    private readonly unitsSecondsTrans: UnitsSecondsTransformer,
  ) {
  }

  /**
   * Complete original service-date by due-dates.
   * (!) Original service-date will be changed in place.
   */
  public transform(serviceDate: IServiceDate, duePair: IDuePair): IServiceDate {
    const timeInterval = duePair.time ? this.makeTimeInterval(duePair.time) : null;
    const odoInterval = duePair.odometer ? this.makeOdometerInterval(duePair.odometer) : null;
    const isOdometer = !!odoInterval;
    const isSmart = timeInterval && isOdometer;

    if (!isSmart) {
      Object.assign(serviceDate, timeInterval || odoInterval);
      serviceDate.is_smart_interval = false;
      serviceDate.smart_intervals = [];
    } else {
      serviceDate.interval_value = null;
      serviceDate.is_due = timeInterval.is_due || odoInterval.is_due;
      serviceDate.is_odometer = null;
      serviceDate.odometer_suffix = null;
      serviceDate.time_data = null;
      serviceDate.odometer_data = null;
      serviceDate.is_smart_interval = true;
      serviceDate.smart_intervals = [
        timeInterval,
        odoInterval,
      ];
    }

    return serviceDate;
  }

  /** Create partial interval structure by time due-data */
  private makeTimeInterval(timeDue: IDateTimeDue): IServiceDate {
    const timeData = this.timeTrans.transform(timeDue);
    const intervalValue = this.unitsSecondsTrans.transform(timeDue.due_value, timeDue.due_unit);
    return {
      interval_value: intervalValue,
      is_due: intervalValue < 0,
      time_data: timeData,
      is_odometer: false,
      odometer_suffix: null,
      odometer_data: null,
    } as IServiceDate;
  }

  /** Create partial interval structure by odometer due-data */
  private makeOdometerInterval(odometerDue: IOdometerDue): IServiceDate {
    const odoData = this.odometerTrans.transform(odometerDue);
    return {
      interval_value: odoData.when,
      is_due: odoData.when < 0,
      time_data: null,
      is_odometer: true,
      odometer_suffix: odoData.suffix,
      odometer_data: odoData,
    } as IServiceDate;
  }
}
