import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { IChecklistVariantShort} from '@interfaces';

/** Checklist variants client */
@Injectable({
  providedIn: 'root'
})
export class VariantsClient {
  constructor(
    private readonly apiSrv: ApiService,
    private readonly routeSrv: RouteService,
  ) {
  }

  /** Fetch checklist-variants by list of UIDs */
  public getVariantsByUids(intervalUids: string[], objectUids?: string[]): Promise<IChecklistVariantShort[]> {
    return this.apiSrv
      .post(
        this.routeSrv.makeUrl(this.routeSrv.PATHS.checklistVariants.batch.list),
        {uids: intervalUids, objectUids}
      );
  }
}
