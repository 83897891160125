import { Component, Input } from '@angular/core';
import { FormatExtensionWithDotPipe } from '../../pipes/format-extension-with-dot.pipe';
import { IAsset } from '@interfaces';
import { CommonModule } from '@angular/common';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  providers: [FormatExtensionWithDotPipe],
  standalone: true,
  imports: [CommonModule, SharedPipesModule],
})
export class VideoPlayerComponent {
  @Input() asset: IAsset;

  constructor() {}
}
