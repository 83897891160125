import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatExtensionWithDot'
})
export class FormatExtensionWithDotPipe implements PipeTransform {
  /**
   * @param extension Concat dot before a string
   */
  public transform(extension: string): string {
    return '.' + extension;
  }
}
