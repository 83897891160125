import {NgModule, APP_INITIALIZER} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { Drivers } from '@ionic/storage';

import { MediaCapture } from '@awesome-cordova-plugins/media-capture/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HomeWidgetModule } from './components/home-widget/home-widget.module';
import { SharedModule } from './components/shared.module';
import { SideBarMenuModule } from './components/side-bar-menu/side-bar-menu.module';
import { SideBarMenuPublicModule } from './components/side-bar-menu-public/side-bar-menu-public.module';
import { SharedModalsModule } from './modals/shared-modals.module';

import { MsalModule } from '@azure/msal-angular';
import { msalConfig, msalGuardConfig, msalInterceptorConfig } from './constants/azure-config-v2';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@NgModule({
  declarations: [AppComponent],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    IonicModule.forRoot({
      backButtonText: '',
      backButtonIcon: '',
      loadingSpinner: 'crescent'
    }),
    IonicStorageModule.forRoot({
      name: '__checkproof_db',
      driverOrder: [Drivers.IndexedDB, 'websql', Drivers.LocalStorage]
    }),
    AppRoutingModule,
    MsalModule.forRoot(msalConfig, msalGuardConfig, msalInterceptorConfig),
    HttpClientModule,
    HomeWidgetModule,
    SharedModule,
    SideBarMenuModule,
    SideBarMenuPublicModule,
    SharedModalsModule,
  ],

  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    },
    HTTP,
    InAppBrowser,
    MediaCapture,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [],
      multi: true
    }
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
