import { Injectable } from '@angular/core';
import { ITimeData, ITransformer, IDateTimeDue } from '@interfaces';
import { FNS_ATOM_DATE_TIME } from '../../../../../constants/data-time-formats';
import { format, parseISO } from 'date-fns';
import { UnitsTimeTransformer } from '../units-time/units-time.transformer';
import { UnitsTimeSubTransformer } from '../units-time/units-time-sub.transformer';

@Injectable({
  providedIn: 'root',
})
export class DueTimeTransformer implements ITransformer {
  constructor(private readonly timeUnitsTrans: UnitsTimeTransformer, private readonly timeUnitsTransSub: UnitsTimeSubTransformer) {}

  /** Create time-data from due time */
  public transform(timeDue: IDateTimeDue): ITimeData | null {
    return timeDue
      ? {
          when_at: timeDue.when,
          activate_at: this.makeTimeStringForActivate(parseISO(timeDue.when), timeDue.activates_value, timeDue.activates_unit),
          expire_at: this.makeTimeStringForExpirate(parseISO(timeDue.when), timeDue.expires_value, timeDue.expires_unit),
        }
      : null;
  }

  /**
   * Convert due-date to string date-time
   * @see UnitsTimeTransformer
   */
  private makeTimeStringForExpirate(whenDate: Date, value?: number, unit?: number): string | null {
    return value || unit ? format(this.timeUnitsTrans.transform(value, unit, whenDate), FNS_ATOM_DATE_TIME) : null;
  }

  /**
   * Convert due-date to string date-time
   * @see UnitsTimeTransformer
   */
  private makeTimeStringForActivate(whenDate: Date, value?: number, unit?: number): string | null {
    return value || unit ? format(this.timeUnitsTransSub.transform(whenDate, value, unit), FNS_ATOM_DATE_TIME) : null;
  }
}
