import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HelperService } from './helper.service';
import { ToastService } from './toast.service';
import { AppInitService } from './app-init.service';
import { AccessError, NotFoundError, ResolverError } from '../utils/error.util';

@Injectable({
  providedIn: 'root'
})
export class PageResolverService {
  constructor(
    private helperService: HelperService,
    private toastService: ToastService,
    private router: Router,
    private appInitService: AppInitService,
  ) { }

  /**
   * Resolve all page promises with showing of loading window
   * @param {Array<Promise<any>> | Promise<any>} promises
   * @return {Promise<any>}
   */
  async resolve(promises: Array<Promise<any>>|Promise<any>): Promise<any> {
    let objectResult = false;
    if (!Array.isArray(promises)) {
      promises = [promises];
      objectResult = true;
    }

    return Promise.all(promises)
      .then(results => {
        return objectResult && results && results.length ? results[0] : results;
      })
      .catch(e => {
        // For some reason it doesn't generate any error, so force error to console manually
        console.error(e);
        if (typeof e === 'string') {
          throw new Error(e);
        } else {
          throw e;
        }
      })
      .finally(() => {
        this.finishResolver();
      });
  }

  /**
   * Call before starting to resolve
   * @return {Promise<void>}
   */
  public async initResolver() {
    await this.helperService.waitForAppInit();
    await this.helperService.showLoader();
  }

  /**
   * Call when resolving is finished
   * @return {Promise<any>}
   */
  public finishResolver() {
    return this.helperService.dismissLoader();
  }

  /**
   * Show generic alert if page can't be resolved
   * @return {Promise<any>}
   */
  public showAlert(e = null): Promise<any> {
    if (e instanceof NotFoundError) {
      return this.toastService.showNotFoundNotification();
    } else if (e instanceof ResolverError) {
      return this.helperService.showAlertMessage(e['message']);
    } else if (e instanceof AccessError) {
      return this.toastService.showAccessDeniedNotification();
    }

    return Promise.resolve();
  }

  /**
   * Get default resolver error handler
   * @return {(err) => Promise<boolean>}
   */
  public getErrorHandler() {
    return (err) => {
      this.showAlert(err);
      return this.router.navigate([this.router.url]);
    };
  }

  /**
   * Get "state" options passed while navigation
   * Example: this.navController.navigateBack('/home', {state: {tab: 'ongoing'}});
   */
  public getNavigationState() {
    const navigation = this.router.getCurrentNavigation();
    return navigation && navigation.extras && navigation.extras.state ? navigation.extras.state : {};
  }

  public navigateHome() {
    return this.router.navigate(['/home']);
  }
}

