import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ICategory } from '@interfaces';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  /**
   * @param api Api service.
   */
  constructor(private api: ApiService, private routeSrv: RouteService) {}

  /**
   * Fetch all categories.
   */
  public getCategories(): Promise<ICategory[]> {
    return this.api.get(this.routeSrv.PATHS.categories);
  }
}
