import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {HTTP} from '@awesome-cordova-plugins/http/ngx';
import {Platform} from '@ionic/angular';
import {EnvironmentService} from './environment.service';
import {OfflineHandlerService} from './offline/handler.service';

import {Storage} from '@ionic/storage-angular';
import {Observable} from 'rxjs';
import {EventsService} from './events.service';
import {HelperService} from './helper.service';
import {ToastService} from './toast.service';
import {ApiService} from './api.service';
import {LangService} from './lang.service';


@Injectable({
    providedIn: 'root'
})

export class MicroserviceService {
    /**
     *  Microservice url specified in environment(.prod).ts
     */
    private BASE_URL = '';
    private httpOptions: HttpHeaders;

    constructor(
        private http: HttpClient,
        private httpNative: HTTP,
        private storage: Storage,
        private offlineHandler: OfflineHandlerService,
        private platform: Platform,
        private helperService: HelperService,
        private events: EventsService,
        private environmentService: EnvironmentService,
        private toastService: ToastService,
        private apiService: ApiService,
        private langService: LangService,
    ) {
        this.events.subscribe('env:changeRegion', this.onEnvChangeRegion.bind(this));
    }

    /**
     * Initialize service based on environment
     */
    public initMicroService() {
        const environment = this.environmentService.getEnvironment();
        this.BASE_URL = environment.microservicesBaseUrl;
        this.storage.get('token').then(token => {
               this.httpOptions = new HttpHeaders({
                'Content-Type': 'application/json;',
                'Authorization': 'Bearer ' + token,
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS'
            });
        }).catch(() => {
          return;
        });
    }

    /**
     * Return get MicroService call promise
     * @param {string} url Endpoint that should be called
     * @return {Promise<any>}
     */
    public async get(url: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.getMS(url).subscribe({
                next: data => {
                    resolve(data);
                },
                error: error => {
                    reject(error);
                }
            });
        });
    }

    /**
     * Send GET request to MicroService
     * @param {string} url Endpoint that should be called
     * @return {Promise<any>}
     */
    private getMS(url): Observable<any[]> {
        return this.http.get<any[]>(this.BASE_URL + url, {headers: this.httpOptions });
    }

    /**
     * Triggered when environment region is changed
     */
    private onEnvChangeRegion() {
        this.initMicroService();
    }

    /**
     * Show error messages based on http code
     * @param error
     */
    public showErrorByHttpCode(error) {
        this.toastService.showToast({
            message: this.getErrorMessageByHttpCode(error),
            color: 'danger'
        });
    }

    /**
     * Get error message based on http code
     * @param error
     */
    public getErrorMessageByHttpCode(error) {
        let message = '';
        if (this.apiService.isBadNetworkErrorCode(error.status)) {
            message = this.langService.t('errors.network-connection');
        } else if (error.status === 400) {
            message = error.error.message;
        } else if (error.status === 401 || error.status === 403) {
            message = this.langService.t('errors.access-denied');
        } else if (error.status === 404) {
            message = this.langService.t('errors.not-found');
        } else if (error.status === 500) {
            message = this.langService.t('errors.generic-notification');
        } else {
            message = this.langService.t('errors.generic-notification');
        }
        return message;
    }
}
