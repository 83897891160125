import { Injectable } from '@angular/core';
import { IResponsiblesBatch, IUserShort, IGroupShort, IExternalShort } from '@interfaces';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsiblesClient {
  /** Delay before making batch request for expecting new possible wished responsibles */
  private readonly REQUEST_DELAY = 50;
  /** Cached responsibles mapped by UIDs */
  private readonly responsibles = {
    users: new Map<string, IUserShort>(),
    groups: new Map<string, IGroupShort>(),
    externals: new Map<string, IExternalShort>(),
  };
  /** Responsible identifiers which is in pending for fetching */
  private readonly uids = new Set<string>();
  /** Request of fetching */

  /** Executing request */
  private request: Promise<IResponsiblesBatch> | null = null;

  /** Fill groups of responsible by a new batch */
  private static completeBatch(
    srcBatch: IResponsiblesBatch,
    addBatch: IResponsiblesBatch
  ): IResponsiblesBatch {
    srcBatch.users = Array.from(new Set(srcBatch.users.concat(addBatch.users)));
    srcBatch.groups = Array.from(new Set(srcBatch.groups.concat(addBatch.groups)));
    srcBatch.externals = Array.from(new Set(srcBatch.externals.concat(addBatch.externals)));

    return srcBatch;
  }

  public constructor(
    private apiSrv: ApiService,
    private routeSrv: RouteService,
  ) {
  }

  /** Get responsibles by UIDs */
  public getResponsibles(uids: string[]): Promise<IResponsiblesBatch> {
    uids.forEach(uid => this.uids.add(uid));

    if (!this.request) { // If there is no executing request
      this.request = new Promise((resolve) => setTimeout( // Postpone fetching to collect new UIDs
        () => resolve(null),
        this.REQUEST_DELAY
      ))
        .then(() => this.fetchResponsibles()) // Fetch
        .finally(() => this.request = null); // Unset completed request
    }

    return this.request
      .then(() => this.getCached(uids.slice(0)));
  }

  /** Fetch an user by UID */
  public getUser(uid: string): Promise<IUserShort | null> {
    return this.getResponsibles([uid])
      .then(batch => batch.users.pop() || null);
  }

  /** Fetch an user group by UID */
  public getGroup(uid: string): Promise<IGroupShort | null> {
    return this.getResponsibles([uid])
      .then(batch => batch.groups.pop() || null);
  }

  /** Fetch an external user by UID */
  public getExternal(uid: string): Promise<IExternalShort | null> {
    return this.getResponsibles([uid])
      .then(batch => batch.externals.pop() || null);
  }

  /** Fetch responsibles by UIDs */
  private fetchResponsibles(): Promise<IResponsiblesBatch> {
    const uids = Array.from(this.uids);
    const resultBatch = this.getCached(uids);
    this.uids.clear();

    if (!uids.length) { // Check if updated list still has identifiers
      return Promise.resolve(resultBatch);
    }

    return this.apiSrv
      .post(
        this.routeSrv.makeUrl(
          this.routeSrv.PATHS.responsibles.batches,
        ),
        { uids }
      )
      .then(batch => {
        this.cache(batch);
        return ResponsiblesClient.completeBatch(resultBatch, batch);
      });
  }

  /** Cache fetched records */
  private cache(batch: IResponsiblesBatch): void {
    batch.users.forEach(user => this.responsibles.users.set(user.uid, user));
    batch.groups.forEach(group => this.responsibles.groups.set(group.uid, group));
    batch.externals.forEach(external => this.responsibles.externals.set(external.uid, external));
  }

  /** Retrieve cached records */
  private getCached(uids: string[]): IResponsiblesBatch {
    const batch: IResponsiblesBatch = {
      users: [],
      groups: [],
      externals: [],
    };
    const restUids = new Set<string>(uids);

    for (const uid of uids) {
      if (this.responsibles.users.has(uid)) {
        batch.users.push(this.responsibles.users.get(uid));
      } else if (this.responsibles.groups.has(uid)) {
        batch.groups.push(this.responsibles.groups.get(uid));
      } else if (this.responsibles.externals.has(uid)) {
        batch.externals.push(this.responsibles.externals.get(uid));
      } else {
        continue;
      }

      restUids.delete(uid);
    }

    uids.splice(0, uids.length, ...Array.from(restUids)); // Exclude fetched uids

    return batch;
  }
}
