import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Filesystem, Directory, ReadFileResult } from '@capacitor/filesystem';
import { HttpClient } from '@angular/common/http';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import {PromiseQueueUtil} from '../utils/promise-queue.util';
import { FileDownloaderService } from './file-downloader.service';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  readonly imagesPrefix = '';
  readonly imagesDirectory = 'images/';
  readonly targetDir;
  private syncedImages = {};
  private imagesDownloadQueue;
  private targetImagesDirectoryPath: string;

  constructor(
    private platform: Platform,
    private http: HttpClient,
    private httpNative: HTTP,
    private fileDownloaderService: FileDownloaderService
  ) {
    this.targetDir = Directory.Data;
    this.initService();
  }

  public async initService() {
    this.imagesDownloadQueue = new PromiseQueueUtil(10);
    await this.initImagesDirectoryFullPath();
  }

  /**
   * Create images subfolder where all downloaded images should be stored
   * @return {Promise<any>}
   */
  public createImagesDirectory(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!this.platform.is('capacitor')) {
        return resolve();
      }

      Filesystem.stat({
        path: this.imagesDirectory,
        directory: this.targetDir
      }).then(() => {
        // directory already exists
        resolve();
      }).catch(() => {
        Filesystem.mkdir({
          path: this.imagesDirectory,
          directory: this.targetDir
        }).then(() => {
          // directory created
          resolve();
        }).catch(reject);
      });
    });
  }

  /**
   * Get list of images that already synced (downloaded)
   * @return {Promise<void>}
   */
  public getSyncedImages(): Promise<void> {
    this.syncedImages = {};
    return new Promise<void>((resolve, reject) => {
      if (!this.platform.is('capacitor')) {
        return resolve();
      }

      Filesystem.readdir({
        path: this.imagesDirectory,
        directory: this.targetDir
      }).then(res => {
        if (!res || !res.files || !res.files.length) {
          return resolve();
        }

        const promises = [];
        res.files.forEach(file => {
          const fileName = file.name;
          promises.push(Filesystem.stat({
            path: this.imagesDirectory + fileName,
            directory: this.targetDir
          }));
        });

        const supportedTypes = ['file', 'NSFileTypeRegular'];  // android and ios have different type
        Promise.all(promises).then((files) => {
          files.forEach(file => {
            if (supportedTypes.indexOf(file.type) < 0) {
              return;
            }
            const fileName = this.getFilenameFromPath(file.uri);
            this.syncedImages[fileName] = file;
          });
          resolve();
        }).catch(reject);
      }).catch(reject);
    });
  }

  /**
   * Download and store locally image by url
   * @param {string} url
   * @return {Promise<string>}
   */
  public async downloadImage(url: string): Promise<string> {
    if (!this.platform.is('capacitor')) {
      return url;
    }

    const fileName = this.getTargetFileNameFromUrl(url);
    if (!fileName) {
      return url;
    }

    // check if this image already exists in file storage (synced before)
    if (this.syncedImages && this.syncedImages.hasOwnProperty(fileName)) {
      return this.formatFileUri(this.syncedImages[fileName].uri);
    }

    const targetPath = this.targetImagesDirectoryPath + '/' + fileName;

    const downloadedImage = await this.fileDownloaderService.download(url, targetPath);

    if (!downloadedImage) {
      throw new Error('Failed to download image');
    }

    return this.formatFileUri(targetPath);
  }

  /**
   * Clear all files (downloaded images)
   * @return {Promise<void>}
   */
  public clearAllFiles(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.getSyncedImages().then(() => {
        Object.keys(this.syncedImages).forEach(imageName => {
          Filesystem.deleteFile({
            path: this.imagesDirectory + imageName,
            directory: this.targetDir
          });
        });
        resolve();
      }).catch(reject);
    });
  }

  /**
   * Get name of file based on url
   * take first part of domain (bucket name) as a prefix and add name of source file
   * example: "https://foo.cloudfront.net/bar.jpg" -> "foo-bar.jpg"
   *
   * @param {string} url
   * @return {string}
   */
  private getTargetFileNameFromUrl(url: string): string {
    if (!url) {
      return null;
    }

    const fileName = this.getFilenameFromPath(url);

    let urlObject = null;
    try {
      urlObject = new URL(url);
    } catch (e)  {
      return null;
    }

    let targetName = this.imagesPrefix;
    if (urlObject && urlObject.hostname) {
      const subDomain = urlObject.hostname.substring(0, urlObject.hostname.indexOf('.'));
      targetName += (subDomain || urlObject.hostname) + '-';
    }
    targetName += fileName;
    return targetName;
  }

  /**
   * Get full path to images directory
   * @return Promise<string>
   */
  private async initImagesDirectoryFullPath(): Promise<string> {
    if (!this.platform.is('capacitor')) {
      return ''; // ignore for web app
    }

    if (this.targetImagesDirectoryPath) {       // check cache
      return this.targetImagesDirectoryPath;
    }

    this.getFilesystemAccess();

    const result = await Filesystem.getUri({
      directory: this.targetDir,
      path: this.imagesDirectory
    });

    this.targetImagesDirectoryPath = result.uri;  // cache it

    return this.targetImagesDirectoryPath;
  }

  async getFilesystemAccess(): Promise<boolean> {
    // TODO Improve async
    return new Promise(async (resolve) => {
      const status = await Filesystem.checkPermissions();
      const state = status.publicStorage;

      if (state === 'granted') {
        return resolve(true);
      } else if (state === 'denied') {
        return resolve(false);
      } else {
        await Filesystem.requestPermissions();
      }
      return resolve(false);
    });
  }

  public async readFile(filePath): Promise<ReadFileResult> {
    return Filesystem.readFile({
      path: filePath
    }).then((data) => {
      return data;
    }).catch(err => {
      console.error('----- readFile fail ---', err);
      throw err;
    });
  }

  /**
   * Read data from blob response
   * @param {Blob} blobFile
   * @return {Promise<any>}
   */
  private readFromBlob(blobFile: Blob): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const reader = new (<any>window).FileReader();

      if (blobFile) {
        reader.readAsDataURL(blobFile);
        reader.onloadend = () => {
          const data = reader.result.toString();
          resolve(data.substring(data.indexOf(',') + 1));
          // resolve(reader.result); // doesn't work on android, maybe will fix soon
        };
        reader.onerror = () => {
          reject();
        };
      } else {
        resolve(null);
      }
    });
  }

  /**
   * Format file url according to current platform
   * @param {string} uri
   * @return {string}
   */
  public formatFileUri(uri: string): string {
    return Capacitor.convertFileSrc(uri);
  }

  /**
   * Save a file in images directory
   * @param fileData
   * @param targetFileName
   */
  public async saveDataToFile(fileData, targetFileName) {
    try {
      await this.createImagesDirectory();
    } catch (e) {
      console.error(e);
      return null;
    }

    return await this.saveFile(fileData, this.imagesDirectory + targetFileName, this.targetDir);
  }

  /**
   * Save a file in a directory
   * @param fileData
   * @param fileName
   * @param directory
   */
  public async saveFile(fileData, fileName, directory) {
    try {
      const res = await Filesystem.writeFile({
        path: fileName,
        data: fileData,
        directory: directory
      });

      if (res && res.uri) {
        return res.uri;
      }
    } catch (e) {
      console.error(e);
    }

    return null;
  }

  public deleteFile(path: string) {
    return Filesystem.deleteFile({
      path: path
    });
  }

  public deleteFiles(filePaths) {
    if (!filePaths || !filePaths.length) {
      return;
    }
    filePaths.forEach(async filePath => {
      try {
        await this.deleteFile(filePath);
      } catch (e) {
        console.error('###### file.service : Unable to delete File : e, filePath', e, filePath);
      }
    });
  }

  public async moveToImagesFolder(filePath: string): Promise<string> {
    try {
      await this.createImagesDirectory();
    } catch (e) {
      console.error(e);
      return null;
    }

    const targetFileName = this.getFilenameFromPath(filePath);

    const copyResult = await Filesystem.copy({
      from: filePath,
      to: this.imagesDirectory + targetFileName,
      toDirectory: this.targetDir
    });

    await this.deleteFile(filePath);

    return copyResult.uri;
  }

  /**
   * Download file using "blob" response type
   * @param {string} url
   * @param {string} targetFileName
   * @return {Promise<any>}
   */
  private downloadBlob(url: string, targetFileName: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      return this.http.get(url, {
        responseType: 'blob'
      }).toPromise().then((blobFile: Blob) => {
        this.readFromBlob(blobFile).then(fileData => {
          Filesystem.writeFile({
            path: this.imagesDirectory + targetFileName,
            data: fileData,
            directory: this.targetDir
          }).then(() => {
            Filesystem.getUri({
              directory: this.targetDir,
              path: this.imagesDirectory + targetFileName
            }).then((result) => {
              resolve(this.formatFileUri(result.uri));
            }).catch(reject);
          }).catch(reject);
        }).catch(reject);
      }).catch(reject);
    });
  }

  private getFilenameFromPath(path: string): string {
    const fileName = path.substring(path.lastIndexOf('/') + 1);
    return fileName.split('?')[0].split('#')[0]; // removing search query
  }
}
