import { Component, OnChanges, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import pick from 'lodash/pick';

import { ApiService } from '@services';
import { LangService } from '@services';
import { sortBy } from '../../utils/sort.util';
import { ArrayUtil } from '../../utils/array.util';
import { RouteService } from '@services';

@Component({
  selector: 'app-select-check-variant',
  templateUrl: './select-check-variant.component.html',
  styleUrls: ['./select-check-variant.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectCheckVariantComponent),
      multi: true,
    },
  ],
})
export class SelectCheckVariantComponent implements OnChanges, ControlValueAccessor {
  public isDisabled = false;
  public variantsPromise;
  public variantsLoading;

  private value;
  private selectedWorkplaces = [];
  private selectedSections = [];

  @Input() dependables;
  @Input() writableOnly = true;
  @Output() changed = new EventEmitter();

  constructor(
    private apiService: ApiService,
    private langService: LangService,
    private routeSrv: RouteService,
  ) {}

  ngOnChanges(changes) {
    if (changes.dependables) {
      let shouldRefetch = false;
      if (ArrayUtil.isDifferentArrays(this.dependables.workplace, this.selectedWorkplaces)) {
        if (this.dependables.workplace) {
          this.selectedWorkplaces = Array.isArray(this.dependables.workplace) ? this.dependables.workplace : [this.dependables.workplace];
        } else {
          this.selectedWorkplaces = [];
        }

        shouldRefetch = true;
      }

      if (ArrayUtil.isDifferentArrays(this.dependables.section, this.selectedSections)) {
        if (this.dependables.section) {
          this.selectedSections = Array.isArray(this.dependables.section) ? this.dependables.section : [this.dependables.section];
        } else {
          this.selectedSections = [];
        }

        shouldRefetch = true;
      }

      if (shouldRefetch) {
        this.fetchVariants();
      }
    }
  }

  /**
   * get accessor for selectedVariant
   * @return {any}
   */
  get selectedVariant(): any {
    return this.value;
  }

  /**
   * set accessor for selectedVariant
   * @param v
   */
  set selectedVariant(v: any) {
    if (v !== this.value) {
      this.value = v;
      this._onChange(v);

      // trick to prevent angular error
      setTimeout(() => {
        this.changed.emit();
      }, 50);
    }
  }

  writeValue(obj: any): void {
    this.selectedVariant = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  private _onChange = (_: any) => {};

  /**
   * triggers when selected object is changes
   */
  public onVariantChange() {
    this.changed.emit();
  }

  /**
   * get list of variants
   * @param {any} options
   */
  private fetchVariants(options = <any>{}) {
    // unselect currently selected variant if it doesn't match selected workplace or section
    if (this.selectedVariant && this.selectedWorkplaces.length) {
      const selectedWorkplaceIds = this.selectedWorkplaces.map(selectedWorkplace => selectedWorkplace.id);
      const selectedSectionIds = this.selectedSections.map(selectedSection => selectedSection.id);

      let selectedVariants = [this.selectedVariant];

      selectedVariants = selectedVariants.filter(variant => {
        const object = variant.object;

        if (selectedWorkplaceIds.indexOf(object.workplace_id) < 0) {
          return false;
        }

        if (selectedSectionIds.length) {
          if (!object.section || !object.section.length || selectedSectionIds.indexOf(object.section[0].id) < 0) {
            return false;
          }
        }

        return true;
      });

      if (!selectedVariants.length) {
        this.selectedVariant = null;
      }
    }

    const defaultOptions = <any>{
      compact: true,
      objectInUse: 'all',
    };

    if (this.writableOnly) {
      defaultOptions.writable = true;
    }
    if (this.selectedWorkplaces && this.selectedWorkplaces.length) {
      defaultOptions.workplaces = this.selectedWorkplaces.map(selectedWorkplace => selectedWorkplace.id);

      if (this.selectedSections) {
        defaultOptions.sections = this.selectedSections.map(selectedSection => selectedSection.id);
      }
    }

    defaultOptions.appends = 'checklistDetails';

    options = Object.assign(defaultOptions, options);

    this.variantsLoading = true;
    this.variantsPromise = this.apiService.get(this.routeSrv.PATHS.objects, options, { cache: true }).then(objects => {
      if (!objects || !objects.length) {
        return [];
      }

      const groupedVariants = [];

      const variants = {
        completed: [],
        ongoing: [],
        overdue: [],
        upcoming: [],
      };

      objects.forEach(object => {
        if (!object.checklistDetails || !object.checklistDetails.checklist_variants) {
          return;
        }

        const objectCopy = pick(object, ['id', 'name', 'workplace_id', 'section', 'unit_type']);

        object.checklistDetails.checklist_variants.forEach(variant => {
          if (!variant.due_data) {
            return;
          }

          variant.object = objectCopy;

          if (variant.ongoing_checks_count) {
            variants.ongoing.push(variant);
          } else if (variant.due_data.is_due) {
            variants.overdue.push(variant);
          } else {
            variants.upcoming.push(variant);
          }
        });
      });

      if (variants.ongoing.length > 0) {
        groupedVariants.push({
          title: this.langService.t('services.status_ongoing'),
          variants: sortBy(variants.ongoing, 'name'),
        });
      }
      if (variants.overdue.length > 0) {
        groupedVariants.push({
          title: this.langService.t('followup.overdue'),
          variants: sortBy(variants.overdue, 'name'),
        });
      }
      if (variants.upcoming.length > 0) {
        groupedVariants.push({
          title: this.langService.t('followup.upcoming'),
          variants: sortBy(variants.upcoming, 'name'),
        });
      }

      this.variantsLoading = false;

      return groupedVariants;
    });
  }
}
