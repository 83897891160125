import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { IDeviationShort, IDeviation } from '@interfaces';
import { DeviationsWithEnum } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class DeviationsClient {
  constructor(
    private apiService: ApiService,
    private routeSrv: RouteService,
  ) {
  }

  /** Fetch a deviation by ID */
  public getDeviation(id: number): Promise<IDeviation> {
    return this.apiService.get(
      this.routeSrv.makeUrl(
        this.routeSrv.PATHS.deviations.deviation,
        { id }
      )
    );
  }

  /** Fetch deviation by list of UID */
  public getDeviationsByUids(uids: string[], withParams: DeviationsWithEnum[] = []): Promise<IDeviationShort[]> {
    return this.apiService.post(
      this.routeSrv.makeUrl(
        this.routeSrv.PATHS.deviations.batch.list,
        {
          with: withParams
        }
      ),
      { uids }
    );
  }
}
