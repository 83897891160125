import { Injectable } from '@angular/core';
import SearchFilterBuilder from '../../tasks/search-filter-builder/search-filter.builder';
import { OfflineHandlerService } from '../../../offline/handler.service';
import { ITask, ITasksPage } from '@interfaces';
import { map } from 'rxjs/operators';

type OnPageFetch = (page: ITasksPage) => void;

/** The services manages offline data. */
@Injectable({
  providedIn: 'root'
})
export class Storage {
  /** Name of tasks collection in the storage */
  private readonly COLLECTION_KEY = 'pt-tasks';

  constructor(
    /** Data storage */
    private readonly offlineStorage: OfflineHandlerService
  ) {
  }

  /** Store filtered tasks */
  public storeTasks(
    search: SearchFilterBuilder,
    onPageFetch: OnPageFetch = () => ({})
  ): Promise<void> {
    const savings: Promise<any>[] = []; // Promises of saving tasks to storage

    return this.offlineStorage
      .setData(this.COLLECTION_KEY, []) // Clear previous data
      .then(
        () => new Promise(
          resolve => search
            .fetchAll() // Fetch all tasks pages
            .pipe(
              map(
                (page: ITasksPage) => {
                  onPageFetch(page);
                  return this.offlineStorage
                    .getData(this.COLLECTION_KEY) // Fetch collection of stored tasks
                    .then(tasks => tasks || [])
                    .then((tasks: ITask[]) => Storage.joinTasks( // Add new tasks to collection of stored ones
                      tasks,
                      page.data
                    ))
                    .then(tasks => this.offlineStorage.setData(this.COLLECTION_KEY, tasks));
                } // Store updated collection
              )
            )
            .subscribe(
              tasksSaving => savings.push(tasksSaving), // Collect all processes of savings
              (error) => console.error(error),
              () => Promise.all(savings).then(() => resolve(undefined)) // Complete on finishing all saving processes
            )
        )
      );
  }

  /** Get previously stored tasks */
  public getTasks(): Promise<ITask[]> {
    return this.offlineStorage
      .getData(this.COLLECTION_KEY)
      .then(tasks => tasks || []);
  }

  /** Join groups of tasks */
  private static joinTasks(sourceTasks: ITask[], newTasks: ITask[]): ITask[] {
    sourceTasks.push(...newTasks);
    /**
     * Filter possible duplicates by task UID.
     * Duplicated values can be generated by several storing with intersecting filters.
     */
    return Array.from(
      new Map(
        sourceTasks.map(
          task => [task.id, task]
        )
      ).values()
    );
  }
}
