import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Platform, NavController } from '@ionic/angular';
import { AuthService, HomeScreenService, KeyboardService, EventsService } from '@services';

@Component({
  selector: 'app-home-widget',
  templateUrl: './home-widget.component.html',
  styleUrls: ['./home-widget.component.scss']
})
export class HomeWidgetComponent implements OnInit, OnDestroy {
  @ViewChild('homeWidget', {static: false}) content: ElementRef;

  public isVisible = false;

  private isOpen = false;
  private routerSubscription = null;
  private backButtonSubscription = null;

  constructor(
    private router: Router,
    private platform: Platform,
    private authService: AuthService,
    private homeScreenService: HomeScreenService,
    public keyboardService: KeyboardService,
    private events: EventsService,
    private navController: NavController,
  ) { }

  ngOnInit() {
    this.initComponent();
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  public async initComponent() {
    this.routerSubscription = this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.onRouteChange(event);
        }
      });
  }

  public openHomePage(): void {
    this.homeScreenService.fetchTasks();
    // Routes to home view
    this.navController.navigateRoot(['/home'],
      {
        animated: true,
        animationDirection: 'back'
      });
  }

  /**
   * Toggle home widget overlay
   */
  public toggleWidget(): void {
    if (this.isOpen) {
      this.closeWidget();
    } else {
      this.showWidget();
    }
  }

  /**
   * Show home widget overlay
   */
  private showWidget(): void {
    this.isOpen = true;

    document.body.classList.add('home-widget-open');

    this.homeScreenService.fetchOngoingMineChecks();

    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.closeWidget();
      return;
    });
  }

  /**
   * Close home widget overlay
   */
  private closeWidget(): void {
    this.isOpen = false;

    // scroll to top of the element
    if (this.content && this.content.nativeElement) {
      this.content.nativeElement.scrollTop = 0;
    }

    document.body.classList.remove('home-widget-open');

    this.backButtonSubscription.unsubscribe();

    this.events.publish('homeWidget:closed');
  }

  /**
   * Show or hide home widget button based on current route
   * @param event
   * @return {Promise<void>}
   */
  private async onRouteChange(event) {
    if (this.isOpen) {
      this.closeWidget();
    }

    const isAuthenticated = await this.authService.isAuthenticated();
    if (!isAuthenticated) {
      this.isVisible = false;
      return;
    }

    let eventUrl = event.url;
    let hasUrlParams = eventUrl.indexOf(';');
    if (hasUrlParams < 0) {
      hasUrlParams = eventUrl.indexOf('#');
    }
    if (hasUrlParams >= 0) {
      eventUrl = eventUrl.substring(0, hasUrlParams);
    }

    if (['/', '/home', '/login', '/loading', '/public/home'].indexOf(eventUrl) !== -1) {
      this.isVisible = false;
      document.body.classList.remove('home-widget-button-visible');
    } else {
      this.isVisible = true;
      document.body.classList.add('home-widget-button-visible');
    }
  }

}
