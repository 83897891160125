import { EventEmitter, Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ImageModalComponent } from '../components/image-modal/image-modal.component';
import { OfflineHandlerService } from './offline/handler.service';
import {Capacitor} from '@capacitor/core';

@Injectable({
  providedIn: 'root'
})
export class ImageViewerService {
  // Holds all asset-groups
  public assets = [];
  public onModalShowHideChange = new EventEmitter<boolean>();

  constructor(
    private modalController: ModalController,
    private offlineHandler: OfflineHandlerService
  ) { }

  /**
   * Sort asset in into correct group-array
   * @param asset
   * @param group
   */
  public addAssetToFullscreen(asset, group: string) {
    // Check if a group is specified
    if (group) {
      // Creates group array if it does not exists in assets.
      if (!(group in this.assets)) {
        this.assets[group] = [];
      }

      if (!(this.assets[group].find(srcAsset => srcAsset.url === asset.url))) {
        this.assets[group].push(asset);
      }
    }
  }

  /**
   * Delete specified group from this.assets
   * @param group
   */
  public deleteGroup(group) {
    if (this.assets.hasOwnProperty(group)) {
      delete this.assets[group];
    }
  }

  /**
   * Delete asset from specific group-array in this.assets.
   * @param assetUrl
   * @param group
   */
  public deleteAssetFromGroup(assetUrl, group) {
    if (!this.assets.hasOwnProperty(group)) {
      return;
    }

    this.assets[group] = this.assets[group].filter(srcAsset => srcAsset.url !== assetUrl);
  }

  /**
   * Return correct assets based on group if that is defined.
   * Else it returns single asset sent in to asset-viewer
   *
   * @param asset
   * @param group
   */
  private async getAssets(asset, group): Promise<any> {
    // Checks if group is specified, else returns asset
    const assets = (group) ? this.assets[group] : [asset];

    if (await this.offlineHandler.isOfflineEnable()) {
      return this.getOfflineAssets(assets);
    }

    return assets;
  }

  /**
   * Create instance of modalController and loads content for fullsScreen modal.
   * @param asset
   * @param group
   */
  public showAssetViewer(asset, group) {
    this.setInitialSlide(asset.url, group).then(index => {
      this.getAssets(asset, group).then(assets => {
        const onClose = new EventEmitter();
        onClose.subscribe(() => {
          this.onModalShowHideChange.emit(false);
        });
        this.modalController.create({
          component: ImageModalComponent,
          componentProps: {
            assets: assets,
            // asset: asset,
            initialSlide: index,
            onClose
          }
        }).then( modal => {
          modal.present();
          this.onModalShowHideChange.emit(true);
        });
      });
    });
  }

  /**
   * Resolves with index of initial slide for fullscreen modal
   *
   * @param assetUrl
   * @param group
   * @return {Promise<number>}
   */
  private setInitialSlide(assetUrl, group: string): Promise<number> {
    return new Promise(resolve => {
      if (group) {
        const index = this.assets[group].findIndex(asset => asset.url === assetUrl);
        resolve(index);
      } else {
        resolve(0);
      }
    });
  }

  public isDataUrl(url: string): boolean {
    return url.substring(0, 5) === 'data:' || url.indexOf('/_capacitor_file_/') > 0 ;
  }

  /**
   * Get all asset urls from local storage
   * @param assets
   */
  private getOfflineAssets(assets: any[]): Promise<any> {
    return Promise.all(assets.map(async asset => {
      if (this.isDataUrl(asset.url)) {
        // don't get asset from local storage if url is base64 data image
        return asset;
      }

      // if We get data from parent component with file:// url.
      // we need to convert it with Capacitor.convertFileSrc to make app working
      // TODO Refactor Assets in app to get directly good url
      if (asset.url.indexOf('file://') === 0) {
        asset.url = Capacitor.convertFileSrc(asset.url);
        return asset;
      }

      return {
        url: await this.offlineHandler.getData(asset.url),
        type: asset.type
      };
    }));
  }
}
