import { Injectable } from '@angular/core';
import isEqual from 'lodash/isEqual';
import { LangService } from './lang.service';
import { CategoryUtil } from '../utils/category.util';

@Injectable({
  providedIn: 'root'
})
export class FormCategoryService {
  constructor(
    private langService: LangService,
  ) {
  }

  /**
   * Compare categories list to check are there some changes
   * @param oldCategories
   * @param newCategories
   */
  public hasCategoryChanges(oldCategories, newCategories) {
    const oldServiceCats = oldCategories.map(cat => ({'id': cat.id, 'parent_id': cat.parent_id, 'name': cat.name}));
    const newServiceCats = newCategories.map(cat => ({'id': cat.id, 'parent_id': cat.parent_id, 'name': cat.name}));

    return !isEqual(oldServiceCats, newServiceCats);
  }

  /**
   * Get all changed categories as a text
   * @param categories
   */
  public getCategoryChanges(categories) {
    return categories.map(category => {
      return this.getCategoryChangesString(category, categories);
    }).join('');
  }

  /**
   * Get changed category as a text
   * @param category
   * @param categories
   * @private
   */
  private getCategoryChangesString(category, categories) {
    const categoryLevel = CategoryUtil.getCategoryLevel(category, categories);

    let label = this.langService.t('properties.category');
    if (categoryLevel === 2) {
      label = this.langService.t('properties.second_category');
    } else if (categoryLevel === 3) {
      label = this.langService.t('properties.third_category');
    }

    return '<b>' + label + '</b>' + category.name;
  }
}
