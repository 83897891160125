import { environment } from '../../environments/environment';
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';

export const msalConfig = new PublicClientApplication({
  auth: {
    clientId: environment.azureAD.clientId,
    authority: "https://login.microsoftonline.com/common/",
    redirectUri: environment.azureAD.redirectUrl,
    postLogoutRedirectUri: environment.azureAD.redirectUrl,
    navigateToLoginRequestUrl: true
  },
  cache: {
    cacheLocation : BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true, // set to true for IE 11
  },
  system: {
    loggerOptions: {
      loggerCallback: () => {},
      piiLoggingEnabled: false
    }
  }
});

export const msalGuardConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Popup,
  authRequest: {
    scopes: ['user.read']
  },
  loginFailedRoute: "/login-failed" 
};

const protectedResourceMap = new Map<string, Array<string>>();
protectedResourceMap.set("https://graph.microsoft.com/v1.0/me", ["user.read"]);

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect,
  protectedResourceMap
};

