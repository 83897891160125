import { Injectable } from '@angular/core';
import pick from 'lodash/pick';
import { FavoritesTypeEnum } from '@enums';
import { IUnit, IFavorite } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class RefillService {

  public static REFILLS_FAVORITES_TYPE = 'unit-refill';

  /**
   * Get favorite object for refill
   * @param unit
   * @return Favorite
   */
  public getObjectRefillFavorite(unit: IUnit): IFavorite {
    let section = null;
    if (unit.section && unit.section.length > 0) {
      section = pick(unit.section[0], ['id', 'name']);
    }

    return {
      id: 'unit-' + unit.id,
      objectable: pick(unit, ['id', 'name', 'unit_type']),
      workplace: pick(unit.workplace, ['id', 'name']),
      section: section,
      type: FavoritesTypeEnum.REFILL
    };
  }
}
