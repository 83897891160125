import { Injectable, NgZone } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';

@Injectable({
  providedIn: 'root'
})
export class KeyboardService {
  private element = <any>false;
  private interval = <any>false;

  public isOpen = false;

  constructor(public zone: NgZone) {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        Keyboard.setAccessoryBarVisible({isVisible: true});
      }

      Keyboard.addListener('keyboardWillHide', () => {
        this.element = false;
      });

      Keyboard.addListener('keyboardDidHide', () => {
        this.zone.run(() => this.isOpen = false);
      });

      Keyboard.addListener('keyboardWillShow', () => {
        this.zone.run(() => this.isOpen = true);
      });

      Keyboard.addListener('keyboardDidShow', () => {
        if (this.interval !== false) {
          return;
        }

        this.interval = setInterval(() => {
          if (this.element !== false) {
            clearInterval(this.interval);
            this.interval = false;

            if (!this.isElementInViewport(this.element)) {
              this.scrollToInput(this.element);
            }
          }
        }, 40);
      });
    }
  }

  public setElement(event): void {
    this.element = event.target;
  }

  private scrollToInput(element): void {
    const dropdown = element.closest('app-select-dropdown');
    if (dropdown) {
      dropdown.scrollIntoView(false);
    } else {
      element.scrollIntoView(false);
    }
  }

  private isElementInViewport(el) {
    const rect = el.getBoundingClientRect();

    return (
      rect.top >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    );
  }
}
