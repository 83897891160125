export enum AppFeatureEnum {
  ARTICLES = 'articles',
  CHECKS = 'checks',
  DEVIATIONS = 'deviations',
  DEVIATIONS_CATEGORIES = 'deviations_categories',
  DEVIATIONS_DOWNTIME = 'deviations_downtime',
  DEVIATIONS_WHO_AND_WHEN = 'deviations_who_and_when',
  DEVIATIONS_ODOMETER = 'deviations_odometer',
  DEVIATIONS_DUE_WITHIN = 'deviations_due_within',
  DEVIATIONS_RESPONSIBLE = 'deviations_responsible',
  DEVIATIONS_DATE_AND_TIME = 'deviations_date_and_time',
  DEVIATIONS_ASSETS_USED = 'deviations_assets_used',
  DEVIATIONS_WORKTIME = 'deviations_wortime',
  DEVIATIONS_REFILL = 'deviations_refill',
  DEVIATIONS_ITEMS = 'deviations_items',
  INCIDENTS = 'incidents',
  INFO = 'info',
  INSPECTIONS = 'inspections',
  OFFLINE_MODE = 'offlineMode',
  OFFLINE_BACKUP_IMPORT = 'offlineBackupImport',
  REFILLS = 'refills',
  PLANNER_TOOL = 'planner_tool',
  SERVICES = 'services',
  SERVICE_CATEGORIES = 'services_categories',
  SERVICE_DOWNTIME = 'services_downtime',
  SERVICE_ODOMETER = 'services_odometer',
  SERVICE_DATE_AND_TIME = 'services_date_and_time',
  SERVICE_ASSETS_USED = 'services_assets_used',
  SERVICE_WORKTIME = 'services_wortime',
  SERVICE_REFILL = 'services_refill',
  SERVICE_ITEMS = 'services_items',
  SHORTCUTS = 'shortcuts',
  UNITS_ROUNDS = 'unitsRounds',
  UNITS_ROUNDS_CREATE = 'unitsRoundsCreate',
  UPDATE_NOTICE = 'updateNotice',
  SAML = 'saml',
  OEM_COMPANY = 'oemCompany',
  RFID = 'rfid',
  VIDEO = 'video',
  FLEET_OPTIMIZATION = 'fleetOptimization',
  ENABLE_CASCADING_POSITIONS = 'cascade_positions',
  DEVIATION_TEMPLATES = 'deviation_templates',
}
