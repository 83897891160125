<div class="offline-bottom-banner" *ngIf="type === 'bottomBanner' && isOffline">
  {{'offline.mode-active' | lang}}
  <span *ngIf="formatRemainTime">(<b>{{formatRemainTime}}</b> {{'general.remaining' | lang}})</span>
  <span *ngIf="!formatRemainTime">({{'general.expired' | lang}})</span>
</div>

<div *ngIf="type === 'homeBanner' && isOffline" class="ion-text-center">
  <div class="" [ngClass]="[isOfflineExpireSoon ? 'offline-home-banner-expiring': 'offline-home-banner-active']">
    {{'offline.mode-active' | lang}}
    <span *ngIf="formatRemainTime">(<b>{{formatRemainTime}}</b> {{'general.remaining' | lang}})</span>
    <span *ngIf="!formatRemainTime">({{'general.expired' | lang}})</span>
    <br /><br />
    <span *ngIf="!isOfflineCacheNewerAppVersion" [innerHTML]="'offline.appVersionCacheOutOfDate' | lang"></span>
  </div>
</div>
