<div class="input-group" [ngClass]="{ 'input-group-has-error': isInvalid, 'input-group-small': smallSize }">

  <div class="form-control">
    <i class="input-icon" [ngClass]="iconClass" *ngIf="iconShow"></i>
    <input [type]="type"
           [attr.id]="id"
           [attr.pattern]="pattern"
           [attr.maxlength]="maxlength"
           [attr.autocapitalize]="autocapitalize ? 'on' : 'off'"
           [attr.min]="min"
           [placeholder]="placeholder"
           (keyup)="onKeyUp($event)"
           [ngClass]="{ 'input-with-icon': iconShow }"
           [disabled]="disabled"
           [(ngModel)]="currentValue"
           autocomplete="off"
           spellcheck="false"
           (focus)="keyboardService.setElement($event)"
           (change)="inputElChanged($event)"
           class="input " />
  </div>

  <div class="input-group-append" *ngIf="suffix">
    <span class="input-group-text">{{suffix}}</span>
  </div>

</div>

