<div class="message" tappable (click)="toggleCollapse()" [ngClass]="[isExpanded ? 'collapse-trigger-open': '', isRead ? 'read' : 'unread']">
  <div class="content">
    <div class="top">
      <span class="time_sent">{{message.created_at | formatDate:'datetime'}}</span>
    </div>
    <div class="body">
      <h1 class="title">{{message.title}}</h1>
      <p [ngClass]="{'text': 'text', 'open': isExpanded === true}">{{message.message}}</p>
    </div>
    <p class="sender">{{message.sender | formatUserName}}</p>
  </div>
  <i class="far fa-angle-down collapse-trigger-icon"></i>
</div>
