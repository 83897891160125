import { Injectable } from '@angular/core';
import { ActionSheetController } from '@ionic/angular';
import { createAnimation, Animation } from '@ionic/core';
import { EventsService } from './events.service';

@Injectable({
  providedIn: 'root'
})
export class ActionSheetService {

  constructor(
    private actionSheetController: ActionSheetController,
    private events: EventsService,
  ) {
    this.events.subscribe('api:unauthenticated', () => {
      this.close();
    });
  }

  /**
   * Open action sheet
   * @param options
   * @return {Promise<*>}
   */
  public async open(options: any): Promise<any> {
    const defaultOptions = {
      leaveAnimation: this.leaveAnimation,
    };

    const actionSheetOptions = Object.assign(defaultOptions, options);

    const actionSheet = await this.actionSheetController.create(actionSheetOptions);

    actionSheet.present();
  }

  /**
   * Close current action sheet
   * @return {Promise<*>}
   */
  public close(): Promise<any> {
    return this.actionSheetController.getTop().then(as => {
      if (as) {
        return as.dismiss(null);
      }
    });
  }

  /**
   * Define animation for hiding action sheet
   * (default one makes a bug with home-screen button)
   * @param {HTMLElement} baseEl
   * @return {Animation}
   */
  private leaveAnimation(baseEl: HTMLElement): Animation {
    const baseAnimation = createAnimation();
    const backdropAnimation = createAnimation();
    const wrapperAnimation = createAnimation();

    backdropAnimation
      .addElement(baseEl.querySelector('ion-backdrop'))
      .fromTo('opacity', 'var(--backdrop-opacity)', 0);

    wrapperAnimation
      .addElement(baseEl.querySelector('.action-sheet-wrapper'))
      .fromTo('opacity', 0.99, 0);

    return baseAnimation
      .addElement(baseEl)
      .easing('cubic-bezier(.36,.66,.04,1)')
      .duration(450)
      .addAnimation([backdropAnimation, wrapperAnimation]);
  }

}
