import { Injectable } from '@angular/core';
import { IDeviation, IDeviationShort, IFavorite } from '@interfaces';
import { ObjectableUtil } from '../utils/objectable.util';
import pick from 'lodash/pick';
import { DeviationsClient } from './clients/cp-api/deviations/deviations.client';
import { IndependentDeviationsClient } from './clients/cp-api/independent-deviations/independent-deviations.client';
import { DeviationsWithEnum, DeviationTypeEnum, FavoritesTypeEnum } from '@enums';


@Injectable({
  providedIn: 'root'
})
export class DeviationService {

  public static DEVIATIONS_FAVORITES_TYPE = 'object-independent-deviation';

  /**
   *
   * @param deviationsClient
   * @param indDeviationsClient
   */
  constructor(
    private readonly deviationsClient: DeviationsClient,
    private readonly indDeviationsClient: IndependentDeviationsClient,
  ) {
  }

  /** Fetch deviations by list of UIDs */
  public getDeviationsByUids(uids: string[], withParams: DeviationsWithEnum[] = []): Promise<IDeviationShort[]> {
    return Promise.all([
      this.deviationsClient.getDeviationsByUids(uids, withParams),
      this.indDeviationsClient.getDeviationsByUids(uids, withParams),
    ])
      .then(
        ([
           deviations,
           indDeviations
         ]) => deviations
          .map(deviation => Object.assign(deviation, { type: DeviationTypeEnum.DEVIATION }))
          .concat(
            indDeviations
              .map(indDeviation => Object.assign(indDeviation, { type: DeviationTypeEnum.INDEPENDENT }))
          )
      );
  }

  /**
   * Get favorite for deviation
   * @param deviation
   */
  public getObjectDeviationFavorite(deviation: IDeviation): IFavorite {
    let section = null;
    if (deviation.objectable.section && deviation.objectable.section.length > 0) {
      section = pick(deviation.objectable.section[0], ['id', 'name']);
    }

    const type = ObjectableUtil.isUnit(deviation.objectable) ? 'unit' : 'round';
    const id = type + '-' + deviation.objectable.id;

    return {
      id: id,
      objectable: pick(deviation.objectable, ['id', 'name', 'unit_type']),
      workplace: pick(deviation.objectable.workplace, ['id', 'name']),
      section: section,
      type: FavoritesTypeEnum.DEVIATION
    };
  }
}
