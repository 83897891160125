import { Storage } from '../../../../../planning-tool/offliine/storage/storage.service';
import { ObjectableTypeEnum, TaskTypeEnum, TaskStatusEnum } from '@enums';
import { Injectable } from '@angular/core';
import { set } from 'date-fns';
import { UnitsTimeTransformer } from '../../../../../planning-tool/tasks/transformers/units-time/units-time.transformer';
import { ITasksPage, ITaskDetails, ITaskInfo, IActiveTasksWithHistory, ICompletedTaskId, ITasksClient, IDueTimeFilterSet, IResponsibleFilterSet, 
  IIdentifierFilterSet, ITask, ISearchFilterSet, IRoundFilterSet, IUnitFilterSet } from '@interfaces';

/** Tasks client for offline mode */
@Injectable({
  providedIn: 'root',
})
export default class OfflineTasksClient implements ITasksClient {
  /** Default pages size on the server side */
  private readonly SERVER_PAGE_SIZE = 50;

  public constructor(private readonly storage: Storage, private readonly unitsTimeTrans: UnitsTimeTransformer) {}

  /** @inheritDoc */
  public getTasks(filter: ISearchFilterSet = {}): Promise<ITasksPage> {
    return this.storage
      .getTasks()
      .then(tasks => this.filter(tasks, filter))
      .then(tasks => this.makePage(tasks, filter));
  }

  /** @inheritDoc */
  public getTaskInfo(uid: string): Promise<ITaskInfo> {
    return this.storage
      .getTasks()
      .then(tasks => tasks.find(task => task.id === uid))
      .then(task =>
        task
          ? ({
              task: {
                history: [],
                task,
              },
            } as ITaskInfo)
          : null
      );
  }

  /**
   * Fetch task By Origin UID
   *
   * TODO: There is no history from tasks list, if you need it you need to implement it
   * */
  public getTaskDetailsByOrigin(requestParam: IActiveTasksWithHistory): Promise<ITaskDetails | null> {
    return this.storage.getTasks().then(tasks => {
      const task = tasks.find(task => task.origin.id === requestParam.originId && task.objectable.id === requestParam.objectableId);

      return task ? { task } : null;
    });
  }

  /** @inheritDoc */
  public getCompletedTaskDetails(_identifiers: ICompletedTaskId): Promise<ITaskDetails | null> {
    return Promise.resolve(null);
  }

  /** Apply filter for the tasks lis */
  private filter(tasks: ITask[], filter: ISearchFilterSet): ITask[] {
    return tasks.filter(
      task =>
        self.hasMatchObjectType(task, filter) &&
        this.hasMatchObject(task, task.objectable.type === ObjectableTypeEnum.UNIT ? filter.unit : filter.round) &&
        this.hasMatchResponsibles(task, filter.responsible) &&
        this.hasMatchDueDate(task, filter.dueTime) &&
        self.hasMatchStatus(task, filter.showInactive)
    );
  }

  /** Make a page(response body) of tasks */
  private makePage(tasks: ITask[], filter: ISearchFilterSet): ITasksPage {
    const pageNum = filter.current_page || 1;
    const pageSize = filter.page_size || this.SERVER_PAGE_SIZE;
    const start = (pageNum - 1) * pageSize;
    const end = start + pageSize;
    const pageTasks = tasks.slice(start, end);

    return {
      data: pageTasks,
      current_page: pageNum,
      page_size: pageTasks.length,
      total: tasks.length,
    } as ITasksPage;
  }

  /** Check if the task matches object type */
  private static hasMatchObjectType(task: ITask, filter: ISearchFilterSet): boolean {
    return !((filter.units === false && task.objectable.type === ObjectableTypeEnum.UNIT) || (filter.rounds === false && task.objectable.type === ObjectableTypeEnum.ROUND));
  }

  /** Check if the task matches object filter */
  private hasMatchObject(task: ITask, objectFilter?: IUnitFilterSet | IRoundFilterSet): boolean {
    if (!objectFilter) {
      // Sub filter is not define
      return true;
    }

    return self.hasMatchTaskType(task, objectFilter) && this.hasMatchObjectIdentifiers(task, objectFilter.objects) && this.hasMatchWorkplaces(task, objectFilter.workplaces) && this.hasMatchSections(task, objectFilter.sections);
  }

  /** Check if the task matches "in-use" flag */
  private static hasMatchStatus(task: ITask, includeInactive?: boolean): boolean {
    if (typeof includeInactive !== 'boolean') {
      // Sub filter is not define
      return true;
    }

    return includeInactive ? true : task.status !== TaskStatusEnum.INACTIVE;
  }

  /** Check if the task matches types */
  private static hasMatchTaskType(task: ITask, objectFilter?: IUnitFilterSet | IRoundFilterSet): boolean {
    if (!objectFilter) {
      // Sub filter is not define
      return true;
    }

    const allowedTypes = new Set<TaskTypeEnum>( // Define required types by filter-settings
      [
        objectFilter.checks ? TaskTypeEnum.CHECKLIST : null,
        (objectFilter as IUnitFilterSet).services ? TaskTypeEnum.SERVICE : null,
        objectFilter.inspections ? TaskTypeEnum.INSPECTION : null,
        objectFilter.deviations ? TaskTypeEnum.DEVIATION : null,
      ].filter(type => type !== null)
    );

    return allowedTypes.size
      ? allowedTypes.has(task.type) // Has required type
      : true; // Required types are not defined
  }

  /** Check if the task matches workplaces */
  private hasMatchWorkplaces(task: ITask, workplaces?: IIdentifierFilterSet[]): boolean {
    if (!workplaces?.length) {
      // Sub filter is not define
      return true;
    }

    return !!workplaces.find(workplace => workplace.id === task.workplace.id);
  }

  /** Check if the task matches sections */
  private hasMatchSections(task: ITask, sections?: IIdentifierFilterSet[]): boolean {
    if (!sections?.length) {
      // Sub filter is not define
      return true;
    }

    const targetSections = new Set<string>(sections.map(section => section.id));

    return !!task.sections.find(section => targetSections.has(section.id));
  }

  /** Check if the task matches related unit/rounds */
  private hasMatchObjectIdentifiers(task: ITask, identifiers?: IIdentifierFilterSet[]): boolean {
    if (!identifiers?.length) {
      // Sub filter is not define
      return true;
    }

    return !!identifiers.find(identifier => identifier.id === task.objectable.id);
  }

  /** Check if the task has required responsibles */
  private hasMatchResponsibles(task: ITask, responsibles?: IResponsibleFilterSet): boolean {
    if (!responsibles?.userNames?.length) {
      // Sub filter is not define
      return true;
    }

    const tasksResponsibles = new Set<string>(task.responsibles.map(responsible => responsible.id));

    return !!responsibles.userNames.find(identifier => tasksResponsibles.has(identifier.id)); // Task has at least one of assigned responsibles
  }

  /** Check if the task has matches allowed due-time states */
  private hasMatchDueDate(task: ITask, dueTime?: IDueTimeFilterSet): boolean {
    if (!dueTime?.noDueDate && !dueTime?.overdue && !dueTime?.ongoing && !dueTime?.upcoming && !dueTime?.to && !dueTime?.from) {
      // Sub filter is not define
      return true;
    }

    if (
      dueTime.noDueDate === false && // Filter doesn't allow tasks without due-date
      !task.dateTime_data &&
      !task.odometer_data // Tasks doesn't have due-date
    ) {
      return false;
    }

    if (dueTime.from && dueTime.to) {
      if (!task.dateTime_data?.due_unit) {
        // Tasks without date doesn't match date range
        return false;
      }

      // Parse dates
      const taskDate = this.unitsTimeTrans.transform(task.dateTime_data.due_value, task.dateTime_data.due_unit);
      const from = set(
        dueTime.from,
        { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 } // Start of the day
      );
      const to = set(
        dueTime.to,
        { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 } // End of the day
      );

      return from <= taskDate && taskDate <= to; // Compare dates
    }

    return true;
  }
}

const self = OfflineTasksClient;
