import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { OfflineBannerComponent } from './offline-banner.component';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';

@NgModule({
  imports: [
    CommonModule,
    SharedPipesModule,
    IonicModule,
  ],
  declarations: [
    OfflineBannerComponent
  ],
  exports: [
    OfflineBannerComponent
  ],
})
export class OfflineBannerModule {}
