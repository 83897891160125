import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  public static FAVORITES_CHECKS_CHANGED = 'favorite-checks:changed';
  public static FAVORITES_INDEPENDENT_DEVIATIONS_CHANGED = 'favorite-independent-deviations:changed';
  public static FAVORITES_REFILLS_CHANGED = 'favorite-refills:changed';
  public static FAVORITES_REFUELS_CHANGED = 'favorite-refuels:changed';
  public static OFFLINE_ACTIVATED = 'offline:activated';
  public static CHECKLIST_CHANGED = 'checklist:changed';
  public static DEVIATION_CHANGED = 'deviation:changed';
  public static SERVICE_CHANGED = 'service:changed';

  private events = <any>{};
  private subscriptions = <any>{};

  /**
   * Publish an event
   *
   * @param name event name
   * @param arg data to send as the event
   */
  public publish(name, arg = null) {
    this.initEvent(name);
    this.events[name].next(arg);
  }

  /**
   * Subscribe to an event
   *
   * @param name event name
   * @param handler event handler
   */
  public subscribe(name, handler) {
    this.initEvent(name);

    const instance = this.events[name].asObservable().subscribe(handler);

    this.subscriptions[name].push({
      instance,
      handler
    });

    return instance;
  }

  /**
   * Unsubscribe from the event
   *
   * @param name event name
   * @param handler event handler
   *
   * @return true if a handler was removed
   */
  public unsubscribe(name, handler = null) {
    if (!this.events[name] || !this.subscriptions[name] || !this.subscriptions[name].length) {
      return false;
    }

    this.subscriptions[name] = this.subscriptions[name].filter(subscription => {
      if (!handler || subscription.handler === handler || subscription.instance === handler) {
        subscription.instance.unsubscribe();
        return false;
      }

      return true;
    });

    return true;
  }

  /**
   * Initialize all properties related to this event
   * @param name
   */
  private initEvent(name) {
    if (!this.events[name]) {
      this.events[name] = new Subject<any>();
    }

    if (!this.subscriptions[name]) {
      this.subscriptions[name] = [];
    }
  }
}
