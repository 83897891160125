import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { SwiperContainer } from 'swiper/element';
import { SwiperOptions } from 'swiper/types';
import {HelperService} from '@services';

@Directive({
  selector: '[appSwiper]',
  standalone: true,
})
export class SwiperDirective implements AfterViewInit {
  @Input() config?: SwiperOptions;
  @Input() assets?: [];

  public readonly ASSETS_TYPES = {
    VIDEO: this.helperService.protocol?.assets.types.VIDEO,
    IMAGE: this.helperService.protocol?.assets.types.IMAGE,
  };
  constructor(private el: ElementRef<SwiperContainer>,
              private helperService: HelperService) { }

  ngAfterViewInit(): void {
    Object.assign(this.el.nativeElement, this.config);
    this.el.nativeElement.initialize();
    this.initSwiperEventListener();
  }

  private initSwiperEventListener() {
    if (this.el) {
      this.el.nativeElement.addEventListener('swiperslidechange', (event: any) => {
        const previousAsset = this.assets[event.detail[0].previousIndex];
        this.pauseVideo(previousAsset);
      });
    }
  }

  private pauseVideo(assetToPause) {
    if (assetToPause.type === this.ASSETS_TYPES.VIDEO) {
      const audioPlayer = document.getElementById(assetToPause.url) as HTMLVideoElement;
      if(audioPlayer) {
        audioPlayer.pause();
      }
    }
  }
}
