import {DefaultPrivacyLevel} from '@datadog/browser-rum';

export const environment = {
  production: false,
  browserOfflineMode: true,
  browserRfid: true,
  // Url to api
  apiUrl: 'https://api.dev2.coneqt.com/api/v1',
  baseUrl: 'https://api.dev2.coneqt.com',

  // Url to MS
  microservicesBaseUrl: 'https://api2.dev.checkproof.com/dev2-',

  academyUrl: 'https://dev.academy-staging.checkproof.com/',

  auth: {
    clientId: 2,
    clientSecret: 'Y4FjXzWhrJdgrnQhj7AzmAwWgdq6vwiF5m8Wy7XL',
  },

  appVersion: require('../../package.json').version,

  pusher: {
    key: '8c10d9684a7676757374',
    cluster: 'mt1'
  },

  azureAD: {
    clientId: 'bd161c32-c7c8-4c3d-85c3-ff0d4f4ea895',
    redirectUrl: 'https://app2.stage.checkproof.com'
  },

  // region based overrides
  region: {},

  sentry: {
    dsn: 'https://c477c8acf0344e0fadc808d3c5082be6@o485066.ingest.sentry.io/5544755',
    tracePropagationTargets: ['localhost', /^https:\/\/api\.dev2\.conneqt\.com\/api\/v1/],
    environment: 'Dev-2',
    replay: false,
    tracesSampleRate: 0,
    // This sets the sample rate at 10%. You may want this to be 100% while
    // in development, then sample at a lower rate in production.
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 0,
    enabled: false,
  },

  datadog: {
    applicationId: '269dff23-033c-4998-aeb6-baef6a821c0a',
    clientToken: 'pubf26c6dd667a678755699b599fa4475ee',
    site: 'datadoghq.eu',
    service: 'checkproof-app2',
    env: 'dev2',
    forwardErrorsToLogs: true,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: require('../../package.json').version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK,
    allowedTracingUrls: ['https://api.dev2.coneqt.com', 'https://api2.dev.checkproof.com'],
    enabled: true,
  }
};
