<div class="modal-header ion-margin-bottom">
  {{'notifications.notificationCenter' | lang}}
  <i class="fal fa-window-close" (click)="closeModal()" tappable></i>
</div>
<ion-content>
  <div class="ion-margin-bottom" *ngIf="isSynchronising">
    <div class="sync-progress">
      <ion-spinner name="crescent"></ion-spinner>
    </div>
  </div>

  <div class="ion-margin-bottom" *ngIf="!isSynchronising">
    <div class="ion-margin-bottom">
      <app-segment (ionChange)="switchNotificationType($event)">
        <app-segment-button value="notifications" [checked]="true">
          {{'notifications.notification-tab' | lang}}<i *ngIf="(hasUnreadNotifications$ | async)" class="has-notice-badge"></i>
        </app-segment-button>
        <app-segment-button value="messages">
          {{'notifications.message-tab' | lang}}<i *ngIf="(hasUnreadMessages$ | async)" class="has-notice-badge"></i>
        </app-segment-button>
      </app-segment>
    </div>

    <div tappable class="selectCompany ion-no-padding" *ngIf=canSwitchCompany>
      <app-select-company (changed)="switchCompany()" [(ngModel)]="selectedCompany" [disabled]="isOffline"></app-select-company>
    </div>

    <div class="content-wrapper">
      <div class="notifications" *ngIf="showNotifications">
        <div *ngIf="notifications?.length">
          <!-- Notification start -->
          <div tappable *ngFor="let notification of notifications"
               [ngClass]="['notification', (notification.read_at !== null) ? 'read' : '']"
               (click)="goToNotification(notification)">
            <div class="top">
              <i class="{{getNotificationIcon(notification.type)}}"></i><span class="time_sent">{{getNotificationDate(notification) | formatDate:'human'}}</span>
            </div>
            <div class="body">
              <p>{{formatNotificationText(notification.data)}}</p>
            </div>
            <div class="subject">
              <span>{{notification.data.sublabel}}</span>
            </div>
          </div>
          <!-- notification end -->
          <app-button-load-more (onLoad)="loadMoreNotifications()" [hasMore]="hasMoreNotifications" [isLoading]="isLoadingMoreNotifications"></app-button-load-more>
        </div>
        <div class="no-notifications" *ngIf="!notifications?.length">
          <i class="fal fa-comment"></i>
          <p *ngIf="!isOffline">{{'notifications.no-notifications' | lang}}</p>
          <p *ngIf="isOffline">{{'notifications.offlinemode-no-notifications' | lang}}</p>
        </div>

      </div>

      <div class="messages" *ngIf="!showNotifications">
        <div *ngIf="messages?.length">
          <app-nc-message [message]="message" (markAsRead)="markMessageAsRead(message)" class="ion-margin-bottom" *ngFor="let message of messages"></app-nc-message>
          <app-button-load-more (onLoad)="loadMoreMessages()" [hasMore]="hasMoreMessages" [isLoading]="isLoadingMoreMessages"></app-button-load-more>
        </div>
        <div class="no-messages" *ngIf="!messages?.length">
          <i class="fal fa-envelope-open"></i>
          <p>{{'notifications.no-messages' | lang}}</p>
        </div>
      </div>
    </div>
  </div>
</ion-content>
