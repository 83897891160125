import { Injectable } from '@angular/core';
import * as JSZip from 'jszip';
import { OfflineHandlerService } from '../handler.service';
import { JSZipObject } from 'jszip';
import { AuthService } from '../../auth.service';

@Injectable({
  providedIn: 'root'
})
export class ImportService {
  constructor(
    private offlineHandlerService: OfflineHandlerService,
    private authService: AuthService
  ) { }

  public async importBackup(file) {
    const zip = new JSZip();
    const backup = await zip.loadAsync(file);
    const currentUser = await this.authService.getCurrentUser();
    backup.folder('backup/requests').forEach((relativePath, zipEntry) => {
      this.importRequest(backup, zipEntry, currentUser);
    });
  }

  private async importRequest(backup: JSZip, zipEntry: JSZipObject, currentUser) {
    if (zipEntry.dir) {
      return;
    }

    let request = await this.parseRequest(zipEntry);
    if (!request) {
      return;
    }

    // for assets we need to get it's content from zip and attach to request
    if (request.url === '/assets') {
      request = await this.importAssetData(backup, request);

      if (!request.data.data) {
        console.error("Asset does not exist or empty. Skipping it.");
        return;
      }
    }


    // set current user as a creator of request, so it will appear in Wait For Sync section
    request.user_id = currentUser.id;

    await this.offlineHandlerService.saveRequestToSync(request);
  }

  private async importAssetData(backup: JSZip, request) {
    // get related asset from zip file
    const file = backup.file('backup/assets/' + request.data.type + '/' + request.data.uid);
    if (!file) {
      return request;
    }
    request.data.data = await file.async('base64');

    // these fields not needed for uploading assets from base64 string
    delete request.data.asset;
    delete request.options.upload;

    return request;
  }

  private async parseRequest(zipEntry: JSZipObject) {
    const content = await zipEntry.async('string');
    try {
      return JSON.parse(content);
    } catch (e) {
      console.error(e);
      return null;
    }
  }
}
