<div class="ion-text-center">
  <ion-grid fixed>
    <ion-row>
      <ion-col class="text">{{'general.privacyPolicyUpdated' | lang }}</ion-col>
    </ion-row>
    <ion-row>
      <ion-col><a href="https://www.checkproof.com/privacy-policy/" class="text-link" target="_blank">{{'general.privacyPolicyLink' | lang }}</a></ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <app-button iconClass="fal fa-check-circle color-success" (onClick)="closeModal()">{{'general.move-on' | lang }}</app-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>
