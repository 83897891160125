import { Component, OnInit } from '@angular/core';
import { ModalService } from '@services';

@Component({
  selector: 'app-placeable-selector',
  templateUrl: './placeable-selector.component.html',
  styleUrls: ['./placeable-selector.component.scss'],
})
export class PlaceableSelectorComponent implements OnInit {
  public title = '';
  public writableOnly = true;
  public onSelect = null;
  public selectedPlace = null;
  public type: 'any'|'workplace'|'section' = 'any';
  public workplaceOnly = false;
  public disabled = true;

  constructor(
    private modalService: ModalService
  ) { }

  ngOnInit() {
    if (this.type === 'workplace') {
      this.workplaceOnly = true;
    }
  }

  public closeModal() {
    this.modalService.close();
  }

  /**
   * on click Next button
   */
  public clickNext() {
    if (typeof this.onSelect === 'function' && this.selectedPlace) {
      this.onSelect(this.selectedPlace);
      this.closeModal();
    }
  }

  /**
   * triggers when selected object is changed
   */
  public onPlaceChange() {

    if (!this.selectedPlace) {
      this.disabled = true;
    } else if (this.type === 'section' && !this.selectedPlace.section) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }
}
