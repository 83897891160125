import AbstractSubBuilder from '../../../search-filter-builder/sub-sections/abstract.sub-builder';
import { ExtSearchFilterBuilder } from '../../ext-search-filter-builder';
import { IRelationsFilterSet } from '@interfaces';

/**
 * Configuration of relations which will be joined result tasks.
 * @see ExtTaskInterface.relations
 */
export default class RelationsBuilder extends AbstractSubBuilder<ExtSearchFilterBuilder, IRelationsFilterSet> {
  /** Related checklist-variants are included in the result */
  private enableCheckIntervals = false;
  /** Related deviations are included in the result */
  private enabledDeviations = false;
  /** Related service-intervals are included in the result */
  private enabledServiceIntervals = false;
  /** Related ongoing checks */
  private enabledOngoingChecks = false;
  /** Related ongoing services */
  private enabledOngoingServices = false;
  /** Related positions */
  private enabledPositions = false;

  /** Include check-intervals in the result */
  public withCheckIntervals(): this {
    this.enableCheckIntervals = true;
    return this;
  }

  /** Exclude check-intervals from the result */
  public withoutCheckIntervals(): this {
    this.enableCheckIntervals = false;
    return this;
  }

  /** Include deviations in the result */
  public withDeviations(): this {
    this.enabledDeviations = true;
    return this;
  }

  /** Exclude deviations from the result */
  public withoutDeviations(): this {
    this.enabledDeviations = false;
    return this;
  }

  /** Include service-intervals in the result */
  public withServiceIntervals(): this {
    this.enabledServiceIntervals = true;
    return this;
  }

  /** Exclude service-intervals from the result */
  public withoutServiceIntervals(): this {
    this.enabledServiceIntervals = false;
    return this;
  }

  /** Include ongoing checks */
  public withOngoingChecks(): this {
    this.enabledOngoingChecks = true;
    return this;
  }

  /** Exclude ongoing checks */
  public withoutOngoingChecks(): this {
    this.enabledOngoingChecks = false;
    return this;
  }

  /** Include ongoing services */
  public withOngoingServices(): this {
    this.enabledOngoingServices = true;
    return this;
  }

  /** Exclude ongoing services */
  public withoutOngoingServices(): this {
    this.enabledOngoingServices = false;
    return this;
  }

  /** Include positions */
  public withPositions(): this {
    this.enabledPositions = true;
    return this;
  }

  /** Exclude positions */
  public withoutPositions(): this {
    this.enabledPositions = false;
    return this;
  }

  /** @inheritDoc */
  public build(): IRelationsFilterSet {
    return {
      enabledCheckIntervals: this.enableCheckIntervals,
      enabledDeviations: this.enabledDeviations,
      enabledServiceIntervals: this.enabledServiceIntervals,
      enabledOngoingChecks: this.enabledOngoingChecks,
      enabledOngoingServices: this.enabledOngoingServices,
      enabledPositions: this.enabledPositions,
    };
  }

  /** @inheritDoc */
  public reset(): this {
    this.enableCheckIntervals = false;
    this.enabledDeviations = false;
    this.enabledServiceIntervals = false;
    this.enabledOngoingChecks = false;
    this.enabledOngoingServices = false;

    return this;
  }
}
