import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { FormValidate } from '../form-validate';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => CheckboxComponent),
    multi: true
  }]
})
export class CheckboxComponent extends FormValidate implements OnInit, OnDestroy, ControlValueAccessor {
  public isSelected: boolean;
  public isDisabled = false;

  @Input() label = '';
  @Input() style = 'card';
  @Input() required = false;
  @Output() ionChange = new EventEmitter();

  ngOnInit() {
    this.initValidator();
  }

  ngOnDestroy(): void {
    this.destroyValidator();
  }

  writeValue(obj: any): void {
    this.isSelected = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState ? (isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * Fired when checkbox checked status is changed
   * @param {Event} event
   */
  public checkboxChange(event: Event): void {
    this.isSelected = (<HTMLInputElement>event.target).checked;
    this._onChange(this.isSelected);
    this.ionChange.emit();
  }

  /**
   * Call if input was "touched"
   * @private
   */
  private _onTouched = () => {};

  /**
   * Call if value was changed inside our component
   * @param _
   * @private
   */
  
  private _onChange = (_: any) => {};

}
