<div class="modal-header ion-margin-bottom" *ngIf="showCloseIcon">
  <div class="ion-margin-bottom" *ngIf="headerTitle" [innerHtml]="headerTitle"></div>
  <i class="fal fa-window-close" (click)="closeDialog()" tappable></i>
</div>

<div class="ion-text-center">
  <div class="ion-margin-bottom" *ngIf="title" [innerHtml]="title"></div>
  <div class="ion-margin-bottom" [innerHtml]="message" *ngIf="message"></div>
  <div class="ion-margin-bottom"  *ngIf="component">
    <template #container></template>
  </div>
  <div *ngIf="showSpinner" class="ion-text-center scan-loader"><ion-spinner name="crescent"></ion-spinner></div>

  <div class="compensate-horizontal-margins">
    <ion-grid fixed>
      <ion-row>
        <ion-col *ngFor="let button of buttons">
          <app-button
            [attr.expand]="buttonsExpand"
            [iconClass]="button.iconClass"
            [iconAlign]="button.iconAlign || 'left'"
            [disabled]="button.disabled"
            (onClick)="onButtonClick(button)">{{button.text}}</app-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
