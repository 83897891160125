import { Injectable } from '@angular/core';
import { VariantsClient } from '../cp-api/variants/variants.client';
import { ServiceIntervalsClient } from '../cp-api/service-intervals/service-intervals.client';
import { ChecksClient } from '../cp-api/checks/checks.client';
import { ServicesClient } from '../cp-api/services/services.client';
import { PositionsClient } from '../cp-api/positions/positions.client';

/** Service Locator of clients, allowing batch of injections for services which communicate with different parts of APIs. */
@Injectable({
  providedIn: 'root'
})
export class ClientsLocator {
  constructor(
    private readonly variantsClient: VariantsClient,
    private readonly serviceIntervalsClient: ServiceIntervalsClient,
    private readonly checksClient: ChecksClient,
    private readonly servicesClient: ServicesClient,
    private readonly positionsClient: PositionsClient,
  ) {
  }

  /** Get checklist variants client */
  public getVariantsClient(): VariantsClient {
    return this.variantsClient;
  }

  /** Get service intervals client */
  public getServiceIntervalsClient(): ServiceIntervalsClient {
    return this.serviceIntervalsClient;
  }

  /** Get checks client */
  public getChecksClient(): ChecksClient {
    return this.checksClient;
  }

  /** Get services client */
  public getServicesClient(): ServicesClient {
    return this.servicesClient;
  }

  /** Get positions client */
  public getPositionsClient(): PositionsClient {
    return this.positionsClient;
  }
}
