import { Injectable } from '@angular/core';
import SearchFilterBuilder from '../search-filter-builder/search-filter.builder';
import RelationsBuilder from './sub-sections/relations-builder/relations.builder';
import ClientProvider from '../../client-provider/client-provider';
import { DeviationService } from '../../../deviation.service';
import TasksPageModel from './models/tasks-page.model';
import { AuthService } from '../../../auth.service';
import { IUser, ICompany, ITasksPage, IExtTask } from '@interfaces';
import { ClientsLocator } from '../../../clients/clients-locator/clients-locator.service';
import { DeviationsWithEnum, OriginTypeEnum } from '@enums';

/**
 * Configurable provider of PT tasks.
 * It allows completing(supplementing) fetched tasks by extra data from other sources, such as CP-API.
 */
@Injectable({
  providedIn: 'root'
})
export class ExtSearchFilterBuilder extends SearchFilterBuilder {
  private readonly relationsSection = new RelationsBuilder(this);

  /**
   * @param clientProvider Tasks client provider
   * @param authService Current user/company provider
   * @param clients Clients provider
   * @param deviationsService Deviations provider
   */
  public constructor(
    clientProvider: ClientProvider,
    private readonly clients: ClientsLocator,
    private readonly authService: AuthService,
    private readonly deviationsService: DeviationService,
  ) {
    super(clientProvider);
  }

  /** @inheritDoc */
  public create(): ExtSearchFilterBuilder {
    return new ExtSearchFilterBuilder(
      this.provider,
      this.clients,
      this.authService,
      this.deviationsService,
    );
  }

  /** Get section of desired relations */
  public getRelationsSection(): RelationsBuilder {
    return this.relationsSection;
  }

  /** @inheritDoc */
  public fetch(): Promise<TasksPageModel> {
    const relationsConfig = this.relationsSection.build();

    return super.fetch()
      .then(page => this.completeByMandatoryRelations(page))
      // Complete by primary relations
      .then(page => Promise.all(
          [].concat(
            relationsConfig.enabledCheckIntervals || relationsConfig.enabledPositions ? this.completeByCheckIntervals(page.data) : [],
            relationsConfig.enabledServiceIntervals ? this.completeByServiceIntervals(page.data) : [],
            relationsConfig.enabledDeviations ? this.completeByDeviations(page.data) : [],
            relationsConfig.enabledOngoingChecks ? this.completeByOngoingChecks(page.data) : [],
            relationsConfig.enabledOngoingServices ? this.completeByOngoingServices(page.data) : [],
          )
        )
          .then(() => page)
      )
      // Complete by secondary relations which depend on primary ones
      .then(page => Promise.all(
          [].concat(
            relationsConfig.enabledPositions ? this.completeByPositions(page.data) : [],
            // ...
          )
        )
          .then(() => page)
      )
      .catch(err => {
        console.error(err);
        return Promise.reject(err);
      });
  }

  /** Complete by tasks by relations >>> */

  /** Complete tasks by constantly required relations */
  private completeByMandatoryRelations(page: ITasksPage): Promise<TasksPageModel> {
    return this.getCompany()
      .then(company => new TasksPageModel(Object.assign(
          page,
          {
            data: page.data.map(
              task => Object.assign(task, {relations: {company}})
            )
          }
        ))
      );
  }

  /** Complete tasks by check intervals */
  private completeByCheckIntervals(tasks: IExtTask[]): Promise<IExtTask[]> {
    const targetTasks = this.groupByOrigin(tasks, [OriginTypeEnum.CHECKLIST_VARIANT]);
    const objectIds = Array.from(new Set<string>(tasks.map(task => task.objectable.id)));

    return targetTasks.size === 0
      ? Promise.resolve(tasks) // Don't sent request if there aren't target models
      : this.clients
        .getVariantsClient()
        .getVariantsByUids(Array.from(targetTasks.keys()), objectIds) // Fetch API models
        .then(variants => variants.map( // Put models into related tasks
            variant => targetTasks
              .get(variant.uid)
              .forEach(task => task.relations.checkInterval = variant)
          )
        )
        .then(() => tasks);
  }

  /** Complete tasks by service intervals */
  private completeByServiceIntervals(tasks: IExtTask[]): Promise<IExtTask[]> {
    const targetTasks = this.groupByOrigin(tasks, [OriginTypeEnum.SERVICE_INTERVAL]);

    return targetTasks.size === 0
      ? Promise.resolve(tasks) // Don't sent request if there aren't target models
      : this.clients
        .getServiceIntervalsClient()
        .getIntervalsByUids(Array.from(targetTasks.keys())) // Fetch API models
        .then(intervals => intervals.map( // Put models into related tasks
            interval => targetTasks
              .get(interval.uid)
              .forEach(task => task.relations.serviceInterval = interval)
          )
        )
        .then(() => tasks);
  }

  /** Complete tasks by deviations */
  private completeByDeviations(tasks: IExtTask[]): Promise<IExtTask[]> {
    const targetTasks = this.groupByOrigin(
      tasks,
      [OriginTypeEnum.DEVIATION, OriginTypeEnum.INDEPENDENT_DEVIATION]
    );

    return targetTasks.size === 0
      ? Promise.resolve(tasks) // Don't sent request if there aren't target models
      : this.deviationsService
        .getDeviationsByUids( // Fetch API models
          Array.from(targetTasks.keys()),
          [
            DeviationsWithEnum.TAGS,
            DeviationsWithEnum.CATEGORY_PARENT_PARENT,
            DeviationsWithEnum.DEVIATION_ACTIVITIES_ASSETS,
          ]
        )
        .then(deviations => deviations.map( // Put models into related tasks
            deviation => targetTasks
              .get(deviation.uid)
              .forEach(task => task.relations.deviation = deviation)
          )
        )
        .then(() => tasks);
  }

  /** Complete tasks by ongoing checks */
  private completeByOngoingChecks(tasks: IExtTask[]): Promise<IExtTask[]> {
    const checkUidTask = this.groupByOngoing(
      tasks
        .filter(task => task.origin.type === OriginTypeEnum.CHECKLIST_VARIANT)
        .map(task => {
          task.relations.ongoingChecks = [];
          return task;
        })
    );

    return checkUidTask.size === 0
      ? Promise.resolve(tasks)
      : this.clients
        .getChecksClient()
        .getChecksByUids(Array.from(checkUidTask.keys()))
        .then(
          checks => checks?.forEach(
            check => checkUidTask.get(check.uid)
              .relations
              .ongoingChecks
              .push(check)
          )
        )
        .then(() => tasks);
  }

  /** Complete tasks by ongoing services */
  private completeByOngoingServices(tasks: IExtTask[]): Promise<IExtTask[]> {
    const serviceUidTask = this.groupByOngoing(
      tasks
        .filter(task => task.origin.type === OriginTypeEnum.SERVICE_INTERVAL)
        .map(task => {
          task.relations.ongoingServices = [];
          return task;
        })
    );

    return serviceUidTask.size === 0
      ? Promise.resolve(tasks)
      : this.clients
        .getServicesClient()
        .getServicesByUids(Array.from(serviceUidTask.keys()))
        .then(
          services => services?.forEach(
            service => serviceUidTask.get(service.uid)
              .relations
              .ongoingServices
              .push(service)
          )
        )
        .then(() => tasks);
  }

  /** Complete tasks by Positions */
  private completeByPositions(tasks: IExtTask[]): Promise<IExtTask[]> {
    // Collect Position UIDs
    const positionTasks = tasks
      // Get Task Position
      .map((task) => [
        task,
        [].concat(
          task.relations.checkInterval?.due_items ?? [],
          task.relations.serviceInterval ? [task.relations.serviceInterval] : []
        )
          .reduce(
            (triggers, dueItem) => [
              ...triggers,
              ...(dueItem.interval_due_triggers ?? [])
            ],
            []
          )
          .find(
            (dueTrigger) => dueTrigger.external?.trigger.watcher.unit.localId === task.objectable.id
          )
          ?.external?.device.position?.localId,
      ])
      // Exclude Tasks without Position
      .filter(([, positionId]) => !!positionId)
      // Map Position -> Task
      .reduce(
        (positionTasks, [task, positionId]) => {
          positionTasks.set(
            positionId,
            [].concat(positionTasks.get(positionId) ?? [], [task])
          );
          return positionTasks
        },
        new Map<string, IExtTask[]>()
      );

    // Fetch Positions
    return !positionTasks.size
      ? Promise.resolve(tasks)
      : this.clients
        .getPositionsClient()
        .getPositionsByUids(Array.from(positionTasks.keys()))
        .then(
          (positions) => positions
            .filter(position => positionTasks.has(position.uid))
            .forEach(
              position => positionTasks
                .get(position.uid)
                .forEach(
                  task => task.relations.position = position
                )
            )
        )
        .then(() => tasks);
  }

  /** <<< Complete by tasks by relations */

  /** Get company using for filtering */
  private getCompany(): Promise<ICompany> {
    return this.getCompanyUid() // Get specific company required in the request
      ? this.authService
        .getCurrentUserClones()
        .then(
          (users: IUser[]) => users
            .find(user => user.company?.uid === this.getCompanyUid())
            ?.company
        )
      : this.authService // Get current user company by default
        .getCurrentUser()
        .then((user: IUser) => user.company);
  }

  /** Get ongoing tasks grouped by origin */
  private groupByOngoing(tasks: IExtTask[]): Map<string, IExtTask> {
    const checkUidTask = new Map<string, IExtTask>();

    tasks.filter(task => task.ongoing && task.ongoing_ids?.length)
      .forEach(
        task => task.ongoing_ids
          ?.forEach(uid => checkUidTask.set(uid, task))
      );

    return checkUidTask;
  }

  /**
   * Get tasks with specific types of origin.
   * Returned tasks are grouped by the common original UID.
   */
  private groupByOrigin(tasks: IExtTask[], types: OriginTypeEnum[]): Map<string, IExtTask[]> {
    return tasks
      .filter(task => types.includes(task.origin.type))
      .reduce(
        (tasks, task) => {
          tasks.set( // Init list of model grouped by the common interval UID
            task.origin.id,
            tasks.get(task.origin.id) || []
          );
          tasks.get(task.origin.id).push(task); // Put new task in the list
          return tasks;
        },
        new Map<string, IExtTask[]>()
      );
  }
}
