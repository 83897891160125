import { Injectable } from '@angular/core';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { LangService } from "./lang.service";

@Injectable({
  providedIn: 'root'
})

export class DocumentViewerService {
  /** Max of total fetching time (seconds) */
  private readonly TIME_LIMIT = 5 * 60;

  /** Delay between trying (seconds) */
  private readonly TRIES_DELAY = 2;

  /** Options of document window */
  private readonly BROWSER_OPTIONS: InAppBrowserOptions = {
    location: 'yes',
    usewkwebview: 'yes'
  }

  constructor(
    private iab: InAppBrowser,
    private langService: LangService,
  ) {
  }

  /**
   * Show/download external file
   *
   * @param url
   */
  public viewPdf(url: string) {
    return new Promise((resolve, reject) => {
      const endTime = (new Date()).setSeconds((new Date).getSeconds() + this.TIME_LIMIT);
      const fetchDocument = () => {
        fetch(url)
          .then(res => {
            if (res.ok) {
              console.log(this);
              this.iab.create(url, '_system', this.BROWSER_OPTIONS);
              return resolve(url);
            }


            if ((new Date()).getTime() <= endTime) {
              setTimeout(fetchDocument, this.TRIES_DELAY * 1000);
            } else {
              reject(new Error(this.langService.t('errors.documentNotAvailable')));
            }
          });
      };
      fetchDocument();
    });
  }
}
