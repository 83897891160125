import { GroupTypeEnum, TaskTypeEnum } from '@enums';

export class BaseTask {
  type: TaskTypeEnum;
  group: GroupTypeEnum;
  name: string;
  due: number;
  variant: any;

  constructor(res, taskType: TaskTypeEnum) {
    Object.assign(this, res);
    this.type = taskType;
  }

  public static compare(task1: BaseTask, task2: BaseTask): number {
    const commonSort = (task1, task2) => {
      if (task1.due < task2.due) {
        return -1;
      }
      if (task1.due > task2.due) {
        return 1;
      }
      if (task1.type !== task2.type) {
        if (task1.type === TaskTypeEnum.CHECK) {
          return 1;
        } else if (task1.type === TaskTypeEnum.DEVIATION) {
          return 1;
        }
        return -1;
      }
      if (task1.type === TaskTypeEnum.DEVIATION && task2.type === TaskTypeEnum.DEVIATION) {
        if ((task1 as any).deviation_serial > (task2 as any).deviation_serial) {
          return -1;
        } else if ((task1 as any).deviation_serial < (task2 as any).deviation_serial) {
          return 1;
        }
      } else {
        if (task1.name < task2.name) {
          return -1;
        } else if (task1.name > task2.name) {
          return 1;
        }
      }
      return 0;
    };
    if (task1.type === task2.type && task1.type === TaskTypeEnum.CHECK && task1.group === task2.group && task1.group === GroupTypeEnum.TODAY) {
      if (task1.due > 0 && !task1.variant.due_data.is_due && !task1.variant.due_data.active && task2.due > 0 && !task2.variant.due_data.is_due && !task2.variant.due_data.active) {
        return commonSort(task1, task2);
      } else if (task1.due > 0 && !task1.variant.due_data.is_due && !task1.variant.due_data.active) {
        return 1;
      } else if (task2.due > 0 && !task2.variant.due_data.is_due && !task2.variant.due_data.active) {
        return -1;
      }

      if (task1.due !== 0 && !task1.variant.due_data.is_due && task2.variant.due_data.is_due) {
        return 1;
      } else if (task2.due !== 0 && !task2.variant.due_data.is_due && task1.variant.due_data.is_due) {
        return -1;
      }

      if ((task1 as any).noInterval && (task2 as any).noInterval) {
        return commonSort(task1, task2);
      } else if ((task1 as any).noInterval) {
        return 1;
      } else if ((task2 as any).noInterval) {
        return -1;
      }

      if (task1.due === 0 && (task1 as any).isFixed && !(task2 as any).isFixed && task2.due !== 0 && task2.variant.due_data.is_due) {
        return 1;
      } else if (task2.due === 0 && (task2 as any).isFixed && !(task1 as any).isFixed && task1.due !== 0 && task1.variant.due_data.is_due) {
        return -1;
      }
    }

    return commonSort(task1, task2);
  }
}
