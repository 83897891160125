import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { ICheckShort } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class ChecksClient {
  constructor(
    private readonly apiService: ApiService,
    private readonly routeService: RouteService,
  ) {
  }

  /** Fetch batch of checks */
  public getChecksByUids(uids: string[]): Promise<ICheckShort[]> {
    return this.apiService.post(
      this.routeService.makeUrl(
        this.routeService.PATHS.checks.batches.list,
      ),
      { uids }
    );
  }
}
