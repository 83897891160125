import { Injectable } from '@angular/core';
import { ITransformer } from '@interfaces';
import { UnitsTimeTransformer } from '../units-time/units-time.transformer';

@Injectable({
  providedIn: 'root'
})
export class UnitsSecondsTransformer implements ITransformer {
  public constructor(
    private readonly unitsTimeTrans: UnitsTimeTransformer,
  ) {
  }

  /** Transform time units to diff of seconds */
  public transform(value: number, unit: number): number {
    return ( // Define difference in milliseconds
      this.unitsTimeTrans.transform(value, unit).getTime() // Due time
      - (new Date().getTime()) // Current time
    ) / 1000; // Convert to seconds
  }
}
