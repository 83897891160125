import { Injectable } from '@angular/core';
import { IUnit } from '@interfaces';
import { ApiService } from './api.service';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class UnitService {
  constructor(
    private apiService: ApiService,
    private routeSrv: RouteService
  ) {
  }

  /**
   * Fetch Unit by ID
   *
   * @param id Unit ID
   */
  public getUnit(id: number): Promise<IUnit> {
    return this.apiService.get(
      this.routeSrv.makeUrl(
        this.routeSrv.PATHS.units.unit,
        {id}
      ),
    );
  }
}
