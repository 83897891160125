import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ObjectableUtil } from '../../utils/objectable.util';
import { LangService, ModalService, UserAppPreferencesService, OfflineHandlerService, RfidClientService, ActionSheetService, AuthService, ToastService } from '@services';
import { NavController} from '@ionic/angular';
import { AppFeatureEnum, RfidScanMethodEnum } from '@enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-object-selector',
  templateUrl: './object-selector.component.html',
  styleUrls: ['./object-selector.component.scss'],
})
export class ObjectSelectorComponent implements OnInit, OnDestroy {
  public title = '';
  public unitsOnly = false;
  public roundsOnly = false;
  public writableOnly = true;
  public onSelect = null;
  public isPerformCheck = false;
  public selectedObject = null;
  public rfidTag = null;
  public allowedSetupRFID = false;
  public allowedRFID = false;
  public isOfflineMode = false;
  private placeableObject;
  private nfcSubscription: Subscription;
  public RfidScanMethodEnum = RfidScanMethodEnum;
  public rfidSetupRequireScan = false;
  constructor(
    private modalService: ModalService,
    private userAppPreferences: UserAppPreferencesService,
    private rfidClt: RfidClientService,
    private toastService: ToastService,
    private langService: LangService,
    private actionSheetService: ActionSheetService,
    private navController: NavController,
    private authService: AuthService,
    private offlineHandlerService: OfflineHandlerService,
    private ngZone: NgZone,
  ) { }

  async ngOnInit() {
    this.isOfflineMode = await this.offlineHandlerService.isOfflineEnable();
    this.allowedRFID = this.authService.getAllowedFeatures()[AppFeatureEnum.RFID];

    this.initLastSelected();
  }

  ngOnDestroy(): void {
    if (this.nfcSubscription !== undefined) {
      this.nfcSubscription.unsubscribe();
    }
  }
  get selectedPlaceableObject() {
    return this.placeableObject;
  }

  set selectedPlaceableObject(obj) {
    this.placeableObject = obj;
  }

  public initLastSelected() {
    if (!this.userAppPreferences.lastSelectedObject && !this.userAppPreferences.lastSelectedPlace) {
      return;
    }

    this.placeableObject = {};

    // init last selected workplace/section
    if (this.userAppPreferences.lastSelectedPlace) {
      if (this.writableOnly) {
        if (this.userAppPreferences.lastSelectedPlace.workplace && !this.userAppPreferences.lastSelectedPlace.workplace.writable) {
          return;
        }
        if (this.userAppPreferences.lastSelectedPlace.section && !this.userAppPreferences.lastSelectedPlace.section.writable) {
          return;
        }
      }

      this.placeableObject.place = this.userAppPreferences.lastSelectedPlace;
    }

    // init last selected object
    if (this.userAppPreferences.lastSelectedObject) {
      if (this.unitsOnly && !ObjectableUtil.isUnit(this.userAppPreferences.lastSelectedObject)) {
        return;
      } else if (this.roundsOnly && !ObjectableUtil.isRound(this.userAppPreferences.lastSelectedObject)) {
        return;
      }
      this.rfidSetupRequireScan = true;
      this.placeableObject.object = this.userAppPreferences.lastSelectedObject;
      this.selectedObject = this.placeableObject.object;
    }
  }

  public async closeModal() {
    await this.modalService.close();
  }

  /**
   * Used to update the parent ObjectSelectorComponent onSelect function
   * on click Next button
   */
  public clickNext() {
    if (typeof this.onSelect === 'function' && this.selectedObject) {
      this.onSelect(this.selectedPlaceableObject);
      this.closeModal();
    }
  }

  /**
   * clickSetupRfidNextButton
   * Setup the rfid tag object to setup the tag to link it to an Category position or Control Point
   */
  public clickSetupRfidNextButton() {
    if (typeof this.onSelect === 'function' && this.selectedObject) {
      if (this.rfidTag) {
        this.rfidClt.reloadTags().then(() => {
          // Find the Tag object registered in the db
          this.rfidClt.findTag(this.rfidTag).then((tag) => {
            this.selectedObject.tag = tag;
            this.clickNext();
            this.closeModal();
          }).catch(() => {
            // Tag never registered before
            // Setup tag object
            this.selectedObject.tag = {
              categories: [],
              checklist_control_points: [],
              company_id: null,
              created_at: '',
              objectables: [],
              positions: [],
              tag_uid: this.rfidTag,
              uid: '',
              updated_at: '',
            };
            this.clickNext();
            this.closeModal();
          });
        });
      }
    }
  }

  /**
   * triggers when selected object is changed
   * @param object
   */
  public onObjectChange(object) {
    this.selectedObject = object;
    this.ngZone.run(() => {
      this.rfidSetupRequireScan = !!this.selectedObject;
    });
    this.userAppPreferences.lastSelectedObject = object;
  }

  public onPlaceChange(place) {
    this.userAppPreferences.lastSelectedPlace = place;
  }

  public callBackButtonRfid(tagUid) {
    this.rfidTag = tagUid;
    if (!this.allowedSetupRFID) {
      this.findTagAndContinue(this.rfidTag);
    } else {
      // case we are going to setup RFID tag
      this.clickSetupRfidNextButton();
    }
  }

  private findTagAndContinue(tagUid) {
    this.rfidClt.findTag(tagUid).then(async (tag) => {
      // If Multiple objectables tag.
      if (tag.objectables.length > 1) {
        // remove Rounds or others if we are only selecting units
        if (this.unitsOnly) {
          tag.objectables = tag.objectables.filter(val => val.unit_type === 0);
        }
        const buttons = [];
        tag.objectables.forEach(objectable => {
          const button = {
            text: objectable.name,
            cssClass: 'action-sheet-icon-checkproof unitsrounds',
            handler: () => {
              this.placeableObject = objectable;
              this.placeableObject.object = this.placeableObject;
              this.selectedObject = this.placeableObject.object;
              this.clickNext();
            }
          };
          buttons.push(button);
        });

        buttons.push({
          text: this.langService.t('actions.cancel'),
          role: 'cancel'
        });

        await this.actionSheetService.open({
          cssClass: 'action-sheets-groups-page',
          buttons: buttons
        });
        await this.closeModal();
      } else if (tag.objectables.length === 1) {
        // If 1 objectable tag.
        this.placeableObject = tag.objectables[0];
        this.placeableObject.object = this.placeableObject;
        this.selectedObject = this.placeableObject.object;
        await this.closeModal();
        this.clickNext();
      } else {
        // No objectables conected to this tag
        this.toastService.showToast({
          message: this.langService.t('rfid.messages.notConnectedAuthorized'),
          color: 'danger'
        });
      }
    }).catch(() => {
      this.toastService.showToast({
        message: this.langService.t('rfid.messages.notConnectedAuthorized'),
        color: 'danger'
      });
    });
  }
}
