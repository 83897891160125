import { ISearchFilterBuilder } from '@interfaces';

/** Builder of sub-filter */
export default abstract class AbstractSubBuilder<Parent extends ISearchFilterBuilder, Filter extends object> implements ISearchFilterBuilder {
  public constructor(
    /** Parent builder */
    private readonly parent: Parent
  ) {
  }

  /** Get parent builder */
  public getParent<CustomParent extends Parent>(): CustomParent {
    return this.parent as CustomParent;
  }

  /** @inheritDoc */
  public abstract build(): Filter;

  /** @inheritDoc */
  abstract reset(): this;
}
