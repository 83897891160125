import { v4 as uuidv4 } from 'uuid';

/**
 * Get uniq id string
 * @param {string} prefix
 * @return {string}
 */
export function uniqid(prefix = '') {
    return (prefix ? prefix + '-' : '') + uuidv4();
}
