import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '../utils/format-date.util';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(value: any, format: 'date'|'time'|'human'|'interval'|'datetime'|'taskdate'|'taskday'|'taskdaytime' = 'datetime'): any {
    return formatDate(value, format);
  }
}
