/**
 * Add pipes here to be loaded wherever this module is loaded.
 */

import { NgModule } from '@angular/core';
import { LangPipe } from './lang.pipe';
import { FormatTimeDurationPipe } from './format-time-duration.pipe';
import { UnixToDays } from './unix-to-days.pipe';
import { FormatUserName } from './format-user-name.pipe';
import { OdometerSuffixPipe } from './odometer-suffix.pipe';
import { FormatDueDatePipe } from './format-due-date.pipe';
import { ProtocolLang } from './protocol-lang.pipe';
import { FormatUserTextPipe } from './format-user-text.pipe';
import { ControlPointBadgeClassPipe } from './control-point-badge-class.pipe';
import { CompanyStringPipe } from './company-string.pipe';
import { FormatExtensionWithDotPipe } from './format-extension-with-dot.pipe';
import { UserNameByUidPipe } from './responsibles/user-name-by-uid/user-name-by-uid.pipe';
import { GroupNameByUidPipe } from './responsibles/group-name-by-uid/group-name-by-uid.pipe';
import { ExternalNameByUidPipe } from './responsibles/external-name-by-uid/external-name-by-uid.pipe';
import { ResponsibleNamePipe } from './responsibles/responsible-name/responsible-name.pipe';
import { SensorNamePipe } from './sensor/sensor-name/sensor-name.pipe';

@NgModule({
  imports: [
  ],

  declarations: [
    LangPipe,
    FormatTimeDurationPipe,
    UnixToDays,
    FormatUserName,
    OdometerSuffixPipe,
    ProtocolLang,
    FormatDueDatePipe,
    FormatUserTextPipe,
    ControlPointBadgeClassPipe,
    CompanyStringPipe,
    FormatExtensionWithDotPipe,
    UserNameByUidPipe,
    GroupNameByUidPipe,
    ExternalNameByUidPipe,
    ResponsibleNamePipe,
    SensorNamePipe,
  ],
  providers: [
    UserNameByUidPipe,
    GroupNameByUidPipe,
    ExternalNameByUidPipe,
  ],
  exports: [
    LangPipe,
    FormatTimeDurationPipe,
    UnixToDays,
    FormatUserName,
    OdometerSuffixPipe,
    ProtocolLang,
    FormatDueDatePipe,
    FormatUserTextPipe,
    ControlPointBadgeClassPipe,
    CompanyStringPipe,
    FormatExtensionWithDotPipe,
    UserNameByUidPipe,
    GroupNameByUidPipe,
    ExternalNameByUidPipe,
    ResponsibleNamePipe,
    SensorNamePipe,
  ]
})

export class SharedPipesModule {
}
