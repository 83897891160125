import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

import { promiseTimeout, PromiseTimeoutError } from '../utils/promise-timeout.util';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private promiseGetter = null;
  private promiseCacheExpire = 0;

  /** 20 seconds of location cache */
  private cacheExpireTimeout = 20000;

  /** max time to get geo location */
  private geolocationTimeout = 10000;

  /** sometimes geolocation service can stuck, so we need a timeout on top of it */
  private serviceTimeout = 15000;

  /** Is geo service blocked (e.g. by country) */
  private isGeoServiceBlocked = false;

  /**
   * Get user coordinates
   * @return {Promise<any>}
   */
  public async getGeoPosition(): Promise<any> {
    if (this.isGeoServiceBlocked) {
      return null;
    }

    let coordinates = null;
    this.promiseGetter = promiseTimeout(
      Geolocation.getCurrentPosition({
        enableHighAccuracy: false,
        timeout: this.geolocationTimeout,
        maximumAge: this.cacheExpireTimeout
      }).then((res) => coordinates = res),
      this.serviceTimeout
    ).catch(e => {
      if (e instanceof PromiseTimeoutError) {
        console.error('Geo service timeout. Probably it is blocked by current country.');
        this.isGeoServiceBlocked = true;
      }

      return coordinates;
    });


    return this.promiseGetter;
  }

  /**
   * Check expiration time of promise cache
   */
  private checkCacheExpiration() {
    return Date.now() < this.promiseCacheExpire;
  }
}
