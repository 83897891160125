import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import cloneDeep from 'lodash/cloneDeep';

import { OfflineRouterService } from './router.service';
import { OfflineDataController } from './data-controller';
import { OfflineDataUtil } from '../../utils/offline-data.util';
import { HelperService } from '../helper.service';
import { uniqid } from '../../utils/uniqid.util';
import { LangService } from '../lang.service';
import { NotFoundError } from '../../utils/error.util';
import { EventsService } from '../events.service';
import {format, parseISO} from 'date-fns';
import {FNS_ATOM_DATE_TIME} from '../../constants/data-time-formats';
import {EnvironmentService} from '../environment.service';


@Injectable({
  providedIn: 'root'
})
export class OfflineHandlerService {
  /**
   * Max lifetime of offline mode in hours
   * @type {number}
   */
  public offlineModeMaxLifetime = 24;
  public user = null;

  readonly storagePrefix = 'offline-';
  readonly expireSoonThreshold = 5; // 5 hours

  constructor(
    private storage: Storage,
    private router: OfflineRouterService,
    public helperService: HelperService,
    public langService: LangService,
    private events: EventsService,
    private environmentService: EnvironmentService,
  ) {
    this.events.subscribe('user:logout', () => {
      this.onUserLogout();
    });

    this.events.subscribe('user:init', (user) => {
      this.onUserInit(user);
    });
  }

  /**
   * Get offline data from local storage
   * @param {string} key
   * @return {Promise<any>}
   */
  public getData(key: string): Promise<any> {
    return this.storage.get(this.storagePrefix + key);
  }

  /**
   * Get data from local storage without offline prefix
   * @param {string} key
   * @return {Promise<any>}
   */
  public getDataWithoutOfflinePrefix(key: string): Promise<any> {
    return this.storage.get(key);
  }
  /**
   * Save offline data to local storage
   * @param {string} key
   * @param data
   * @return {Promise<any>}
   */
  public setData(key: string, data: any): Promise<any> {
    return this.storage.set(this.storagePrefix + key, data);
  }

  /**
   * Remove offline data from local storage
   * @param key
   */
  public removeData(key): Promise<any> {
    return this.storage.remove(this.storagePrefix + key);
  }

  /**
   * Check is offline mode is enabled
   * @return {Promise<boolean>}
   */
  public async isOfflineEnable(): Promise<boolean> {
    return this.getData('isActive').then(isActive => {
      return isActive ? true : false;
    });
  }

  /**
   * Check is offline mode is enabled and not expired
   * @return {Promise<boolean>}
   */
  public async isOfflineActive(): Promise<boolean> {
    const isOffline = await this.isOfflineEnable();
    const remainTime = await this.getRemainTime();

    return new Promise<any>((resolve) => {
      if (!isOffline) {
        return resolve(false);
      }

      if (remainTime < 0) {
        return resolve(false);
      }

      return resolve(true);
    });
  }

  /**
   * Check current network status of device
   * @return {Promise<any>}
   */
  public async isDeviceOnline(): Promise<any> {
    if (!Capacitor.isNativePlatform()) {
      return Promise.resolve(true);
    }
    const status = await Network.getStatus();
    return Promise.resolve(status && status.connected);
  }

  /**
   * Check if user can work with data (online or offline)
   * @return {Promise<any>}
   */
  public async hasDataAccess(): Promise<any> {
    const isOnline = await this.isDeviceOnline();
    let hasAccess = false;
    if (isOnline) {
      hasAccess = true;
    } else {
      const isOfflineActive = await this.isOfflineActive();
      if (isOfflineActive) {
        hasAccess = true;
      }
    }

    return Promise.resolve(hasAccess);
  }

  /**
   * Get remain time for active offline mode
   * @return {Promise<number | null>}
   */
  public async getRemainTime(): Promise<number|null> {
    const lastSyncDate = await this.getLastSyncDate();
    const expireAfter = this.offlineModeMaxLifetime * 60 * 60 * 1000; // 24h

    return new Promise<any>((resolve) => {
      if (!lastSyncDate || !expireAfter) {
        resolve(null);
      }

      const currentDate = new Date();
      let remainTime = lastSyncDate.getTime() + expireAfter - currentDate.getTime();
      if (remainTime < 0) {
        remainTime = 0;
      }

      return resolve(remainTime);
    });
  }

  /**
   * Format remaining time (to display it using hours or minutes)
   * @param remainTime
   * @return {string}
   */
  public formatRemainTime(remainTime) {
    if (!remainTime || remainTime <= 0) {
      return '';
    }
    const hour = 60 * 60 * 1000; // 1 hour

    let remainTimeFormat = '';
    if (remainTime > hour) {
      remainTimeFormat = (Math.round(remainTime / hour)) + 'h';
    } else {
      remainTimeFormat = (Math.ceil(remainTime / 60 / 1000)) + 'min';
    }

    return remainTimeFormat;
  }

  /**
   * Is offline mode will be expired soon
   * @param {number} remainTime
   * @return {boolean}
   */
  public willExpireSoon(remainTime: number): boolean {
    const hour = 60 * 60 * 1000; // 1 hour

    if (remainTime < this.expireSoonThreshold * hour) {
      return true;
    }

    return false;
  }

  /**
   * Set offline mode on or off
   * @param {boolean} active
   * @param {boolean} publishEvent
   * @return {Promise<any>}
   */
  public async setOffline(active: boolean, publishEvent = true): Promise<any> {
    // set or remove offline-app-version
    if (active) {
      await this.setData('app-version', this.environmentService.getEnvironment().appVersion);
    } else {
      await this.removeData('app-version');
    }

    if (publishEvent) {
      this.events.publish('offline:activated', active);
    }

    return this.setData('isActive', active);
  }

  /**
   * Set last date/time of data synchronization
   * @param {Date} lastSyncDate
   * @return {Promise<any>}
   */
  public setLastSyncDate(lastSyncDate: Date): Promise<any> {
    return this.setData('lastSyncDate', lastSyncDate);
  }

  /**
   * Get last date/time of data synchronization
   * @return {Promise<Date | null>}
   */
  public getLastSyncDate(): Promise<Date|null> {
    return this.getData('lastSyncDate').then(lastSyncDate => {
      if (lastSyncDate) {
        return parseISO(format(lastSyncDate, FNS_ATOM_DATE_TIME));
      }
      return null;
    });
  }

  /**
   * Clear all offline data from local storage (except pending requests)
   * @return {Promise<any>}
   */
  public clearAllData(userId?: number): Promise<any> {
    return this.storage.forEach((value, key) => {
      if (userId && this.isOfflineRequestKey(key)) {
        this.addRequestUserId(value, userId);
        return;
      }
      if (key.indexOf(this.storagePrefix) === 0) {
        this.storage.remove(key);
      }
    });
  }

  /**
   * Save collection data to local storage
   * @param {string} url
   * @param data
   * @return {Promise<any>}
   */
  public setCollectionData(url: string, data: any): Promise<any> {
    return this.setData(url, {data: data});
  }

  /**
   * Get collection data from local storage
   * @param {string} url
   * @return {Promise<any>}
   */
  public getCollection(url: string): Promise<any> {
    return this.getData(url).then(data => {
      if (!data || !data.data) {
        return null;
      }
      return data.data;
    });
  }

  /**
   * Get response from api
   * @param {string} url Request url
   * @param params Request parameters
   * @return {Promise<any>}
   */
  public getResponse(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const route = this.router.getRoute(url);
      if (!route) {
        console.error('no offline route for', url);
        return reject(new NotFoundError());
      }

      const offlineController = new OfflineDataController(this, route, params);
      offlineController.getResponse().then((data) => {
        resolve(data);
      }).catch(reject);
    });
  }

  /**
   * Save outgoing POST/PUT/DELETE request to local storage
   * @param {string} method
   * @param {string} url
   * @param data
   * @return {Promise<any>}
   */
  public async saveRequest(method: string, url: string, data?: any, options?: any): Promise<any> {
    if (method === 'post') {
      return this.savePostRequest(url, data, options);
    } else if (method === 'put') {
      return this.savePutRequest(url, data, options);
    } else if (method === 'delete') {
      return this.saveDeleteRequest(url, data, options);
    }
    return Promise.resolve();
  }

  /**
   * Save "post" outgoing request
   * @param url
   * @param data
   * @return {Promise<Promise<any> | Promise<any>>}
   */
  private async savePostRequest(url, data, options?: any) {
    const method = 'post';
    const result = await this.performOfflineRequest(method, url, data);

    const requestToSync = {
      method: method,
      url: url,
      data: data ? cloneDeep(data) : {},
      options: options,
      result: result || null
    };

    await this.saveRequestToSync(requestToSync);

    return Promise.resolve(requestToSync.result);
  }

  /**
   * Save "put" outgoing request
   * @param url
   * @param data
   * @return {Promise<Promise<any> | Promise<any>>}
   */
  private async savePutRequest(url, data, options?: any) {
    const method = 'put';
    const requests = await this.getRequestsToSync();
    const isNewItem = OfflineDataUtil.isNewItem(data);

    let requestData = data ? cloneDeep(data) : {};

    let requestToSync = requests.find(request => {
      // if item was added during current offline mode session
      // - find corresponding post request and replace it with current data
      if (isNewItem && request.method === 'post' && url.indexOf(request.url) === 0 && request.result && request.result.id === data.id) {
        return true;
      }

      // check if there another put request with same method and url
      // if so - replace it
      if (request.method === method && request.url === url) {
        return true;
      }

      return false;
    });

    if (!requestToSync) {
      requestToSync = {
        method: method,
        url: url,
        options: options,
      };
    } else {
      const route = this.router.getRoute(url);
      if (route && route.mergePutData) {
        requestData = route.mergePutData((requestToSync.data || {}), requestData);
      } else {
        // replace data properties from original request by current one
        requestData = Object.assign({}, (requestToSync.data || {}), requestData);
      }
    }

    requestToSync.data = requestData;

    const result = await this.performOfflineRequest(method, url, requestData);

    if (!result) {
      return Promise.resolve();
    }

    requestToSync.result = result || null;

    await this.saveRequestToSync(requestToSync);

    return Promise.resolve(requestToSync.result);
  }

  /**
   * Save "delete" outgoing request
   * @param url
   * @param data
   * @return {Promise<Promise<any> | Promise<any>>}
   */
  private async saveDeleteRequest(url, data?: any, options?: any) {
    const method = 'delete';
    const requests = await this.getRequestsToSync();

    const route = this.router.getRoute(url);

    if (url.indexOf('/assets/uid') === 0) {
      // check if this asset was added while offline mode
      // if so - remove it from pending requests to not upload it to api

      await this.performOfflineRequest(method, url, data);

      const postRequest = requests.find(request => {
        if (request.method === 'post' && request.url === '/assets' && request.data && request.data.uid === route.params[':uid']) {
          return true;
        }

        return false;
      });

      if (postRequest) {
        await this.removeRequestToSync(postRequest.id);
        return Promise.resolve();
      }
    }

    // check if it is item that was created while offline mode
    if (route && route.params && route.params[':id'] && OfflineDataUtil.isOfflineCreated(route.params[':id'])) {
      await this.performOfflineRequest(method, url, data);

      // find corresponding post request that should be removed
      const postRequest = requests.find(request => {
        if (request.method === 'post' && url.indexOf(request.url) === 0 && request.result && request.result.id === route.params[':id']) {
          return true;
        }

        return false;
      });

      if (postRequest) {
        await this.removeRequestToSync(postRequest.id);
      }

      return Promise.resolve();
    }

    let parentRequest = null;
    let putRequest = null;
    requests.forEach(request => {
      // check if there put request for the parent object
      // (e.g. DELETE /deviations/1234/articles/345 has parent PUT /deviations/1234/)
      // if so - it should be a depended request
      if (request.method === 'put' && url !== request.url && url.indexOf(request.url) === 0) {
        parentRequest = request;
      }

      // check if there put request for the same object
      // if so - it should be removed
      if (request.method === 'put' && url === request.url) {
        putRequest = request;
      }
    });

    const result = await this.performOfflineRequest(method, url, data);
    const requestToSync = <any>{
      method: method,
      url: url,
      data: data ? cloneDeep(data) : {},
      options: options,
      result: result || null
    };

    if (parentRequest) {
      requestToSync.parent_id = parentRequest.id;
    }

    if (putRequest) {
      await this.removeRequestToSync(putRequest.id);
    }

    await this.saveRequestToSync(requestToSync);

    return Promise.resolve(requestToSync.result);
  }

  /**
   * Get list of outgoing request that should be synced
   * @return {Promise<any>}
   */
  public async getRequestsToSync(): Promise<any> {
    const requests = [];
    await this.storage.forEach((request, key) => {
      if (!this.isOfflineRequestKey(key)) {
        return;
      }

      this.addRequestUserId(request, this.user?.id);

      if (!this.user || request.user_id !== this.user.id) {
        return;
      }

      requests.push(request);
    });

    return requests;
  }

  private isOfflineRequestKey(key): boolean {
    return key.indexOf(this.storagePrefix + 'request-') === 0;
  }

  /**
   * check if request doesn't have user_id property - add it
   * to support old apps so user don't lose offline data
   * can be removed if no one use app version <= 4.37
   * @param request
   * @param userId
   * @private
   */
  private async addRequestUserId(request: any, userId: number) {
    if (request.user_id || !userId) {
      return;
    }
    request.user_id = userId;
    await this.storage.set(request.id, request);
  }

  /**
   * Save outgoing request
   * @param request
   * @return {Promise<any>}
   */
  public async saveRequestToSync(request) {
    if (request && request.id) {
      await this.storage.set(request.id, request);
      this.events.publish('requestToSync:changed');
      return Promise.resolve();
    }

    request.id = this.generateRequestId();
    request.user_id = this.user ? this.user.id : null;
    await this.storage.set(request.id, request);

    this.events.publish('requestToSync:changed');
    return Promise.resolve();
  }

  public generateRequestId() {
    return this.storagePrefix + uniqid('request');
  }

  /**
   * Remove outgoing request
   * @param id
   * @return {Promise<any>}
   */
  public async removeRequestToSync(id) {
    await this.storage.remove(id);
    this.events.publish('requestToSync:changed');
    return Promise.resolve();
  }

  /**
   * Set request to sync object parameter
   * @param requestId
   * @param paramKey
   * @param paramValue
   */
  public async setRequestParameter(requestId, paramKey, paramValue) {
    const request = await this.storage.get(requestId);
    request[paramKey] = paramValue;
    return this.saveRequestToSync(request);
  }

  /**
   * Get synchronize settings (workplaces and sections that should be synced)
   * @return {Promise<any>}
   */
  public async getSyncSettings(): Promise<any> {
    let syncSettings = await this.getData('syncSettings');
    if (!syncSettings) {
      syncSettings = {
        workplaces: []
      };
    }
    return syncSettings;
  }

  /** Get offline preset From Storage */
  public async getSyncPreset(): Promise<any> {
    let syncPreset = await this.getData('syncPreset');
    if (!syncPreset) {
      syncPreset = {
        workplace: [],
        section: [],
        objects: [],
        allObjectsChecked: false,
        allSectionsChecked: false,
      };
    }
    return syncPreset;
  }

  /**
   * Save synchronize settings object to local storage
   * @param {object} settings
   * @return {Promise<any>}
   */
  public async setSyncSettings(settings: object): Promise<any> {
    return this.setData('syncSettings', settings);
  }

  /**
   * Save synchronize settings object to local storage
   * @param {object} settings
   * @return {Promise<any>}
   */
  public async setSyncPreset(settings: object): Promise<any> {
    return this.setData('syncPreset', settings);
  }

  /**
   * Save specific synchronize setting to local storage
   * @param {string} key
   * @param value
   * @return {Promise<any>}
   */
  public async setSyncSetting(key: string, value: any): Promise<any> {
    return this.getSyncSettings().then(syncSettings => {
      syncSettings[key] = value;
      this.setSyncSettings(syncSettings);
    });
  }

  /**
   * Save synchronize settings object that was used while last sync process
   * @param {object} settings
   * @return {Promise<any>}
   */
  public async setLastSyncSettings(settings: object): Promise<any> {
    return this.setData('lastSyncedSettings', settings);
  }

  /**
   * Get synchronize settings object that was used while last sync process
   * @return {Promise<any>}
   */
  public async getLastSyncSettings(): Promise<any> {
    return this.getData('lastSyncedSettings');
  }

  /**
   * Check if some offline collection should be updated
   * @param {string} method
   * @param {string} url
   * @param data
   * @return {Promise<any>}
   */
  private updateCollections(method: string, url: string, data?: any): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const route = this.router.getRoute(url);
      if (!route || !route.collection) {
        return resolve();
      }

      this.getCollection(route.collection).then(items => {
        if (!items || !Array.isArray(items) || !items.length) {
          items = [];
        }

        let updatedItem = data;
        if (typeof route.beforeUpdate === 'function' && updatedItem) {
          updatedItem = route.beforeUpdate(updatedItem, method);
        }

        let isUpdated = false;
        if (method === 'post') {
          items.push(updatedItem); // add this object to collection
          isUpdated = true;
        } else if (route.findBy) {
          items.forEach((item, key) => {
            if (!OfflineDataUtil.filterData(item, route.findBy, route.params, {})) {
              return;
            }

            if (method === 'put') {
              items[key] = updatedItem;  // replace this item in collection
              isUpdated = true;
            } else if (method === 'delete') {
              items.splice(key, 1);  // remove this item from collection
              isUpdated = true;
            }
          });
        }

        if (!isUpdated) {
          return resolve();
        }

        this.setCollectionData(route.collection, items).then(() => {
          resolve();
        }).catch(reject);
      }).catch(reject);
    });
  }

  /**
   * Run offline route hooks based on current request
   * @param {string} method
   * @param {string} url
   * @param data
   * @return {Promise<any>}
   */
  public performOfflineRequest(method: string, url: string, data?: any): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      const route = this.router.getRoute(url);
      if (!route) {
        return resolve();
      }

      const offlineController = new OfflineDataController(this, route, data);
      offlineController.performRequest(method, url)
        .then(resolve)
        .catch(reject);
    });
  }

  /**
   * Triggers when user has been logged out
   * @return {Promise<any>}
   */
  public onUserLogout(): Promise<any> {
    const userId = this.user ? this.user.id : null;
    this.user = null;

    return Promise.all([
      this.setOffline(false),
      this.clearAllData(userId)
    ]);
  }

  /**
   * Triggers when user has been initialized
   * @param user
   */
  private onUserInit(user) {
    this.user = user;

    if (user && user.company && user.company.company_settings) {
      user.company.company_settings.forEach(setting => {
        if (parseInt(setting.key, 10) === this.helperService.protocol.companySettings.OFFLINE_MODE_LIFETIME && setting.value) {
          const value = parseInt(setting.value, 10);
          if (value && !isNaN(value)) {
            this.offlineModeMaxLifetime = value;
          }
        }
      });
    }
  }
}
