<ion-menu
        id="sidebar-menu"
        side="end"
        menuId="sidebar-menu"
        contentId="main-content"
        type="overlay"
        swipeGesture="false">
    <ion-header *ngIf="isEnabled">
      <ion-toolbar>
        <div class="toolbar">
          <button tappable (click)="close()">
            <i class="far fa-arrow-alt-circle-right"></i>
          </button>
        </div>
      </ion-toolbar>
    </ion-header>
    <ion-content class="content" *ngIf="isEnabled">
      <ion-list>
        <ion-item lines="full">
          <ion-avatar slot="start">
            <i class="fal fa-user round-icon"></i>
          </ion-avatar>
          <ion-label>
            <strong>{{user | formatUserName}}</strong>
          </ion-label>
        </ion-item>
        <app-side-bar-menu-phone-support></app-side-bar-menu-phone-support>
        <app-side-bar-menu-email-support></app-side-bar-menu-email-support>
        <ion-item class="ion-item-no-padding" lines="full" tappable>
          <div class="bottom">
            <p class="version">{{'app.version' | lang}} {{appVersionNumber}}</p>
            <p>
              <app-button iconClass="far fa-sign-out-alt color-danger" iconAlign="left" (onClick)="logout()">{{'actions.logOut' | lang}}</app-button>
            </p>
          </div>
        </ion-item>
      </ion-list>
    </ion-content>
</ion-menu>
