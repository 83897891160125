import { Injectable } from '@angular/core';
import { HelperService} from './helper.service';
import { IIntervalFormattedValue, IOdometer } from '@interfaces';
import { FormComponentService } from './form-component.service';
import { ModalService } from './modal.service';
import { LangService } from './lang.service';
import { UntypedFormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class OdometerService {
  private message: string;
  private confirmOdometerValue = this.langService.t('units.confirmOdometerValue');

  constructor(private readonly helper: HelperService,
    private formComponentService: FormComponentService,
    private modalService: ModalService,
    private langService: LangService
    ) {}

  /**
   * Transforms 'interval_value' to formatted value (more human-readable)
   */
  getFormattedValue(value): IIntervalFormattedValue {
    let iType = this.helper.protocol.inspections.intervals.DAILY;
    let iValue = value / (24 * 60 * 60);

    if (value > 0) {
      const month = 2630880; // 30.45 * 24 * 60 * 60
      const monthXConvert = 2629800; // https://www.xconvert.com/unit-converter/months-to-seconds

      if (value / (12 * month) === Math.floor(value / (12 * month))) {
        iType = this.helper.protocol.inspections.intervals.YEARLY;
        iValue = Math.floor(value / (12 * month));
      } else if (value / (12 * monthXConvert) === Math.floor(value / (12 * monthXConvert))) {
        iType =  this.helper.protocol.inspections.intervals.YEARLY;
        iValue = Math.floor(value / (12 * monthXConvert));
      } else if (value / month === Math.floor(value / month)) {
        iType = this.helper.protocol.inspections.intervals.MONTHLY;
        iValue = Math.floor(value / month);
      } else if (value / monthXConvert === Math.floor(value / monthXConvert)) {
        iType = this.helper.protocol.inspections.intervals.MONTHLY;
        iValue = Math.floor(value / monthXConvert);
      } else if (value / (7 * 24 * 60 * 60) === Math.floor(value / (7 * 24 * 60 * 60))) {
        iType = this.helper.protocol.inspections.intervals.WEEKLY;
        iValue = Math.floor(value / (7 * 24 * 60 * 60));
      }
      if (iValue < 1) {
        iValue = 1;
        iType = this.helper.protocol.inspections.intervals.MONTHLY;
      }
    }

    return {
      value: parseInt(String(iValue), 10),
      type: iType
    };
  }

  /**
   * Create confirmation popup in control points
   */
  public async createConfirmationPopupFromControlPoint(currentControlPoint: UntypedFormControl, odometers: IOdometer[]): Promise<boolean> {
    const newValue: string = currentControlPoint.value.value;

    // Skip if input is null
    if (newValue === null){
      return true;
    }

    const currentOrder = this.formComponentService.getComponentSettings(currentControlPoint.value).odometerOrder;
    const currentOdometer = odometers.find((odometer: IOdometer) => odometer.order === currentOrder);

    // Skip if odometer type is range
    if (currentOdometer.ranges?.length) {

      return true;
    } else {
      // Create confrimationPopop if odometer type is default
      return await this._createConfirmationPopup(currentOdometer.value, currentOdometer.suffix, newValue);
    }
  }

  /**
   * Create confirmation popup in Refill
   */
  public async createConfirmationPopupFromRefill(currentOdometer: IOdometer, newValue: string): Promise<boolean> {
    const currentOdometerValue = currentOdometer.value;

    return await this._createConfirmationPopup(currentOdometerValue, currentOdometer.suffix, newValue);
  }

  /**
   * Create confirmation popup message if new value is lower or double the current value
   */
  public async _createConfirmationPopup(currentOdometerValue: string | number, currentOdometerSuffix: string, newOdometerValue: string): Promise<boolean> {
    currentOdometerValue = parseFloat(currentOdometerValue.toString());
    const doubleCurrentValue = currentOdometerValue < 0 ? currentOdometerValue / 2 : currentOdometerValue * 2;

    if (currentOdometerValue === parseFloat(newOdometerValue)) {
      return true;
    } else if (parseFloat(newOdometerValue) < currentOdometerValue) {
      this.message = this.langService.t('units.lowerOdometerValueMessage');
      return await this.modalService.showOdometerConfirmation(currentOdometerValue, currentOdometerSuffix, newOdometerValue, this.message, this.confirmOdometerValue);
    } else if (parseFloat(newOdometerValue) >= doubleCurrentValue) {
      this.message = this.langService.t('units.doubleOdometerValueMessage');
      return await this.modalService.showOdometerConfirmation(currentOdometerValue, currentOdometerSuffix, newOdometerValue, this.message, this.confirmOdometerValue);
    } else {
      return true;
    }
  }
}
