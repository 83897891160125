import { Injectable } from '@angular/core';
import { ITransformer } from '@interfaces';
import { sub} from 'date-fns';
import {MAP_UNITS} from '../../../../../constants/units-time';

@Injectable({
    providedIn: 'root'
})
export class UnitsTimeSubTransformer implements ITransformer {
    /** Map between PT units & Date-FNS units */
    private readonly UNITS = MAP_UNITS;

    /** Transform time units to time and add it to initial date */
    public transform(initialDate: Date, value: number, unit: number): Date {
        return sub(
            initialDate,
            {
                [this.UNITS[unit]]: value
            }
        );
    }
}
