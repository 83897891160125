import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import { datadogRum } from '@datadog/browser-rum';
import {datadogLogs, LogsEvent} from '@datadog/browser-logs';

if (environment.datadog.clientToken && environment.datadog.enabled) {
  datadogLogs.init({
    //applicationId: environment.datadog.applicationId,
    clientToken: environment.datadog.clientToken,
    beforeSend: (log: LogsEvent) => {
      // discard 401 network errors
      if (log.http && log.http.status_code === 401) {
        return false;
      }
      return  true;
    },
    site: environment.datadog.site,
    service: environment.datadog.service,
    env: environment.datadog.env,
    forwardErrorsToLogs: environment.datadog.forwardErrorsToLogs,
  });
  datadogRum.init({
    applicationId: environment.datadog.applicationId,
    clientToken: environment.datadog.clientToken,
    site: environment.datadog.site,
    service: environment.datadog.service,
    env: environment.datadog.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: environment.datadog.version,
    sessionSampleRate: environment.datadog.sessionSampleRate,
    sessionReplaySampleRate: environment.datadog.sessionReplaySampleRate,
    trackUserInteractions: environment.datadog.trackUserInteractions,
    trackResources: environment.datadog.trackResources,
    trackLongTasks: environment.datadog.trackLongTasks,
    defaultPrivacyLevel: environment.datadog.defaultPrivacyLevel,
    allowedTracingUrls: environment.datadog.allowedTracingUrls
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// Call the element loader after the platform has been bootstrapped
// Needed for Capacitor Camera plugins
defineCustomElements(window);

