import { GroupTypeEnum, TaskTypeEnum } from '@enums';
import { BaseTask } from './baseTask';
import { isBefore, isSameDay, parseISO } from 'date-fns';

export class ServiceTask extends BaseTask {
    isInterval: boolean;

    constructor(res) {
      super(res, TaskTypeEnum.SERVICE);

      this.isInterval = !! res.interval;

      if (this.isInterval) {
        this.group = GroupTypeEnum.UPCOMING;
        if (res.is_due) {
            this.group = GroupTypeEnum.OVERDUE;
        } else if (res.time_data?.when_at) {
            const date = parseISO(res.time_data.when_at);
            if (isSameDay(date, new Date())) {
                this.group = GroupTypeEnum.TODAY;
            } else if (isBefore(date, new Date())) {
                this.group = GroupTypeEnum.OVERDUE;
            }
        }

        // Get due
        this.due = this.getDue(res);

        this.name = res?.name || '';
      } else {
        this.group = GroupTypeEnum.TODAY;
        this.name = res.service_interval.name;
      }
    }

    private getDue(res) {
        if (res?.is_smart_interval) {
            let lowestDue = 0;

            // Get lowest due
            res.smart_intervals.forEach(smartInterval => {
                const tmpDue = this.getDue(smartInterval);
                if (tmpDue < lowestDue) {
                    lowestDue = tmpDue;
                }
            });

            return lowestDue;
        } else if (res?.is_odometer) {
            switch (res?.odometer_suffix) {
                case 'h':
                    return res?.interval_value / 24;
                case 's':
                case 'sec':
                    return Math.round((res?.interval_value || 0) / (60 * 60 * 24));
                default:
                    return res?.interval_value;
            }
        } else {
            return Math.round((res?.interval_value || 0) / (60 * 60 * 24));
        }

        return 0;
    }
}
