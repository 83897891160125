import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { INotificationList } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class NotificationsClient {
  constructor(private readonly apiService: ApiService, private readonly routeSrv: RouteService) {}

  public readSystemNotification(id: string): Promise<void> {
    return this.apiService.post(this.routeSrv.makeUrl(this.routeSrv.PATHS.notifications.system.read, { id }));
  }

  public getSystemNotifications(): Promise<INotificationList> {
    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.notifications.system.list));
  }
}
