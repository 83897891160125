import { Pipe, PipeTransform } from '@angular/core';
import { LangService, ResponsiblesClient } from '@services';

@Pipe({
  name: 'groupNameByUid'
})
export class GroupNameByUidPipe implements PipeTransform {
  public constructor(
    private readonly responsiblesClt: ResponsiblesClient,
    private readonly langSrv: LangService
  ) {
  }

  /** Get group name by UID */
  public async transform(uid: string): Promise<string> {
    return this.responsiblesClt
      .getGroup(uid)
      .then(group => group?.name || this.langSrv.t('groups.removedGroup'));
  }
}
