import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, ActivatedRoute } from '@angular/router';
import { AuthService, HelperService, AppInitService } from '@services';
import { UserRoleEnum } from '@enums';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(
    private auth: AuthService,
    private helperService: HelperService,
    private appInitService: AppInitService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.auth.isAuthenticated()
        .then((isLoggedIn) => {
          if (isLoggedIn) {

            this.helperService.waitForAppInit().then(() => {
              const authConfig = route.data.authConfig || {};
              if (!this.checkRoles(authConfig.roles)
                || !this.checkFeatures(authConfig.features)
              ) {
                return resolve(false);
              }

              resolve(true);
            }).catch(error => {
              console.error('auth-guard.service.ts.waitForAppInit() catch error', error);
              console.error('auth-guard.service.ts.waitForAppInit() catch return resolve(false)');
              resolve(false);
            });

          } else {
            let jwt =  this.route.snapshot.queryParams['jwt'] || null;
            if (jwt === null && this.location.path().indexOf('?jwt=') !== -1) {
              jwt = this.location.path().slice(this.location.path().indexOf('?jwt=') + 5);
            }

            if (!jwt) {
              this.router.navigate(['/login']);
            }

            resolve(true);
          }
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  /**
   * Get map between auth roles and user roles
   * @return {any}
   */
  private getRoleMap(): any {
    const roleMap = {};

    roleMap[UserRoleEnum.SUPER_ADMIN] = [
      this.helperService.protocol.roles.SUPER_ADMIN,
    ];

    roleMap[UserRoleEnum.AGENT] = [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT
    ];

    roleMap[UserRoleEnum.ADMIN] = [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT,
      this.helperService.protocol.roles.ADMIN,
    ];

    roleMap[UserRoleEnum.MANAGER] = [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT,
      this.helperService.protocol.roles.ADMIN,
      this.helperService.protocol.roles.MANAGER,
    ];

    roleMap[UserRoleEnum.USER]  = [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT,
      this.helperService.protocol.roles.ADMIN,
      this.helperService.protocol.roles.MANAGER,
      this.helperService.protocol.roles.USER,
    ];

    roleMap[UserRoleEnum.INCIDENT_USER]  = [
      this.helperService.protocol.roles.SUPER_ADMIN,
      this.helperService.protocol.roles.AGENT,
      this.helperService.protocol.roles.ADMIN,
      this.helperService.protocol.roles.MANAGER,
      this.helperService.protocol.roles.USER,
      this.helperService.protocol.roles.INCIDENT_USER,
    ];

    roleMap[UserRoleEnum.PUBLIC_USER] = [
      this.helperService.protocol.roles.PUBLIC_USER
    ];

    return roleMap;
  }

  /**
   * Check current user role
   * @param roles
   * @return {boolean}
   */
  private checkRoles(roles): boolean {
    if (!roles || !roles.length) {
      return true;
    }

    const currentUserRole = this.auth.getCurrentUserRole();
    const roleMap = this.getRoleMap();
    let allowed = true;

    roles.forEach(role => {
      if (roleMap[role] && roleMap[role].indexOf(currentUserRole?.hierarchy) < 0) {
        allowed = false;
      }
    });

    return allowed;
  }

  /**
   * Check allowed features for current user
   * @param features
   * @return {boolean}
   */
  private checkFeatures(features): boolean {
    if (!features || !features.length) {
      return true;
    }

    const allowedFeatures = this.auth.getAllowedFeatures();
    let allowed = true;

    features.forEach((feature) => {
      if (!allowedFeatures[feature]) {
        allowed = false;
      }
    });
    return allowed;
  }
}
