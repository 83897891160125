import { OfflineDataUtil } from '../../utils/offline-data.util';
import { sortBy } from '../../utils/sort.util';
import cloneDeep from 'lodash/cloneDeep';
import pick from 'lodash/pick';
import { NotFoundError } from '../../utils/error.util';
import { isSameDay, isSameWeek, parseISO } from 'date-fns';
/*

{
  collection: '',  // data from local storage
  // OR
  route: '',       // data from other route
  // OR
  method: {},      // data from custom method (deprecated: better use controller+action)
  arguments: [],   // array of arguments for custom method
  // OR
  controller: '',  // data from controller's action
  action: '',

  defaultParams: {},         // default request parameters

  filterBy: {},              // filter array by attributes (or callback function)
  findBy: {},                // find first object by attributes (or callback function)

  beforeProcess: () => {},   // callback function to process data from local storage
  beforeReturn: () => {},    // process data before returning
  beforeUpdate: () => {}     // process data before saving

  isFetching: boolean // Mark not GET request as fetching one to enable its handler and exclude from synchronization
}

*/

export const offlineRoutes = {
  '/protocol': {
    collection: '/protocol'
  },
  '/categories': {
    collection: '/categories'
  },
  '/refilltypes': {
    collection: '/refilltypes',
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      if (params.search || params.query) {
        const searchPhrase = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchPhrase, ['name']);
      }

      return items;
    }
  },
  '/refilltypes/:id': {
    route: '/refilltypes',
    findBy: {
      id: ':id'
    }
  },
  '/refilltypes/:id/documents': {
    route: '/refilltypes/:id',
    select: 'documents'
  },
  '/fuelrefilltypes': {
    collection: '/fuelrefilltypes'
  },
  '/messages': {
    collection: '/messages',
    defaultParams: { per_page: 10 }
  },
  '/objecttypes/categories': {
    collection: '/objecttypes/categories'
  },
  '/workplaces/list': { // used to get all workplaces, even not synced
    collection: '/workplaces/list'
  },
  '/workplaces': {      // used to get only synced workplaces
    method: 'getWorkplaces'
  },
  '/workplaces/:id': {
    route: '/workplaces',
    findBy: {
      id: ':id'
    }
  },
  '/workplaces/:id/documents': {
    route: '/workplaces/:id',
    select: 'documents'
  },
  '/workplaces/:id/permissibles': {
    method: 'getPermissibles',
    arguments: ['Workplace']
  },
  '/sections/:id/permissibles': {
    method: 'getPermissibles',
    arguments: ['Section']
  },
  '/users/me': {
    collection: '/users/me'
  },
  '/users/clones': {
    collection: '/users/clones'
  },
  '/users/me/company/documents': {
    collection: '/users/me/company/documents'
  },
  '/users/me/groups': {
    collection: '/users/me/groups'
  },
  '/users': {
    collection: '/groupsandusers/permissibles',
    beforeProcess: (items, params) => {
      items = items.filter(item => {
        if (item.type !== 0) {
          return false;
        }

        return true;
      });

      if (params.search || params.query) {
        const searchPhrase = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchPhrase, ['name']);
      }

      return items;
    }
  },
  '/groups/search': {
    collection: '/groupsandusers/permissibles',
    beforeProcess: (items, params) => {
      items = items.filter(item => {
        if (item.type !== 1) {
          return false;
        }

        return true;
      });

      if (params.search || params.query) {
        const searchPhrase = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchPhrase, ['name']);
      }

      return items;
    }
  },
  '/groupsandusers/search': {
    method: 'getGroupsAndUsers'
  },

  '/objects': {
    method: 'getObjects'
  },
  '/units/list': {
    collection: '/units/list',
    defaultParams: { per_page: 10 }
  },
  '/units': {
    collection: '/units/list',
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      if (params.search || params.query) {
        const searchPhrase = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchPhrase, ['name']);
      }

      return items;
    }
  },
  '/units/:id': {
    collection: '/units/list',
    beforeProcess: (items, params, route) => {
      const field = isNaN(route.params[':id']) ? 'uid' : 'id';
      return items.find(item => item[field] === route.params[':id']);
    }
  },
  '/units/:id/positions': {
    route: '/units/:id',
    select: 'positions'
  },
  '/units/:id/statistics': {
    route: '/units/:id',
    select: 'statistics'
  },
  '/units/:id/inspectionintervals': {
    route: '/units/:id',
    select: 'inspection_intervals'
  },
  '/units/:id/inspectionintervals/:iid': {
    route: '/units/:id/inspectionintervals',
    findBy: {
      id: ':iid'
    }
  },
  '/units/:id/allservicedates': {
    route: '/units/:id',
    select: 'servicesCountdowns'
  },
  '/units/:id/categoryrefilltypes': {
    route: '/units/:id',
    select: 'category_refill_types'
  },
  '/units/:id/refills/latest': {
    route: '/units/:id',
    select: 'latestRefill'
  },
  '/units/:id/odometers': {
    route: '/units/:id',
    select: 'odometers'
  },
  '/units/:id/checklistvariants': {
    route: '/units/:id',
    select: 'checklistDetails'
  },
  '/units/:uid/checklistvariants/:id': {
    method: 'getObjectChecklistVariant',
    arguments: ['Unit']
  },
  '/units/:id/incidents': {
    method: 'getObjectIncidents',
    arguments: ['Unit']
  },
  '/units/:id/inspections': {
    route: '/units/:id',
    select: 'lastInspections'
  },
  '/units/:id/services/search': {
    method: 'getUnitServices'
  },
  '/units/:id/suffixesstatistics': {
    route: '/units/:id',
    select: 'suffixesStatistics' // TODO: implement this on API side
  },
  '/serviceintervals/:id/all': {
    route: '/units/:id',
    select: 'servicesCountdowns'
  },
  '/serviceintervals/:id/:iid': {
    route: '/serviceintervals/:id/all',
    beforeProcess: (items, params, route) => {
      let serviceInterval = null;
      items.forEach(item => {
        if (item.id === route.params[':iid']) {
          serviceInterval = item.interval;
          serviceInterval.due_data = pick(item, ['interval_value', 'is_due', 'is_odometer', 'odometer_suffix', 'value']);
          return;
        }
      });
      return serviceInterval;
    }
  },
  '/services/batches': {
    isFetching: true,
  },
  '/services/:id?': {
    collection: '/units/list',
    beforeProcess: (items, params, route) => {
      let service = null;
      items.forEach(unit => {
        (unit.services || []).forEach(unitService => {
          if (unitService.id === route.params[':id']) {
            service = cloneDeep(unitService);
            service.unit = unit;
            return;
          }
        });
      });
      if (!service) {
        throw new NotFoundError();
      }
      return service;
    },
    onPost: {
      method: 'updateServiceCollection',
      arguments: ['post']
    },
    onPut: {
      method: 'updateServiceCollection',
      arguments: ['put']
    }
  },
  '/services/:id/refills': {
    route: '/services/:id',
    select: 'refills'
  },
  '/services/:id/refills/:rid': {
    onDelete: {
      method: 'removeServiceRefill',
    }
  },
  '/services/:id/articles': {
    route: '/services/:id',
    select: 'article_usages'
  },
  '/services/:id/articles/:aid': {
    onDelete: {
      method: 'removeServiceArticle',
    }
  },

  '/units/:id/documents': {
    route: '/units/:id',
    select: 'documents'
  },
  '/units/:id/articles': {
    collection: '/articles',
    beforeProcess: (items, params, route) => {
      return items.filter(item => {
        if (!item.units || !item.units.length) {
          return false;
        }

        let found = false;
        item.units.forEach(unit => {
          if (unit.id === route.params[':id']) {
            found = true;
          }
        });
        return found;
      });
    }
  },
  '/units/:id/deviations/all/active': {
    collection: '/deviations/query/active',
    filterBy: {
      objectable_id: ':id',
      objectable_type: 'App\\Models\\Unit'
    }
  },
  '/units/:id/categories': {
    method: 'getObjectCategories',
    arguments: ['Unit']
  },

  '/rounds/list': {
    collection: '/rounds/list',
    defaultParams: { per_page: 10 }
  },
  '/rounds': {
    collection: '/rounds/list',
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      if (params.search || params.query) {
        const searchPhrase = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchPhrase, ['name']);
      }

      return items;
    }
  },
  '/rounds/:id': {
    collection: '/rounds/list',
    beforeProcess: (items, params, route) => {
      const field = isNaN(route.params[':id']) ? 'uid' : 'id';
      return items.find(item => item[field] === route.params[':id']);
    }
  },
  '/rounds/:id/positions': {
    route: '/rounds/:id',
    select: 'positions'
  },
  '/rounds/:id/statistics': {
    route: '/rounds/:id',
    select: 'statistics'
  },
  '/rounds/:id/checklistvariants': {
    route: '/rounds/:id',
    select: 'checklistDetails'
  },
  '/rounds/:uid/checklistvariants/:id': {
    method: 'getObjectChecklistVariant',
    arguments: ['Round']
  },
  '/rounds/:id/inspectionintervals': {
    route: '/rounds/:id',
    select: 'inspection_intervals'
  },
  '/rounds/:id/inspectionintervals/:iid': {
    route: '/rounds/:id/inspectionintervals',
    findBy: {
      id: ':iid'
    }
  },
  '/rounds/:id/incidents': {
    method: 'getObjectIncidents',
    arguments: ['Round']
  },
  '/rounds/:id/inspections': {
    route: '/rounds/:id',
    select: 'lastInspections'
  },
  '/rounds/:id/documents': {
    route: '/rounds/:id',
    select: 'documents'
  },
  '/rounds/:id/deviations/all/active': {
    collection: '/deviations/query/active',
    filterBy: {
      objectable_id: ':id',
      objectable_type: 'App\\Models\\Round'
    }
  },
  '/rounds/:id/categories': {
    method: 'getObjectCategories',
    arguments: ['Round']
  },

  '/deviations/query/all': {
    collection: '/deviations/query/active',
    defaultParams: { per_page: 10 },
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      const workplaces = OfflineDataUtil.convertToArray(params.workplaces);
      const sections = OfflineDataUtil.convertToArray(params.sections);
      const units = OfflineDataUtil.convertToArray(params.units);
      const rounds = OfflineDataUtil.convertToArray(params.rounds);
      const statuses = OfflineDataUtil.convertToArray(params.statuses);
      const categories = OfflineDataUtil.convertToArray(params.categories);
      const due = params.due;
      const taskMemberUsers = OfflineDataUtil.convertToArray(params.taskMemberUsers);
      const taskMemberGroups = OfflineDataUtil.convertToArray(params.taskMemberGroups);
      const tags = OfflineDataUtil.convertToArray(params.tags);

      items = items.filter(item => {
        if (workplaces && workplaces.indexOf(item.deviationable.workplace_id) < 0) {
          return false;
        }

        if (sections && sections.length) {
          let objectSectionId = 0;
          if (item.hasOwnProperty('section_id')) {
            objectSectionId = item.section_id;
          } else if (item.deviationable.objectable && item.deviationable.objectable.section && item.deviationable.objectable.section.length) {
            objectSectionId = item.deviationable.objectable.section[0].id;
          }
          if (sections.indexOf(objectSectionId) < 0) {
            return false;
          }
        }

        if (units && units.length) {
          if (!item.deviationable.objectable_type || item.deviationable.objectable_type.indexOf('Unit') < 0 || units.indexOf(item.deviationable.objectable_id) < 0) {
            return false;
          }
        }

        if (rounds && rounds.length) {
          if (!item.deviationable.objectable_type || item.deviationable.objectable_type.indexOf('Round') < 0 || rounds.indexOf(item.deviationable.objectable_id) < 0) {
            return false;
          }
        }

        if (statuses && statuses.length && statuses.indexOf(item.deviationable.status) < 0) {
          return false;
        }

        if (categories && categories.length && categories.indexOf(item.deviationable.category_id) < 0) {
          return false;
        }

        if (due) {
          if (due === 'no_due_date' && item.deviationable.due_date !== null) {
            return false;
          } else if (due === 'today' && isSameDay(parseISO(item.deviationable.due_date), new Date()) === false) {
            return false;
          } else if (due === 'this_week' && isSameWeek(parseISO(item.deviationable.due_date), new Date()) === false) {
            return false;
          }
        }

        if (taskMemberUsers || taskMemberGroups) {
          if (!item.deviationable.task || !item.deviationable.task.task_members.length) {
            return false;
          }

          const found = item.deviationable.task.task_members.find(taskMember => {
            if (taskMember.member_type === 'User') {
              if (taskMemberUsers && taskMemberUsers.indexOf(taskMember.member_id) >= 0) {
                return true;
              }
            } else {
              if (taskMemberGroups && taskMemberGroups.indexOf(taskMember.member_id) >= 0) {
                return true;
              }
            }

            return false;
          });

          if (!found) {
            return false;
          }
        }

        if (tags && tags.length) {
          if (!item.deviationable.tags) {
            return false;
          }
          const found = item.deviationable.tags.find(tag => tags.indexOf(tag.id) >= 0);
          if (!found) {
            return false;
          }
        }

        return true;
      });

      if (params.search || params.query) {
        const searchPhrase = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchPhrase, ['deviation_serial', 'deviation_title']);
      }

      if (params.sortProperty) {
        items = sortBy(items, params.sortProperty, params.sortDirection === 'desc');
      }

      return items;
    },
  },

  '/deviations/query/active': {
    collection: '/deviations/query/active',
    defaultParams: { per_page: 10 },
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      const workplaces = OfflineDataUtil.convertToArray(params.workplaces);
      const sections = OfflineDataUtil.convertToArray(params.sections);
      const units = OfflineDataUtil.convertToArray(params.units);
      const rounds = OfflineDataUtil.convertToArray(params.rounds);

      items = items.filter(item => {
        if (workplaces && workplaces.indexOf(item.workplace_id) < 0) {
          return false;
        }

        if (sections && sections.indexOf(item.section_id) < 0) {
          return false;
        }

        if (units) {
          if (!item.objectable_type || item.objectable_type.indexOf('Unit') < 0 || units.indexOf(item.objectable_id) < 0) {
            return false;
          }
        }

        if (rounds) {
          if (!item.objectable_type || item.objectable_type.indexOf('Round') < 0 || rounds.indexOf(item.objectable_id) < 0) {
            return false;
          }
        }

        return true;
      });

      if (params.search) {
        items = OfflineDataUtil.filterBySearch(items, params.search, ['serial_number', 'label']);
      }

      if (params.sortProperty) {
        items = sortBy(items, params.sortProperty, params.sortDirection === 'desc');
      }

      return items;
    },
  },
  '/deviations/batches': {
    controller: 'DeviationsController',
    action: 'getDeviationsBatch',
    isFetching: true,
  },
  '/deviations/:id?': {
    collection: '/deviations/query/active',
    findBy: {
      deviationable_type: 'App\\Models\\Deviation',
      deviationable_id: ':id'
    },
    beforeReturn: (item) => {
      return item.deviationable || null;
    },
    onPost: {
      method: 'updateDeviationCollection',
      arguments: ['post', 'Deviation']
    },
    onPut: {
      method: 'updateDeviationCollection',
      arguments: ['put', 'Deviation']
    }
  },
  '/deviations/:id/refills': {
    route: '/deviations/:id',
    select: 'refills'
  },
  '/deviations/:id/refills/:rid': {
    onDelete: {
      method: 'removeDeviationRefill',
      arguments: ['Deviation']
    }
  },
  '/deviations/:id/articles': {
    route: '/deviations/:id',
    select: 'article_usages'
  },
  '/deviations/:id/articles/:aid': {
    onDelete: {
      method: 'removeDeviationArticle',
      arguments: ['Deviation']
    }
  },
  '/independentdeviations/batches': {
    controller: 'DeviationsController',
    action: 'getIndDeviationsBatch',
    isFetching: true,
  },
  '/independentdeviations/:id?': {
    collection: '/deviations/query/active',
    findBy: {
      deviationable_type: 'App\\Models\\IndependentDeviation',
      deviationable_id: ':id'
    },
    beforeReturn: (item) => {
      return item.deviationable || null;
    },
    onPost: {
      method: 'updateDeviationCollection',
      arguments: ['post', 'IndependentDeviation']
    },
    onPut: {
      method: 'updateDeviationCollection',
      arguments: ['put', 'IndependentDeviation']
    }
  },
  '/independentdeviations/:id/refills': {
    route: '/independentdeviations/:id',
    select: 'refills'
  },
  '/independentdeviations/:id/refills/:rid': {
    onDelete: {
      method: 'removeDeviationRefill',
      arguments: ['IndependentDeviation']
    }
  },
  '/independentdeviations/:id/articles': {
    route: '/independentdeviations/:id',
    select: 'article_usages'
  },
  '/independentdeviations/:id/articles/:aid': {
    onDelete: {
      method: 'removeDeviationArticle',
      arguments: ['IndependentDeviation']
    }
  },

  '/incidents/categories': {
    collection: '/incidents/categories',
  },
  '/incidents/types': {
    collection: '/incidents/types',
  },
  '/incidenttemplates': {
    collection: '/incidenttemplates',
  },
  '/incidenttemplates/:id': {
    collection: '/incidenttemplates',
    findBy: {
      id: ':id'
    }
  },
  '/incidents/me': {
    method: 'getMyIncidents'
  },
  '/incidents': {
    collection: '/incidents',
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      const workplaces = OfflineDataUtil.convertToArray(params.workplaces);
      const sections = OfflineDataUtil.convertToArray(params.sections);

      items = items.filter(item => {
        if (workplaces && item.placeable_type === 'App\\Models\\Workplace' && workplaces.indexOf(item.placeable_id) < 0) {
          return false;
        }

        if (sections && item.placeable_type === 'App\\Models\\Section' && sections.indexOf(item.placeable_id) < 0) {
          return false;
        }

        return true;
      });

      if (params.search || params.query) {
        const searchTerm = params.search || params.query;
        items = OfflineDataUtil.filterBySearch(items, searchTerm, ['serial_number', 'name']);
      }

      if (params.sortProperty) {
        items = sortBy(items, params.sortProperty, params.sortDirection === 'desc');
      }

      return items;
    },
    onPost: {
      method: 'saveIncident'
    },
  },

  '/articles': {
    collection: '/articles',
    beforeProcess: (items, params) => {
      if (!params) {
        return items;
      }

      const categories = params.categories || null;
      if (categories && categories.length) {
        items = items.filter(item => {
          if (!item.categories || !item.categories.length) {
            return false;
          }

          let found = false;
          item.categories.forEach(category => {
            if (params.categories.indexOf(category.id) >= 0) {
              found = true;
            }
          });

          return found;
        });
      }

      if (params.query) {
        items = OfflineDataUtil.filterBySearch(items, params.query, ['name', 'article_no', 'keywords']);
      }

      // if (params.sortProperty) {
      //   items = sortBy(items, params.sortProperty, params.sortDirection === 'desc');
      // }

      return items;
    }
  },
  '/checklistvariants/:id': {
    collection: '/checklistvariants',
    findBy: {
      id: ':id'
    }
  },
  '/checks': {
    collection: '/checks'
  },
  '/checks/:id': {
    collection: '/checksongoing',
    findBy: {
      id: ':id'
    }
  },
  '/checksongoing': {
    collection: '/checksongoing',
    method: 'getOngoingChecks',
    onPost: {
      method: 'updateCheckCollection',
      arguments: ['post']
    },
  },
  '/checksongoing/:id': {
    collection: '/checksongoing',
    findBy: {
      id: ':id'
    },
    onPut: {
      method: 'updateCheckCollection',
      arguments: ['put']
    },
    onDelete: {
      method: 'removeCheckOngoing'
    }
  },

  '/assets/uid/:uid': {
    method: 'getAssetByUid',
    onDelete: {
      method: 'removeAssetByUid'
    }
  },
  '/assets/:id': {
    collection: '/assets',
    findBy: {
      id: ':id'
    }
  },
  '/assets': {
    collection: '/assets',
    onPost: {
      method: 'uploadAsset'
    },
  },
  '/inspections/:id': {
    method: 'getInspection',
  },
  '/tags': {
    collection: '/tags'
  },
  '/externals': {
    collection: '/externals'
  },

  // user's tasks
  '/users/me/tasks/checks': {
    controller: 'TasksController',
    action: 'getChecks'
  },
  '/users/me/tasks/services/upcoming': {
    controller: 'TasksController',
    action: 'getServices'
  },
  '/users/me/tasks/services/ongoing': {
    controller: 'TasksController',
    action: 'getOngoingServices'
  },
  '/users/me/tasks/deviations': {
    controller: 'TasksController',
    action: 'getDeviations'
  },
  '/users/me/settings': {
    collection: '/users/me/settings',
    onPut: {
      controller: 'AppSettingsController',
      action: 'setAppPreferences',
    }
  },
  '/worktimes/categories': {
    collection: '/worktimes/categories'
  },
  '/rfid/tags/': {
    collection: '/rfid/tags/'
  },
  '/rfid/unit/:id': {
    controller: 'RfidTagsController',
    action: 'getUnitRfidTags'
  },
  '/rfid/round/:id': {
    controller: 'RfidTagsController',
    action: 'getRoundRfidTags'
  },
  '/rfid/tags/:tagId/units/:unitId/categories': {
    controller: 'RfidTagsController',
    action: 'getUnitCategories'
  },
  '/rfid/tags/:tagId/rounds/:unitId/categories': {
    controller: 'RfidTagsController',
    action: 'getRoundCategories'
  },
  '/rfid/tags/:tagId/units/:unitId/positions': {
    controller: 'RfidTagsController',
    action: 'getUnitPositions'
  },
  '/rfid/tags/:tagId/rounds/:unitId/positions': {
    controller: 'RfidTagsController',
    action: 'getRoundPositions'
  },
  // Seems to be route from deleted component from UI
  // '/rfid/tags/units/:id/checklistcontrolpoints': {
  //   controller: 'RfidTagsController',
  //   action: 'getChecklistControlPoints'
  // },
  // '/rfid/tags/:id/deviations': {
  //   controller: 'RfidTagsController',
  //   action: 'getDeviations'
  // },
  '/formtemplates/default/type/:type': {
    collection: '/formtemplates/defaults',
    findBy: {
      type: ':type'
    }
  },
  '/formtemplates/:id': {
    collection: '/formtemplates',
    findBy: {
      id: ':id'
    }
  },
  '/units/:id/independent-deviations/formtemplates': {
    controller: 'FormTemplatesController',
    action: 'getUnitTemplates'
  },
  '/rounds/:id/independent-deviations/formtemplates': {
    controller: 'FormTemplatesController',
    action: 'getRoundTemplates'
  },
  '/checklists/deviation/:id': {
    controller: 'ChecklistsController',
    action: 'getDeviation'
  },
  '/checklist-variants/batches': {
    controller: 'ChecklistsController',
    action: 'getChecklistVariantsBatch',
    isFetching: true,
  },
  '/serviceintervals/batches': {
    controller: 'ServiceIntervalsController',
    action: 'getServiceIntervalsBatch',
  },
  '/checks/batches': {
    isFetching: true,
  },
  '/positions/batches': {
    controller: 'PositionsController',
    action: 'getPositionsBatch',
    isFetching: true,
  },
};
