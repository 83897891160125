import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { DialogComponent } from '../modals/dialog/dialog.component';
import { LangService } from './lang.service';
import { EventsService } from './events.service';
import { OdometerConfirmationComponent } from '../modals/odometer-confirmation/odometer-confirmation.component';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private backButtonSubscription = null;

  constructor(
    private popoverController: PopoverController,
    private platform: Platform,
    private lang: LangService,
    private events: EventsService,
  ) {
    this.events.subscribe('api:unauthenticated', () => {
      this.close();
    });
  }

  /**
   * Open modal window
   * @param options
   * @return {Promise<any>}
   */
  public open(options: any): Promise<any> {
    const defaultOptions = {
      cssClass: 'popover-modal',
      backdropDismiss: true,
      keyboardClose: true,
      verticalAlignCenter: true,
      mode: 'ios',
      noPadding: false,
      confirmationWarning: false
    };

    const popoverOptions = Object.assign(defaultOptions, options);

    if (popoverOptions.verticalAlignCenter) {
      popoverOptions.cssClass += ' popover-modal-vertical-center';
    }

    if (popoverOptions.noPadding) {
      popoverOptions.cssClass += ' popover-modal-no-padding';
    }

    if (popoverOptions.confirmationWarning) {
      popoverOptions.cssClass += ' popover-modal-confirmation-warning';
    }

    if (popoverOptions.hasOwnProperty('backdropDismiss') && !popoverOptions.backdropDismiss) {
      this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(11, () => {
        return;
      });
    }

    document.getElementsByTagName('ion-app').item(0).classList.add('disable-scroll');

    return this.popoverController.create(popoverOptions)
      .then(async (popover) => {
        await popover.present();
        return popover;
      });
  }

  /**
   * Close top modal window
   * @return {Promise<any>}
   */
  public close(): Promise<any> {
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }

    if (document.getElementsByTagName('ion-app').item(0).classList.contains('disable-scroll')) {
      document.getElementsByTagName('ion-app').item(0).classList.remove('disable-scroll');
    }

    return this.popoverController.getTop().then(popover => {
      if (popover) {
        return popover.dismiss(null);
      }
    });
  }

  /**
   * Show dialog modal
   * @param options
   * @param backdropDismiss
   * @return {Promise<any>}
   */
  public async showDialog(options, backdropDismiss: boolean = true): Promise<any> {
    return new Promise(async (resolve) => {
      if (options.buttons) {
        options.buttons.forEach(button => {
          button.handler = () => {
            resolve(button.value);
            this.close();
          };
        });
      }
      options.closeHandler = () => {
        resolve(false);
        this.close();
      };

      const popover = await this.open({
        component: DialogComponent,
        componentProps: options,
        backdropDismiss: backdropDismiss,
      });
      popover.onDidDismiss().then(() => {
        resolve(false);
      });
    });
  }

  /**
   * Show dialog modal when leaving a page
   * @return {Promise<any>}
   */
  public async showLeavePageDialog() {
    return await this.showDialog({
      title: this.lang.t('general.unsavedChangesWillBeLost'),
      showCloseIcon: true,
      buttons: [
        {
          text: this.lang.t('actions.cancel'),
          iconClass: 'fal fa-times',
          value: false,
        },
        {
          text: this.lang.t('general.ok'),
          iconClass: 'fal fa-check-circle color-success',
          value: true,
        }
      ]
    });
  }

  public async showDialogMessage(title, message = '') {
    return new Promise(async (resolve) => {
      const options = {
        title: title,
        message: message,
        buttons: [
          {
            text: this.lang.t('general.ok'),
            iconClass: 'fal fa-check-circle color-success',
            handler: () => {
              resolve(true);
              this.close();
            }
          }
        ]
      };

      await this.open({
        component: DialogComponent,
        componentProps: options,
        backdropDismiss: false
      });
    });
  }

  public async showOdometerConfirmation(currentOdometerValue: number, suffix: string, newOdometerValue: string, message: string, confirmOdometerValue: string): Promise<boolean> {
    return new Promise(async (resolve) => {
      const options = {
        headerTitle: this.lang.t('units.warning'),
        message: message,
        confirmOdometerValue: confirmOdometerValue,
        currentValue: currentOdometerValue,
        newValue: newOdometerValue,
        suffix: suffix,
        buttons: [
          {
            text: this.lang.t('general.no'),
            iconClass: 'fal fa-times',
            handler: () => {
              resolve(false);
              this.close();
            }
          },
          {
            text: this.lang.t('general.yes'),
            iconClass: 'fal fa-check-circle color-success',
            handler: () => {
              resolve(true);
              this.close();
            }
          }
        ]
      };

      await this.open({
        component: OdometerConfirmationComponent,
        componentProps: options,
        backdropDismiss: false,
        confirmationWarning: true,
      });
    });
  }
}
