/**
 * Translation pipe passing translation key and any replacement data to translation service.
 * Will return translation string if found, else original key.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { LangService } from '@services';

@Pipe({
  name: 'lang'
})
export class LangPipe implements PipeTransform {
  constructor(
    private langService: LangService
  ) {
  }

  transform(key: string, replacements: any = {}) {
    replacements = typeof replacements === 'object' ? replacements : {};

    return this.langService.t(key, replacements);
  }
}
