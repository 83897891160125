import { BaseTask } from './baseTask';
import { TaskTypeEnum } from '@enums';
import {getHours} from 'date-fns';

export class CheckTask extends BaseTask {
    objectable: any;
    dueSort: any;
    variant: any;
    ongoing: any;
    due_unit: string;
    isTodayInterval: boolean;

    constructor(res) {
        super(res, TaskTypeEnum.CHECK);
        const due_data = res?.variant?.due_data;
        switch (due_data?.due_unit) {
            case 'h':
                this.due = due_data?.due / 24;
                break;
            case 's':
            case 'sec':
                this.due = (due_data?.due || 0) / (60 * 60 * 24);
                break;
            default:
                this.due = due_data?.due;
        }
        this.name = res?.variant?.name || '';

        if (due_data?.active && !due_data?.is_due && !due_data?.no_check_done) {
            const now = getHours(new Date()) / 24;
            this.isTodayInterval = now + this.due < 1;
        }
    }
}
