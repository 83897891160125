import { ApiService } from '../api.service';
import { HelperService } from '../helper.service';
import { OfflineHandlerService } from './handler.service';
import { OfflineRequestBase } from './requests/base';
import { OfflineRequestCheck } from './requests/check';

export class RequestsHandler {
  constructor(
    public apiService: ApiService,
    public helperService: HelperService,
    public offlineHandlerService: OfflineHandlerService
  ) {}

  /**
   * Execute request
   * @param request
   * @return {any}
   */
  public execute(request) {
    const instance = this.getInstance(request);
    const promise = instance.execute(request);

    if (!promise) {
      return Promise.resolve();
    }

    return promise
      .then(async (res) => {
        // if everything is fine - remove request from local storage
        await this.offlineHandlerService.removeRequestToSync(request.id);
        return res;
      }).catch((err) => {
        let message = 'undefined_error';
        let code = null;
        if (err && err.status) {
          code = err.status;

          if (err.status === 500) {
            message = 'server_error';
          } else if (err.status === 401 || err.status === 403) {
            message = 'no_permissions';
          } else if (err.status === 404) {
            message = 'no_resource';
          } else if (this.apiService.isBadNetworkErrorCode(err.status)) {
            message = 'bad_network';
          }
        }

        this.offlineHandlerService.setRequestParameter(request.id, 'error', {
          code: code,
          message: 'offline.sync-errors.' + message
        });

        throw err;
      });
  }

  /**
   * Get instance of class based on request url
   * @param request
   * @return {any}
   */
  private getInstance(request) {
    if (request.url && request.url.indexOf('/checksongoing') === 0) {
      return new OfflineRequestCheck(this);
    } else {
      return new OfflineRequestBase(this);
    }
  }
}
