import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { ObjectTypeEnum } from '@enums';
import { ITask, IInspectionInterval, IPaginationResponse } from '@interfaces';
import { RouteService } from '../../../route.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionsCertificatesClient {

  constructor(
    private readonly apiService: ApiService,
    private readonly routeSrv: RouteService
  ) {}

  public getUpcomingInspectionsIntervals(
    objectID: string | number,
    objectType: ObjectTypeEnum
  ): Promise<IInspectionInterval[]> {
    return this.apiService.get(this.routeSrv.makeUrl(
      this.routeSrv.PATHS.inspectionsCertificates.intervals,
      {
        objectType,
        objectID
      }
    ));
  }

  public getPerformedInspections(
    objectID: string | number,
    objectType: ObjectTypeEnum,
    pageSize: number,
    page = 1,
  ): Promise<IPaginationResponse> {
    return this.apiService.get(
      this.routeSrv.makeUrl(
        this.routeSrv.PATHS.inspectionsCertificates.performed,
        {
          objectType,
          objectID
        }
      ), {
        per_page: pageSize,
        page
      }
    );
  }

  public updatePlannerToolDueDatesForInspections(
    inspections: IInspectionInterval[],
    plannerToolTasks: ITask[]
  ): IInspectionInterval[] {
    inspections.forEach((inspection)=> {
      const found = plannerToolTasks.find(task => task.origin.id === inspection.uid);

      if (found && 'dateTime_data' in found) {
        inspection.dateTime_data = found.dateTime_data;
      }
    });

    return inspections;
  }
}
