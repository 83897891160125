import { Injectable } from '@angular/core';
import { TasksService } from '../tasks/tasks.service';
import { UnitService } from '../../unit.service';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { IUnit, ITask, IInspectionInterval } from '@interfaces';
import SearchFilterBuilder from '../tasks/search-filter-builder/search-filter.builder';
import { ObjectTypeEnum } from '@enums';
import { InspectionsCertificatesClient } from '../../clients/cp-api/inspections-certificates/inspections-certificates.client';
import { ApiService } from "../../api.service";
import { RouteService } from '../../route.service';

@Injectable({
  providedIn: 'root'
})
export class InspectionsTasksService {
  constructor(
    private readonly apiService: ApiService,
    private readonly tasksSearch: SearchFilterBuilder,
    private readonly tasksSrv: TasksService,
    private readonly unitsSrv: UnitService,
    private readonly inspectionsClient: InspectionsCertificatesClient,
    private routeSrv: RouteService,
  ) {}

  public fetchInspectionsIntervalsWithPTDueDates(
    objectID: number,
    objectType: ObjectTypeEnum,
    pageSize?: number
  ): Observable<IInspectionInterval[]> {
    return this.fetchTasks(objectID, objectType, pageSize)
      .pipe(
        mergeMap(tasks =>
          from(this.inspectionsClient.getUpcomingInspectionsIntervals(objectID, objectType))
          .pipe(
            map(inspections =>
              this.inspectionsClient.updatePlannerToolDueDatesForInspections(inspections, tasks)
            )
          )
        )
      );
  }

  private fetchTasks(objectID: number, objectType: ObjectTypeEnum, pageSize?: number): Observable<ITask[]> {
    return (objectType === ObjectTypeEnum.UNIT ? this.fetchUnit(objectID) : this.fetchRound(objectID))
      .pipe(
        mergeMap(
          object => this.tasksSrv
            .requireInspections(object.uid, pageSize)
            .fetchAll()
        ),
        map(page => page.data)
      );
  }

  private fetchUnit(unitId: number): Observable<IUnit> {
    return from(this.unitsSrv.getUnit(unitId));
  }

  // TODO: Move request to a client
  private fetchRound(roundId: number): Observable<IUnit> {
    return from(this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.rounds.round, { id: roundId})));
  }
}
