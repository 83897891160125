import { TaskStatusEnum, TaskTypeEnum } from '@enums';
import SensorType from '../../../../../types/sensor.type';
import { IWorkplace, IPositionShort, IExtTask, IIntervalDueTrigger, ICompany, IDeviationShort, IChecklistVariantShort, ITaskRelationsSet,
  IServiceIntervalShort, IServiceShort, ICheckShort, IDateTimeDue, IOdometerDue, ITimeEstimate, IObject, IOrigin, ISection, ITaskResponsible } from '@interfaces';

export default class TaskModel implements IExtTask {

  /** @see IExtTask */
  // Attributes
  public id: string;
  public name: string;
  public type: TaskTypeEnum;
  public status: TaskStatusEnum;
  public ongoing: boolean;
  public all_day?: boolean;
  public checklist_type?: number;
  // Relation identifiers
  public parent_id: string;
  public ongoing_ids?: string[];
  public connection_id?: string;
  public interval_due_trigger_id?: string;
  // Relations
  public objectable: IObject;
  public workplace: IWorkplace;
  public sections: ISection[];
  public origin: IOrigin;
  public relations: ITaskRelationsSet;
  public time_estimate?: ITimeEstimate;
  public responsibles?: ITaskResponsible[];
  public dateTime_data?: IDateTimeDue;
  public odometer_data?: IOdometerDue;

  public constructor(
    data: IExtTask
  ) {
    Object.assign(this, data)
  }

  /** Attributes >>> */

  public get isActive(): boolean {
    return this.status !== TaskStatusEnum.INACTIVE
  }

  /** <<< Attributes */

  /** Direct relations >>> */

  public get company(): ICompany {
    return this.relations.company;
  }

  public get position(): IPositionShort | null {
    return this.relations.position ?? null;
  }

  public get deviation(): IDeviationShort | null {
    return this.relations.deviation ?? null;
  }

  public get checkInterval(): IChecklistVariantShort | null {
    return this.relations.checkInterval;
  }

  public get serviceInterval(): IServiceIntervalShort | null {
    return this.relations.serviceInterval ?? null;
  }

  public get ongoingServices(): IServiceShort[] {
    return this.relations.ongoingServices ?? [];
  }

  public get ongoingChecks(): ICheckShort[] {
    return this.relations.ongoingChecks ?? [];
  }

  /** <<< Direct relations */

  /** Interval Due Trigger of the task */
  public get dueTrigger(): IIntervalDueTrigger | null {
    return this.interval_due_trigger_id
      ? this.dueTriggers
      .find(trigger => trigger.uid === this.interval_due_trigger_id) || null
      : null;
  }

  /** Sensor which the Task was triggered by */
  public get sensor(): SensorType | null {
    return this.dueTrigger
      ?.external
      ?.device
      .sensor ?? null;
  }

  /** All Interval Due Triggers */
  private get dueTriggers(): IIntervalDueTrigger[] {
    switch (this.type) {
      case  TaskTypeEnum.CHECKLIST:
        return (this.relations?.checkInterval?.due_items || [])
          .reduce(
            (triggers, dueItem) => [
              ...triggers,
              ...dueItem.interval_due_triggers || []
            ],
            []
          );
      case TaskTypeEnum.SERVICE:
        return this.relations?.serviceInterval?.interval_due_triggers || [];
      default:
        return [];
    }
  }
}
