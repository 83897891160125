<div class="ion-margin-bottom warning-header-container">
    <i class="fa-solid fa-triangle-exclamation"></i> <p>{{ headerTitle }}</p>
</div>

<div class="ion-text-center">
  <div class="ion-margin-horizontal" [innerHtml]="message" *ngIf="message"></div>
  <div class="value-container ion-margin-vertical">
    <p>{{ enteredValueText }} <span class="bold entered-value">{{ newValueWithSuffix }}</span></p>
    <p>{{ currentValueText }} <span class="bold">{{ currentValueWithSuffix }}</span></p>
  </div>
  <div class="ion-margin-top">
    <p class="ion-margin-bottom bold">{{ confirmOdometerValue }}</p>
  </div>
  <div class="ion-margin-bottom">
    <template #container></template>
  </div>
  <div *ngIf="showSpinner" class="ion-text-center scan-loader"><ion-spinner name="crescent"></ion-spinner></div>

  <div class="ion-margin-horizontal">
    <ion-grid fixed>
      <ion-row>
        <ion-col *ngFor="let button of buttons">
          <app-button
            [attr.expand]="buttonsExpand"
            [iconClass]="button.iconClass"
            [iconAlign]="button.iconAlign || LEFT"
            [disabled]="button.disabled"
            (onClick)="onButtonClick(button)">{{button.text}}</app-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>
