<div class="home-widget-background"></div>

<div *ngIf="isVisible" class="home-widget" #homeWidget [ngClass]="{'invisible': !isOpen && keyboardService.isOpen}">
  <div class="home-widget-button-container" >
    <div class="home-widget-button" (click)="openHomePage()">
      <i class="far fa-home"></i>
    </div>
    <div class="home-widget-button-background" (click)="openHomePage()"></div>
  </div>

  <div class="home-widget-button-bottom-block">
    <app-offline-banner type="bottomBanner"></app-offline-banner>
  </div>

  <!-- uncomment it when home screen overlay will be enabled again -->
  <!--<div class="home-widget-content">
    <app-home-screen type="widget"></app-home-screen>
  </div>-->
</div>
