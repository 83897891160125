import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ICategoryRefillType } from '@interfaces';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root',
})
export class CategoryRefillTypesService {
  /**
   * @param api Api service.
   */
  constructor(private api: ApiService, private routeSrv: RouteService) {}

  /**
   * Fetch all category refill types.
   */
  public getCategoryRefillTypes(objectType: string, objectID: number, cache = true, data = {}): Promise<ICategoryRefillType[]> {
    return this.api.get(
      this.routeSrv.makeUrl(this.routeSrv.PATHS.objectable.categoryrefilltypes, {
        objectType,
        id: objectID,
      }),
      data,
      { cache }
    );
  }
}
