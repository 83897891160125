export const langDefaults = {
  'en.errors': {
    'generic-notification': 'Something went wrong. Please try again or contact support.',
    'network-connection': 'Network connection problems. Please try later.',
    'access-denied': 'You don\'t have access.',
    'not-found': 'Object not found'
  },
  'en.actions': {
    'close': 'Close'
  },
};
