import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { IRoute } from '@interfaces';
import { SharedUserService } from './shared-user.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class NavigationService {
  private defaultReturnUrl = '/home';
  private returnUrl = '';

  constructor(
    private router: Router,
    private navController: NavController,
    private sharedUserService: SharedUserService,
    private authService: AuthService,
  ) {}

  /**
   * Set url which will be used to redirect after adding new deviation/check/service/etc
   */
  setReturnUrl() {
    this.returnUrl = this.router.url || this.defaultReturnUrl;
  }

  /**
   * Get current return url
   * @return {string}
   */
  getReturnUrl() {
    return this.returnUrl || this.defaultReturnUrl;
  }

  /**
   * Navigate back to return url
   */
  navigateReturnUrl(): Promise<any> {
    return this.navController.navigateRoot(this.getReturnUrl(), {
      animated: true,
      animationDirection: 'back'
    });
  }

  async navigateForward(route: IRoute) {
    const user = await this.authService.getCurrentUser();
    const url = route.queryParams ? [route.url, route.queryParams] : route.url;

    if (route.companyId && route.companyId !== user.company.id) {
      return this.sharedUserService.navigateTo(route.companyId, url);
    }

    return this.navController.navigateForward(url);
  }
}
