import { Injectable } from '@angular/core';
import { LangService } from './lang.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  /** Delay before disappearing */
  private readonly DEFAULT_DURATION = 3000;

  constructor(
    private toastController: ToastController,
    private langService: LangService
  ) {}

  /**
   * Show toast
   * @param options
   * @return {Promise<any>}
   */
  public async showToast(options: any): Promise<any> {
    const topToast = await this.toastController.getTop();

    options.animated = !topToast;

    // Add close button
    options.buttons = [{ text: this.langService.t('actions.close'), role: 'cancel' }];

    const toast = await this.toastController.create(options);
    await toast.present();

    toast.animated = true;

    if (topToast) {
      topToast.animated = false;
      topToast.dismiss();
    }

    return toast;
  }

  /**
   * Show success notification
   * @param message
   */
  public async showSuccessNotification(message: string): Promise<any> {
    return this.showToast({
      message,
      color: 'success',
      duration: this.DEFAULT_DURATION
    });
  }

  /**
   * Show error notification
   * @return {Promise<void>}
   */
  public async showErrorNotification(message: string) {
    return this.showToast({
      message,
      color: 'danger',
      duration: this.DEFAULT_DURATION
    });
  }

  /**
   * Show generic error notification
   * @return {Promise<void>}
   */
  public async showGenericErrorNotification() {
    return this.showToast({
      message: this.langService.t('errors.generic-notification'),
      color: 'danger'
    });
  }

  /**
   * Show error notification about bad network connection
   * @return {Promise<void>}
   */
  public async showBadNetworkErrorNotification() {
    return this.showToast({
      message: this.langService.t('errors.network-connection'),
      color: 'danger'
    });
  }

  /**
   * Show error notification when user does not have access
   * @return {Promise<any>}
   */
  public async showAccessDeniedNotification() {
    return this.showToast({
      message: this.langService.t('errors.access-denied'),
      color: 'danger'
    });
  }

  /**
   * Show error notification that resource was not found
   * @return {Promise<any>}
   */
  public async showNotFoundNotification() {
    return this.showToast({
      message: this.langService.t('errors.not-found'),
      color: 'danger'
    });
  }

  /**
   * Close all error notifications
   */
  public async closeErrorNotifications() {
    const topToast = await this.toastController.getTop();

    if (topToast) {
      return topToast.dismiss();
    } else {
      return Promise.resolve();
    }
  }
}
