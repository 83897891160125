import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ObjectableUtil } from '../utils/objectable.util';
import { NotFoundError } from '../utils/error.util';
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class FormTemplateService {
  constructor(
    private apiService: ApiService,
    private routeSrv: RouteService,
  ) { }

  /**
   * Get default template by template type
   * @param templateType
   */
  public getDefaultFormTemplate(templateType) {
    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.formtemplates.defaultType, { templateType }), {}, {cache: true});
  }

  /**
   * Get template by ID
   * @param isIndependent
   * @param templateId
   */
  public async getFormTemplate(isIndependent, templateId) {
    try {
      if (isIndependent) {
        return await this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.formtemplates.id, { templateId }), {}, {cache: true});
      }
      return await this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.checklists.deviation, { templateId }), {}, {cache: true});
    } catch (e) {
      if (e?.status === 404 || e instanceof NotFoundError) {
        return null;
      }
      throw e;
    }
  }

  /**
   * Get all available templates for specific object
   * @param object
   */
  public getObjectIndependentDeviationTemplates(object) {
    return this.apiService.get(this.routeSrv.makeUrl(
      this.routeSrv.PATHS.objectable.independentDeviationsFormtemplates, 
        { objectType: ObjectableUtil.getObjectType(object), 
          id: object.id
        }), {}, {cache: true});
  }
}
