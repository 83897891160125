import { Injectable } from '@angular/core';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import retry from 'async/retry';
import { PromiseQueueUtil } from '../utils/promise-queue.util';
import { badNetworkErrorCodes } from '../constants/error-codes';

@Injectable({
  providedIn: 'root'
})
export class FileDownloaderService {
  private static readonly ATTEMPTS_MAX = 3; // how many attempts to download one file
  private static readonly ATTEMPTS_INTERVAL = [500, 5000]; // 500ms for first retry, 5s for second retry

  private filesDownloadQueue;

  constructor(
    private httpNative: HTTP,
  ) {
    this.filesDownloadQueue = new PromiseQueueUtil(10);
  }

  /**
   * Download and store locally file by url
   * @param {string} url
   * @param {string} targetPath
   * @return {Promise<string>}
   */
  public download(url: string, targetPath: string): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      this.filesDownloadQueue.pushPromise(
        () => {
          return retry(
              {
                times: FileDownloaderService.ATTEMPTS_MAX,
                interval: (retryCount) => {
                  // use different delay depends on attempt
                  return  FileDownloaderService.ATTEMPTS_INTERVAL[retryCount - 1] || FileDownloaderService.ATTEMPTS_INTERVAL[0];
                },
                errorFilter: (err) => {
                  // retry only if bad network
                  return err && err.hasOwnProperty('status') && badNetworkErrorCodes.indexOf(err.status) >= 0;
                }
              },
              callback => {
                return this.callHttpDownloadFile(url, targetPath)
                  .then(res => {
                    callback(null, res);
                  })
                  .catch(err => {
                    callback(err);
                  });
              }
            )
            .then(resolve)
            .catch(reject);
        }
      );
    });
  }

  /**
   * Call http request to download file
   * @param url
   * @param targetPath
   * @return Promise<any>
   */
  private async callHttpDownloadFile(url, targetPath): Promise<any> {
    return this.httpNative.downloadFile(url, null, null, targetPath)
      .then(res => {
        if (res) {
          return res;
        }

        throw new Error('Empty response');
      });
  }
}
