/**
 * 0 - timeout for web http call
 * 1 - timeout for native http call
 * 2 - device offline
 * 502 - bad gateway for both http call
 * -4 - timeout for native http call
 * @type {number[]}
 */
export const badNetworkErrorCodes = [0, 1, 2, 502, -4];

export const unauthorizedError = 401;
