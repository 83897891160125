import { Injectable } from '@angular/core';
import { BaseTask } from '../models/tasks/baseTask';
import { CheckService } from './check.service';
import { ServiceTask } from '../models/tasks/serviceTask';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { AppFeatureEnum, GroupTypeEnum, TaskTypeEnum } from '@enums';
import { UserService } from './user.service';
import { DeviationTask } from '../models/tasks/deviationTask';
import { Subject } from 'rxjs';
import { IntervalTriggerService } from './interval-trigger.service';
import SearchFilterBuilder from './planning-tool/tasks/search-filter-builder/search-filter.builder';
import { VariantsService } from './planning-tool/variants/variants.service';
import { RouteService } from './route.service';
import { ITasksPage } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserTasksService {
  private allowedFeatures;

  public overdue: BaseTask[] = [];
  public today: BaseTask[] = [];
  public upcoming: BaseTask[] = [];
  public tasks: BaseTask[] = [];
  public requestOptions: any;
  public hasMoreDeviations: boolean;
  public hasMore: boolean;
  public isLoadingMore: boolean;
  public isTasksLoading: boolean;

  private _showChecks = true;
  private _showDeviations = true;
  private _showServices = true;

  public checksCount = 0;
  public deviationsCount = 0;
  public servicesCount = 0;

  private itemsPerPage = 15;
  private currentCursor = 15;
  private currentDeviationPage;
  private taskPages: ITasksPage;
  private currentUser;
  public tasksChanged: Subject<void> = new Subject();

  public get showChecks() {
    return this._showChecks;
  }
  public get showDeviations() {
    return this._showDeviations;
  }
  public get showServices() {
    return this._showServices;
  }

  public set showChecks(value: boolean) {
    this._showChecks = value;
    this.groupTasks();
  }
  public set showDeviations(value: boolean) {
    this._showDeviations = value;
    this.groupTasks();
  }
  public set showServices(value: boolean) {
    this._showServices = value;
    this.groupTasks();
  }

  public get totalCount() {
    return this.checksCount + this.deviationsCount + this.servicesCount;
  }

  public get allTasks() {
    return [...this.overdue, ...this.today, ...this.upcoming];
  }
  constructor(
    private checkService: CheckService,
    private userService: UserService,
    private authService: AuthService,
    private apiService: ApiService,
    private intervalTriggerService: IntervalTriggerService,
    private taskFilter: SearchFilterBuilder,
    private variantService: VariantsService,
    private routeSrv: RouteService
  ) {}

  public fetchTasks(filter): Promise<any[]> {
    this.tasks = [];
    this.hasMore = false;
    this.hasMoreDeviations = false;
    this.isTasksLoading = true;
    this.allowedFeatures = this.authService.getAllowedFeatures();
    this.currentUser = this.authService.getCurrentUser();
    this.requestOptions = filter;
    this.currentCursor = this.itemsPerPage;
    this.taskPages = { current_page: 0, data: [], page_size: 0, total: 0 };

    return new Promise<any>((resolve, reject) => {
      const promises = [];

      if (this.allowedFeatures[AppFeatureEnum.CHECKS]) {
        promises.push(this.checkService.loadChecks(filter));
      } else {
        promises.push(Promise.resolve(null));
      }
      if (this.allowedFeatures[AppFeatureEnum.DEVIATIONS]) {
        const dfilter = Object.assign({}, filter);
        dfilter.is_due = 'true';
        dfilter.sort_by = 'due';
        dfilter.per_page = -1;
        promises.push(this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.user.me.tasks.deviation), Object.assign({}, dfilter)));
        dfilter.is_due = 'false';
        dfilter.per_page = this.itemsPerPage;
        promises.push(this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.user.me.tasks.deviation), Object.assign({}, dfilter)));
      } else {
        promises.push(Promise.resolve(null));
        promises.push(Promise.resolve(null));
      }
      if (this.allowedFeatures[AppFeatureEnum.SERVICES]) {
        promises.push(this.userService.getAssignedUpcomingServicesTasks(filter));

        promises.push(this.userService.getAssignedOngoingServicesTasks(filter));
      } else {
        promises.push(Promise.resolve(null));
      }
      return Promise.all(promises)
        .then(res => {
          this.tasks = [];
          const checks = res[0] || [];
          const deviationsDue = res[1] || [];
          const deviations = res[2] || [];
          const servicesList = (res[3] || []).concat(res[4] || []);

          this.processChecksAndServices(checks, deviationsDue, deviations, servicesList).then(() => {
            resolve(this.tasks);
          });
        })
        .catch(reject);
    });
  }

  public clear() {
    this._showChecks = true;
    this._showDeviations = true;
    this._showServices = true;
  }

  private processDeviations(deviations, isDue: boolean) {
    const items = (deviations?.data || []).map(item => new DeviationTask(item));
    if (isDue) {
      items.forEach(item => (item.group = GroupTypeEnum.OVERDUE));
    } else {
      this.currentDeviationPage = deviations.current_page;
      if (deviations.current_page < deviations.last_page) {
        this.hasMoreDeviations = true;
      } else {
        this.hasMoreDeviations = false;
      }
    }

    this.tasks.push(...items);
  }

  updateItemsByType(type: TaskTypeEnum, newItems: any[]) {
    this.tasks = this.tasks.filter(item => item.type !== type);
    this.tasks.push(...newItems);
  }

  groupTasks() {
    let tasks = Array.from(
      new Map<string, BaseTask>(
        this.tasks.map(
          // Get unique tasks
          task => [JSON.stringify(task), task]
        )
      ).values()
    );

    this.checksCount = tasks.filter(item => item.type === TaskTypeEnum.CHECK).length;
    this.servicesCount = tasks.filter(item => item.type === TaskTypeEnum.SERVICE).length;

    if (!this._showChecks) {
      tasks = tasks.filter(item => item.type !== TaskTypeEnum.CHECK);
    }
    if (!this.showDeviations) {
      tasks = tasks.filter(item => item.type !== TaskTypeEnum.DEVIATION);
    }
    if (!this._showServices) {
      tasks = tasks.filter(item => item.type !== TaskTypeEnum.SERVICE);
    }

    const overdue = tasks.filter(item => item.group === GroupTypeEnum.OVERDUE).sort(BaseTask.compare);
    let today = tasks.filter(item => item.group === GroupTypeEnum.TODAY).sort(BaseTask.compare);
    let upcoming = tasks.filter(item => item.group === GroupTypeEnum.UPCOMING).sort(BaseTask.compare);
    let todayAndUpcoming = [...today, ...upcoming];
    let hasMore;
    if (this.currentCursor < todayAndUpcoming.length) {
      todayAndUpcoming = todayAndUpcoming.slice(0, this.currentCursor);
      hasMore = true;
    } else {
      hasMore = false;
    }
    if (this.showDeviations) {
      hasMore = hasMore || this.hasMoreDeviations;
    }
    today = todayAndUpcoming.filter(item => item.group === GroupTypeEnum.TODAY);
    upcoming = todayAndUpcoming.filter(item => item.group === GroupTypeEnum.UPCOMING);
    this.overdue = overdue;
    this.today = today;
    this.upcoming = upcoming;
    this.hasMore = hasMore;
  }

  processServices(...services) {
    const serviceTasks = [];

    [].concat(...services).forEach(service => {
      // Flatten triggers
      if (service.interval_due_triggers) {
        this.intervalTriggerService.flattenTriggeredServices(service).forEach(triggeredService => {
          serviceTasks.push(new ServiceTask(triggeredService));
        });

        return;
      }

      serviceTasks.push(new ServiceTask(service));
    });

    this.updateItemsByType(TaskTypeEnum.SERVICE, serviceTasks);
  }

  public loadMoreDeviations() {
    this.hasMore = false;
    if (!this.requestOptions) {
      this.requestOptions = {} as any;
    }
    this.currentCursor += this.itemsPerPage;
    if (this.hasMoreDeviations) {
      this.requestOptions.page = this.currentDeviationPage + 1;
      this.requestOptions.is_due = 'false';
      this.requestOptions.sort_by = 'due';
      this.requestOptions.per_page = this.itemsPerPage;
      this.hasMore = false;
      this.isLoadingMore = true;
      this.apiService.get(this.routeSrv.PATHS.user.me.tasks.deviation, this.requestOptions).then(deviations => {
        this.processDeviations(deviations, false);
        this.groupTasks();
        this.isLoadingMore = false;
        delete this.requestOptions.page;
      });
    } else {
      this.groupTasks();
    }
  }

  private processChecksAndServices(checks, deviationsDue, deviations, servicesList): Promise<any> {
    return this.checkService.processChecks(checks.data).then(result => {
      this.updateItemsByType(TaskTypeEnum.CHECK, result);
      this.updateItemsByType(TaskTypeEnum.DEVIATION, []);
      this.processDeviations(deviationsDue, true);
      this.processDeviations(deviations, false);
      this.deviationsCount = deviationsDue.total + deviations.total || 0;
      this.processServices(servicesList);
      this.isTasksLoading = false;
      this.groupTasks();
      this.tasksChanged.next();
    });
  }
}
