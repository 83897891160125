import { Component, OnInit, OnChanges, HostListener, Input, Output, ElementRef, EventEmitter, NgZone, ViewChild } from '@angular/core';
import { SelectDropdownService } from '../select-dropdown.service';

@Component({
  selector: 'app-select-dropdown-panel',
  templateUrl: './select-dropdown-panel.component.html',
  styleUrls: ['./select-dropdown-panel.component.scss'],
})
export class SelectDropdownPanelComponent implements OnInit, OnChanges {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;
  @Input() sdService: SelectDropdownService;
  @Input() items;
  @Input() groupBy;
  @Input() searchable = true;
  @Input() itemContentTmpl;
  @Input() typeahead;
  @Input() multiple = false;
  @Input() isLoading = false;
  @Input() notFoundText;
  @Input() selectedOptions;
  @Input() addTag;
  @Input() addTagText;
  @Input() hasInitialItems;
  @Input() typeToSearchText;
  @Input() isFiltered = false;
  @Input() showOverflow = false;
  @Input() hideSelected;
  @Input() restrictNumberOfSelectedItem = null;

  @Output() selectOption = new EventEmitter();
  @Output() unselectOption = new EventEmitter();
  @Output() closeDropdown = new EventEmitter();
  @Output() scrollChange = new EventEmitter();

  public disabled = false;
  public options;
  public searchValue = '';
  public clearSearchOnAdd = true;
  public hasVisibleOptions = false;
  public showAddTag = false;

  private selectEl;
  private touchMoved = false;
  private touchCoords = { x: 0, y: 0 };
  private clickOutsideHandled = false;
  public scrollEventWaitingForLoading = false;

  constructor(private _el: ElementRef, private zone: NgZone) {}

  ngOnInit() {
    this.selectEl = this._el.nativeElement.closest('app-select-dropdown');
  }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('items') && this.items) {
      this.options = this.processItems(this.items);
      this.mapSelectedItems();
    }
    if (changes.hasOwnProperty('selectedOptions')) {
      this.mapSelectedItems();
    }
  }

  @HostListener('window:scroll')
  onScroll(): void {
    const element = this.scrollContainer.nativeElement;
    const scrollPosition = element.scrollTop;
    const maxScroll = element.scrollHeight - element.clientHeight;
    if (scrollPosition === maxScroll) {
      this.scrolled();
    }
  }
  @HostListener('document:click', ['$event'])
  documentClick(e) {
    this.handleClick(e);
  }

  @HostListener('document:touchstart', ['$event'])
  touchStart(e) {
    this.touchMoved = false;
    this.touchCoords.x = e.touches[0].clientX;
    this.touchCoords.y = e.touches[0].clientY;
  }

  @HostListener('document:touchmove', ['$event'])
  touchMove(e) {
    // check if the user has dragged past the threshold of 10px
    if (Math.abs(e.touches[0].clientX - this.touchCoords.x) > 10 || Math.abs(e.touches[0].clientY - this.touchCoords.y) > 10) {
      this.touchMoved = true;
    }
  }

  // in some cases (for example in modals on ios devices) "click" event doesn't trigger
  // so we have these touch events as a backup
  @HostListener('document:touchend', ['$event'])
  touchEnd(e) {
    if (!this.touchMoved) {
      // add delay - so it will be triggered after other click events
      setTimeout(() => {
        this.handleClick(e);
      }, 100);
    }
  }

  /**
   * Search for options while typing
   */
  public onSearch() {
    if (this.typeahead) {
      this.typeahead.next(this.searchValue);
    } else {
      this.isFiltered = !!this.searchValue;
      this.filterOptions();
    }
  }

  /**
   * Toggle select/unselect
   * @param option
   */
  public toggleOption(option) {
    if (!option || option.disabled || this.disabled || (this.restrictNumberOfSelectedItem && this.selectedOptions.length >= this.restrictNumberOfSelectedItem)) {
      return;
    }

    if (this.multiple && option.selected) {
      this.unselect(option);
    } else {
      this.select(option);
    }
  }

  /**
   * Add new "tag" option
   */
  public addTagOption() {
    if (typeof this.addTag === 'function') {
      const tag = this.addTag(this.searchValue);
      const tagOption = this.sdService.createOption(tag);
      this.select(tagOption);
    }
  }

  /**
   * Handle outside click
   * @param e
   */
  private handleClick(e) {
    if (this.clickOutsideHandled) {
      return;
    }

    const parent = e.target.closest('app-select-dropdown');
    if (parent !== this.selectEl) {
      this.zone.run(() => {
        this.clickOutsideHandled = true; // prevent triggering of "click outside" event twice
        this.closeDropdown.emit();
      });
    }
  }

  /**
   * Select an option
   * @param option
   */
  private select(option) {
    if (!option.selected) {
      if (this.clearSearchOnAdd) {
        this.clearSearch();
      }

      this.selectOption.emit(option);
    }

    this.closeDropdown.emit();
  }

  /**
   * Unselect an option
   * @param option
   */
  private unselect(option) {
    this.unselectOption.emit(option);
  }

  /**
   * Clear search input
   */
  private clearSearch() {
    this.searchValue = null;
    this.onSearch();
  }

  /**
   * Process input items
   * @param items
   * @return {any[]}
   */
  private processItems(items) {
    if (!items) {
      return [];
    }

    let options = [];

    if (this.groupBy) {
      items.forEach(groupItem => {
        if (groupItem[this.groupBy]) {
          options.push({
            children: true,
            disabled: true,
            title: groupItem.title,
          });

          options = options.concat(this.convertToOptions(groupItem[this.groupBy], groupItem));
        } else {
          options.push(this.sdService.createOption(groupItem));
        }
      });
    } else {
      options = this.convertToOptions(items);
    }

    return options;
  }

  /**
   * Convert list of items to corresponding list of options
   * @param items
   * @param {any} groupItem
   * @return {any}
   */
  private convertToOptions(items, groupItem = null) {
    return items.map(item => {
      return this.sdService.createOption(item, groupItem);
    });
  }

  /**
   * Find and mark/unmark selected items
   */
  private mapSelectedItems() {
    if (this.options && this.options.length > 0) {
      this.options.forEach(option => {
        const selected = this.findOption(option, this.selectedOptions || []);
        if (selected) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
    }

    this.filterOptions();
  }

  /**
   * Find an option in a list
   * @param val
   * @param {any} list
   * @return {any}
   */
  private findOption(val, list = null) {
    let options = [];
    if (list !== null) {
      options = list;
    } else {
      options = this.options;
    }

    return options.find(option => {
      return SelectDropdownService.defaultOptionCompare(option, val);
    });
  }

  /**
   * Filter current options
   */
  private filterOptions() {
    this.showAddTag = this.addTag && !!this.searchValue;

    this.hasVisibleOptions = false;
    if (this.options) {
      this.options.forEach(option => {
        if (option.selected && this.hideSelected) {
          option.hidden = true;
        } else if (!this.typeahead && this.searchValue && !option.children) {
          // default search by text
          option.hidden = option.title.toLowerCase().indexOf(this.searchValue.toLowerCase()) < 0;
        } else {
          option.hidden = false;
        }

        // don't show "add tag" option if same option already exists
        if (this.showAddTag && option.title.toLowerCase() === this.searchValue.toLowerCase()) {
          this.showAddTag = false;
        }

        if (!option.hidden) {
          this.hasVisibleOptions = true;
        }
      });
    }
    this.scrollEventWaitingForLoading = false;
  }

  private scrolled(): void {
    if (!this.scrollEventWaitingForLoading) {
      this.scrollEventWaitingForLoading = true;
      this.scrollChange.emit();
    }
  }
}
