import { Injectable } from '@angular/core';
import { EnvironmentService } from './environment.service';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import { EventsService } from './events.service';
import { OfflineHandlerService } from './offline/handler.service';
import { AuthService } from './auth.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private pusherClient;
  private laravelEcho: Echo;
  private channels = [];

  constructor(
    private environmentService: EnvironmentService,
    private events: EventsService,
    private offlineService: OfflineHandlerService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {}

  public initService() {
    this.events.subscribe('user:init', () => {
      this.subscribeChannels();
    });

    this.events.subscribe('user:logout', () => {
      this.unsubscribeChannels();
    });

    this.events.subscribe('offline:activated', this.onOfflineActivated.bind(this));
  }

  /**
   * Init pusher client
   */
  private initClient() {
    if (this.pusherClient && this.laravelEcho) {
      return;
    }

    const env = this.environmentService.getEnvironment();

    this.pusherClient = new Pusher(env.pusher.key, {
      cluster: env.pusher.cluster,
      authEndpoint: env.baseUrl + '/broadcasting/auth',
    });

    this.laravelEcho = new Echo({
      broadcaster: 'pusher',
      client: this.pusherClient
    });
  }

  /**
   * Subscribe to proper channels
   */
  private async subscribeChannels() {
    if (this.channels.length > 0) {
      return;
    }

    const isOffline = await this.offlineService.isOfflineEnable();
    if (isOffline) {
      return;
    }

    // if user not Authenticated or just going to log out, stop process
    const isAuthenticated = await this.authService.isAuthenticated();
    if (!isAuthenticated) {
      return;
    }

    const user = await this.authService.getCurrentUser();
    if (!user) {
      return;
    }

    this.initClient();

    const userIdForNotificationEvents = user.user_handler_id || user.id;
    const channelName = user.user_handler_id ? 'user_handlers' : 'users';
    const userChannel = channelName + '.' + userIdForNotificationEvents;
    const companyChannel = 'company.' + user.company_id;

    this.channels.push(
      this.laravelEcho.channel(userChannel)
        .listen('OnNotificationCreated', () => {
          this.notificationService.onNotificationCreated();
        })
    );

    this.channels.push(
      this.laravelEcho.channel(userChannel)
        .listen('OnUserTasksChanged', (event) => {
          if (event.objectType) {
            if (event.objectType === 'App\\Models\\Deviation' || event.objectType === 'App\\Models\\IndependentDeviation') {
              this.events.publish('user:newDeviationTask');
            } else if (event.objectType === 'App\\Models\\ChecklistVariant') {
              this.events.publish('user:newCheckVariantTask');
            } else if (event.objectType === 'App\\Models\\ServiceInterval') {
              this.events.publish('user:newServiceIntervalTask');
            }
          }
        })
    );

    this.channels.push(
      this.laravelEcho.channel(companyChannel)
        .listen('OnCompanyItemsChanged', (event) => {
          this.events.publish('company:itemsChanged', event.itemsType || '');
        })
    );
  }

  /**
   * Unsubscribe from all channels
   */
  private unsubscribeChannels() {
    this.channels.forEach(channel => {
      this.laravelEcho.leave(channel.name);
    });
    this.channels = [];
  }

  /**
   * Triggers when offline mode is activated/deactivated
   * @param active
   */
  private onOfflineActivated(active) {
    if (active) {
      this.unsubscribeChannels();
    } else {
      this.subscribeChannels();
    }
  }
}
