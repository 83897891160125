import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared.module';
import { SideBarMenuPublicComponent } from './side-bar-menu-public.component';
import { NotificationCenterModule } from '../../modals/notification-center/notification-center.module';
import { SharedPipesModule } from '../../pipes/shared-pipes.module';


@NgModule({
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    SharedPipesModule,
    NotificationCenterModule
  ],
  declarations: [
    SideBarMenuPublicComponent
  ],
  exports: [
    SideBarMenuPublicComponent
  ],
})
export class SideBarMenuPublicModule {}
