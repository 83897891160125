import * as urlParse from 'url-parse';

export function parseUrl(url) {
  const parsedUrl = urlParse(decodeURIComponent(url), {}, true);

  const parsedPath = parseMatrixUrl(parsedUrl.pathname);

  parsedUrl.pathname = parsedPath.path;

  parsedUrl.params = parsedPath.paramsMatrix;

  return parsedUrl;
}

export function parseMatrixUrl(url) {
  const i = url.indexOf(';');
  if (i === -1) {
    return {
      path: url,
      paramsMatrix: {}
    };
  }

  const path = url.slice(0, i);
  let params = url.slice(i);
  params = params.split(/(;[\w\d-]*=[\w\d-]*)/g);

  const paramObj = <any>{};
  params
    .filter(el => el.startsWith(';'))
    .forEach(paramStr => {
      paramStr = paramStr.slice(1);

      const paramArr = paramStr.split('=');

      paramObj[paramArr[0]] = paramArr[1];
    });

  return {
    path: path,
    paramsMatrix: paramObj
  };
}
