import { ITasksClient } from '@interfaces';
import { OfflineHandlerService } from '../../offline/handler.service';
import { TasksClient } from '../../clients/microservices/planning-tool/tasks/tasks.client';
import OfflineTasksClient from '../../clients/microservices/planning-tool/tasks/offline/offline-tasks.client';
import { Injectable } from '@angular/core';

/** Custom injection dependent on the system state. */
@Injectable({
  providedIn: 'root'
})
export default class ClientProvider {
  public constructor(
    private readonly offlineSrv: OfflineHandlerService,
    private readonly onlineClt: TasksClient,
    private readonly offlineClt: OfflineTasksClient,
  ) {
  }

  /** Get actual client */
  public getClient(): Promise<ITasksClient> {
    return this.offlineSrv
      .isOfflineEnable()
      .then(isOffline => isOffline ? this.offlineClt : this.onlineClt);
  }
}
