import { Injectable } from '@angular/core';
import { ApiService } from '../../../api.service';
import { RouteService } from '../../../route.service';
import { IPublicToken } from '@interfaces';

@Injectable({
  providedIn: 'root',
})
export class PublicTokensClient {
  constructor(private apiService: ApiService, private routeSrv: RouteService) {}

  /** Check public token and get public info about it */
  public get(token: string): Promise<IPublicToken> {
    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.publicTokens.getByToken, { token }), {}, { disableErrorHandler: true });
  }
}
