import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { PromiseQueueUtil } from '../utils/promise-queue.util';
import { UserPreferencePrefixEnum } from '@enums';
import { IFavorite } from '@interfaces';
import {Capacitor} from '@capacitor/core';
import {ApiService} from './api.service';

@Injectable({
  providedIn: 'root'
})
export class UserAppPreferencesService {

  constructor(
    private storage: Storage,
    private apiService: ApiService,
  ) {
    this.updateQueue = new PromiseQueueUtil(1);
  }

  private readonly PATHS = {
    usersMeSettings: '/users/me/settings',
  };

  private storagePrefix = 'pref-';
  private userId;
  private updateQueue;

  public lastSelectedObject;
  public lastSelectedPlace;

  /**
   * Get id of favorite object
   * @param {IFavorite} value
   * @return {string | IFavorite}
   */
  static getValueId(value: IFavorite): string | IFavorite {
    if (typeof value === 'object') {
      if (value.id) {
        return value.id;
      }
    }

    return value;
  }

  public setUser(user) {
    if (user.id !== this.userId) {
      this.resetInstance();
    }

    this.userId = user.id;
  }

  /**
   * Get favorites data from local storage
   * @param {string} key
   * @param user_id
   * @return {Promise<any>}
   */
  public getData(key: string, user_id?: number): Promise<any> {
    user_id = (user_id && user_id > 0) ? user_id : this.userId;
    return this.storage.get(this.storagePrefix + user_id + '-' + key);
  }

  /**
   * Save favorites data to local storage
   * @param {string} key
   * @param data
   * @return {Promise<any>}
   */
  public setData(key: string, data: any): Promise<any> {
    return this.storage.set(this.storagePrefix + this.userId + '-' + key, data);
  }

  /**
   * Get all favorites by type
   * @param {string} type
   * @param user_id
   * @return {Promise<any>}
   */
  public getFavorites(type: string, user_id?: number): Promise<IFavorite[]> {
    return this.getData('favorites-' + type, user_id);
  }

  /**
   * Get ids of all favorites by type
   * @param {string} type
   * @param {number} user_id
   * @return {Promise<any>}
   */
  public async getFavoriteIds(type: string, user_id?: number): Promise<string[]> {
    const favorites = await this.getFavorites(type, user_id);

    let ids = [];
    if (favorites && favorites.length) {
      ids = favorites.map(favorite => {
        return UserAppPreferencesService.getValueId(favorite);
      });
    }

    return Promise.resolve(ids);
  }

  /**
   * Set favorites by type
   * @param {string} type
   * @param favorites
   * @return {Promise<any>}
   */
  public setFavorites(type: string, favorites: any): Promise<any> {
    return this.setData('favorites-' + type, favorites);
  }

  /**
   * Add one favorite to list of favorites of specific type
   * @param {string} type
   * @param value
   * @return {Promise<any>}
   */
  public async addToFavorites(type: string, value: IFavorite): Promise<any> {
    let favorites = await this.getFavorites(type);
    const favoriteIds = await this.getFavoriteIds(type);

    if (!favorites) {
      favorites = [];
    }

    const valueId = UserAppPreferencesService.getValueId(value);

    if (favoriteIds.indexOf(<string>valueId) < 0) {
      favorites.push(value);
    }

    return this.setFavorites(type, favorites);
  }

  /**
   * Remove one favorite from list of favorites of specific type
   * @param {string} type
   * @param value
   * @return {Promise<any>}
   */
  public removeFromFavorites(type: string, value: any): Promise<any> {
    return this.getFavorites(type).then(favorites => {
      if (!favorites) {
        favorites = [];
      }

      const valueId = UserAppPreferencesService.getValueId(value);

      favorites = favorites.filter(favorite => {
        const favoriteId = UserAppPreferencesService.getValueId(favorite);
        return favoriteId !== valueId;
      });

      return this.setFavorites(type, favorites);
    });
  }

  /**
   * Check is this object is in favorites list
   * @param {string} type
   * @param value
   * @return {any}
   */
  public isFavorite(type: string, value: IFavorite) {
    return this.getFavoriteIds(type).then(favoriteIds => {
      return !(!favoriteIds || favoriteIds.indexOf(<string>UserAppPreferencesService.getValueId(value)) < 0);
    });
  }

  /**
   * Update specific item in favorites list
   * @param type
   * @param item
   * @param onFinish callback function when update is finished
   */
  public updateFavoriteItem(type, item, onFinish = null) {
    // updates should be in queue to not update list at the same time
    return this.updateQueue.pushPromise(() => {
      return new Promise<void>(async (resolve) => {
        let favorites = await this.getFavorites(type);
        favorites = favorites.map(fav => {
          if (fav.id === item.id) {
            return item;
          }
          return fav;
        });

        await this.setFavorites(type, favorites);

        if (onFinish) {
          onFinish();
        }

        resolve();
      });
    });
  }

  /**
   * Get setting for "show deviation thumbnails"
   * @return {Promise<any>}
   */
  public getShowDeviationThumbs() {
    return this.getData(UserPreferencePrefixEnum.SHOW_DEVIATION_THUMBS);
  }

  /**
   * Set setting for "show deviation thumbnails"
   * @param value
   * @return {Promise<any>}
   */
  public setShowDeviationThumbs(value) {
    return this.setData(UserPreferencePrefixEnum.SHOW_DEVIATION_THUMBS, value);
  }

  public getCheckPerformLayout() {
    return this.getData(UserPreferencePrefixEnum.CHECK_PERFORM_LAYOUT);
  }

  public setCheckPerformLayout(value) {
    return this.setData(UserPreferencePrefixEnum.CHECK_PERFORM_LAYOUT, value);
  }

  public getFuelRefillType() {
    return this.getData(UserPreferencePrefixEnum.FUEL_REFILL_TYPE);
  }

  public setFuelRefillType(fuelRefillTypeID: number) {
    return this.setData(UserPreferencePrefixEnum.FUEL_REFILL_TYPE, fuelRefillTypeID);
  }

  /**
   * Reset all properties that depends on current user
   */
  public resetInstance() {
    this.lastSelectedObject = null;
    this.lastSelectedPlace = null;
  }

  /**
   * Get all app preferences by user_id
   * @param {string} type
   * @param user_id
   * @return {Promise<any>}
   */
  public getAppPreferences(user_id?: number): Promise<any[]> {
    return this.getData('app', user_id);
  }

  /**
   * Get app preference by user_id and type
   * @param name
   * @return {Promise<any>}
   */
  public getAppPreference(name: string): Promise<any> {
    return this.getAppPreferences(this.userId).then(pref => {
      const res = pref.find(el => (el.key === name));
      return res.setting;
    });
  }

  /**
   * Set app preference by user_id and type
   * @param name
   * @param value
   * @return {Promise<any>}
   */
  public setAppPreference(name: string, value: any): void {
    this.getAppPreferences(this.userId).then(async pref => {
      pref.map(el => {
        if (el.key === name) {
          el.setting = value;
        }
      });
      await this.setAppPreferences(pref);
    });
  }

  /**
   * Set app preferences for an user
   * @param preferences
   * @return {Promise<any>}
   */
  public async setAppPreferences(preferences: any) {
    return await this.setData('app', preferences);
  }

  /**
   * Send to the api preferences for an user
   * @param preferences
   * @return {Promise<any>}
   */
  public saveToDbAppPreferences(preferences: any) {
    return this.apiService.put(this.PATHS.usersMeSettings, preferences).catch((err) => {
      return err;
    });
  }

  /**
   * init app preferences for an user if never setup before
   * update this function each time we add a new setting in our setting page
   * @param preferences
   * @return {Promise<any>}
   */
  public async initFirstTimeAppPreferences() {
    const initAppSettings = [{
      key: UserPreferencePrefixEnum.SAVE_PICTURES_LOCALLY,
      setting: { value: false, osRestriction: Capacitor.getPlatform() === 'ios' }
    }];

    await this.setAppPreferences(initAppSettings);
    return this.saveToDbAppPreferences(initAppSettings);
  }

  public isLocalSettingSameAsDbSetting(localSetting, dbSettings) {
    return dbSettings.filter(preference => preference.key === localSetting.key
        && preference.setting.value === localSetting.setting.value).length === 1;
  }
}
