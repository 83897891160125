import {Injectable} from '@angular/core';
import {ApiService} from '../../../api.service';
import { RouteService } from '../../../route.service';
import { ISendCodeRequest, IUpdateProfileRequest } from '@interfaces';

@Injectable({
  providedIn: 'root'
})
export class PublicUsersClient {
  constructor(
    private apiService: ApiService,
    private routeService: RouteService,
  ) {
  }

  public sendCode(data: ISendCodeRequest): Promise<void> {
    if (!data.email && !data.phone) {
      return Promise.reject();
    }

    return this.apiService.post(
      this.routeService.makeUrl(
        this.routeService.PATHS.publicUsers.sendCode,
      ),
      data,
      { disableErrorHandler: true }
    ).catch((response) => {
      throw new Error(response?.error?.error?.message || this.apiService.getErrorMessageByHttpCode(response.status));
    });
  }

  public updateProfile(data: IUpdateProfileRequest) {
    return this.apiService.put(
      this.routeService.makeUrl(
        this.routeService.PATHS.publicUsers.updateProfile,
      ),
      data
    );
  }
}
