import { ObjectableUtil } from '../utils/objectable.util';
import { AuthService } from './auth.service';
import { ActionSheetService } from './action-sheet.service';
import { NavController } from '@ionic/angular';
import { LangService } from './lang.service';
import { OfflineHandlerService } from './offline/handler.service';
import { AppFeatureEnum } from '@enums';
import { Injectable } from '@angular/core';
import { FormTemplateService } from './form-template.service';
import { sortBy } from '../utils/sort.util';
import { RfidClientService } from './clients/rfid/rfid-client.service';

@Injectable({
  providedIn: 'root'
})
export class DeviationNavService {
  constructor(
    private authService: AuthService,
    private actionSheetService: ActionSheetService,
    private navController: NavController,
    private langService: LangService,
    private offlineHandlerService: OfflineHandlerService,
    private formTemplateService: FormTemplateService,
    private rfidClt: RfidClientService,
  ) { }

  public async openNewDeviation(object, rfidTag = null) {
    const requestData: any = {
      objectId: object.id,
      objectType: ObjectableUtil.isUnit(object) ? 'unit' : 'round'
    };

    // When we are using rfid button, we need to refresh and get connected positions & category
    // for this tag (object.pivot is an rfidtag)
    if (rfidTag) {
      await this.rfidClt.getConnectedCategoryAndPositions(requestData.objectId, requestData.objectType, rfidTag).then(categoryAndPositions => {
        requestData.category = JSON.stringify(categoryAndPositions.category);
        requestData.position = JSON.stringify(categoryAndPositions.position);
      });
    }

    if (this.authService.getAllowedFeatures()[AppFeatureEnum.DEVIATION_TEMPLATES]) {
      // Show selector of deviation pre-defined templates
      // If there are no templates - default one will be used
      try {
        const formTemplateId = await this.getDeviationTemplateId(object);
        if (formTemplateId > 0) {
          requestData.formTemplateId = formTemplateId;
        }
      } catch (e) {
        // user cancelled selecting of deviation template
        return;
      }
    }

    return this.navController.navigateForward(['/deviation', requestData]);
  }

  private async getDeviationTemplateId(object) {
    const templates = await this.formTemplateService.getObjectIndependentDeviationTemplates(object);
    if (!templates || templates.length === 0) {
      // no templates defined for selected object - use default one
      return Promise.resolve(0);
    }

    if (templates.length === 1) {
      return Promise.resolve(templates[0].is_default ? 0 : templates[0].id);
    }

    return new Promise((resolve, reject) => {
      // generate buttons for each template
      const buttons = [];
      sortBy(templates, 'order');
      templates.forEach(template => {
        buttons.push({
          text: template.label,
          handler: () => {
            resolve(template.is_default ? 0 : template.id);
          }
        });
      });

      buttons.push({
        text: this.langService.t('actions.cancel'),
        role: 'cancel',
        handler: () => {
          reject();
        }
      });

      this.actionSheetService.open({
        buttons: buttons
      });
    });
  }
}
