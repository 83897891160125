export function NotFoundError(message = null) {
  this.message = message;
}

export function NotFoundOfflineDeviation(message = null) {
  this.message = message;
}

export function ResolverError(message = null) {
  this.message = message;
}

export class ApiNotAvailableError {
  public message = '';

  constructor(message) {
    this.message = message;
  }
}

export function AccessError(message = null) {
  this.message = message;
}
