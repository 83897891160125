import { Injectable } from '@angular/core';
import Mapper from 'url-mapper';
import {offlineRoutes} from './routes';

@Injectable({
  providedIn: 'root'
})
export class OfflineRouterService {

  readonly routes;
  private urlMapper;

  constructor() {
    this.urlMapper = Mapper();
    this.routes = offlineRoutes;
  }

  /**
   * Get route by url
   * @param {string} url
   * @return {any}
   */
  public getRoute(url: string): any {
    const matchedRoute = this.urlMapper.map(url, this.routes);

    if (!matchedRoute || !matchedRoute.match) {
      return null;
    }

    const route = Object.assign({}, matchedRoute.match);

    if (route.route) {
      const newUrl = this.urlMapper.stringify(route.route, matchedRoute.values);
      route.route = this.getRoute(newUrl);
    }

    // get object of all formatted routed parameters
    const params = {};
    for (const key in matchedRoute.values) {
      if (!matchedRoute.values.hasOwnProperty(key)) {
        continue;
      }
      let value = matchedRoute.values[key];
      if (!isNaN(Number(value))) {
        value = Number(value);
      }
      params[':' + key] = value;
    }
    route.params = params;

    if (route.filterBy) {
      route.filterBy = this.formatFilterObject(route.filterBy, params);
    }
    if (route.findBy) {
      route.findBy = this.formatFilterObject(route.findBy, params);
    }

    // Pass query search parameters to a controller-handler
    route.searchParams = new URLSearchParams(
      url.slice(url.indexOf('?'))
    );

    return route;
  }

  /**
   * Format filter object (replace route parameters with values)
   * @param filter
   * @param params
   * @return {any}
   */
  private formatFilterObject(filter: any, params: any): any {
    let formattedFilter = null;

    if (typeof filter === 'object') {
      formattedFilter = {};

      for (const key in filter) {
        if (!filter.hasOwnProperty(key)) {
          continue;
        }

        if (params.hasOwnProperty(filter[key])) {
          formattedFilter[key] = params[filter[key]];
        } else {
          formattedFilter[key] = filter[key];
        }
      }
    }

    return formattedFilter;
  }
}
