import { Input, Optional, Directive } from '@angular/core';
import { FormGroupDirective, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive()
export class FormValidate {
  @Input() formControlName?: string;
  @Input() control?: AbstractControl;
  @Input() invalid = null;
  @Input() validationType = "bySubmit";
  public    submitted = false;
  protected onSubmitSubscription: Subscription;
  protected skipFirstWriteValue = false;

  constructor(@Optional() protected controlContainer: FormGroupDirective) {}

  /**
   * Init form validations and add subscription to ngSubmit
   */
  initValidator() {
    if (this.controlContainer) {
      if (this.formControlName) {
        this.control = this.controlContainer.control.get(this.formControlName);
      } else {
        this.skipFirstWriteValue = true;
      }

      if (this.control) {
        this.onSubmitSubscription = this.controlContainer.ngSubmit.subscribe(() => {
          this.submitted = true;
        });
      }
    }
  }

  /**
   * Remove all subscriptions
   */
  destroyValidator() {
    if (this.onSubmitSubscription) {
      this.onSubmitSubscription.unsubscribe();
    }
  }

  /**
   * Return is current input valid or not
   * @return {boolean}
   */
  get isInvalid() {

    if (!this.control) {
      if (this.invalid !== null) {
        return this.invalid;
      }
      return false;
    }

    if (this.validationType === "bySubmit") {
      return this.submitted && this.control.invalid;
    }

    return !this.control.pristine && this.control.invalid;
  }

  /**
   * Check if current form control has "required" validator
   *
   * @return {boolean}
   */
  public hasRequiredValidator(): boolean {
    if (!this.control || !this.control.validator) {
      return false;
    }

    const validator = this.control.validator({} as AbstractControl);
    return validator && validator.required;
  }

  /**
   * Hack to avoid angular bug when writeValue is being called twice when using ngModel and not formControl
   * For more info: https://github.com/angular/angular/issues/14988
   * Remove it when they will fix it
   */
  protected shouldSkipFirstWriteValue(): boolean {
    if (this.skipFirstWriteValue) {
      this.skipFirstWriteValue = false;
      return true;
    }

    return false;
  }
}
