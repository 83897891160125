import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HelperService } from './helper.service';
import { IService, IPage, IServiceDate } from "../interfaces";
import { RouteService } from './route.service';

@Injectable({
  providedIn: 'root'
})
export class ServicesListService {
  public perPage = 10;

  constructor(private apiService: ApiService,
              private helperService: HelperService,
              private routeSrv: RouteService,) {}

  /**
   * Get ongoing services
   * @param unitId
   * @param {{}} params
   * @return {Promise<any>}
   */
  public getOngoingServices(unitId, params = {}) {
    const defaultParams = {
      status: this.helperService.protocol.services.statuses.ACTIVE,
    };
    const requestParams = Object.assign({}, defaultParams, params);

    return this.getServices(unitId, requestParams);
  }

  /**
   * Get finished services
   * @param unitId
   * @param {{}} params
   * @return {Promise<any>}
   */
  public getPerformedServices(unitId, params = {}) {
    const defaultParams = {
      status: this.helperService.protocol.services.statuses.FINISHED,
    };
    const requestParams = Object.assign({}, defaultParams, params);

    return this.getServices(unitId, requestParams);
  }

  /**
   * Get services by status
   * @param type
   * @param unitId
   * @param {{}} params
   * @return {any}
   */
  public getServicesByType(type, unitId, params = {}) {
    if (type === 'ongoing') {
      return this.getOngoingServices(unitId, params);
    } else if (type === 'performed') {
      return this.getPerformedServices(unitId, params);
    }

    return Promise.reject();
  }

  /**
   * Get services
   * @param unitId
   * @param {{}} params
   * @return {Promise<any>}
   */
  public getServices(unitId, params = {}): Promise<IPage<IService>> {
    const defaultParams = {
      with: ['serviceInterval', 'intervalDueTrigger'],
      per_page: this.perPage
    };
    const requestParams = Object.assign({}, defaultParams, params);

    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.units.servicesSearch, { id: unitId}), requestParams);
  }

  /**
   * Get upcoming services
   */
  public getUpcomingServices(unitId): Promise<IServiceDate[]> {
    return this.apiService.get(this.routeSrv.makeUrl(this.routeSrv.PATHS.units.allservicedates, { id: unitId}));
  }
}
