import { Component, OnChanges, Input, Output, EventEmitter, ViewChild } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnChanges {
  @Input() disabled = false;
  @Input() size;
  @Input() expand;
  @Input() background;
  @Input() type = 'button';
  @Input() shape = 'rounded';
  @Input() iconClass;
  @Input() iconAlign = 'left';
  @Input() iconBackground = 'circle';
  @Input() activeWhenDisabled = false;
  @Input() classes = [];
  @Input() spinner = false;
  @Output() onClick = new EventEmitter();

  @ViewChild('button', {static: false}) buttonEl;

  public iconShow = false;
  public iconClasses = [];
  public buttonClasses = [];

  constructor() { }

  ngOnChanges() {
    if (this.iconClass) {
      this.iconShow = true;
      this.iconClasses = ['button-icon', this.iconClass];
    }

    if (this.iconBackground && this.iconBackground !== 'none' && this.shape === 'rounded') {
      this.iconClasses.push('button-icon-background-' + this.iconBackground);
    }

    this.buttonClasses = [];

    if (this.classes !== null) {
      for (let i = 0; i < this.classes.length; i++) {
        this.buttonClasses.push(this.classes[i]);
      }
    }

    this.buttonClasses.push('button-' + this.shape);

    if (this.iconShow) {
      if (this.iconShow) {
        this.buttonClasses.push('button-with-icon');
      }
      if (this.iconAlign && this.shape === 'rounded') {
        this.buttonClasses.push('button-icon-align-' + this.iconAlign);
      }
    }

    if (this.disabled) {
      this.buttonClasses.push('button-disabled');
    }
    if (this.background) {
      this.buttonClasses.push('button-background-' + this.background);
    }
    if (this.spinner) {
      this.buttonClasses.push('button-spinner');
    }
  }

  /**
   * Button click handler
   * @param {Event} event
   */
  public buttonClick(event: Event) {
    if (this.buttonEl && this.buttonEl.nativeElement && this.buttonEl.nativeElement.blur) {
      this.buttonEl.nativeElement.blur(); // remove focus from button, so that it won't be triggered by space/enter key
    }

    if (this.disabled && !this.activeWhenDisabled) {
      event.stopPropagation();
    } else {
      this.onClick.emit();
    }
  }

}
