import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CollapsableService {
    private deviationSubject = new BehaviorSubject(0);
    public deviationCount = this.deviationSubject.asObservable();

    private serviceSubject = new BehaviorSubject(0);
    public serviceCount = this.serviceSubject.asObservable();

    private inspectionSubject = new BehaviorSubject(0);
    public inspectionCertificateCount = this.inspectionSubject.asObservable();

    constructor() {
    }

    deviations(val) {
        this.deviationSubject.next(val);
    }

    services(val) {
        this.serviceSubject.next(val);
    }

    inspections(val) {
        this.inspectionSubject.next(val);
    }
}