import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button-load-more',
  templateUrl: './button-load-more.component.html',
  styleUrls: ['./button-load-more.component.scss'],
})
export class ButtonLoadMoreComponent implements OnChanges {
  @Input() hasMore = false;
  @Input() isLoading = false;
  @Output() onLoad = new EventEmitter();

  public iconClass = 'far fa-sync-alt';

  constructor() { }

  ngOnChanges(changes) {
    if (changes.hasOwnProperty('isLoading')) {
      if (this.isLoading) {
        this.iconClass = 'far fa-sync-alt fa-spin';
      } else {
        this.iconClass = 'far fa-sync-alt';
      }
    }
  }

  loadMore() {
    this.onLoad.emit();
  }

}
