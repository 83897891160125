import { IRoundFilterSet } from '@interfaces';
import AbstractSubBuilder from '../abstract.sub-builder';
import SearchFilterBuilder from '../../search-filter.builder';

/** Base builder of object (unit/round) filter */
export default abstract class AbstractObjectBuilder<Filter extends IRoundFilterSet> extends AbstractSubBuilder<SearchFilterBuilder, Filter> {
  /** Include/exclude check tasks */
  private enabledChecks = false;
  /** Include/exclude inspections tasks */
  private enabledInspections = false;
  /** Include/exclude deviations tasks */
  private enabledDeviations = false;

  /** Identifiers of connected objects */
  private objectUids: string[] = [];

  /** Identifiers of connected workplaces */
  private workplaces: string[] = [];
  /** Identifiers of connected sections */
  private sections: string[] = [];

  /** Include check type tasks */
  public withChecks(): this {
    this.enabledChecks = true;
    return this;
  }

  /** Exclude check type tasks */
  public withoutChecks(): this {
    this.enabledChecks = false;
    return this;
  }

  /** Include inspections type tasks */
  public withInspections(): this {
    this.enabledInspections = true;
    return this;
  }

  /** Exclude inspections type tasks */
  public withoutInspections(): this {
    this.enabledInspections = false;
    return this;
  }

  /** Include deviation type tasks */
  public withDeviations(): this {
    this.enabledDeviations = true;
    return this;
  }

  /** Exclude deviations type tasks */
  public withoutDeviations(): this {
    this.enabledDeviations = false;
    return this;
  }

  /** Filter by related objects type tasks */
  public setObjects(uids: string[]): this {
    this.objectUids = uids;
    return this;
  }

  /** Filter by related Workplace type tasks */
  public setWorkplaces(workplaces: string[]): this {
    this.workplaces = workplaces;
    return this;
  }

  /** Filter by related Sections type tasks */
  public setSections(sections: string[]): this {
    this.sections = sections;
    return this;
  }

  /** @inheritDoc */
  public build(): Filter {
    const filter = {
      checks: this.enabledChecks,
      inspections: this.enabledInspections,
      deviations: this.enabledDeviations,
      deviation: { // Exclude rectified ones
        statusDeviation: true,
        statusMalfunctioning: true,
      }
    } as Filter;

    filter.objects = this.objectUids.map(id => ({ id }));
    filter.workplaces = this.workplaces.map(id => ({ id }));
    filter.sections = this.sections.map(id => ({ id }));
    filter.statusCheckNotOngoing = true;
    filter.statusCheckOngoing = true;
    filter.statusServiceNotOngoing = true;
    filter.statusServiceOngoing = true;
    return filter as Filter;
  }

  /** @inheritDoc */
  public reset(): this {
    this.enabledChecks = false;
    this.enabledInspections = false;
    this.enabledDeviations = false;
    this.objectUids = [];
    this.workplaces = [];
    this.sections = [];
    return this;
  }
}
