import { Injectable } from '@angular/core';
import { LoadingController, AlertController } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Subscription } from 'rxjs';
import { LangService } from './lang.service';
import { IProtocol, IUnit, IWorktimeCategory } from '@interfaces';
import { supportContacts } from '../constants/support-contacts';
// TODO Find an alternative to keep awake phone
// import {KeepAwake} from '@capacitor-community/keep-awake';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  public protocol: IProtocol;
  public categories = null;
  public refillTypes = null;
  public isLightTheme = true;
  public companyTags = null;
  public companyRfidTags = [];
  public worktimesCategories: IWorktimeCategory[] = [];

  public measurementSystem: 'metric' | 'imperial' = 'metric';
  public measurementUnits = {
    distance: '',
    volume: '',
    weight: '',
    length: '',
    amount: '',
    hour: '',
    area: ''
  };

  private isLoading = false;
  private loader;
  private loaderOpenCount = 0;

  private safeAreaTop = null;

  public isInitialized = new BehaviorSubject(false);
  private initializedSubscription: Subscription;
  private waitForAppInitPromise = null;

  static getSupportEmail() {
    return supportContacts.email;
  }

  static getSupportPhone() {
    return supportContacts.phone;
  }

  static getSupportPhoneFormatted() {
    return supportContacts.phoneFormatted;
  }

  constructor(
      private loadingController: LoadingController,
      private alertController: AlertController,
      private langService: LangService
  ) {
  }

  /**
   * Reset helper properties to initial state
   */
  public resetInstance(): void {
    this.categories = null;
    this.isLightTheme = true;
  }

  /**
   * Show page spinner/loader
   * @param {{}} options
   * @return {Promise<any>}
   */
  public async showLoader(options = {}): Promise<any> {
    this.loaderOpenCount++;

    if (!this.isLoading) {
      this.isLoading = true;
      Object.assign(options, {spinner: 'crescent'});
      this.loader = await this.loadingController.create(options);

      return await this.loader.present();
    } else {
      return Promise.resolve();
    }
  }

  /**
   * Hide page spinner/loader
   * @param {{}} options
   */
  public async dismissLoader(options = {}): Promise<any> {
    this.loaderOpenCount--;

    if (this.isLoading && this.loaderOpenCount <= 0) {
      this.isLoading = false;
      if (this.loader) {
        return await this.loader.dismiss(options);
      }
    }

    return Promise.resolve();
  }

  /**
   * Show alert window
   * @param options
   * @return {Promise<any>}
   */
  public async showAlert(options: any): Promise<any> {
    const alert = await this.alertController.create(options);
    await alert.present();
  }

  /**
   * Show generic alert dialog (with OK button)
   * @param header
   * @param {string} message
   * @return {Promise<any>}
   */
  public showAlertMessage(header, message = ''): Promise<any> {
    return new Promise<void>((resolve) => {
      this.showAlert({
        header: header,
        message: message || '',
        buttons: [
          {
            text: this.langService.t('general.ok'),
            handler: () => {
              resolve();
            }
          }
        ]
      });
    });
  }

  /**
   * Show confirm window
   * @param {any} message
   * @return {Promise}
   */
  public showConfirm(message = null) {
    return new Promise((resolve) => {
      this.showAlert({
        header: message || this.langService.t('general.confirm-message'),
        buttons: [
          {
            text: this.langService.t('general.no'),
            role: 'cancel',
            handler: () => {
              resolve(false);
            }
          },
          {
            text: this.langService.t('general.yes'),
            handler: () => {
              resolve(true);
            }
          }
        ]
      });
    });
  }

  /**
   * Set default text color for status bar
   */
  public setDefaultStatusBar() {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        StatusBar.setStyle({
          style: Style.Light
        });
      }
    }
  }

  /**
   * Set text color for status bar based on current branding color
   */
  public setBrandingStatusBar() {
    if (Capacitor.isNativePlatform()) {
      if (Capacitor.getPlatform() === 'ios') {
        StatusBar.setStyle({
          style: (this.isLightTheme) ? Style.Light : Style.Dark
        });
      }
    }
  }

  /**
   * Get proper measurement name by article measurement type
   * @param type
   * @return {any}
   */
  public getArticleMeasurement(type) {
    if (type === this.protocol.articleMeasurements.KILOGRAM) {
      return this.measurementUnits.weight;
    }
    if (type === this.protocol.articleMeasurements.METER) {
      return this.measurementUnits.length;
    }
    if (type === this.protocol.articleMeasurements.AMOUNT) {
      return this.measurementUnits.amount;
    }
    if (type === this.protocol.articleMeasurements.VOLUME) {
      return this.measurementUnits.volume;
    }
    if (type === this.protocol.articleMeasurements.DISTANCE) {
      return this.measurementUnits.distance;
    }
    if (type === this.protocol.articleMeasurements.AREA) {
      return this.measurementUnits.area;
    }
    if (type === this.protocol.articleMeasurements.HOUR) {
      return this.measurementUnits.hour;
    }
    return null;
  }

  /**
   * Get odometer suffix based on type
   * @param obj
   * @param {boolean} isDivision
   * @return {string}
   */
  public getOdometerSuffix(obj: any | IUnit, isDivision = false) {
    if (!obj) {
      return '';
    }

    let returnVal = '--';

    if (obj.hasOwnProperty('standard_odometer')) { // Unit
      if (!(obj.standard_odometer instanceof Object)) { // New unit (without odometer params)
        return returnVal;
      }

      returnVal = (
          ([
              this.protocol.odometers.measurementUnits.all.KILOMETERS,
              this.protocol.odometers.measurementUnits.all.MILES
            ].includes(obj.standard_odometer.measurement_units) // is Distance
            && isDivision
          ) ? '100 ' : '') // Prefix
        + this.getOdometerModelSuffix(obj.standard_odometer);
    } else if (obj.hasOwnProperty('type') && obj.hasOwnProperty('suffix')) { // Odometer
      returnVal = this.getOdometerModelSuffix(obj);
    } else if (obj.hasOwnProperty('interval_value') && obj.hasOwnProperty('odometer_suffix')) { // ServiceDate
      returnVal = obj.odometer_suffix || '';
    }

    if (isDivision && returnVal !== '') {
      returnVal = '/' + returnVal;
    }

    return returnVal;
  }

  /**
   * Get unbinded categories list
   * @return {any}
   */
  public getCategories() {
    return cloneDeep(this.categories); // return deeply cloned array of objects
  }

  /**
   * Get device safe area top value
   * @return {any}
   */
  public getSafeAreaTop() {
    if (this.safeAreaTop !== null) {
      return this.safeAreaTop;
    }

    const style = window.getComputedStyle(document.body);
    const safeAreaTop = style.getPropertyValue('--ion-safe-area-top');

    if (safeAreaTop) {
      this.safeAreaTop = parseInt(safeAreaTop, 10);
    }

    if (isNaN(this.safeAreaTop)) {
      this.safeAreaTop = 0;
    }

    return this.safeAreaTop;
  }

  /**
   * Set app initialized
   */
  public setInitialized() {
    this.isInitialized.next(true);
    console.log('helper.service.ts this.isInitialized.getValue() : ', this.isInitialized.getValue());
  }

  /**
   * Return promise that will be resolved when app is fully initialized
   * @return {Promise<any>}
   */
  public async waitForAppInit(): Promise<any> {
    if (this.isInitialized.getValue()) {  // when app already is initialized
      return Promise.resolve(true);
    }

    if (this.waitForAppInitPromise) {
      console.log('helper.service.ts if (this.waitForAppInitPromise)');
      return await this.waitForAppInitPromise;
    }
    const resolver = (resolve: (result: any) => void, result: any) => {
      resolve(result);
    };
    const rejecter = (reject: (error: any) => void, error: any) => {
      reject(error);
      this.initializedSubscription.unsubscribe();
    };

    // We save the promise to avoid new initializedSubscription when this.isInitialized.getValue() return false
    this.waitForAppInitPromise = new Promise((resolve, reject) => {
      this.initializedSubscription = this.isInitialized.subscribe(
          result => {
            if (result) {
              resolver(resolve, result);
            }
          },
          error => {
            console.error('helper.service.ts initializedSubscription error :', error);
            rejecter(reject, error);
          }
      );
    });

    return this.waitForAppInitPromise;
  }

  /**
   * Checks if current company has list of tags
   */
  public hasCompanyTags() {
    return this.companyTags && this.companyTags.length > 0;
  }

  /**
   * Get odometer suffix.
   *
   * @param odometer
   * @private
   */
  private getOdometerModelSuffix(odometer): null | string {
    return odometer.measurement_units
      ? this.langService.t(`odometers.measurement_units.short.${odometer.measurement_units}`)
      : odometer.suffix;
  }

  /**
   * Get get Worktimes Categories list (Types & Activities)
   */
  public getWorktimesCategories() {
    return this.worktimesCategories;
  }


  public async allowSleepMode() {
    // const isKeepAwakeSupported = await KeepAwake.isSupported();
    // const isKeptAwake = await KeepAwake.isKeptAwake();
    // if (isKeepAwakeSupported.isSupported && isKeptAwake.isKeptAwake) {
    //   await KeepAwake.allowSleep();
    // }
  }

  public async allowAwakeMode() {
  //   const isKeepAwakeSupported = await KeepAwake.isSupported();
  //   const isKeptAwake = await KeepAwake.isKeptAwake();
  //   if (isKeepAwakeSupported.isSupported && !isKeptAwake.isKeptAwake) {
  //     await KeepAwake.keepAwake();
  //   }
  }
}
