import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { BehaviorSubject } from 'rxjs';
import { Capacitor } from '@capacitor/core';

import { EnvironmentService } from './environment.service';
import { ModalService } from './modal.service';
import { LangService } from './lang.service';
import {add, format} from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class AppUpdaterService {

  private readonly currentVersion;
  private nextAlertTimestamp;

  public forcedUpdateMessageAlreadyShown = false;
  public hasNewerVersion$ = new BehaviorSubject({
    newVersion: false,
    forcedUpdate: false
  });

  static compareVersion(v1, v2) {
    if (typeof v1 !== 'string' || typeof v2 !== 'string') {
      return false;
    }

    v1 = v1.split('.');
    v2 = v2.split('.');
    const k = Math.min(v1.length, v2.length);
    for (let i = 0; i < k; ++ i) {
      v1[i] = parseInt(v1[i], 10);
      v2[i] = parseInt(v2[i], 10);
      if (v1[i] > v2[i]) {
        return 1;
      }
      if (v1[i] < v2[i]) {
        return -1;
      }
    }

    // 0 = same version
    // -1 = v1 older than v2
    // 1 = v1 newer to v2
    return v1.length === v2.length ? 0 : (v1.length < v2.length ? -1 : 1);
  }

  constructor(
    private environmentService: EnvironmentService,
    private modalService: ModalService,
    private storage: Storage,
    private langService: LangService
  ) {
    const environment = this.environmentService.getEnvironment();
    this.currentVersion = environment.appVersion;
    this.storage.create().then(storage => {
      storage.get('appNewVersionNextAlert').then((appNewVersionNextAlert) => {
        if (appNewVersionNextAlert) {
          this.nextAlertTimestamp = appNewVersionNextAlert;
        }
      });
    });
  }

  public checkVersion(version, minimumVersion): {
    newVersion: boolean,
    forcedUpdate: boolean
  } {
    const result = {
      newVersion: this.isNewerVersion(version),
      forcedUpdate: this.isNewerVersion(minimumVersion)
    };

    this.hasNewerVersion$.next(result);
    return result;
  }

  public showNewVersionMessage() {
    // if alert was shown before and it is postponed to show it later
    if (this.nextAlertTimestamp) {
      const currentTimeStamp = Date.now();
      if (this.nextAlertTimestamp && this.nextAlertTimestamp > currentTimeStamp) {
        return;
      }
    }

    const linkForUpdate = Capacitor.getPlatform() === 'ios' ? this.langService.t('app.app-store-link')
        : this.langService.t('app.play-market-link');

    const storeName = Capacitor.getPlatform() === 'ios' ? this.langService.t('app.app-store') :
        this.langService.t('app.play-market');

    this.modalService.showDialog({
      title: this.langService.t('app.new-version-announcement-title'),
      message: this.langService.t('app.new-version-announcement-body', { storeName: storeName }),
      buttons: [
        {
          text: this.langService.t('app.update-skip-button'),
          iconClass: 'fal fa-ban color-danger',
          value: false
        },
        {
          text: this.langService.t('app.update-button'),
          iconClass: `fal fa-check-circle color-success`,
          value: true,
        }
      ]
    }).then((decision) => {
      if (decision) {
        window.open(linkForUpdate, '_system', 'location=yes');
      }
    });

    this.nextAlertTimestamp = format(add(new Date(), { days: 7 }), 'T');

    return this.storage.set('appNewVersionNextAlert', this.nextAlertTimestamp);
  }

  public showForcedUpdateMessage() {
    if (this.forcedUpdateMessageAlreadyShown) {
      return;
    }

    const linkForUpdate = Capacitor.getPlatform() === 'ios' ? this.langService.t('app.app-store-link')
        : this.langService.t('app.play-market-link');

    this.modalService.showDialog({
      title: this.langService.t('app.forced-update-title'),
      message: this.langService.t('app.forced-update-body'),
      buttons: [
        {
          text: this.langService.t('app.update-button'),
          iconClass: `fal fa-check-circle color-success`,
          value: true,
        }
      ]
    }, false).then((decision) => {
      if (decision) {
        window.open(linkForUpdate, '_system', 'location=yes');
        this.forcedUpdateMessageAlreadyShown = false;
        this.showForcedUpdateMessage();
      } else {
        this.forcedUpdateMessageAlreadyShown = false;
      }
    });

    this.forcedUpdateMessageAlreadyShown = true;
  }

  private isNewerVersion(version) {
    const compare = AppUpdaterService.compareVersion(version, this.currentVersion);
    return compare === 1;
  }

  public isSameVersion(version) {
    const compare = AppUpdaterService.compareVersion(version, this.currentVersion);
    return compare === 0;
  }
}
