<div class="select-dropdown sd-select-single"
     [class.sd-select-single]="!multiple"
     [class.sd-select-multiple]="multiple"
     [class.sd-select-disabled]="disabled"
     [class.input-has-error]="isInvalid">
  <div class="sd-select-container"
       tappable
       (click)="toggleDropdown()"
       [class.sd-select-opened]="isDropdownOpen"
       [class.sd-has-value]="hasValue">
    <div class="sd-value-container" [class.sd-show-overflow]="showOverflow">
      <div class="sd-placeholder" *ngIf="!selectedOptions?.length">{{placeholder}}</div>

      <div class="sd-values-list" *ngIf="selectedOptions?.length > 0">
        <div class="sd-value" *ngFor="let option of selectedOptions" [ngStyle]="getSelectedOptionStyles(option)">
          <span *ngIf="!multiple">{{option.title}}</span>
          <span *ngIf="multiple" (click)="unselectMultiOption($event, option)" class="sd-value-inner" tappable>
            <i class="fal fa-times"></i>
            <span class="text-ellipsis">{{option.title}}</span>
          </span>
        </div>
      </div>
    </div>

    <span *ngIf="isClearable()" (click)="clearSelected($event)" class="sd-icon sd-clear-wrapper" tappable>
      <i class="fal fa-times"></i>
    </span>

    <span *ngIf="!isClearable()" class="sd-icon sd-arrow-wrapper">
      <i class="far fa-angle-down"></i>
    </span>
  </div>

  <app-select-dropdown-panel *ngIf="isDropdownOpen"
                             [sdService]="sdService"
                             [items]="(isItemsPromise ? (items | async) : items)"
                             [groupBy]="groupBy"
                             [selectedOptions]="selectedOptions"
                             [searchable]="searchable"
                             [typeahead]="searchTypeahead"
                             [isLoading]="isLoading"
                             [itemContentTmpl]="itemContentTmpl"
                             [multiple]="multiple"
                             [notFoundText]="notFoundText"
                             [addTag]="addTag"
                             [addTagText]="addTagText"
                             [hasInitialItems]="hasInitialItems"
                             [typeToSearchText]="typeToSearchText"
                             [isFiltered]="isFiltered"
                             [hideSelected]="hideSelected"
                             [showOverflow]="showOverflow"
                             [restrictNumberOfSelectedItem]="restrictNumberOfSelectedItem"
                             (closeDropdown)="closeDropdown()"
                             (selectOption)="selectOption($event)"
                             (unselectOption)="unselectOption($event)"
                             (scrollChange)="scrolled()">
  </app-select-dropdown-panel>
</div>
