import { TimeUnitEnum } from '@enums';

/** Map between PT units & Date-FNS units */
export const MAP_UNITS = {
    [TimeUnitEnum.YEARS]: 'years',
    [TimeUnitEnum.MONTHS]: 'months',
    [TimeUnitEnum.WEEKS]: 'weeks',
    [TimeUnitEnum.DAYS]: 'days',
    [TimeUnitEnum.HOURS]: 'hours',
    [TimeUnitEnum.MINUTES]: 'minutes',
};
